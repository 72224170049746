import React, { useState, useEffect, CSSProperties } from 'react';
import { PersonStatus, TestStatus, ValidationState } from '../../../constants/Interfaces';
import { useValidatedField, validate } from '../../../constants/Validators';
import { DateInput, SelectInput, TextInput, Text, TextArea, ButtonPrimary } from '../../../components';
import { getActiveReferenceOptions, getMultiReferenceLabel, getReferenceLabel } from '../../../constants/Utils';
import moment from 'moment';
import { filterAntigenOptions } from '../../../constants/UtilsTests';
import { ScreenMode } from '../PersonScreen';
import _ from 'lodash';

export interface TestUpdate {
  testResultId: number;
  lineResultId?: number;
  field: string;
}

export const DateCell = ({ value: initialValue, row, column }) => {
  const field = useValidatedField(initialValue, column.id);
  const [previousValue, setPreviousValue] = useState(row);
  if (initialValue !== previousValue) {
    field.setValue(initialValue);
    setPreviousValue(initialValue);
  }
  const { original } = row;

  const onChange = (e) => {
    field.setValue(e);
    const updateObject: TestUpdate = { testResultId: original.testResultId, field: column.id };
    column.updateFunction(updateObject)(e);
  };

  const onBlur = () => {
    validate(field, field.value, column.validators);
  };

  if (original.first) {
    if (original.mode === ScreenMode.EDIT) {
      return (
        <DateInput
          label=""
          onValueChange={onChange}
          value={field.value}
          shouldShowLabel={false}
          shouldShowIcon={false}
          onBlur={onBlur}
          validationState={field.validation}
          id={`date${original.testResultId}`}
          style={column.style}
          inputClassName="noPadding"
        />
      );
    }
    return (
      <Text className="input" style={column.style}>
        {moment(field.value).format('DD/MM/YYYY')}
      </Text>
    );
  }
  return null;
};

export const MultiDropdownCell = ({ value: initialValue, row, column }) => {
  const [value, setValue] = useState(initialValue);
  const [previousValue, setPreviousValue] = useState();
  if (initialValue !== previousValue) {
    setValue(initialValue);
    setPreviousValue(initialValue);
  }

  const { original } = row;
  const lineResultId = original.antiBodyResultId || original.phenotypeResultId;

  const originalValueArray = [];
  _.each(original[`${column.id}Original`], (originalValue) => {
    originalValueArray.push(originalValue);
  });

  const onChange = (e) => {
    setValue(e);
    const updateObject: TestUpdate = {
      testResultId: original.testResultId,
      lineResultId,
      field: column.id,
    };
    column.updateFunction(updateObject)(e);
  };

  if (original.mode === ScreenMode.EDIT) {
    return (
      <SelectInput
        label=""
        onValueChange={onChange}
        defaultValue={value}
        value={value}
        getOptionValue={(option) => option.id}
        options={getActiveReferenceOptions(column.selectOptions, originalValueArray)}
        shouldShowLabel={false}
        shouldShowIcon={false}
        isMulti
        menuPlacement="top"
        style={column.style}
        maxMenuHeight={320}
        id={`${column.id}-${original.testResultId}-${lineResultId}`}
      />
    );
  }
  return (
    <Text className="input" style={{ textAlign: 'left', display: 'inline-block', width: '100%', ...column.style }}>
      {getMultiReferenceLabel(column.selectOptions, value)}
    </Text>
  );
};

// Special verison of the Multidropdown cell that deals with filtering the antigen values
export const AntigenMultiDropdownCell = ({ value: initialValue, row, column }) => {
  const [filteredOptions, setFilteredOptions] = useState(column.selectOptions);
  const field = useValidatedField(initialValue, column.id);
  const [previousValue, setPreviousValue] = useState();
  if (initialValue !== previousValue) {
    field.setValue(initialValue);
    setPreviousValue(initialValue);
    setFilteredOptions(filterAntigenOptions(initialValue, column.selectOptions));
  }
  const { original } = row;
  const lineResultId = original.antiBodyResultId || original.phenotypeResultId;

  const originalValueArray = [];
  _.each(original[`${column.id}Original`], (value) => {
    originalValueArray.push(value);
  });

  const onChange = (e) => {
    field.setValue(e);
    const updateObject: TestUpdate = {
      testResultId: original.testResultId,
      lineResultId,
      field: column.id,
    };
    column.updateFunction(updateObject)(e);
    validate(field, e, column.validators);
  };

  const onBlur = () => {
    validate(field, field.value, column.validators);
  };

  if (original.mode === ScreenMode.EDIT) {
    return (
      <SelectInput
        label=""
        onValueChange={onChange}
        onBlur={onBlur}
        defaultValue={field.value}
        value={field.value}
        validationState={field.validation}
        getOptionValue={(option) => option.id}
        options={getActiveReferenceOptions(filteredOptions, originalValueArray)}
        shouldShowLabel={false}
        shouldShowIcon={false}
        isMulti
        menuPlacement="top"
        style={column.style}
        maxMenuHeight={320}
        filterOption={filterAntigenList}
        id={`${column.id}-${original.testResultId}-${lineResultId}`}
      />
    );
  }
  return (
    <Text className="input" style={{ textAlign: 'left', display: 'inline-block', width: '100%', ...column.style }}>
      {getMultiReferenceLabel(column.selectOptions, field.value)}
    </Text>
  );
};
const trimString = (str) => str.replace(/^\s+|\s+$/g, '');
// I went and librially pinched code from react-select filters.js, as that is what this function replaces.
// we want to keep: search from the start of the field and case sensitive search options, while also filtering out disabled options.
const filterAntigenList = (option: { label; value; data }, inputValue) => {
  if (option.data.isDisabled) {
    return false;
  }
  const input = trimString(inputValue);
  const candidate = trimString(`${option.label} ${option.value}`);
  return candidate.substr(0, input.length) === input;
};

export const DropdownCell = ({ value: initialValue, row, column }) => {
  const field = useValidatedField(initialValue, column.id);
  const [previousValue, setPreviousValue] = useState();
  if (initialValue !== previousValue) {
    field.setValue(initialValue);
    setPreviousValue(initialValue);
  }
  const { original } = row;

  const originalValue = original[`${column.id}Original`];
  const lineResultId = original.antiBodyResultId || original.phenotypeResultId;

  if (column.firstRowOnly && !original.first) {
    return null;
  }

  const onChange = (e) => {
    field.setValue(e);
    if (column.firstRowOnly) {
      const updateObject: TestUpdate = {
        testResultId: original.testResultId,
        field: column.id,
      };
      column.updateFunction(updateObject)(e);
    } else {
      const updateObject: TestUpdate = {
        testResultId: original.testResultId,
        lineResultId,
        field: column.id,
      };
      column.updateFunction(updateObject)(e);
    }
    validate(field, e, column.validators);
  };

  const onBlur = () => {
    validate(field, field.value, column.validators);
  };

  const editable = column.editable === undefined ? true : column.editable;

  if (original.mode === ScreenMode.EDIT && editable) {
    return (
      <SelectInput
        label=""
        onValueChange={onChange}
        onBlur={onBlur}
        value={field.value}
        validationState={field.validation}
        onValueChangeFormat={(result) => result?.id}
        getOptionValue={(option) => option.id}
        findValueField="id"
        options={getActiveReferenceOptions(column.selectOptions, [originalValue])}
        shouldShowLabel={false}
        shouldShowIcon={false}
        searchIgnoreCase={column.searchIgnoreCase}
        menuPlacement="top"
        id={`${column.id}-${original.testResultId}-${lineResultId}`}
        style={column.style}
        maxMenuHeight={320}
      />
    );
  }
  return (
    <Text className="input" style={{ textAlign: 'left', display: 'inline-block', width: '100%', ...column.style }}>
      {getReferenceLabel(column.selectOptions, field.value)}
    </Text>
  );
};

export const TextCell = ({ value: initialValue, row, column }) => {
  const field = useValidatedField(initialValue, column.id);
  const [previousValue, setPreviousValue] = useState();
  if (initialValue !== previousValue) {
    field.setValue(initialValue);
    setPreviousValue(initialValue);
  }
  const { original } = row;

  if (column.firstRowOnly && !original.first) {
    return null;
  }

  const { keyAttribute = 'testResultId' } = column;

  const onChange = (e) => {
    field.setValue(e);
    if (column.firstRowOnly) {
      const updateObject: TestUpdate = {
        testResultId: original[keyAttribute],
        field: column.id,
      };
      column.updateFunction(updateObject)(e);
    } else {
      const updateObject: TestUpdate = {
        testResultId: original[keyAttribute],
        lineResultId: original.antiBodyResultId || original.phenotypeResultId,
        field: column.id,
      };
      column.updateFunction(updateObject)(e);
    }

    if (column.validators) {
      validate(field, e, column.validators, original);
    }
  };

  const onBlur = () => {
    if (column.validators) {
      validate(field, field.value, column.validators, original);
    }
  };

  if (original.mode === ScreenMode.EDIT) {
    let finalValidationState = field.validation;
    if (finalValidationState !== ValidationState.FAILED) {
      if (column.id === 'hospitalNumber') {
        finalValidationState = field.value && original.donationNumber ? ValidationState.FAILED : ValidationState.PASSED;
      }
      if (column.id === 'donationNumber') {
        finalValidationState = field.value && original.hospitalNumber ? ValidationState.FAILED : ValidationState.PASSED;
      }
    }
    return (
      <TextInput
        label=""
        onValueChange={onChange}
        onBlur={onBlur}
        value={field.value || ''}
        shouldShowLabel={false}
        shouldShowIcon={false}
        style={{ ...column.style, ...column.styleEdit }}
        maxLength={column.maxLength}
        validationState={finalValidationState}
      />
    );
  }
  return (
    <Text
      className="input"
      style={{ textAlign: 'left', wordBreak: 'break-word', ...column.style, ...column.styleView }}
    >
      {field.value}
    </Text>
  );
};

export const NumberCell = ({ value: initialValue, row, column }) => {
  const field = useValidatedField(initialValue, column.id);
  useEffect(() => {
    field.setValue(initialValue || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);
  const { original } = row;

  const onChange = (e) => {
    field.setValue(e);
    const updateObject: TestUpdate = {
      testResultId: original.testResultId,
      lineResultId: original.antiBodyResultId || original.phenotypeResultId,
      field: column.id,
    };
    column.updateFunction(updateObject)(e);

    if (column.validators) {
      validate(field, e, column.validators, original);
    }
  };

  const onBlur = () => {
    const formattedValue = column.formatBlurFunction ? column.formatBlurFunction(field.value) : field.value;
    if (field.value.toString() !== formattedValue.toString()) {
      field.setValue(formattedValue);
      const updateObject: TestUpdate = {
        testResultId: original.testResultId,
        lineResultId: original.antiBodyResultId || original.phenotypeResultId,
        field: column.id,
      };
      column.updateFunction(updateObject)(formattedValue);

      if (column.validators) {
        validate(field, field.value, column.validators, original);
      }
    }
  };

  if (original.mode === ScreenMode.EDIT) {
    return (
      <TextInput
        label=""
        onValueChange={onChange}
        value={field.value}
        shouldShowLabel={false}
        shouldShowIcon={false}
        onBlur={onBlur}
        style={column.style}
        validationState={field.validation}
      />
    );
  }
  return (
    <Text className="input" style={{ textAlign: 'left', ...column.style }}>
      {column.formatViewFunction ? column.formatViewFunction(field.value) : field.value}
    </Text>
  );
};

export const FirstFieldTextCell = ({ value: initialValue, row, column }) => {
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue || '');
  }, [initialValue]);
  const { original } = row;

  if (original.first) {
    const onChange = (e) => {
      setValue(e);
      const updateObject: TestUpdate = { testResultId: original.testResultId, field: column.id };
      column.updateFunction(updateObject)(e);
    };

    if (original.mode === ScreenMode.EDIT) {
      return (
        <TextArea
          label=""
          onValueChange={onChange}
          value={value}
          shouldShowLabel={false}
          shouldShowIcon={false}
          style={{ ...column.style, height: 23, display: 'flex', marginTop: 0, overflow: 'visible' }}
          inputStyle={{ height: 40, resize: 'none' }}
          maxLength={column.maxLength || 100}
        />
      );
    }
    return (
      <Text className="input" style={{ textAlign: 'left', wordBreak: 'break-word', ...column.style }}>
        {value}
      </Text>
    );
  }
  return null;
};

/**
 *  Rendering functions
 */
interface RenderStatusButtonPermissions {
  canSubmitTest: boolean;
  canVerifyTest: boolean;
  viewOnly?: boolean;
}
function renderStatusButton(row, isEditing, personStatus, saveStatus, permissions: RenderStatusButtonPermissions) {
  const { original } = row;
  const tdStyle: CSSProperties = {
    textAlign: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    height: 25,
    width: 115,
    paddingLeft: 0,
    paddingRight: 0,
  };
  if (!isEditing) {
    if (
      !permissions?.viewOnly &&
      permissions?.canSubmitTest &&
      (original.status === TestStatus.DRAFT || original.status === TestStatus.UPDATED)
    ) {
      return (
        <td>
          <ButtonPrimary
            onClick={saveStatus(
              original.status === TestStatus.DRAFT
                ? TestStatus.PENDING_VERIFICATION
                : TestStatus.UPDATED_PENDING_VERIFICATION,
              original.testResultId
            )}
            title="Submit"
            buttonClass="clay-outline button-small printHide"
            containerStyle={tdStyle}
          />
        </td>
      );
    }
    if (
      permissions?.canVerifyTest &&
      (original.status === TestStatus.PENDING_VERIFICATION ||
        original.status === TestStatus.UPDATED_PENDING_VERIFICATION)
    ) {
      return (
        <td>
          <ButtonPrimary
            onClick={saveStatus(TestStatus.VERIFIED, original.testResultId)}
            title="Verify"
            buttonClass="clay-outline button-small printHide"
            containerStyle={tdStyle}
            disabled={personStatus !== PersonStatus.VERIFIED || original.canVerify !== 1}
            tooltip={
              personStatus === PersonStatus.VERIFIED && original.canVerify === 1
                ? ''
                : getToolTipMessage(personStatus, original.canVerify)
            }
          />
        </td>
      );
    }
  }
  return <td />;
}
function renderAddRowButton(original, addRow) {
  if (addRow) {
    return (
      <td className="flex">
        <div
          onClick={addRow(original.testResultId)}
          className="link"
          style={{ textAlign: 'center', margin: '0 auto', height: 25 }}
        >
          Add
        </div>
      </td>
    );
  }
  return <td />;
}

function getToolTipMessage(personStatus, canVerify) {
  if (personStatus !== PersonStatus.VERIFIED && canVerify !== 1) {
    return 'Person record must be verified before test records & verification must be performed by a second operator';
  }
  if (personStatus !== PersonStatus.VERIFIED) {
    return 'Person record must be verified before test records';
  }
  return 'verification must be performed by a second operator';
}

// The row inserted after every last row to display controls or just a spacer between sample dates
export function renderSubRow(
  columns,
  isEditing,
  saveStatus,
  personStatus,
  permissions: RenderStatusButtonPermissions,
  addRow?
) {
  return function ({ row }) {
    const { original } = row;
    if (original.last) {
      return (
        <tr className={original.className}>
          {original.mode === ScreenMode.EDIT && <td />}
          {original.mode === ScreenMode.EDIT && renderAddRowButton(original, addRow)}
          {original.mode === ScreenMode.VIEW &&
            renderStatusButton(row, isEditing, personStatus, saveStatus, permissions)}
          {columns.map((_column, i) => {
            // render one 25px height td per column, -2 when viewing, -3 when editing
            // the minuses are for the special styles rows at the end
            if (
              (original.mode === ScreenMode.VIEW && i < columns.length - 3) ||
              (original.mode === ScreenMode.EDIT && i < columns.length - 4)
            ) {
              return (
                <td key={`row-${original.id}-${i}`}>
                  <div style={{ height: 25 }} />
                </td>
              );
            }
            return null;
          })}
          <td className="no-border-right" />
          <td className="transparent-header" />
        </tr>
      );
    }
    return null;
  };
}
