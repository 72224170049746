import React, { useEffect, useState } from 'react';
import './PersonScreen.scss';
import { Header, Spinner, Text } from '../../components';
import Logger from '../../constants/Logger';
import { useParams, useHistory } from 'react-router-dom';
import { personActions } from '../../reducers/person';
import { personResultsActions } from '../../reducers/personResults';
import {
  AppError,
  BloodGroup,
  GenericErrorCode,
  Permission,
  Person,
  PersonErrorCode,
  PersonErrorName,
  PersonType,
  ValidationState,
} from '../../constants/Interfaces';
import PersonView from './PersonView';
import PersonEdit from './PersonEdit';
import 'react-tabs/style/react-tabs.css';
import { referenceActions } from '../../reducers/reference';
import { determinePersonType } from '../../constants/Utils';
import { selectPermission } from '../../constants/Selectors';
import ErrorScreen from '../ErrorScreen';
import { Messages, PersonFieldLabels } from '../../constants/Messages';
import TestTabs from './components/TestTabs';
import { toast, ToastContainer } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { validationErrorMessages } from '../../constants/Validators';
import { dashboardActions } from '../../reducers/dashboard';
import PersonBar from './components/PersonBar';

const LOG_PREFIX = 'Screen -> PersonScreen ->';

export enum ScreenMode {
  VIEW,
  EDIT,
}

interface Props {
  mode: ScreenMode;
  type?: PersonType;
}

export default function PersonScreen(props: Props) {
  const { abrNumber } = useParams();
  const [loading, setLoading] = useState(true);
  const [errorOccured, setErrorOccured] = useState(false);
  const [person, setPerson] = useState<Person>();

  const [scrollMode, setScrollMode] = useState(false);
  const [printMode, setPrintMode] = useState(false);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const canModifyPerson = useAppSelector(selectPermission(Permission.canModifyPerson));

  const personError: AppError = useAppSelector((state) => state.person.error);
  // load person
  useEffect(() => {
    async function getPerson() {
      let currentPerson = null;
      try {
        setLoading(true);
        if (abrNumber) {
          currentPerson = await dispatch(personActions.getPerson(abrNumber));
          dispatch(personResultsActions.getPersonResults(currentPerson.personId));
          setPerson(currentPerson);
          setErrorOccured(false);
        } else {
          await dispatch(personActions.newPerson());
        }
      } catch (e) {
        Logger.error(`${LOG_PREFIX} unable to get person`, e);
        setErrorOccured(true);
      } finally {
        setLoading(false);
      }
    }
    getPerson();
  }, [abrNumber, dispatch, history]);
  // Important react hooks safety tip. useSelector might not update as fast as you think.
  // If you are refreshing redux on the same page, instead start with a null state object instead of a selector
  // otherwise you might load the last thing in the store

  // however still keep this for looking up the saved edits
  const personStore: Person = useAppSelector((state) => state.person.currentPerson);
  useEffect(() => {
    if (!loading) {
      setPerson(personStore);
    }
  }, [loading, personStore]);

  const bloodGroups: BloodGroup[] = useAppSelector((state) => state.reference.bloodGroups);

  // load bloodGroup if it is somehow not loaded already (Maybe the login call timed out)
  // TODO: there is a gap between where useAppSelector has not retrived the blood groups and the loading flag has not been lowered
  // if the useAppSelector took longer than the getPerson api call (very unlikly) this might be an issue
  useEffect(() => {
    async function getReferences() {
      try {
        await dispatch(referenceActions.getReference());
      } catch (e) {
        Logger.error(`${LOG_PREFIX} unable to get reference data`, e);
        setErrorOccured(true);
      }
    }
    if (!loading && !bloodGroups) {
      getReferences();
    }
  }, [loading, bloodGroups, dispatch]);

  const [editMode, setEditMode] = useState(props.mode === ScreenMode.EDIT);

  function handleEdit() {
    setEditMode(true);
    window.scroll(0, 0);
  }

  function handleCancel() {
    if (person && person.personId) {
      setEditMode(false);
    } else {
      history.push('/');
    }
  }

  function handleSave(newAbrNumber?) {
    dispatch(dashboardActions.resetDashboardPage());
    // Edit patch does not return an id, but patch won't change the id either
    if (newAbrNumber && abrNumber?.toString() !== newAbrNumber?.toString()) {
      // redirect to new url to start an api call to get the latest data
      history.push(`/person/${newAbrNumber}`);
    }
    setEditMode(false);
    window.scroll(0, 0);
  }

  function handleSaveError(error, fields?) {
    if (error.response) {
      // Scenario: server error
      Logger.error(`${LOG_PREFIX} handleSaveError: Server error in saving person`, error);
      switch (error.response.status) {
        case GenericErrorCode.PERMISSION_ERROR: {
          Logger.debug(`${LOG_PREFIX} handleSaveError 403: ${error.response}`);
          toast(Messages.ERROR_403_API);
          break;
        }
        case PersonErrorCode.BAD_DATA: {
          switch (error.response.data.errorCode) {
            case PersonErrorName.DUPLICATE_HOSPITAL_NUMBER:
              Logger.debug(`${LOG_PREFIX} handleSaveError: ${error.response}`);
              toast(Messages.PERSON_SCREEN_HOSPITAL_DUPLICATE);
              if (fields) {
                fields.hospitalNumber.setValidation(ValidationState.FAILED);
                fields.hospitalNumber.setErrorMessage(Messages.ERROR_DUPLICATE_HOSPITAL_NUMBER);
              }
              break;
            case PersonErrorName.INVALID_REQUEST:
              // Is also caught in the reducer
              Logger.debug(`${LOG_PREFIX} user saved an edit without changing anything`);
              handleSave(person.abrNumber);
              break;
            default:
              Logger.debug(`${LOG_PREFIX} handleSaveError: ${error.response}`);
              // attempt to get the error field
              if (error.response.data.field && fields) {
                const field = error.response.data.field.includes('address') ? 'address' : error.response.data.field;
                fields[field].setValidation(ValidationState.FAILED);
                fields[field].setErrorMessage(validationErrorMessages[field]);
              }
              toast(Messages.ERROR_API_VALIDATION_GENERIC);
              break;
          }
          break;
        }

        case PersonErrorCode.MATCHING_ERROR: {
          Logger.debug(`${LOG_PREFIX} handleSaveError: ${error.response.data.fields}`);
          toast(Messages.PERSON_SCREEN_HOSPITAL_DUPLICATE);
          break;
        }
        default: {
          // generic server error, show snack bar and let user try again.
          Logger.debug(`${LOG_PREFIX} -> handleSaveError: Generic server error`);
          toast(Messages.ERROR_GENERIC);
          break;
        }
      }
    } else if (error.request) {
      // Scenario: network error
      Logger.error(`${LOG_PREFIX} -> handleSaveError: Network error`, error);
      // network error, show snack bar and let user try again.
      toast(Messages.ERROR_GENERIC);
    } else {
      // Scenario: application error
      Logger.error(`${LOG_PREFIX} -> handleSaveError: Application error occurred`, error);
      toast(Messages.ERROR_GENERIC);
    }
  }

  function handlePDF() {
    setPrintMode(true);
    setTimeout(() => {
      // eslint-disable-next-line no-restricted-globals
      print();
      setPrintMode(false);
    });
  }

  if (loading) {
    return (
      <div className="background">
        <Header />
        <Spinner />
      </div>
    );
  }

  // if a 403 error is in the person reducer error object.
  if (personError?.code === '403') {
    return <ErrorScreen message={Messages.ERROR_403_SCREEN} hideButton />;
  }
  // if another error occured from the loading process
  if (errorOccured) {
    return <ErrorScreen message={Messages.ERROR_GENERIC_API} />;
  }

  if (!canModifyPerson && props.mode === ScreenMode.EDIT) {
    return <ErrorScreen message={Messages.ERROR_403_SCREEN} hideButton />;
  }

  const type = determinePersonType(person, props.type);

  return (
    <div className="person-screen background">
      <Header />
      <PersonBar
        person={person}
        type={type}
        editMode={editMode}
        onEdit={handleEdit}
        onCancel={handleCancel}
        onPDF={handlePDF}
      />
      {!editMode ? (
        <>
          <PersonView
            person={person}
            bloodGroups={bloodGroups}
            scrollMode={scrollMode}
            handleSave={handleSave}
            handleSaveError={handleSaveError}
          />
          {(person?.rhdGenotype || person?.heaGenotype || person?.rhceGenotype || person?.genotypeComments) && (
            <div className="printShow printNoBreak marginNarrowTop">
              <Text className="label">{PersonFieldLabels.GENOTYPE_COMMENTS}</Text>
              <Text style={{ wordBreak: 'break-word', color: 'black' }}>{person?.genotypeComments}</Text>
            </div>
          )}
          <div className="printShow printNoBreak marginNarrowTop">
            <Text className="label">{PersonFieldLabels.COMMENTS}</Text>
            <Text style={{ wordBreak: 'break-word', color: 'black' }}>{person?.personComments}</Text>
          </div>
        </>
      ) : (
        <PersonEdit
          person={person}
          type={type}
          bloodGroups={bloodGroups}
          handleSave={handleSave}
          handleSaveError={handleSaveError}
          scrollMode={scrollMode}
        />
      )}
      {person?.personId && (
        <TestTabs person={person} setScrollMode={setScrollMode} scrollMode={scrollMode} printMode={printMode} />
      )}
      <ToastContainer position="bottom-center" autoClose={10000} hideProgressBar draggable={false} />
    </div>
  );
}
