import { ActionType, AppError, ErrorType, CardStatus, CardStatusApi, PersonCard } from '../constants/Interfaces';
import API from '../constants/API';
import { mapApiError } from '../constants/Utils';
import Logger from '../constants/Logger';
import _ from 'lodash';

interface State {
  currentCardStatus: CardStatus[];
  loading: boolean;
  error: AppError;
}

export function getInitialState(): State {
  return {
    currentCardStatus: null,
    loading: false,
    error: null,
  };
}

export const personCardTypes = {
  NEW_PERSON: 'NEW_PERSON',
  PERSON_GET_CARD_REQUEST: 'PERSON_GET_CARD_REQUEST',
  PERSON_GET_CARD_RESPONSE: 'PERSON_GET_CARD_RESPONSE',
  PERSON_POST_CARD_REQUEST: 'PERSON_POST_CARD_REQUEST',
  PERSON_POST_CARD_RESPONSE: 'PERSON_POST_CARD_RESPONSE',
  PERSON_PATCH_CARD_REQUEST: 'PERSON_PATCH_CARD_REQUEST',
  PERSON_PATCH_CARD_RESPONSE: 'PERSON_PATCH_CARD_RESPONSE',
};

const LOG_PREFIX = `Reducer: personCard ->`;

export const personCardActions = {
  /**
   * Get the consent data by person id
   */
  getPersonCard: (personId: string) => async (dispatch) => {
    dispatch({ type: personCardTypes.PERSON_GET_CARD_REQUEST });
    Logger.info(`${LOG_PREFIX} getPersonCard: Getting person card ${personId}`);
    try {
      const api = API.getInstance();
      const response: CardStatusApi = await api.get(`/person/${personId}/card`);
      Logger.debug(`${LOG_PREFIX} getPersonCard: Person card received.`);
      dispatch({
        type: personCardTypes.PERSON_GET_CARD_RESPONSE,
        error: false,
        payload: response,
      });
      return response;
    } catch (e) {
      dispatch({
        type: personCardTypes.PERSON_GET_CARD_RESPONSE,
        payload: mapApiError(ErrorType.PERSON_RESULT_ERROR, e),
        error: true,
      });
      throw e;
    }
  },

  /**
   * Post a new consent line by person id and type of status
   */
  postPersonCard: (newResult: CardStatusApi, personId) => async (dispatch) => {
    dispatch({ type: personCardTypes.PERSON_POST_CARD_REQUEST });
    Logger.info(`${LOG_PREFIX} postPersonCard: post person card`);
    try {
      const api = API.getInstance();
      await api.post(`/person/${personId}/card`, newResult);
      Logger.debug(`${LOG_PREFIX} postPersonCard: post card complete.`);
      dispatch({ type: personCardTypes.PERSON_POST_CARD_RESPONSE, error: false });
      return true;
    } catch (e: any) {
      dispatch({
        type: personCardTypes.PERSON_POST_CARD_RESPONSE,
        payload: mapApiError(ErrorType.PERSON_RESULT_ERROR, e),
        error: true,
      });
      throw e;
    }
  },

  /**
   * Post a set of consent updates from the dashboard. No state updates
   */
  postBatchCard: (newStatus: PersonCard, personIds: any[]) => async () => {
    Logger.info(`${LOG_PREFIX} postBatchCard: post person card`);
    const api = API.getInstance();
    await api.post('persons/card', {
      cardBatch: {
        status: newStatus,
        personIds: _.uniq(personIds),
      },
    });
  },

  /**
   * Patch a card history comment by id
   */
  patchPersonCard: (personId: string, cardHistoryId: number, comment: string) => async (dispatch) => {
    dispatch({ type: personCardTypes.PERSON_PATCH_CARD_REQUEST });
    Logger.info(`${LOG_PREFIX} patchPersonCard: patch person card`);
    try {
      const api = API.getInstance();
      await api.patch(`/person/${personId}/card/${cardHistoryId}`, { comment });
      Logger.debug(`${LOG_PREFIX} patchPersonCard: patch card complete.`);
      dispatch({ type: personCardTypes.PERSON_PATCH_CARD_RESPONSE, error: false });
      return true;
    } catch (e: any) {
      dispatch({
        type: personCardTypes.PERSON_PATCH_CARD_RESPONSE,
        payload: mapApiError(ErrorType.PERSON_RESULT_ERROR, e),
        error: true,
      });
      throw e;
    }
  },
};

export default function personCard(state = getInitialState(), action) {
  const { type, payload, error } = action;

  switch (type) {
    case personCardTypes.PERSON_GET_CARD_REQUEST: {
      return { ...state, loading: true, error: null };
    }
    case personCardTypes.PERSON_GET_CARD_RESPONSE: {
      if (error) {
        return {
          ...state,
          error: payload,
          loading: false,
          currentCardStatus: null,
        };
      }
      const { card } = payload;
      return {
        ...state,
        currentCardStatus: card || [],
        loading: false,
        error: null,
      };
    }

    case personCardTypes.PERSON_POST_CARD_REQUEST:
    case personCardTypes.PERSON_PATCH_CARD_REQUEST: {
      return { ...state, loading: true, error: null };
    }
    case personCardTypes.PERSON_POST_CARD_RESPONSE:
    case personCardTypes.PERSON_PATCH_CARD_RESPONSE: {
      if (error) {
        return { ...state, error: payload, loading: false };
      }
      return { ...state, loading: false, error: null };
    }

    case ActionType.PURGE:
    case personCardTypes.NEW_PERSON: {
      return getInitialState();
    }
  }

  return state;
}
