import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  CheckboxInput,
  ContainerCard,
  Header,
  Spinner,
  Table,
  Text,
  ButtonPrimary,
  PaginationButtons,
  HeaderButton,
} from '../components';
import { useHistory } from 'react-router-dom';
import {
  AppError,
  DashboardResultsGet,
  GenericErrorCode,
  Permission,
  PersonCard,
  PersonConsentErrorCode,
  PersonConsentLetter,
  PersonStatus,
  TaskType,
} from '../constants/Interfaces';
import { selectPermission } from '../constants/Selectors';
import { toast, ToastContainer } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import DashboardCount from './components/DashboardCount';
import { dashboardActions } from '../reducers/dashboard';
import Logger from '../constants/Logger';
import './Dashboard.scss';
import _ from 'lodash';
import StatusLabel from './components/StatusLabel';
import moment from 'moment';
import { dashboardRecordsPerPage, DashboardTableLabels, Messages, PersonFieldLabels, UI } from '../constants/Messages';
import ErrorScreen from './ErrorScreen';
import API from '../constants/API';
import { personConsentActions } from '../reducers/personConsent';
import { personCardActions } from '../reducers/personCard';

const LOG_PREFIX = 'Screen -> Dashboard ->';

export default function Dashboard() {
  // https://stackoverflow.com/questions/31079081/programmatically-navigate-using-react-router
  const history = useHistory();

  const countsRef = useRef<any>();
  const resultsEndRef = useRef<any>();

  const dispatch = useAppDispatch();

  const searchType: TaskType = useAppSelector((state) => state.dashboard.searchType);
  const currentPage: number = useAppSelector((state) => state.dashboard.currentPage);
  const searchLoading: boolean = useAppSelector((state) => state.dashboard.searchLoading);
  const [searchResults, setSearchResults] = useState(null);
  const [currentTotal, setCurrentTotal] = useState(0);
  const [errorOccured, setErrorOccured] = useState(false);
  const dashboardError: AppError = useAppSelector((state) => state.dashboard.error);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const canViewDashboard = useAppSelector(selectPermission(Permission.canViewDashboard));

  function handleSelectAll() {
    searchResults.forEach((searchResult) => {
      if (searchResult.status === PersonStatus.VERIFIED) {
        handleCheckboxSelection(searchResult.personId, !selectAll);
      }
    });
    setSelectAll(!selectAll);
  }

  function updateSelectAllState(currentSearchResults, currentSelectedRows) {
    // check if any records are checked on screen, if yes, select the select all checkbox
    if (currentSearchResults && currentSelectedRows) {
      // if every result cannot be selected, set checkbox to false;
      if (currentSearchResults.every((result) => result.status !== PersonStatus.VERIFIED)) {
        setSelectAll(false);
        return;
      }
      // if there all checkable checkboxes are selceted, selcet the select all
      setSelectAll(
        currentSearchResults.every(
          (result) => result.status !== PersonStatus.VERIFIED || currentSelectedRows.includes(result.personId)
        )
      );
    }
  }

  // handle a checkbox being checked or unchecked
  const handleCheckboxSelection = useCallback(
    (id, checked) => {
      if (checked) {
        selectedRows.push(id);
        setSelectedRows(selectedRows.slice());
      } else {
        setSelectedRows(_.pull(selectedRows, id).slice());
      }
      updateSelectAllState(searchResults, selectedRows);
    },
    [searchResults, selectedRows]
  );

  // perform the api calls required to update the status on a number of person id's
  async function doStatusUpdate(newStatus: PersonConsentLetter | PersonCard) {
    try {
      if (searchType === TaskType.CONSENT_EXPORTED || searchType === TaskType.CONSENT_PENDING) {
        await dispatch(personConsentActions.postBatchConsent(newStatus as PersonConsentLetter, selectedRows));
      }
      if (searchType === TaskType.CARDS_EXPORTED || searchType === TaskType.CARDS_PENDING) {
        await dispatch(personCardActions.postBatchCard(newStatus as PersonCard, selectedRows));
      }
    } catch (e: any) {
      if (e?.response?.status === GenericErrorCode.PERMISSION_ERROR) {
        toast(Messages.ERROR_403_API);
        return false;
      }
      if (e.response && e.response.status === PersonConsentErrorCode.INVALID_REQUEST) {
        toast('Some rows were not in the correct state');
        return false;
      }
      toast(Messages.ERROR_GENERIC);
      return false;
    }
    return true;
  }

  // call the appropriate csv api, then download the file from the link the api returns.
  async function doGenerate() {
    if (
      searchType === TaskType.CONSENT_EXPORTED ||
      searchType === TaskType.CONSENT_PENDING ||
      searchType === TaskType.CARDS_EXPORTED ||
      searchType === TaskType.CARDS_PENDING
    ) {
      let resultURL = null;
      const api = API.getInstance();
      try {
        let path = null;
        if (searchType === TaskType.CONSENT_EXPORTED || searchType === TaskType.CONSENT_PENDING) {
          path = 'persons/consent';
        } else if (searchType === TaskType.CARDS_EXPORTED || searchType === TaskType.CARDS_PENDING) {
          path = 'persons/card';
        }
        resultURL = await api.get(path, {
          personIds: _.uniq(selectedRows),
        });
      } catch (e: any) {
        if (e?.response?.status === GenericErrorCode.PERMISSION_ERROR) {
          toast(Messages.ERROR_403_API);
          return false;
        }
        if (e.response && e.response.status === PersonConsentErrorCode.INVALID_REQUEST) {
          toast('Some rows were not in the correct state');
          return false;
        }
        toast('Export download has failed. Regenerate export from the Exported - To Print list');
        return false;
      }
      try {
        const a = document.createElement('a');
        a.href = resultURL.fileURL;
        a.download = `Consent-export-${moment().format('YYYY-MM-DD')}`;
        a.click();
      } catch (e: any) {
        toast('Export download has failed. Regenerate export from the Exported - To Print list');
        return false;
      }
      return true;
    }
    return false;
  }

  // perform on screen actions required to handle a csv generation.
  async function handleGenerate() {
    setIsLoadingAction(true);
    const statusUpdate = await doStatusUpdate(PersonConsentLetter.EXPORTED_TO_PRINT);

    if (statusUpdate) {
      clearSelectedRows();

      // since the statues have updated, refresh the tables
      swapPage(1);

      if (searchType === TaskType.CONSENT_PENDING) {
        // need to update the other affected count as well.
        countsRef.current?.updateCount(
          TaskType.CONSENT_EXPORTED,
          await dispatch(dashboardActions.getCount(TaskType.CONSENT_EXPORTED))
        );
      }
      if (searchType === TaskType.CARDS_PENDING) {
        // need to update the other affected count as well.
        countsRef.current?.updateCount(
          TaskType.CARDS_EXPORTED,
          await dispatch(dashboardActions.getCount(TaskType.CARDS_EXPORTED))
        );
      }

      // then generate the csv
      await doGenerate();
    }
    setIsLoadingAction(false);
  }

  // perform on screen actions required to handle the SENT button being clicked.
  async function handleSent() {
    const statusUpdate = await doStatusUpdate(PersonConsentLetter.SENT);
    if (statusUpdate) {
      clearSelectedRows();
      swapPage(1);
    }
  }

  // if an error occurs, perform this action when retry is clicked.
  function handleErrorRetry() {
    dispatch(dashboardActions.clearDashboard());
    window.location.reload();
  }

  /**
   * Handle on screen filtering of results
   */
  async function handleFilter(type?: TaskType) {
    await performTableUpdate(type);
    // make sure the entire table is visible
    resultsEndRef.current?.scrollIntoView();
    clearSelectedRows();
  }

  function clearSelectedRows() {
    // clear the selected rows
    setSelectedRows([]);
    setSelectAll(false);
  }

  function swapPage(page) {
    performTableUpdate(searchType, page);
  }

  // code run when changing the filter type or when changing the page. Performs API calls.
  async function performTableUpdate(type?: TaskType, page = 1) {
    Logger.debug(`${LOG_PREFIX} performTableUpdate called with ${type}`);
    if (type === null) {
      setSearchResults(null);
      setCurrentTotal(0);
      setSelectAll(false);
      return;
    }
    try {
      const newList: DashboardResultsGet = await dispatch(dashboardActions.getList(type, page));
      setSearchResults(newList.records);
      setCurrentTotal(newList.totalCount);
      countsRef.current?.updateCount(type, newList.totalCount);
      // check if any records are checked on screen, if yes, select the select all checkbox
      updateSelectAllState(newList.records, selectedRows);
    } catch (e) {
      setErrorOccured(true);
    }
  }

  /**
   * Initial get data on page load
   */
  useEffect(() => {
    performTableUpdate(searchType, currentPage).then(() => resultsEndRef.current?.scrollIntoView());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * respond to dashboard header being clicked while still on the dashboard.
   */
  useEffect(() => {
    if (!searchType) {
      setSearchResults(null);
      setCurrentTotal(0);
    }
  }, [searchType]);

  /**
   * render table functions / cells
   */

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    const selectPerson = (abrNumber) => () => {
      if (abrNumber) {
        history.push(`/person/${abrNumber}`);
      } else {
        Logger.debug(`${LOG_PREFIX} selectPerson called with no personID`);
      }
    };

    function actionCell({ row }) {
      return (
        <div
          onClick={selectPerson(row.original.abrNumber)}
          className="link"
          style={{ textAlign: 'center', margin: '0 auto' }}
        >
          {UI.SEARCH_VIEW_LINK}
        </div>
      );
    }

    function checkboxCell({ row }) {
      const value = _.includes(selectedRows, row.original.personId);
      const statusNotAllowed = row.original.status !== PersonStatus.VERIFIED;
      const onChange = (e) => {
        handleCheckboxSelection(row.original.personId, e);
      };

      return <CheckboxInput value={value} onValueChange={onChange} disabled={statusNotAllowed} />;
    }

    function statusCell({ row }) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <StatusLabel status={row.original.status} label=" " className="subheading" />
        </div>
      );
    }

    const commonPersonColumns: any[] = [
      {
        Header: DashboardTableLabels.ABR_NUMBER,
        accessor: 'abrNumber',
        style: { width: 130 },
      },
      {
        Header: PersonFieldLabels.LAST_NAME,
        style: { wordBreak: 'break-word' },
        accessor: 'lastName',
      },
      {
        Header: PersonFieldLabels.FIRST_NAME,
        style: { wordBreak: 'break-word' },
        accessor: 'firstName',
      },
      {
        Header: DashboardTableLabels.STATUS,
        accessor: 'status',
        Cell: statusCell,
      },
      {
        Header: '',
        accessor: 'action',
        Cell: actionCell,
        style: { width: 50 },
        className: 'transparent-header',
      },
    ];
    if (searchType === TaskType.PENDING_SUBMISSION) {
      commonPersonColumns.splice(
        4,
        0,
        {
          Header: DashboardTableLabels.CREATED_DATE,
          accessor: dateCellFormatter('createdDate'),
          style: { width: 200 },
        },
        {
          Header: DashboardTableLabels.CREATED_BY,
          accessor: emailCellFormatter('createdBy'),
          className: 'no-border-right',
        }
      );
      setColumns(commonPersonColumns);
      return;
    }
    if (searchType === TaskType.PENDING_VERIFICATION) {
      commonPersonColumns.splice(
        4,
        0,
        {
          Header: DashboardTableLabels.MODIFIED_DATE,
          accessor: dateCellFormatter('modifiedDate'),
          style: { width: 200 },
        },
        {
          Header: DashboardTableLabels.MODIFIED_BY,
          accessor: emailCellFormatter('modifiedBy'),
          className: 'no-border-right',
        }
      );
      setColumns(commonPersonColumns);
      return;
    }

    const commonCardConsentColumns: any[] = [
      {
        Header: DashboardTableLabels.ABR_NUMBER,
        accessor: 'abrNumber',
        style: { width: 130 },
      },
      {
        Header: PersonFieldLabels.LAST_NAME,
        accessor: 'lastName',
      },
      {
        Header: PersonFieldLabels.FIRST_NAME,
        accessor: 'firstName',
      },
      {
        Header: '',
        accessor: 'action',
        Cell: checkboxCell,
        style: { width: 55 },
        className: 'transparent-header',
      },
    ];
    if (searchType === TaskType.CONSENT_PENDING) {
      commonCardConsentColumns.splice(
        3,
        0,
        {
          Header: DashboardTableLabels.STATUS,
          accessor: 'status',
          Cell: statusCell,
        },
        {
          Header: DashboardTableLabels.REQUESTED_ON,
          accessor: dateCellFormatter('createdDate'),
          style: { width: 200 },
          className: 'no-border-right',
        }
      );
      setColumns(commonCardConsentColumns.slice());
      return;
    }
    if (searchType === TaskType.CONSENT_EXPORTED) {
      commonCardConsentColumns.splice(3, 0, {
        Header: DashboardTableLabels.EXPORTED_ON,
        accessor: dateCellFormatter('createdDate'),
        style: { width: 200 },
        className: 'no-border-right',
      });
      setColumns(commonCardConsentColumns.slice());
      return;
    }
    if (searchType === TaskType.CARDS_PENDING) {
      commonCardConsentColumns.splice(
        3,
        0,
        {
          Header: DashboardTableLabels.STATUS,
          accessor: 'status',
          Cell: statusCell,
        },
        {
          Header: DashboardTableLabels.REQUESTED_ON,
          accessor: dateCellFormatter('createdDate'),
          style: { width: 200 },
        }
      );
      setColumns(commonCardConsentColumns.slice());
      return;
    }
    if (searchType === TaskType.CARDS_EXPORTED) {
      commonCardConsentColumns.splice(3, 0, {
        Header: DashboardTableLabels.EXPORTED_ON,
        accessor: dateCellFormatter('createdDate'),
        style: { width: 200 },
      });
      setColumns(commonCardConsentColumns.slice());
      return;
    }

    setColumns([]);
  }, [history, searchType, selectedRows, searchResults, handleCheckboxSelection]);

  /**
   * render functions
   */

  function renderNoRecords() {
    return (
      <div className="centre-row">
        <Text className="subheading">{UI.DASHBOARD_NO_RESULT}</Text>
      </div>
    );
  }

  function renderPaginationButtons() {
    return (
      <PaginationButtons
        totalRecords={currentTotal}
        onClick={swapPage}
        currentPage={currentPage}
        recordsPerPage={dashboardRecordsPerPage}
      />
    );
  }

  function disableSelectAll() {
    // check if there are any verified records.
    // If there is one or more records, then the select all should NOT be disabled
    const row = _.find(searchResults, (result) => result.status === PersonStatus.VERIFIED);
    return !row;
  }

  function renderSelectAll() {
    if (
      !searchLoading &&
      searchResults &&
      (searchType === TaskType.CONSENT_PENDING ||
        searchType === TaskType.CARDS_PENDING ||
        searchType === TaskType.CARDS_EXPORTED ||
        searchType === TaskType.CONSENT_EXPORTED)
    ) {
      return (
        <>
          <Text onClick={handleSelectAll} style={{ marginLeft: 'auto', paddingRight: 10 }}>
            {UI.DASHBOARD_SELECT_ALL}
          </Text>
          <CheckboxInput
            value={selectAll}
            onValueChange={handleSelectAll}
            style={{ marginRight: 18 }}
            disabled={disableSelectAll()}
          />
        </>
      );
    }
    return null;
  }

  if (!canViewDashboard || errorOccured || dashboardError) {
    if (dashboardError?.code === '403') {
      return <ErrorScreen message={Messages.ERROR_403_SCREEN} hideButton />;
    }
    return <ErrorScreen message={Messages.ERROR_GENERIC_API} customAction={handleErrorRetry} />;
  }
  return (
    <div className="dashboard">
      <Header selectedButton={HeaderButton.Dashboard} />
      <DashboardCount handleFilter={handleFilter} ref={countsRef} />
      <div className="marginLarge" style={{ marginTop: 35 }}>
        <ContainerCard title="" className={searchType}>
          {searchLoading && <Spinner className="table-margin" />}
          {!searchLoading && !searchResults && (
            <div className="centre-row">
              <Text className="subheading">{UI.DASHBOARD_NO_LIST_SELECTED}</Text>
            </div>
          )}
          {!searchLoading && searchResults && (
            <>
              <div className="marginNarrowTop" style={{ flexDirection: 'row' }}>
                {renderPaginationButtons()}
                {renderSelectAll()}
              </div>
              <Table
                className="table-margin spacing brand altRowBackground"
                data={searchResults}
                columns={columns}
                noDataChildren={renderNoRecords()}
              />
              <div className="marginNarrowTop" style={{ flexDirection: 'row' }}>
                {renderPaginationButtons()}
                {(searchType === TaskType.CONSENT_PENDING || searchType === TaskType.CARDS_PENDING) && (
                  <>
                    {isLoadingAction ? (
                      <Spinner style={{ height: 45 }} />
                    ) : (
                      <ButtonPrimary
                        onClick={handleGenerate}
                        title={UI.DASHBOARD_GENERATE_BUTTON}
                        disabled={selectedRows.length === 0}
                        containerStyle={{ marginLeft: 'auto' }}
                      />
                    )}
                  </>
                )}
                {(searchType === TaskType.CONSENT_EXPORTED || searchType === TaskType.CARDS_EXPORTED) && (
                  <div style={{ flexDirection: 'row', marginLeft: 'auto' }}>
                    {isLoadingAction ? (
                      <Spinner style={{ height: 45 }} />
                    ) : (
                      <>
                        <ButtonPrimary
                          onClick={handleGenerate}
                          title={UI.DASHBOARD_REGENERATE_BUTTON}
                          disabled={selectedRows.length === 0}
                          containerStyle={{ paddingRight: 0 }}
                        />
                        <ButtonPrimary
                          onClick={handleSent}
                          title={UI.DASHBOARD_SENT_BUTTON}
                          disabled={selectedRows.length === 0}
                          containerStyle={{ paddingRight: 0 }}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
          <div ref={resultsEndRef} />
          <ToastContainer position="bottom-center" autoClose={10000} hideProgressBar draggable={false} />
        </ContainerCard>
      </div>
    </div>
  );
}

const dateCellFormatter = (field) => (originalRow) =>
  originalRow[field] ? moment.utc(originalRow[field]).local().format('DD/MM/YYYY') : '';

const emailCellFormatter = (field) => (originalRow) => originalRow[field] ? originalRow[field].split('@')[0] : '';
