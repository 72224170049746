import React, { CSSProperties } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import './CheckboxInput.scss';

interface Props {
  disabled?: boolean;
  onValueChange: (value: boolean) => void;
  value: boolean;
  style?: CSSProperties;
  tooltip?: string;
  tabIndex?: number;
}

export function CheckboxInput(props: Props) {
  const { disabled = false, onValueChange, value, style, tooltip, tabIndex } = props;

  const defaultStyle: CSSProperties = { backgroundColor: 'transparent', padding: 0 };
  const finalStyle = { ...defaultStyle, ...style };

  return (
    <Checkbox
      onChange={(result) => onValueChange(result.target.checked)}
      disabled={disabled}
      checked={value}
      style={finalStyle}
      title={tooltip}
      disableRipple
      tabIndex={tabIndex}
    />
  );
}
