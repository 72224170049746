import React, { useState } from 'react';
import './ErrorScreen.scss';
import 'react-tabs/style/react-tabs.css';
import { ButtonPrimary, Header, Text } from '../components';
import Layout from '../constants/Layout';

// const LOG_PREFIX = 'Screen -> ErrorScreen ->';

interface Props {
  message: string;
  customAction?: () => void;
  noHeader?: boolean;
  hideHeaderButtons?: boolean;
  hideButton?: boolean;
}

export default function ErrorScreen(props: Props) {
  const { hideHeaderButtons = false } = props;
  const [message] = useState(props.message);

  function reloadPage() {
    window.location.reload();
  }

  return (
    <div className="error-screen background">
      {!props.noHeader && <Header hideButtons={hideHeaderButtons} />}
      <div className="error-alignment marginNormal">
        <Text>{message}</Text>
        {!props.hideButton && (
          <ButtonPrimary
            containerStyle={{ marginTop: Layout.backgroundMarginNormal.top }}
            title="Retry"
            onClick={props.customAction ? props.customAction : reloadPage}
          />
        )}
      </div>
    </div>
  );
}
