/* eslint-disable @typescript-eslint/no-shadow */
import { ActionType, AppError, ErrorType, User, UserResponse } from '../constants/Interfaces';
import API from '../constants/API';
import { mapApiError } from '../constants/Utils';
import Logger from '../constants/Logger';

interface State {
  user: User;
  loading: boolean;
  error: AppError;
}

export function getInitialState(): State {
  return {
    user: null,
    loading: false,
    error: null,
  };
}

export const userTypes = {
  USERS_GET_REQUEST: 'USERS_GET_REQUEST',
  USERS_GET_RESPONSE: 'USERS_GET_RESPONSE',
};

export const userActions = {
  /**
   * Get the user data
   */
  getUsers: () => async (dispatch) => {
    dispatch({ type: userTypes.USERS_GET_REQUEST });
    Logger.info(`Reducer: user -> getUsers: Getting user information`);
    try {
      const api = API.getInstance();
      const response: UserResponse = await api.get('/users');
      const { user } = response;
      Logger.debug(`Reducer: user -> getUsers: User information received.`);
      dispatch({ type: userTypes.USERS_GET_RESPONSE, error: false, payload: { user } });
      return user;
    } catch (e) {
      dispatch({ type: userTypes.USERS_GET_RESPONSE, payload: mapApiError(ErrorType.USER_ERROR, e), error: true });
      throw e;
    }
  },
};

export default function user(state = getInitialState(), action) {
  const { type, payload, error } = action;

  switch (type) {
    case userTypes.USERS_GET_REQUEST: {
      return { ...state, loading: true, error: null };
    }

    case userTypes.USERS_GET_RESPONSE: {
      if (error) {
        return { ...state, error: payload, loading: false };
      }
      const { user } = payload;
      return { ...state, user, swrveSet: true, loading: false, error: null };
    }

    case ActionType.PURGE: {
      return getInitialState();
    }
  }

  return state;
}
