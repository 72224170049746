// https://stackoverflow.com/questions/28230845/communication-between-tabs-or-windows
// https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API
import Logger from './Logger';

/**
 * The hook seems to trigger itself, since it creates a new broadcast channel instance each time it's called.
 * Here is a class based version. Can manage multiple channels, but only one handler per channel
 */
export default class CrossTabMessage {
  private static instances: { [channelName: string]: CrossTabMessage } = {};
  private channel: BroadcastChannel;

  private constructor(channelName) {
    Logger.info(`CrossTabMessage -> constructor: initializing ${channelName} now...`);
    try {
      this.channel = new BroadcastChannel(channelName);
    } catch (e) {
      Logger.error(`Error setting up cross tab messager ${e}`);
    }
  }

  static getInstance(channelName = 'abr_message_channel'): CrossTabMessage {
    try {
      if (!CrossTabMessage.instances[channelName]) {
        CrossTabMessage.instances[channelName] = new CrossTabMessage(channelName);
      }
      return CrossTabMessage.instances[channelName];
    } catch (e) {
      Logger.error(`Error getting up cross tab messager ${e}`);
    }
    return null;
  }

  setHandler(handerFunction) {
    if (this.channel) {
      this.channel.onmessage = handerFunction;
    }
  }

  postMessage(message) {
    if (this.channel) {
      this.channel.postMessage(message);
    }
  }
}
