import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Antibody,
  AntibodyTestAntibody,
  AntibodyTestResult,
  AntibodyTestTranslated,
  GenericErrorCode,
  Method,
  PendingOperation,
  Permission,
  PersonErrorCode,
  PersonErrorName,
  PersonStatus,
  TestLab,
  TestStatus,
  Titre,
  Validator,
} from '../../../constants/Interfaces';
import { ButtonPrimary, ButtonLink, Table, TextArea } from '../../../components';
import { ScreenMode } from '../PersonScreen';
import _ from 'lodash';
import { AntibodyValidators, isHospitalAndDonationNumberPresent } from '../../../constants/Validators';
import { toast } from 'react-toastify';
import { personResultsActions, ResultFilter } from '../../../reducers/personResults';
import { personActions } from '../../../reducers/person';
import Logger from '../../../constants/Logger';
import { AntibodyTestTableLabels, Messages, PersonFieldLabels } from '../../../constants/Messages';
import {
  markFirstRows,
  buildAntibodyTestTableRows,
  handleTestUpdate,
  TestResultType,
  HandleTestUpdateParams,
  findListOfUpdates,
  executeTestUpdates,
  deleteRow,
  editRow,
  ConfirmConfig,
  editQuant,
  TestTabEditStyle,
  TestTabViewStyle,
} from '../../../constants/UtilsTests';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  DateCell,
  DropdownCell,
  FirstFieldTextCell,
  NumberCell,
  TextCell,
  MultiDropdownCell,
  renderSubRow,
} from './TestTableCells';
import moment from 'moment';
import { selectPermission } from '../../../constants/Selectors';
import { dashboardActions } from '../../../reducers/dashboard';

const LOG_PREFIX = 'Component -> AntibodyTestTable ->';

interface Props {
  scrollMode?: boolean;
  setLoading?: (value: boolean) => void;
  isAntibodyEditing?: boolean;
  updateScrollMode?: (translatedTests: AntibodyTestTranslated[], value?: boolean) => void;
  personId: string;
  abrNumber: string;
  translatedTests: AntibodyTestTranslated[];
  setTranslatedTests: (translatedTests: AntibodyTestTranslated[]) => void;
  antibodyTestResults: AntibodyTestResult[];
  personAntibodyComments: string;
  setPersonAntibodyComments?: (value: string) => void;
  newTestId: number;
  setNewTestId: (value: number) => void;
  confirmConfig?: ConfirmConfig;
  viewOnly?: boolean;
}

const AntibodyTestTable = forwardRef((props: Props, ref) => {
  const {
    scrollMode = false,
    setLoading = () => {},
    isAntibodyEditing = false,
    updateScrollMode = () => {},
    personId,
    abrNumber,
    translatedTests,
    setTranslatedTests,
    antibodyTestResults,
    personAntibodyComments,
    setPersonAntibodyComments = () => {},
    newTestId,
    setNewTestId,
    confirmConfig = {
      setIsOpen: () => {},
      setMessage: () => {},
      setHandleAccept: () => {},
      setHandleDecline: () => {},
    },
    viewOnly,
  } = props;

  const antibodies: Antibody[] = useAppSelector((state) => state.reference.antibodies);
  const methodReference: Method[] = useAppSelector((state) => state.reference.methods);
  const testLabs: TestLab[] = useAppSelector((state) => state.reference.testLabs);
  const titres: Titre[] = useAppSelector((state) => state.reference.titres);
  const personStatus: PersonStatus = useAppSelector((state) => state.person.currentPerson?.status);
  const dispatch = useAppDispatch();

  const canModifyPerson = useAppSelector(selectPermission(Permission.canModifyPerson));
  const canModifyTest = useAppSelector(selectPermission(Permission.canModifyTest));
  const canSubmitTest = useAppSelector(selectPermission(Permission.canSubmitTest));
  const canVerifyTest = useAppSelector(selectPermission(Permission.canVerifyTest));
  const canDeleteTestUptoVerify = useAppSelector(selectPermission(Permission.canDeleteTestUptoVerify));
  const canDeleteTestAfterFirstVerify = useAppSelector(selectPermission(Permission.canDeleteTestAfterFirstVerify));
  const canViewDonationNumber = useAppSelector(selectPermission(Permission.canViewDonationNumber));

  const [subrowPermissions, setSubrowPermissions] = useState({ canSubmitTest, canVerifyTest, viewOnly });
  useEffect(() => {
    setSubrowPermissions({ canSubmitTest, canVerifyTest, viewOnly });
  }, [canSubmitTest, canVerifyTest, viewOnly]);

  // record the list of tests deleted from the API, for cases when a save only goes part way through we do not want to delete the row again.
  const [deletedTestIds, setDeletedTestIds] = useState([]);
  // when the master list of tests is refreshed, reset the list of deleted records.
  useEffect(() => {
    setDeletedTestIds([]);
  }, [antibodyTestResults]);

  /**
   * Handle recording if the tab is in an editable state or not
   */

  const [tabEditMode, setTabEditMode] = useState(false);

  function updateEditMode(currentTranslatedTests: AntibodyTestTranslated[], updateCommentsInEditMode?) {
    // clear dashboard
    dispatch(dashboardActions.resetDashboardPage());
    // update the comment is being edited flag if passed in
    if (typeof updateCommentsInEditMode !== 'undefined') {
      setTabEditMode(updateCommentsInEditMode);
    }
    // if either the comment flag being passed or the state is true, we are editing untill the save button is hit
    // TODO: need to update this to a general flag to handle deletion
    if (updateCommentsInEditMode || tabEditMode) {
      updateScrollMode(currentTranslatedTests, updateCommentsInEditMode || tabEditMode);
      return;
    }
    updateScrollMode(currentTranslatedTests);
  }

  // handle the edit button near the table comments
  function editComment() {
    // pass true into the function, since if you just update the state here then call the function the function won't have the updated state
    updateEditMode(translatedTests, true);
  }

  /**
   *  Save functions
   */

  // function for updating the status of a row
  function saveStatus(status: TestStatus, testResultId) {
    return async function () {
      try {
        dispatch(dashboardActions.resetDashboardPage());
        await dispatch(personResultsActions.patchPersonResult(personId, testResultId, status));
        dispatch(personActions.getPersonDerived(abrNumber));
        // TODO - check if more optimised way to do this.
        await dispatch(personResultsActions.getPersonResults(personId, ResultFilter.ANTIBODIES));
      } catch (e: any) {
        if (e.response) {
          switch (e.response.status) {
            case GenericErrorCode.PERMISSION_ERROR: {
              Logger.error(`${LOG_PREFIX} saveStatus: 403 in status save`, e);
              // generic server error, show snack bar and let user try again.
              toast(Messages.ERROR_403_API);
              return;
            }
          }
        }
        Logger.error(`${LOG_PREFIX} submitVerification: Error updating the status on this test`);
        await dispatch(personResultsActions.getPersonResults(personId, ResultFilter.ANTIBODIES));
        toast(Messages.TEST_STATUS_CHANGE_ERROR);
      }
    };
  }

  const testUpdateOptions: HandleTestUpdateParams = {
    type: TestResultType.ANTIBODY,
    translatedTests,
    setTranslatedTests,
    updateEditMode,
  };

  // big save button hander, figures out what has changed and what needs to be sent to the api
  function saveTests(submitRequested?: boolean) {
    return async function () {
      // validation
      const result = await validateAllFields();
      if (!result) {
        // the messages are handed in validateAllFields
        return;
      }

      setLoading(true);
      setTabEditMode(false);

      const updateList: AntibodyTestResult[] = findListOfUpdates(
        antibodyTestResults,
        translatedTests,
        createTestObject,
        submitRequested
      );
      // translatedTests should have all the data at this point
      // antibodyTestResults are the originals

      try {
        await executeTestUpdates(updateList, dispatch, testUpdateOptions, personId, deletedTestIds, submitRequested);
        updateScrollMode([], false);
        // fire a refresh of the tests to rerender the screen if all successful
        await dispatch(personResultsActions.getPersonResults(personId, ResultFilter.ANTIBODIES));
      } catch (e: any) {
        // if one reducer errors, catch it and display an error (hilight row? toast?)
        Logger.error(`${LOG_PREFIX} -> saveTests: error with antibody test save`, e);
        if (e?.response?.status === GenericErrorCode.PERMISSION_ERROR) {
          toast(Messages.ERROR_403_API);
          return;
        }
        toast(Messages.ERROR_GENERIC);
        // TODO: At this point, pressing cancel will replace the table with the values before save.
        // However if some rows were saved before one errored this will be incorrect
        // But we can't simply run a GET, as it will blow away any unsaved changes (the line that errored and below)
      } finally {
        // lower spinner.
        setLoading(false);
        setDeletedTestIds(deletedTestIds.slice());
      }

      try {
        // save the antibody comments in a person PATCH
        // send null instead of empty string
        dispatch(personActions.patchPerson({ antibodyComments: personAntibodyComments || null, personId }));
      } catch (e: any) {
        Logger.error(`${LOG_PREFIX} -> saveTests: error with antibody test comments save`, e);
        if (e?.response?.status === GenericErrorCode.PERMISSION_ERROR) {
          toast(Messages.ERROR_403_API);
          return;
        }
        if (
          e?.response.status === PersonErrorCode.BAD_DATA &&
          e?.response?.data?.errorCode === PersonErrorName.INVALID_REQUEST
        ) {
          return;
        }
        toast(Messages.ERROR_GENERIC);
      } finally {
        dispatch(personActions.getPersonDerived(abrNumber));
      }
    };
  }

  // create the object for the api
  function createTestObject(tests: AntibodyTestTranslated[], submitRequested?: boolean): AntibodyTestResult {
    let isDirty = false;
    let isEditMode = false;
    let performSubmit = false;
    const antibodyResults: AntibodyTestAntibody[] = [];
    _.each(tests, (test: AntibodyTestTranslated) => {
      if (test.isDirty) {
        isDirty = true;
      }
      if (test.mode === ScreenMode.EDIT) {
        isEditMode = true;
      }
      if (submitRequested && _.includes([TestStatus.DRAFT, TestStatus.UPDATED], test.status)) {
        performSubmit = true;
      }
      const methods = [];
      // translate the methods column to id's
      _.each(test.methods, (method: Method) => {
        methods.push(method.id);
      });
      const quantity = test.quantity ? +test.quantity : null;
      antibodyResults.push({
        antiBodyResultId: test.antiBodyResultId,
        antibodyReference: test.antibodyReference,
        methods,
        titreReference: test.titreReference,
        quantity,
      });
    });

    // ones with negative testResultId values need to be marked as CREATE
    // ones that do not have a negative testResultId, but have a row with an isDirty flag need to be UPDATE
    // if a submit was requested, then draft or updated rows should be submitted even if there are no changes

    let pendingOperation = PendingOperation.NONE;
    if (tests[0].testResultId < 0) {
      pendingOperation = PendingOperation.CREATE;
    } else if (isEditMode && (isDirty || performSubmit)) {
      pendingOperation = PendingOperation.UPDATE;
    }

    return {
      testResultId: tests[0].testResultId,
      sampleDate: `${tests[0].sampleDate} ${moment().format('HH:mm Z')}`,
      antibodyResults,
      recommendedBloodForTransfusion: tests[0].recommendedBloodForTransfusion
        ? tests[0].recommendedBloodForTransfusion.trim()
        : null,
      hospitalNumber: tests[0].hospitalNumber ? tests[0].hospitalNumber.trim() : null,
      donationNumber: tests[0].donationNumber ? tests[0].donationNumber.trim() : null,
      testedByReference: tests[0].testedByReference,
      pendingOperation,
      status: tests[0].status,
    };
  }

  /**
   *  Row manipulation (adding rows or sub rows )
   */
  // Functions that can be called from parent components
  useImperativeHandle(ref, () => ({
    addTest(newTest?: AntibodyTestResult) {
      if (newTest) {
        const newRows = buildAntibodyTestTableRows([newTest], methodReference);
        // Now that tests are added to the start of the table, need to reverse the order each row is inserted.
        newRows.reverse();
        _.each(newRows, (row: AntibodyTestTranslated) => {
          translatedTests.splice(0, 0, {
            ...row,
            isDirty: true,
            mode: ScreenMode.EDIT,
            status: TestStatus.DRAFT,
            testResultId: newTestId,
          });
        });
      } else {
        translatedTests.splice(0, 0, {
          ...newRowDefaultValues,
          testResultId: newTestId,
          antiBodyResultId: -1,
        });
      }

      markFirstRows(translatedTests);
      setTranslatedTests(translatedTests.slice());
      setNewTestId(newTestId - 1);
      updateEditMode(translatedTests);

      // focus on the new row after rerender
      setTimeout(() => {
        const element = document.getElementById(`date${newTestId}`);
        element && element.focus();
      });
    },

    cancelChanges(initalAntibodyComments) {
      setPersonAntibodyComments(initalAntibodyComments);
      setTranslatedTests(buildAntibodyTestTableRows(antibodyTestResults, methodReference));
      updateScrollMode([], false);
      setDeletedTestIds([]);
    },
  }));

  const newRowDefaultValues = {
    methods: [],
    isDirty: true,
    mode: ScreenMode.EDIT,
    status: TestStatus.DRAFT,
    testedByReferenceOriginal: null,
    antibodyReferenceOriginal: null,
    methodsOriginal: [],
    titreReferenceOriginal: null,
  };

  function addRow(testResultId) {
    return function () {
      if (testResultId !== null) {
        const testIndexLast = _.findLastIndex(translatedTests, (test) => test.testResultId === testResultId);
        const newRow: AntibodyTestTranslated = {
          ...newRowDefaultValues,
          testResultId,
          antiBodyResultId: newTestId,
          status: translatedTests[testIndexLast].status,
          canVerify: translatedTests[testIndexLast].canVerify,
          sampleDate: translatedTests[testIndexLast].sampleDate,
          testedByReference: translatedTests[testIndexLast].testedByReference,
          hospitalNumber: translatedTests[testIndexLast].hospitalNumber,
          donationNumber: translatedTests[testIndexLast].donationNumber,
          recommendedBloodForTransfusion: translatedTests[testIndexLast].recommendedBloodForTransfusion,
          testedByReferenceOriginal: translatedTests[testIndexLast].testedByReferenceOriginal,
        };
        translatedTests.splice(testIndexLast + 1, 0, newRow);
        markFirstRows(translatedTests);
        setTranslatedTests(translatedTests.slice());
        setNewTestId(newTestId - 1);

        setTimeout(() => {
          const element = document.getElementById(`antibody${newTestId}`);
          const inputs = element && element.getElementsByTagName('input');
          inputs && inputs[0].focus();
        });
      }
    };
  }

  /**
   * field validation
   */
  async function validateAllFields() {
    // Validating all the fields again.
    const validators: Promise<ValidationResult>[] = [];
    for (const test of translatedTests) {
      if (test.isDirty) {
        const hospitalDonationResult = await isHospitalAndDonationNumberPresent(
          test.hospitalNumber,
          test.donationNumber
        );
        if (!hospitalDonationResult) {
          toast(`${Messages.ERROR_GENERIC_FIELD_VALIDATION} ${Messages.ERROR_EXLUSIVE_HOSPITAL_DONATION_NUMBER}`);
          return false;
        }

        validators.push(validateField('sampleDate', test.sampleDate));
        validators.push(validateField('antibodyReference', test.antibodyReference));
        validators.push(validateField('quantity', test.quantity));
      }
    }
    const results: ValidationResult[] = await Promise.all(validators);

    // If any of the validation has failed, terminate the process immediately
    const firstFailedFieldIndex = _.findIndex(results, (result: ValidationResult) => !result.result);
    if (firstFailedFieldIndex > -1) {
      Logger.debug(
        `${LOG_PREFIX} -> validateAllFields: Validation failed for one or more fields. First failed field is ${firstFailedFieldIndex}`
      );
      toast(`${Messages.ERROR_GENERIC_FIELD_VALIDATION} ${results[firstFailedFieldIndex].message}`);
      return false;
    }
    return true;
  }

  interface ValidationResult {
    result: boolean;
    message?: string;
  }

  async function validateField(field, value) {
    const validators: Validator[] = _.concat(AntibodyValidators[field]);

    let validationResult: ValidationResult = { result: true };

    for (const validator of validators) {
      if (validator) {
        const result: boolean = await validator.fn(value);
        if (!result) {
          validationResult = { result: false, message: validator.errorMessage };
          break;
        }
      }
    }
    return validationResult;
  }

  /**
   *  Rendering functions
   */

  function renderEditCell({ row }) {
    const { original } = row;
    if (original.mode === ScreenMode.EDIT) {
      if (
        (canDeleteTestUptoVerify && _.includes([TestStatus.DRAFT, TestStatus.PENDING_VERIFICATION], original.status)) ||
        (canDeleteTestAfterFirstVerify &&
          _.includes(
            [TestStatus.VERIFIED, TestStatus.UPDATED, TestStatus.UPDATED_PENDING_VERIFICATION],
            original.status
          ))
      ) {
        const deleteRowParams = {
          testResultId: original.testResultId,
          lineResultId: original.antiBodyResultId,
          type: TestResultType.ANTIBODY,
          originalTestResults: antibodyTestResults,
          translatedTests,
          setTranslatedTests,
          setTabEditMode,
          updateEditMode,
          confirmConfig,
        };
        return <ButtonLink onClick={deleteRow(deleteRowParams)} title="Delete" />;
      }
      // no permissions
      return null;
    }
    if (original.first && canModifyTest && !viewOnly) {
      return (
        <ButtonLink
          onClick={editRow(original.testResultId, translatedTests, setTranslatedTests, updateEditMode)}
          title="Edit"
        />
      );
    }

    return null;
  }

  /**
   * Table calumn layout
   */
  const columns = [
    {
      Header: AntibodyTestTableLabels.SAMPLE_DATE,
      accessor: 'sampleDate',
      Cell: DateCell,
      style: { width: 115 },
      updateFunction: handleTestUpdate(testUpdateOptions),
      validators: AntibodyValidators,
    },
    {
      Header: AntibodyTestTableLabels.ANTIBODY,
      accessor: 'antibodyReference',
      Cell: DropdownCell,
      selectOptions: antibodies,
      style: { minWidth: 150, textAlign: 'left' },
      className: 'antibodyColumnPrint',
      updateFunction: handleTestUpdate(testUpdateOptions),
      searchIgnoreCase: false,
      validators: AntibodyValidators,
    },
    {
      Header: AntibodyTestTableLabels.METHOD,
      accessor: 'methods',
      Cell: MultiDropdownCell,
      selectOptions: methodReference,
      style: { minWidth: 160, textAlign: 'left' },
      className: 'widthAutoPrint',
      updateFunction: handleTestUpdate(testUpdateOptions),
    },
    {
      Header: AntibodyTestTableLabels.TITRE,
      accessor: 'titreReference',
      Cell: DropdownCell,
      selectOptions: titres,
      style: { minWidth: 130, textAlign: 'center' },
      className: 'widthAutoPrint',
      updateFunction: handleTestUpdate(testUpdateOptions),
      validators: AntibodyValidators,
    },
    {
      Header: AntibodyTestTableLabels.QUANT,
      accessor: 'quantity',
      Cell: NumberCell,
      style: { width: 90, textAlign: 'center' },
      updateFunction: handleTestUpdate(testUpdateOptions),
      formatBlurFunction: editQuant,
      formatViewFunction: viewQuant,
      validators: AntibodyValidators,
    },
    {
      Header: AntibodyTestTableLabels.HOSPITAL_NUMBER,
      accessor: 'hospitalNumber',
      Cell: TextCell,
      firstRowOnly: true,
      style: { width: 130, textAlign: 'left' },
      updateFunction: handleTestUpdate(testUpdateOptions),
      maxLength: 20,
      validators: AntibodyValidators,
    },
    // donation number (if user has permissions)
    {
      Header: AntibodyTestTableLabels.TESTED_BY,
      accessor: 'testedByReference',
      Cell: DropdownCell,
      firstRowOnly: true,
      selectOptions: testLabs,
      style: { minWidth: 150, textAlign: 'left' },
      className: 'widthAutoPrint',
      updateFunction: handleTestUpdate(testUpdateOptions),
      validators: AntibodyValidators,
    },
    {
      Header: PersonFieldLabels.RECOMMENDED_BLOOD,
      accessor: 'recommendedBloodForTransfusion',
      Cell: FirstFieldTextCell,
      style: { minWidth: 160, textAlign: 'left' },
      updateFunction: handleTestUpdate(testUpdateOptions),
      className: 'no-border-right',
    },
    {
      Header: '',
      accessor: 'delete',
      Cell: renderEditCell,
      style: { width: 55 },
      className: 'transparent-header printHide',
    },
  ];

  if (canViewDonationNumber) {
    columns.splice(6, 0, {
      Header: AntibodyTestTableLabels.DONATION_NUMBER,
      accessor: 'donationNumber',
      firstRowOnly: true,
      Cell: TextCell,
      style: { width: 175, textAlign: 'left' },
      updateFunction: handleTestUpdate(testUpdateOptions),
      maxLength: 16,
      validators: AntibodyValidators,
    });
  }

  function getRowId(row, relativeIndex, parent?) {
    return parent
      ? [parent.id, relativeIndex, row.testResultId, row.antiBodyResultId].join('.')
      : [relativeIndex, row.testResultId, row.antiBodyResultId].join('.');
  }

  const scrollModeStyle: any = scrollMode ? TestTabEditStyle : TestTabViewStyle;

  return (
    <div>
      <div id="antibody-table-scroll" className="printNoScroll" style={scrollModeStyle}>
        <Table
          style={{ width: '100%' }}
          data={translatedTests}
          columns={columns}
          renderRowSubComponent={renderSubRow(
            columns,
            isAntibodyEditing,
            saveStatus,
            personStatus,
            subrowPermissions,
            addRow
          )}
          noDataMessage="No tests added"
          className="sticky brand"
          tableGetRowId={getRowId}
        />
      </div>
      <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
        {isAntibodyEditing && <ButtonPrimary title="Save" buttonClass="clay-outline" onClick={saveTests()} />}
        {isAntibodyEditing && <ButtonPrimary title="Submit" buttonClass="clay-outline" onClick={saveTests(true)} />}
        <TextArea
          label="Comments"
          onValueChange={setPersonAntibodyComments}
          value={personAntibodyComments || ''}
          labelStyle={{ marginRight: 10 }}
          style={{ marginTop: 0 }}
          inputStyle={{ height: 40, resize: 'vertical' }}
          disabled={!isAntibodyEditing}
        />
        {!isAntibodyEditing && canModifyPerson && !viewOnly ? (
          <div onClick={editComment} className="link" style={{ textAlign: 'center', margin: '0 auto' }}>
            Edit
          </div>
        ) : (
          <div style={{ width: 30 }} />
        )}
      </div>
    </div>
  );
});

export default AntibodyTestTable;

const viewQuant = (value) => {
  let number = _.replace(value, new RegExp(/[^0-9.]/, 'g'), '');
  if (!number || number.length === 0) {
    return '';
  }

  // anything below 0.1 is returned as < 0.1
  if (+number < 0.1) {
    return '< 0.1';
  }

  // round to 1 decimal
  number = (Math.round(number * 100) / 100).toFixed(1);

  return number;
};
