import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import ApiConfig from '../config/ApiConfig';
import { AccessToken, ApiError, Scope } from './Interfaces';
import moment from 'moment-timezone';
import Logger from './Logger';
import { isValidJSON, LogoutUser } from './Utils';
import { oktaAuth } from '../App';
import { addressActions } from '../reducers/address';

// seconds
const AUTH_EXPIRY_BUFFER = 300;

/**
 * Use function to get common config in case it gets overwritten downstream
 */
export function getCommonConfig(): AxiosRequestConfig {
  return {
    baseURL: ApiConfig.baseUrl,
    timeout: ApiConfig.defaultTimeout,
    responseType: 'json',
    headers: {},
  };
}

/**
 * Singleton class containing common methods for calling API.
 */
// This was copied from the donor mobile app
// in general, it sets up a location to hold onto the various access tokens required by the app (though abr only needs 1)
// It handles retriving the okta token from sesssion storage, and then applies that key to every api call.
export default class API {
  private static instance: API;
  readonly store;
  readonly accessTokens: { [key: string]: AccessToken };
  private urls: { [key: string]: string };
  private tokenPromises: { [key: string]: Promise<any> };

  private constructor(store?: any) {
    Logger.info(`API -> constructor: initializing now...`);
    this.accessTokens = {
      api: null,
      address: null,
    };
    this.store = store;
    this.urls = {};
    this.tokenPromises = {
      api: null,
      address: null,
    };
  }

  static getInstance(store?: any): API {
    if (!API.instance) {
      API.instance = new API(store);
      // since you can enter arbitrary points of the app via url, get api token here if it exists.
      const oktaToken = API.instance.getLocalOktaToken();
      if (oktaToken && oktaToken.accessToken) {
        API.instance.setToken(
          Scope.API,
          oktaToken.accessToken.accessToken,
          moment.unix(oktaToken.accessToken.expiresAt).toDate()
        );
      }
    }
    return API.instance;
  }

  getLocalOktaToken() {
    const tokensString = window.sessionStorage.getItem('okta-token-storage');
    if (tokensString) {
      return JSON.parse(tokensString);
    }
    return null;
  }

  /**
   * Set access token for a particular scope
   * @param scope the name of the scope.
   * @param token the access token.
   * @param expiry the expiry time of the access token.
   */
  setToken(scope: Scope, token: string, expiry: Date) {
    Logger.debug(
      `API -> setToken: Setting the access token for scope ${scope} now. Expiry date: ${moment(expiry).format(
        'YYYY-MM-DD HH:mm:ss'
      )}`
    );
    // Below logging is for debugging API related issues ONLY. DO NOT ENABLE ON PRODUCTION!!
    // Logger.debug(`API -> setToken: access token for scope ${scope} is: \n${token}`);
    // Above logging is for debugging API related issues ONLY. DO NOT ENABLE ON PRODUCTION!!
    this.accessTokens[scope] = {
      token,
      expiry,
    } as AccessToken;
  }

  /**
   * Private: Get access token for a scope.
   * @param scope the scope for the access token.
   */
  async getToken(scope: Scope = Scope.API): Promise<AccessToken> {
    // since okta seems to manage it's tokens itself. Just grab that token and be done with it.
    if (scope === Scope.API) {
      const oktaToken = this.getLocalOktaToken();
      if (oktaToken && oktaToken.accessToken) {
        return { token: oktaToken.accessToken.accessToken, expiry: oktaToken.accessToken.expiresAt };
      }
    }

    const token: AccessToken = this.accessTokens[scope];
    if (token && moment().add(AUTH_EXPIRY_BUFFER, 'seconds').isBefore(token.expiry)) {
      return token;
    }

    if (scope === Scope.API) {
      if (token) {
        // if token is expiring soon, renew it first.
        // https://github.com/okta/okta-auth-js token.renew(tokenToRenew)
        const oktaToken = this.getLocalOktaToken();
        if (oktaToken && oktaToken.accessToken) {
          const newOktaToken: any = await oktaAuth.token.renew(oktaToken.accessToken);
          if (!newOktaToken) {
            // logout the user
            Logger.warn('API -> getToken: Failed to renew okta token, logging out');
            LogoutUser();
            return null;
          }
          this.setToken(Scope.API, newOktaToken.accessToken, moment.unix(newOktaToken.expiresAt).toDate());

          const newToken: AccessToken = this.accessTokens[scope];
          Logger.debug(`API -> getToken: New token expiry is: ${newToken.expiry}`);
          if (newToken && moment().add(AUTH_EXPIRY_BUFFER, 'seconds').isBefore(newToken.expiry)) {
            return newToken;
          }
        }
      }
    } else if (scope === Scope.ADDRESS) {
      try {
        if (!this.tokenPromises[scope]) {
          this.tokenPromises[scope] = this.store.dispatch(addressActions.getToken());
        }
        await this.tokenPromises[scope];
      } catch (e) {
        Logger.info(`API -> getToken: Unable to obtain ADDRESS access token at the moment.`);
      } finally {
        this.tokenPromises[scope] = null;
      }
    }

    // if there is still no access token, return null
    Logger.error('API -> getToken: unable to aquire valid token');
    return null;
  }

  /**
   * Private: Append access token to the request. This method MUTATES the config object passed in.
   * @param config the Axios request config
   * @param scope the scope of the request
   */
  async appendToken(config: AxiosRequestConfig, scope: Scope = Scope.API): Promise<void> {
    if (scope) {
      // if a scope is given, we need to append the access token in the request header.
      const accessToken = await this.getToken(scope);

      if (!accessToken) {
        LogoutUser();
        throw new Error(`API -> appendToken: NOT_AUTHORIZED No access token can be obtained for the scope: ${scope}`);
      }
      config.headers.Authorization = `Bearer ${accessToken.token}`;
    }
  }

  /**
   * Wrapper for API GET method for preventing caching in the donor API.
   * @param path the path of the API.
   * @param params the key-value pair parameters.
   * @param opts additional options for the donor API.
   * @param scope optional scope for the api. If null is passed in, the call won't attach any access token.
   */
  async get(path: string, params?: object, opts?: AxiosRequestConfig, scope?: Scope) {
    Logger.info(`API -> get: Getting data from ${path}`);
    const config: AxiosRequestConfig = {
      ...getCommonConfig(),
      ...opts,
      params,
    };

    try {
      await this.appendToken(config, scope);
      const response = await axios.get(path, config);
      return { ...response.data, headers: response.headers };
    } catch (e) {
      Logger.error(`API -> get: Error in getting the data`, e);
      throw e;
    }
  }

  /**
   * Wrapper for API HEAD method for preventing caching in the donor API.
   * @param path the path of the API.
   * @param params the key-value pair parameters.
   * @param opts additional options for the donor API.
   * @param scope optional scope for the api. If null is passed in, the call won't attach any access token.
   */
  async head(path: string, params?: object, opts?: AxiosRequestConfig, scope?: Scope) {
    Logger.info(`API -> head: Getting data from ${path}`);
    const config: AxiosRequestConfig = {
      ...getCommonConfig(),
      ...opts,
      params,
    };

    try {
      await this.appendToken(config, scope);
      const response = await axios.head(path, config);
      return response.headers;
    } catch (e) {
      Logger.error(`API -> head: Error in getting the data`, e);
      throw e;
    }
  }

  /**
   * Wrapper for  API PATCH method.
   * @param path the path of the API.
   * @param patch the key-value pair patch.
   * @param opts additional options for the donor API.
   * @param scope optional scope for the api. If null is passed in, the call won't attach any access token.
   */
  async patch(path: string, patch: object, opts?: AxiosRequestConfig, scope?: Scope) {
    Logger.info(`API -> patch: Patch data to ${path}`);
    const config: AxiosRequestConfig = {
      ...getCommonConfig(),
      ...opts,
    };

    try {
      await this.appendToken(config, scope);
      const response = await axios.patch(path, patch, config);
      return response.data;
    } catch (e) {
      Logger.error(`API -> patch: Error in patching the data`, e);
      throw e;
    }
  }

  /**
   * Wrapper for donor API POST method.
   * @param path the path of the API.
   * @param payload the payload of the post method.
   * @param opts additional options for the donor API.
   * @param scope optional scope for the api. If null is passed in, the call won't attach any access token.
   */
  async post(path: string, payload: object, opts?: AxiosRequestConfig, scope?: Scope) {
    Logger.info(`API -> post: Posting data to ${path}`);
    const config: AxiosRequestConfig = {
      ...getCommonConfig(),
      ...opts,
    };

    try {
      await this.appendToken(config, scope);
      const response = await axios.post(path, payload, config);
      return response.data;
    } catch (e) {
      Logger.error(`API -> post: Error in posting the data`, e);
      throw e;
    }
  }

  /**
   * Wrapper for donor API PUT method.
   * @param path the path of the API.
   * @param payload the payload of the post method.
   * @param opts additional options for the donor API.
   * @param scope optional scope for the api. If null is passed in, the call won't attach any access token.
   */
  async put(path: string, payload: object, opts?: AxiosRequestConfig, scope?: Scope) {
    Logger.info(`API -> put: Putting data in ${path}`);
    const config: AxiosRequestConfig = {
      ...getCommonConfig(),
      ...opts,
    };

    try {
      await this.appendToken(config, scope);
      const response = await axios.put(path, payload, config);
      return response.data;
    } catch (e) {
      Logger.error(`API -> post: Error in puting the data`, e);
      throw e;
    }
  }

  /**
   * Wrapper for donor API DELETE method.
   * @param path the path of the API.
   * @param opts additional options for the donor API.
   * @param scope optional scope for the api. If null is passed in, the call won't attach any access token.
   */
  async delete(path: string, opts?: AxiosRequestConfig, scope?: Scope) {
    Logger.info(`API -> del: Deleting data from ${path}`);
    const config: AxiosRequestConfig = {
      ...getCommonConfig(),
      ...opts,
    };

    try {
      await this.appendToken(config, scope);
      const response = await axios.delete(path, config);
      return response.data;
    } catch (e) {
      Logger.error(`API -> del: Error in deleting the data`, e);
      throw e;
    }
  }

  getApiError(error: AxiosError): ApiError {
    if (!error.response) {
      // If there is no response return null
      return null;
    }

    const statusCode: number = error.response.status;
    const responseData: string = error.response.data;
    let message: string = responseData;
    let name: string = null;
    if (isValidJSON(responseData)) {
      // If the response is a JSON. try to get the error message out of it
      const jsonData = JSON.parse(responseData);
      if (jsonData.message) {
        message = jsonData.message;
      } else if (jsonData.error) {
        message = jsonData.error;
      } else if (jsonData.errorMessage) {
        message = jsonData.errorMessage;
      }

      if (jsonData.name) {
        name = jsonData.name;
      } else if (jsonData.code) {
        name = jsonData.code;
      } else if (jsonData.errorName) {
        name = jsonData.errorName;
      }
    }

    return {
      message,
      name,
      statusCode,
    } as ApiError;
  }
}
