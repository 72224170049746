import React, { useState } from 'react';
import './PersonScreen.scss';
import {
  ButtonPrimary,
  ContainerCard,
  DetailText,
  GenotypeTable,
  GenotypeTypes,
  Spinner,
  TextArea,
} from '../../components';
import '../../styles.scss';
import {
  BloodGroup,
  Permission,
  Person,
  PersonDerived,
  PersonErrorCode,
  PersonErrorName,
  PersonStatus,
} from '../../constants/Interfaces';
import moment from 'moment';
import { getAddressString, getPreviousNameLabel, getReferenceLabel } from '../../constants/Utils';
import { selectPermission } from '../../constants/Selectors';
import Logger from '../../constants/Logger';
import { personActions } from '../../reducers/person';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Messages, PersonFieldLabels, UI } from '../../constants/Messages';

interface Props {
  person?: Person;
  bloodGroups: BloodGroup[];
  handleSave: (personId) => void;
  handleSaveError: (error, fields?) => void;
  scrollMode: boolean;
}

const LOG_PREFIX = 'Screen -> PersonView ->';

export default function PersonView(props: Props) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const canViewDonorId = useAppSelector(selectPermission(Permission.canViewDonorId));
  const canViewMobile = useAppSelector(selectPermission(Permission.canViewMobile));
  const canViewEmail = useAppSelector(selectPermission(Permission.canViewEmail));
  const canViewAddress = useAppSelector(selectPermission(Permission.canViewAddress));
  const canSubmitPerson = useAppSelector(selectPermission(Permission.canSubmitPerson));
  const canVerifyPerson = useAppSelector(selectPermission(Permission.canVerifyPerson));

  const personDerived: PersonDerived = useAppSelector((state) => state.person.currentPersonDerived);

  function submitVerification() {
    if (person.status === PersonStatus.DRAFT) {
      submitStatusChange(PersonStatus.PENDING_VERIFICATION);
    } else if (person.status === PersonStatus.PENDING_VERIFICATION) {
      submitStatusChange(PersonStatus.VERIFIED);
    } else {
      Logger.error(`${LOG_PREFIX} submitVerification: Person in incorrect status`);
      toast(Messages.PERSON_VIEW_STATUS_CHANGE_ERROR);
    }
  }

  async function submitStatusChange(status: PersonStatus) {
    const { personId, abrNumber } = person;
    try {
      if (!personId) {
        Logger.error(`${LOG_PREFIX} submit: Person ID is not set`);
        throw new Error('Person ID is not set');
      }
      setLoading(true);
      if (status === PersonStatus.PENDING_VERIFICATION) {
        await dispatch(personActions.patchPerson({ personId, status, canVerify: 0 }));
      } else await dispatch(personActions.patchPerson({ personId, status }));
      props.handleSave(abrNumber);
    } catch (error: any) {
      Logger.error(`${LOG_PREFIX} submit: Error occurred while saving the person`, error);
      // if it's an invalid input, then refresh the person record because the status might be out of date
      if (
        error.response &&
        error.response.status === PersonErrorCode.BAD_DATA &&
        error.response.data?.errorCode === PersonErrorName.INVALID_REQUEST
      ) {
        dispatch(personActions.getPerson(abrNumber));
        toast(Messages.PERSON_VIEW_STATUS_CHANGE_API_ERROR);
      } else {
        // send to global person error hander for things like 403
        props.handleSaveError(error);
      }

      return;
    } finally {
      setLoading(false);
    }
  }

  const { person, scrollMode } = props;
  // Important react hooks safety tip. Don't store the props in a useState hook
  // if you do it's the components responsibilty to respond to prop changes with useEffect.

  if (!person) {
    return (
      <div className="person-details" style={{ flexGrow: 1, flexDirection: 'row', justifyContent: 'center' }}>
        <ContainerCard>
          <Spinner />
        </ContainerCard>
      </div>
    );
  }

  const previousNames = getPreviousNameLabel(person);
  const scollModeStyle: any = scrollMode ? { maxHeight: '30vh', overflowY: 'scroll' } : {};

  return (
    <div
      className="person-details"
      style={{ flexGrow: 1, flexDirection: 'row', justifyContent: 'center', marginTop: 0 }}
    >
      <ContainerCard style={{ ...scollModeStyle }} className="printNoHeight personViewCard">
        <div style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <div
            style={{
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <DetailText label={PersonFieldLabels.LAST_NAME} detail={person.lastName} labelStyle={{ minWidth: 180 }} />
            <DetailText label={PersonFieldLabels.FIRST_NAME} detail={person.firstName} labelStyle={{ minWidth: 180 }} />
            <DetailText
              label={PersonFieldLabels.MIDDLE_NAME}
              detail={person.middleName}
              labelStyle={{ minWidth: 180 }}
            />
            <DetailText label={PersonFieldLabels.GENDER} detail={person.gender} labelStyle={{ minWidth: 180 }} />
            <DetailText
              label={PersonFieldLabels.DATE_OF_BIRTH}
              detail={moment(person.dob).format('DD/MM/YYYY')}
              labelStyle={{ minWidth: 180 }}
            />
          </div>
          <div style={{ flexDirection: 'column', flex: 1 }}>
            {(person.hospitalNumber || !person.donorId) && (
              <DetailText
                label={PersonFieldLabels.HOSPITAL_NUMBER}
                detail={person.hospitalNumber}
                labelStyle={{ minWidth: 173 }}
              />
            )}
            {canViewDonorId && person.donorId && (
              <DetailText label={PersonFieldLabels.DONOR_ID} detail={person.donorId} labelStyle={{ minWidth: 173 }} />
            )}
            {canViewAddress && (
              <DetailText
                label={PersonFieldLabels.ADDRESS}
                detail={person.address ? getAddressString(person.address) : ''}
                labelStyle={{ minWidth: 173 }}
              />
            )}
            {canViewEmail && (
              <DetailText label={PersonFieldLabels.EMAIL} detail={person.email} labelStyle={{ minWidth: 173 }} />
            )}
            {canViewMobile && (
              <DetailText label={PersonFieldLabels.MOBILE} detail={person.mobile} labelStyle={{ minWidth: 173 }} />
            )}
          </div>
        </div>
        <div style={{ flexDirection: 'row', justifyContent: 'left' }}>
          <DetailText label={PersonFieldLabels.PREVIOUS_NAME_1} detail={previousNames} labelStyle={{ minWidth: 180 }} />
        </div>
        <div className="divider" style={{ marginTop: 10 }} />
        <div style={{ marginTop: 10 }}>
          <DetailText
            label={PersonFieldLabels.BLOOD_GROUP}
            detail={getReferenceLabel(props.bloodGroups, person.bloodGroupRefId)}
            labelStyle={{ minWidth: 180 }}
          />
          <DetailText
            label={PersonFieldLabels.KNOWN_PHENOTYPE}
            detail={personDerived ? personDerived.knownPhenotypes : ''}
            labelStyle={{ minWidth: 180 }}
          />
          <DetailText
            label={PersonFieldLabels.KNOWN_ANTIBODIES}
            detail={personDerived ? personDerived.knownAntibodies : ''}
            labelStyle={{ minWidth: 180 }}
          />
          <DetailText
            label={PersonFieldLabels.RECOMMENDED_BLOOD}
            detail={personDerived ? personDerived.recommendBloodTransfusion : ''}
            labelStyle={{ maxWidth: 180 }}
          />
        </div>
        <div className="marginNarrowTop">
          <div style={{ flexGrow: 1, flexDirection: 'row' }}>
            {person.rhdGenotype && (
              <GenotypeTable
                label={PersonFieldLabels.RHD_GENOTYPE}
                labelStyle={{ minWidth: 180 }}
                value={person.rhdGenotype}
                type={GenotypeTypes.RHD}
                style={{ flex: 1, maxWidth: '50%' }}
              />
            )}
            {person.rhceGenotype && (
              <GenotypeTable
                label={PersonFieldLabels.RHCE_GENOTYPE}
                labelStyle={{ minWidth: 180 }}
                value={person.rhceGenotype}
                type={GenotypeTypes.RHCE}
                style={{ flex: 1, maxWidth: '50%' }}
              />
            )}
          </div>

          {person.heaGenotype && (
            <GenotypeTable
              label={PersonFieldLabels.HEA_GENOTYPE}
              labelStyle={{ minWidth: 180 }}
              value={person.heaGenotype}
              type={GenotypeTypes.HEA}
              style={{ flexDirection: 'row' }}
            />
          )}
          {(person.rhdGenotype || person.heaGenotype || person.rhceGenotype || person.genotypeComments) && (
            <TextArea
              label={PersonFieldLabels.GENOTYPE_COMMENTS}
              value={person.genotypeComments}
              inputStyle={{ height: 40 }}
              containerStyle={{ marginRight: 22 }}
              shouldShowIcon={false}
              style={{ marginTop: 0 }}
              className="printHide"
              disabled
            />
          )}
        </div>
        <div className="printHide paddingNarrowTop">
          <TextArea
            label={PersonFieldLabels.COMMENTS}
            value={person.personComments}
            containerStyle={{ marginRight: 22 }}
            shouldShowIcon={false}
            disabled
          />
        </div>

        {loading ? (
          <div style={{ marginLeft: 40, marginTop: 20, height: 57, justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {(person.status === PersonStatus.DRAFT && canSubmitPerson) ||
            (person.status === PersonStatus.PENDING_VERIFICATION && canVerifyPerson) ? (
              <ButtonPrimary
                title={
                  person.status === PersonStatus.DRAFT
                    ? UI.PERSON_VIEW_STATUS_SUBMIT_BUTTON
                    : UI.PERSON_VIEW_STATUS_VERIFY_BUTTON
                }
                buttonClass="clay-outline printHide"
                disabled={person.canVerify !== 1 && person.status === PersonStatus.PENDING_VERIFICATION}
                tooltip={
                  person.canVerify !== 1 && person.status === PersonStatus.PENDING_VERIFICATION
                    ? 'Verification must be performed by a second operator'
                    : ''
                }
                containerStyle={{ marginTop: 20, flexDirection: 'row', paddingLeft: 0 }}
                onClick={submitVerification}
              />
            ) : null}
          </>
        )}
      </ContainerCard>
    </div>
  );
}
