import { combineReducers } from 'redux';
import user from './user';
import config from './config';
import dashboard from './dashboard';
import person from './person';
import personResults from './personResults';
import personConsent from './personConsent';
import personCard from './personCard';
import reference from './reference';
import permissions from './permissions';
import address from './address';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';

const basePersistConfig = {
  storage: storageSession,
  blacklist: ['error', 'loading'],
};

const userPersistConfig = { ...basePersistConfig, key: 'user' };
const configPersistConfig = { ...basePersistConfig, key: 'config' };
const dashboardPersistConfig = { ...basePersistConfig, key: 'dashboard' };
const personPersistConfig = { ...basePersistConfig, key: 'person' };
const personResultsPersistConfig = { ...basePersistConfig, key: 'personResults' };
const personConsentPersistConfig = { ...basePersistConfig, key: 'personConsent' };
const personCardPersistConfig = { ...basePersistConfig, key: 'personCard' };
const referencePersistConfig = { ...basePersistConfig, key: 'reference' };
const permissionsPersistConfig = { ...basePersistConfig, key: 'permissions' };
const addressPersistConfig = { ...basePersistConfig, key: 'address', blacklist: ['error', 'loading', 'source'] };

export default combineReducers({
  user: persistReducer(userPersistConfig, user),
  config: persistReducer(configPersistConfig, config),
  dashboard: persistReducer(dashboardPersistConfig, dashboard),
  person: persistReducer(personPersistConfig, person),
  personResults: persistReducer(personResultsPersistConfig, personResults),
  personConsent: persistReducer(personConsentPersistConfig, personConsent),
  personCard: persistReducer(personCardPersistConfig, personCard),
  reference: persistReducer(referencePersistConfig, reference),
  permissions: persistReducer(permissionsPersistConfig, permissions),
  address: persistReducer(addressPersistConfig, address),
});
