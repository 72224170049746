import { ActionType, AppError, ErrorType, PermissionsResponse } from '../constants/Interfaces';
import API from '../constants/API';
import { mapApiError } from '../constants/Utils';
import Logger from '../constants/Logger';

interface State {
  permissions: object;
  permissionsDebug: boolean;
  loading: boolean;
  error: AppError;
}

export function getInitialState(): State {
  return {
    permissions: null,
    permissionsDebug: false,
    loading: false,
    error: null,
  };
}

export const permissionTypes = {
  PERMISSIONS_GET_REQUEST: 'PERMISSIONS_GET_REQUEST',
  PERMISSIONS_CLEAR: 'PERMISSIONS_CLEAR',
  PERMISSIONS_GET_RESPONSE: 'PERMISSIONS_GET_RESPONSE',
  PERMISSIONS_SET_DEBUG: 'PERMISSIONS_SET_DEBUG',
};

const LOG_PREFIX = `Reducer: permissions ->`;

export const permissionsActions = {
  getPermissions: () => async (dispatch) => {
    dispatch({ type: permissionTypes.PERMISSIONS_GET_REQUEST });
    Logger.info(`${LOG_PREFIX} getPermissions: Getting reference information`);
    try {
      const api = API.getInstance();
      const response: PermissionsResponse = await api.get(`/permissions`);
      Logger.debug(`${LOG_PREFIX} getPermissions: reference information received.`);
      dispatch({ type: permissionTypes.PERMISSIONS_GET_RESPONSE, error: false, payload: response });
      return response.records;
    } catch (e) {
      dispatch({
        type: permissionTypes.PERMISSIONS_GET_RESPONSE,
        payload: mapApiError(ErrorType.REFERENCE_ERROR, e),
        error: true,
      });
      throw e;
    }
  },

  clearPermissions: () => async (dispatch) => {
    dispatch({ type: permissionTypes.PERMISSIONS_CLEAR });
  },
};

export default function permissions(state = getInitialState(), action) {
  const { type, payload, error } = action;

  switch (type) {
    case permissionTypes.PERMISSIONS_GET_REQUEST: {
      return { ...state, loading: true, error: null };
    }

    case permissionTypes.PERMISSIONS_GET_RESPONSE: {
      if (error) {
        return { ...state, error: payload, loading: false, currentPerson: null };
      }
      const permissionsArray = payload.records;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const permissions = {};
      permissionsArray.forEach((item) => {
        permissions[item] = true;
      });
      return {
        ...state,
        permissions,
        loading: false,
        error: null,
      };
    }

    case permissionTypes.PERMISSIONS_SET_DEBUG: {
      const permissionsDebug = payload;
      return {
        ...state,
        permissionsDebug,
      };
    }

    case permissionTypes.PERMISSIONS_CLEAR: {
      return {
        ...state,
        permissions: null,
      };
    }

    case ActionType.PURGE: {
      return getInitialState();
    }
  }

  return state;
}
