import React, { useEffect, useState } from 'react';
import { ButtonPrimary, ContainerCard, DateInput, Header, HeaderButton, SelectInput, Spinner } from '../components';

import { ReportValidators, useValidatedField, validate } from '../constants/Validators';
import {
  GenericErrorCode,
  PersonConsentStatus,
  ReportConsentStatusOptions,
  ReportErrorCode,
  ReportSearchParams,
  ValidationState,
} from '../constants/Interfaces';
import moment from 'moment-timezone';
import Logger from '../constants/Logger';
import _ from 'lodash';
import Layout from '../constants/Layout';
import { toast, ToastContainer } from 'react-toastify';
import { Messages, ReportConsentFieldLabels, UI } from '../constants/Messages';
import { useEventListener } from '../constants/Utils';
import API from '../constants/API';

const LOG_PREFIX = 'Screen -> Report ->';

export default function Report() {
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  useEventListener('keydown', onKeyDown);

  const fields = {
    dateFrom: useValidatedField('', 'dateFrom'),
    dateTo: useValidatedField('', 'dateTo'),
    consentStatus: useValidatedField('', 'consentStatus'),
  };

  useEffect(() => {
    fields.dateFrom.setValue(moment().subtract(2, 'months').format('YYYY-MM-DD'));
    fields.dateTo.setValue(moment().subtract(1, 'months').format('YYYY-MM-DD'));
    fields.consentStatus.setValue(PersonConsentStatus.PENDING);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function clearSearch() {
    fields.dateFrom.clear();
    fields.dateTo.clear();
    fields.consentStatus.clear();
  }

  function onKeyDown(event) {
    if (event && event.key === 'Enter') {
      doGenerate();
    }
    if (event && event.key === 'Escape') {
      clearSearch();
    }
  }

  function handleFieldUpdate(field: string) {
    return function (value: string) {
      fields[field].setValue(value);
      fields[field].setValidation(ValidationState.NULL);
    };
  }

  function validateField(field) {
    return async function () {
      const { value } = fields[field];
      return validate(fields[field], value, ReportValidators);
    };
  }

  async function validateAllFields() {
    // Validating all the fields again.
    const validators: Promise<boolean>[] = [];
    _.each(Object.keys(fields), (field: string) => {
      validators.push(validateField(field)());
    });
    const results: boolean[] = await Promise.all(validators);

    // If any of the validation has failed, terminate the process immediately and scroll to the the respective field.
    const firstFailedFieldIndex = _.indexOf(results, false);
    if (firstFailedFieldIndex > -1) {
      Logger.debug(
        `${LOG_PREFIX} -> register: Validation failed for one or more fields. First failed field is ${firstFailedFieldIndex}`
      );
      return false;
    }

    if (!moment(fields.dateTo.value).isSameOrAfter(fields.dateFrom.value)) {
      fields.dateTo.setValidation(ValidationState.FAILED);
      fields.dateTo.setErrorMessage('Date To before Date From date');
      return false;
    }
    return true;
  }

  function getSearchParams(): ReportSearchParams {
    return {
      dateFrom: moment(fields.dateFrom.value).format('YYYY-MM-DD 00:00 Z'),
      dateTo: moment(fields.dateTo.value).format('YYYY-MM-DD 23:59 Z'),
      consentStatus: fields.consentStatus.value,
    };
  }

  async function doGenerate() {
    const validated = await validateAllFields();
    if (validated) {
      setIsLoadingAction(true);
      let resultURL = null;
      const api = API.getInstance();
      try {
        const path = 'reports/consent';
        const searchParams = getSearchParams();
        resultURL = await api.get(path, {
          dateFrom: searchParams.dateFrom,
          dateTo: searchParams.dateTo,
          consentStatus: searchParams.consentStatus,
        });
      } catch (error: any) {
        if (error?.response) {
          switch (error?.response.status) {
            case GenericErrorCode.PERMISSION_ERROR: {
              Logger.error(`${LOG_PREFIX} search: 403 in search`, error);
              toast(Messages.ERROR_403_API);
              break;
            }
            case GenericErrorCode.NOT_FOUND: {
              Logger.info(`${LOG_PREFIX} search: 404 - No Records Found`);
              toast('No consent records found');
              break;
            }
            case ReportErrorCode.INVALID_REQUEST: {
              Logger.error(`${LOG_PREFIX} search: 400 `, error);
              toast('An error occurred, field values were incorrect');
              break;
            }
            default: {
              // Scenario: server error
              Logger.error(`${LOG_PREFIX} search: Server error in report`, error);
              toast(Messages.ERROR_GENERIC);
            }
          }
        } else if (error?.request) {
          // Scenario: network error
          Logger.error(`${LOG_PREFIX} -> search: Network error in report`, error);
          // network error, show snack bar and let user try again.
          toast(Messages.ERROR_GENERIC);
        } else {
          // Scenario: application error
          Logger.error(`${LOG_PREFIX} -> search: Application error in report`, error);
          toast('Export download has failed');
        }
        setIsLoadingAction(false);
        return false;
      }
      try {
        const a = document.createElement('a');
        a.href = resultURL.fileURL;
        a.download = `Report-consents-export-${moment().format('YYYY-MM-DD')}`;
        a.click();
      } catch (error: any) {
        toast('Export download has failed. Regenerate export from the Exported - To Print list');
        setIsLoadingAction(false);
        return false;
      }
      setIsLoadingAction(false);
      return true;
    }
    return false;
  }

  return (
    <div className="search">
      <Header selectedButton={HeaderButton.Reports} />
      <div className="marginLarge">
        <ContainerCard title={UI.REPORT_CONSENT_TITLE}>
          <span style={{ marginTop: 10 }} />
          <DateInput
            label={ReportConsentFieldLabels.DATE_FROM}
            onValueChange={handleFieldUpdate('dateFrom')}
            value={fields.dateFrom.value}
            validationState={fields.dateFrom.validation}
            errorMessage={fields.dateFrom.errorMessage}
            ref={fields.dateFrom.inputRef}
            onBlur={validateField('dateFrom')}
            labelContainerStyle={{ minWidth: 175 }}
            style={{ marginLeft: Layout.backgroundMarginNarrow.left }}
            shouldShowIcon={false}
          />
          <DateInput
            label={ReportConsentFieldLabels.DATE_TO}
            onValueChange={handleFieldUpdate('dateTo')}
            value={fields.dateTo.value}
            validationState={fields.dateTo.validation}
            errorMessage={fields.dateTo.errorMessage}
            ref={fields.dateTo.inputRef}
            onBlur={validateField('dateTo')}
            labelContainerStyle={{ minWidth: 175 }}
            style={{ marginLeft: Layout.backgroundMarginNarrow.left }}
            shouldShowIcon={false}
          />
          <SelectInput
            label={ReportConsentFieldLabels.CONSENT_STATUS}
            clearable={false}
            onValueChange={handleFieldUpdate('consentStatus')}
            value={fields.consentStatus.value}
            errorMessage={fields.consentStatus.errorMessage}
            validationState={fields.consentStatus.validation}
            options={ReportConsentStatusOptions}
            ref={fields.consentStatus.inputRef}
            onBlur={validateField('consentStatus')}
            labelContainerStyle={{ minWidth: 175 }}
            style={{ marginLeft: Layout.backgroundMarginNarrow.left, maxWidth: 392 }}
          />
          <div style={{ flexDirection: 'row', width: 210, marginTop: 10 }}>
            {isLoadingAction ? (
              <Spinner style={{ height: 45 }} />
            ) : (
              <>
                <ButtonPrimary
                  title={UI.REPORT_GENERATE_BUTTON}
                  onClick={doGenerate}
                  containerStyle={{ marginLeft: 10, paddingLeft: 200, maxWidth: 200, minWidth: 200 }}
                />
                <ButtonPrimary title="Clear" onClick={clearSearch} />
              </>
            )}
          </div>
          <ToastContainer position="bottom-center" autoClose={10000} hideProgressBar draggable={false} />
        </ContainerCard>
      </div>
    </div>
  );
}
