import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

interface Props {
  style?: object;
  className?: string;
}

export class Spinner extends React.PureComponent<Props> {
  render() {
    const { style, className = '' } = this.props;
    return (
      <div
        className={`padding ${className}`}
        style={{ flex: 1, alignItems: 'center', justifyContent: 'center', ...style }}
      >
        <CircularProgress />
      </div>
    );
  }
}
