import moment from 'moment';
import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { toast } from 'react-toastify';
import { ButtonLink, Spinner, Table } from '../../../components';
import {
  AppError,
  BloodGroup,
  CardStatus,
  GenericErrorCode,
  Permission,
  PersonCard,
  PersonCardOptions,
} from '../../../constants/Interfaces';
import Logger from '../../../constants/Logger';
import { CardTabTableLabels, Messages, PersonFieldLabels } from '../../../constants/Messages';
import _ from 'lodash';
import './Consent.scss';
import { personCardActions } from '../../../reducers/personCard';
import { consentTabNewRowControls } from './ConsentTabEditRow';
import { ConfirmConfig, StatusTabStyle } from '../../../constants/UtilsTests';
import { getReferenceLabel } from '../../../constants/Utils';
import { selectPermission } from '../../../constants/Selectors';
import ErrorTab from './ErrorTab';
import { DropdownCell, TestUpdate, TextCell } from './TestTableCells';
import { ScreenMode } from '../PersonScreen';

const LOG_PREFIX = 'Component -> CardTab ->';

interface Props {
  editableCardStatus: CardStatus[];
  setEditableCardStatus: (value: CardStatus[]) => void;
  personId: string;
  confirmConfig: ConfirmConfig;
  setIsCardEditing: (value: boolean) => void;
}

const CardTab = forwardRef((props: Props, ref) => {
  const { editableCardStatus, setEditableCardStatus, personId, confirmConfig, setIsCardEditing } = props;

  const isLoading: boolean = useAppSelector((state) => state.personCard.loading);
  const isError: AppError = useAppSelector((state) => state.personCard.error);

  const bloodGroups: BloodGroup[] = useAppSelector((state) => state.reference.bloodGroups);

  const canAddCard = useAppSelector(selectPermission(Permission.canAddCard));
  /**
   * Initial get data
   */
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!editableCardStatus) {
      dispatch(personCardActions.getPersonCard(personId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Logic funcitons
   */

  useImperativeHandle(ref, () => ({
    cancelChanges() {
      // Note: only to be called via tab changes. As there is no current way to reach into the consentTabNewRowControls
      // and cancel out of the add new row controls except for rerendering the tab
      editableCardStatus.forEach((card) => {
        card.mode = ScreenMode.VIEW;
        card.comment = card.commentOriginal;
      });
      setEditableCardStatus(editableCardStatus.slice());
      setIsCardEditing(false);
    },
  }));

  function addNewRow() {
    return async function (row) {
      try {
        await dispatch(
          personCardActions.postPersonCard(
            {
              card: [
                {
                  status: row.status,
                },
              ],
            },
            personId
          )
        );
        setEditableCardStatus(_.orderBy(editableCardStatus, ['date', 'createdDate'], ['desc', 'desc']).slice());
      } catch (e: any) {
        Logger.error(`${LOG_PREFIX} addNewRow: Error occurred while saving a new row`, e);
        if (e.response && e.response.status === GenericErrorCode.PERMISSION_ERROR) {
          toast(Messages.ERROR_403_API);
        } else if (e.response && e.response.status === GenericErrorCode.BAD_DATA && e.response.data?.error) {
          toast(e.response.data?.error);
        } else {
          toast(Messages.ERROR_GENERIC);
        }
      }

      dispatch(personCardActions.getPersonCard(personId));
    };
  }

  const [cardNewEditing, setIsCardNewEditing] = useState(false);
  const [cardRowEditing, setIsCardRowEditing] = useState(false);
  const cardNewRow = consentTabNewRowControls(
    setIsCardNewEditing,
    addNewRow(),
    PersonCardOptions,
    [],
    confirmConfig,
    true
  );

  // if any of the tables are being edited, the tab is being edited
  useEffect(() => {
    if (cardNewEditing || cardRowEditing) {
      setIsCardEditing(true);
    } else {
      setIsCardEditing(false);
    }
  }, [cardNewEditing, cardRowEditing, setIsCardEditing]);

  const editRow = (cardHistoryId) => () => {
    editableCardStatus.forEach((card) => {
      if (card.cardHistoryId === cardHistoryId) {
        card.mode = ScreenMode.EDIT;
        card.commentOriginal = card.comment;
      }
    });
    setEditableCardStatus(editableCardStatus.slice());
    isAllRowsInViewMode(editableCardStatus);
  };

  const cancelRow = (cardHistoryId) => () => {
    editableCardStatus.forEach((card) => {
      if (card.cardHistoryId === cardHistoryId) {
        card.mode = ScreenMode.VIEW;
        card.comment = card.commentOriginal;
      }
    });
    setEditableCardStatus(editableCardStatus.slice());
    isAllRowsInViewMode(editableCardStatus);
  };

  const handleCommentUpdate = (update: TestUpdate) => (value) => {
    editableCardStatus.forEach((card) => {
      if (card.cardHistoryId === update.testResultId) {
        card.comment = value;
      }
    });
    setEditableCardStatus(editableCardStatus.slice());
  };

  const saveRow = (cardHistoryId) => () => {
    editableCardStatus.forEach((card) => {
      if (card.cardHistoryId === cardHistoryId) {
        // call patch api
        dispatch(personCardActions.patchPersonCard(personId, cardHistoryId, card.comment));
        card.mode = ScreenMode.VIEW;
      }
    });
    setEditableCardStatus(editableCardStatus.slice());
    isAllRowsInViewMode(editableCardStatus);
  };

  function isAllRowsInViewMode(editableCards: CardStatus[]) {
    for (const card of editableCards) {
      if (card.mode === ScreenMode.EDIT) {
        setIsCardRowEditing(true);
        return false;
      }
    }
    setIsCardRowEditing(false);
    return true;
  }

  function renderEditCell({ row }) {
    const { original } = row;
    if (original.mode === ScreenMode.EDIT) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 15, alignItems: 'flex-start' }}>
          <ButtonLink
            onClick={saveRow(original.cardHistoryId)}
            style={{ display: 'flex', marginTop: 2 }}
            title="Save"
          />
          <ButtonLink
            onClick={cancelRow(original.cardHistoryId)}
            style={{ display: 'flex', marginLeft: 15, marginTop: 2 }}
            title="Cancel"
          />
        </div>
      );
    }
    if (original.status === PersonCard.EXPORTED_TO_PRINT && canAddCard) {
      return (
        <div style={{ paddingLeft: 15, alignItems: 'flex-start' }}>
          <ButtonLink
            onClick={editRow(original.cardHistoryId)}
            style={{ display: 'flex', marginTop: 2 }}
            title="Edit"
          />
        </div>
      );
    }

    return <div style={{ height: 28 }} />;
  }

  const cardStatusColumns = [
    {
      Header: CardTabTableLabels.CARD_STATUS,
      accessor: statusCellFormatter(PersonCardOptions),
      style: { minWidth: 165, verticalAlign: 'top' },
    },
    {
      Header: CardTabTableLabels.CARD_DATE,
      accessor: dateCellUTCFormatter('date'),
      style: { minWidth: 119, width: 119, verticalAlign: 'top' },
    },
    {
      Header: PersonFieldLabels.FIRST_NAME,
      accessor: 'firstName',
      style: { verticalAlign: 'top', wordBreak: 'break-word', minWidth: 130 },
    },
    {
      Header: PersonFieldLabels.LAST_NAME,
      accessor: 'lastName',
      style: { verticalAlign: 'top', wordBreak: 'break-word', minWidth: 130 },
    },
    {
      Header: PersonFieldLabels.BLOOD_GROUP,
      accessor: 'bloodGroupRefId',
      Cell: DropdownCell,
      editable: false,
      selectOptions: bloodGroups,
      style: { textAlign: 'center', minWidth: 102, maxWidth: 110, verticalAlign: 'top' },
    },
    {
      Header: CardTabTableLabels.CARD_ANTIBODIES,
      accessor: 'knownAntibodies',
      style: { verticalAlign: 'top' },
    },
    {
      Header: CardTabTableLabels.CARD_SAMPLE_DATE,
      accessor: dateCellFormatter('sampleDate'),
      style: { minWidth: 119, width: 119, verticalAlign: 'top' },
    },
    {
      Header: PersonFieldLabels.RECOMMENDED_BLOOD,
      accessor: 'recommendedBloodTransfusion',
      style: { textAlign: 'left', verticalAlign: 'top', wordBreak: 'break-word', minWidth: 130 },
    },
    {
      Header: CardTabTableLabels.CARD_MANUAL_CHANGES,
      accessor: 'comment',
      Cell: TextCell,
      style: { textAlign: 'left', verticalAlign: 'top', minWidth: 208 },
      styleEdit: { marginTop: 0 },
      updateFunction: handleCommentUpdate,
      keyAttribute: 'cardHistoryId',
      maxLength: 50,
    },
    {
      Header: '',
      accessor: 'delete',
      Cell: renderEditCell,
      style: { minWidth: 118, maxWidth: 118, verticalAlign: 'top' },
      className: 'transparent-header',
    },
  ];

  const scrollModeStyle = StatusTabStyle;

  if (isLoading) {
    return (
      <div className="consent" style={{ justifyContent: 'center', minHeight: '30vh' }}>
        <Spinner />
      </div>
    );
  }

  if (isError) {
    // if a 403 error is in the error object.
    if (isError.code === '403') {
      return <ErrorTab message={Messages.ERROR_403_SCREEN} />;
    }
    return (
      <ErrorTab
        retryFunction={() => {
          dispatch(personCardActions.getPersonCard(personId));
        }}
      />
    );
  }

  return (
    <div className="consent">
      <div className="row">
        <div style={{ width: '100%', flex: 1, ...scrollModeStyle }} className="column">
          <Table
            data={editableCardStatus}
            columns={cardStatusColumns}
            noDataMessage="No consent status results to display"
            style={{ width: '99%' }}
            className="sticky brand altRowBackground"
            showNoDataMessage={false}
            preElements={canAddCard ? cardNewRow : null}
          />
        </div>
      </div>
    </div>
  );
});

export default CardTab;

const statusCellFormatter = (options) => (originalRow: CardStatus) =>
  originalRow.status ? getReferenceLabel(options, originalRow.status, 'value') : null;

const dateCellUTCFormatter = (column) => (originalRow) =>
  originalRow[column] ? moment.utc(originalRow[column]).local().format('DD/MM/YYYY') : '';

const dateCellFormatter = (column) => (originalRow) =>
  originalRow[column] ? moment(originalRow[column]).format('DD/MM/YYYY') : '';
