import React from 'react';
import './PersonBar.scss';
import { ButtonPrimary, Text } from '../../../components';
import { Permission, Person, PersonDerived, PersonType } from '../../../constants/Interfaces';
import { UI } from '../../../constants/Messages';
import ConsentLabel from '../../components/ConsentLabel';
import StatusLabel from '../../components/StatusLabel';
import { useAppSelector } from '../../../store/hooks';
import { selectPermission } from '../../../constants/Selectors';

// const LOG_PREFIX = 'Component -> PersonBar ->';

interface Props {
  person: Person;
  type: PersonType;
  onEdit?: () => void;
  onCancel?: () => void;
  onPDF: () => void;
  editMode: boolean;
}

export default function PersonBar(props: Props) {
  const { person, type, onEdit, onPDF, onCancel, editMode } = props;

  const canPrintPDF = useAppSelector(selectPermission(Permission.canPrintPDF));
  const canModifyPerson = useAppSelector(selectPermission(Permission.canModifyPerson));

  const isPrintingReady = useAppSelector((state) => !state.person.loading && !state.personResults.loading);

  const personDerived: PersonDerived = useAppSelector((state) => state.person.currentPersonDerived);

  const title = `${type} ${UI.PERSON_SCREEN_RECORD_SUFFIX}`;

  return (
    <div className="person-bar" style={{ display: 'flex', flexDirection: 'row' }}>
      <div className="action-bar">
        <div className="label-container">
          <Text className={person ? 'heading4' : 'heading2'}>{title}</Text>
          {person && person.status ? (
            <div style={{ flexDirection: 'row' }}>
              <Text className="label">{`${UI.ABR_NUMBER_SHORT}: ${person.abrNumber}`}</Text>
              <StatusLabel status={person.status} />
              <ConsentLabel consent={personDerived ? personDerived.latestConsentStatus : null} />
            </div>
          ) : null}
        </div>
        {canPrintPDF && (
          <Text
            className={editMode || !isPrintingReady ? 'link-disabled printHide' : 'link printHide'}
            style={{ marginLeft: 'auto' }}
            onClick={onPDF}
          >
            {UI.PERSON_SCREEN_PRINT_PDF_BUTTON}
          </Text>
        )}
        <div className="printHide">
          {editMode ? (
            <ButtonPrimary
              title={UI.PERSON_SCREEN_CANCEL_BUTTON}
              onClick={onCancel}
              containerStyle={{ paddingRight: 0 }}
            />
          ) : (
            <>
              {canModifyPerson && (
                <ButtonPrimary
                  title={UI.PERSON_SCREEN_EDIT_BUTTON}
                  onClick={onEdit}
                  containerStyle={{ paddingRight: 0 }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
