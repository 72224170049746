import React, { RefObject } from 'react';
import { Text } from '../text/Text';
import Colors, { transparentize } from '../../constants/Colors';
import { ElementLocation, ValidationState } from '../../constants/Interfaces';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import './TextArea.scss';
import Logger from '../../constants/Logger';

interface State {}

interface Props {
  disabled?: boolean;
  inputStyle?: object;
  labelStyle?: object;
  style?: object;
  className?: string;
  containerStyle?: object;
  label: string;
  onValueChange?: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  value: any;
  placeholder?: string;
  icon?: any;
  shouldShowIcon?: boolean;
  shouldShowLabel?: boolean;
  required?: boolean;
  validationState?: ValidationState;
  errorMessage?: string;
  inProgressMessage?: string;
  instructionMessage?: string;
  maxLength?: number;
  nextField?: RefObject<any>;
}

export class TextArea extends React.Component<Props, State> {
  private readonly inputRef: RefObject<HTMLTextAreaElement>;
  private readonly viewRef: RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.viewRef = React.createRef();
  }

  getLocation() {
    const { label } = this.props;
    Logger.debug(`Component -> TextArea -> getLocation: measuring location now for field ${label}`);
    if (this.viewRef && this.viewRef.current) {
      const rect = this.viewRef.current.getBoundingClientRect();
      Logger.debug(`Field ${label} location: ${rect.x}, ${rect.y}`);
      return {
        x: rect.x + window.scrollX,
        y: rect.y + window.scrollY,
      } as ElementLocation;
    }
    return null;
  }

  getInputRef() {
    return this.inputRef;
  }

  render() {
    const {
      disabled = false,
      inputStyle,
      label,
      labelStyle,
      style,
      className = '',
      containerStyle,
      placeholder,
      required,
      validationState = ValidationState.NULL,
      errorMessage,
      inProgressMessage,
      instructionMessage,
      icon,
      shouldShowIcon = true,
      shouldShowLabel = true,
      value,
      onValueChange,
      onBlur,
      onFocus,
      maxLength = 500,
    } = this.props;

    const borderStyle = () => {
      if (validationState === ValidationState.FAILED) return { borderColor: Colors.red, borderStyle: 'solid' };
      if (disabled) return { borderColor: transparentize(Colors.black, 0.2), borderStyle: 'solid' };
      return { borderColor: '#edd0d5', borderStyle: 'solid' };
    };

    const valueColor = () => {
      if (validationState === ValidationState.FAILED) return Colors.red;
      return Colors.black;
    };

    const labelColor = () => {
      if (validationState === ValidationState.FAILED) return Colors.red;
      return Colors.darkStone;
    };

    const rightIcon = () => {
      // if should show icon is disabled, do not show icon
      if (!shouldShowIcon) {
        return null;
      }

      let iconColor = Colors.black;
      if (validationState === ValidationState.FAILED) {
        iconColor = Colors.red;
      }
      if (validationState === ValidationState.PASSED) {
        iconColor = Colors.green;
      }

      // if icon is specified, use that but override the icon color
      // TODO: does this work
      if (icon) {
        return {
          size: 20,
          ...icon,
          color: iconColor,
        };
      }

      if (validationState === ValidationState.PASSED) {
        return <AiOutlineCheckCircle color={Colors.green} size={20} />;
      }
      if (validationState === ValidationState.FAILED) {
        return <AiOutlineCloseCircle color={Colors.red} size={20} />;
      }

      // default no icon
      return null;
    };

    return (
      <div className={`text-area ${className}`} style={{ ...styles.container, ...style }}>
        {shouldShowLabel && (
          <div style={styles.labelContainer}>
            <Text className="label" style={{ ...styles.labelText, ...labelStyle, ...{ color: labelColor() } }}>
              {label}
            </Text>
            {required ? (
              <Text className="label" style={{ ...styles.requiredAsterisks, ...{ color: labelColor() } }}>
                *
              </Text>
            ) : null}
          </div>
        )}
        <div style={{ flexDirection: 'row', flexGrow: 1, ...containerStyle }}>
          <div className="container" style={{ ...borderStyle(), flexGrow: 1 }}>
            <textarea
              onChange={onValueChange ? (result) => onValueChange(result.target.value) : null}
              autoComplete="off"
              disabled={disabled}
              placeholder={placeholder}
              className="input"
              style={{
                ...{ resize: 'vertical', height: 100, color: valueColor() },
                ...inputStyle,
              }}
              value={value || ''}
              onBlur={onBlur}
              onFocus={onFocus}
              maxLength={maxLength}
              ref={this.inputRef}
            />
          </div>
          {shouldShowIcon && <div className="icon">{rightIcon()}</div>}
        </div>
        {instructionMessage && !inProgressMessage && validationState !== ValidationState.FAILED && (
          <Text className="instruction-text" style={{ ...styles.instructionMessage }}>
            {instructionMessage}
          </Text>
        )}
        {inProgressMessage && validationState === ValidationState.NULL && (
          <Text className="instruction-text" style={{ ...styles.instructionMessage }}>
            {inProgressMessage}
          </Text>
        )}
        {errorMessage && validationState === ValidationState.FAILED && (
          <Text className="instruction-text" style={{ ...styles.errorMessage }}>
            {errorMessage}
          </Text>
        )}
      </div>
    );
  }
}

const styles = {
  container: {
    opacity: 1,
    flexGrow: 1,
  },
  labelContainer: {
    flexDirection: 'row' as 'row',
  },
  labelText: {},
  requiredAsterisks: {
    // fontSize: Fonts.sizes.large,
  },
  instructionMessage: {
    marginTop: 4,
    lineHeight: '14px',
    color: Colors.darkStone,
  },
  errorMessage: {
    marginTop: 4,
    lineHeight: '14px',
    color: Colors.red,
  },
};
