import React, { useCallback, useEffect, useState } from 'react';
import './LoginScreen.scss';
import { ButtonLink, ButtonPrimary, Header, Spinner, SpinnerScreen, Text } from '../components';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import image1 from '../assets/images/Scientist.png';
import image2 from '../assets/images/Donation.png';
import { version } from '../constants/Messages';

// https://developer.okta.com/docs/guides/sign-into-spa/react/add-signin-button/

export default function LoginScreen() {
  const { oktaAuth, authState } = useOktaAuth();

  const postLoginUrl = '/';

  const [sessionExists, setSessionExists] = useState(null);
  const [loading, setLoading] = useState(true);

  // drop user on dashboard after authenticating
  const login = useCallback(() => oktaAuth.signInWithRedirect({ originalUri: postLoginUrl }), [oktaAuth]);

  // do a session check
  useEffect(() => {
    async function sessionCheck() {
      const result = await oktaAuth.session.exists();
      if (result) {
        login();
      } else {
        setLoading(false);
      }
      setSessionExists(result);
    }
    if (!sessionExists && authState && !authState.isAuthenticated) {
      sessionCheck();
    }
  }, [sessionExists, oktaAuth.session, login, authState]);

  // check the current auth state
  if (!authState) return <SpinnerScreen message="Awaiting auth state" />;

  const helpLink = () => {
    window.open('https://www.lifeblood.com.au/abr-faq');
  };

  /** harsh fix for strange situation where the authState says the user is unauthenticated
   * however the okta library does not oktaAuth.signInWithRedirect correctly
   * instead dropping the user back to the login screen
   * TODO: unsure if it to do with the API.ts's handling of expiring tokens, however this seems unlikly.
   * Seems to happen only when a token fully expires */
  if (!authState.isAuthenticated) {
    window.sessionStorage.removeItem('okta-token-storage');
    // harsher method if required, we are logged out so the reducers could be cleared
    // window.sessionStorage.clear();
  }
  // skip the login screen if already authenticated
  if (authState.isAuthenticated) {
    return <Redirect to={{ pathname: postLoginUrl }} />;
  }

  return (
    <div className="loginScreen">
      <Header title="" hideVersion />
      <div
        style={{ flexDirection: 'row', height: '100%', width: '100vw', justifyContent: 'center', alignItems: 'center' }}
      >
        <div
          className="fill"
          style={{ width: '25vw', marginRight: '10vw', marginLeft: '5vw', alignItems: 'flex-start' }}
        >
          <img src={image1} alt="test" />
        </div>

        <div style={{ flexDirection: 'column', width: '30vw', paddingBottom: '20vh' }}>
          <div
            style={{
              flexDirection: 'row',
              width: '30vw',
              marginBottom: 15,
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <Text className="heading1" style={{ textAlign: 'center' }}>
              {process.env.REACT_APP_SITE_TITLE}
            </Text>
            <Text className="version" style={{ textAlign: 'center' }}>
              {version}
            </Text>
          </div>

          {loading ? (
            <Spinner style={{ height: 45 }} />
          ) : (
            <ButtonPrimary
              title="Login"
              onClick={login}
              buttonStyle={{ minWidth: 100, width: '30vw', marginLeft: 'auto', marginRight: 'auto' }}
              containerStyle={{ paddingRight: 0, paddingLeft: 0 }}
              titleStyle={{ fontSize: 16 }}
            />
          )}

          <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <ButtonLink
              title="System help"
              onClick={helpLink}
              className="link-text"
              style={{ marginLeft: 5, marginRight: 0, marginTop: 5 }}
            />
          </div>
          <Text style={{ marginTop: 0, alignItems: 'center', textAlign: 'center' }}>
            For the best experience, we recommend using the latest version of Chrome, Edge or Firefox
          </Text>
        </div>
        <div className="fill" style={{ width: '30vw', marginLeft: '5vw', marginRight: '5vw', alignItems: 'flex-end' }}>
          <img src={image2} alt="test" style={{ transform: 'scaleX(-1)', marginBottom: '5vh' }} />
        </div>
      </div>
    </div>
  );
}
