import React from 'react';
import './Confirm.scss';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ButtonPrimary } from '..';

interface Props {
  isOpen: boolean;
  handleAccept?: () => void;
  handleDecline: () => void;
  title: string;
  message?: string | string[];
  acceptTitle?: string;
  declineTitle?: string;
  children?: any;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export function Confirm(props: Props) {
  const {
    isOpen,
    handleAccept,
    handleDecline,
    title,
    message,
    acceptTitle = 'OK',
    declineTitle = 'Cancel',
    children,
    maxWidth,
  } = props;

  const messages = Array.isArray(message) ? message : [message];

  function renderMessage(m, index) {
    return <DialogContentText key={`alert-dialog-description-${index}`}>{m}</DialogContentText>;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleDecline}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="confirm"
      maxWidth={maxWidth}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {message && <DialogContent>{messages.map((m, index) => renderMessage(m, index))}</DialogContent>}
      <div className="childrenPadding">{children}</div>
      <DialogActions style={{ flexDirection: 'row', justifyContent: 'center' }}>
        {handleDecline && <ButtonPrimary onClick={handleDecline} buttonClass="clay-outline" title={declineTitle} />}
        {handleAccept && <ButtonPrimary onClick={handleAccept} title={acceptTitle} />}
      </DialogActions>
    </Dialog>
  );
}
