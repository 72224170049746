import { applyMiddleware, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import rootReducer from '../reducers/rootReducer';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

// use the blacklist to prevent duplicate stores inside root.
const persistConfig = {
  storage: storageSession,
  key: 'root',
  blacklist: [
    'user',
    'config',
    'dashboard',
    'person',
    'personResults',
    'personConsent',
    'personCard',
    'reference',
    'permissions',
    'address',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
// Bizzare typing requirements
// https://redux.js.org/recipes/usage-with-typescript/#usage-with-redux-thunk
export type RootState = ReturnType<typeof persistedReducer>;
export type AppDispatch = typeof store.dispatch;

let store = null;

export function getStore() {
  return store;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (preloadedState?) => {
  store = createStore(persistedReducer, preloadedState, composeWithDevTools(applyMiddleware(reduxThunk)));
  const persistor = persistStore(store);
  return { store, persistor };
};
