import {
  ActionType,
  AppError,
  ErrorType,
  BloodGroup,
  ReferenceResponse,
  Method,
  TestLab,
  Titre,
  Antibody,
  Antigen,
  ProbRhGeno,
  Rhd,
  PhenotypeSubgroup,
  AHP,
} from '../constants/Interfaces';
import API from '../constants/API';
import { mapApiError } from '../constants/Utils';
import Logger from '../constants/Logger';

interface State {
  antibodies: Antibody[];
  bloodGroups: BloodGroup[];
  methods: Method[];
  antigens: Antigen[];
  probRhGenos: ProbRhGeno[];
  rhds: Rhd[];
  phenotypeSubGroups: PhenotypeSubgroup[];
  testLabs: TestLab[];
  titres: Titre[];
  ahps: AHP[];
  loading: boolean;
  error: AppError;
}

export function getInitialState(): State {
  return {
    antibodies: null,
    bloodGroups: null,
    methods: null,
    antigens: null,
    probRhGenos: null,
    rhds: null,
    phenotypeSubGroups: null,
    testLabs: null,
    titres: null,
    ahps: null,
    loading: false,
    error: null,
  };
}

export const referenceTypes = {
  REFERENCE_GET_REQUEST: 'REFERENCE_GET_REQUEST',
  REFERENCE_GET_RESPONSE: 'REFERENCE_GET_RESPONSE',
};

const LOG_PREFIX = `Reducer: reference ->`;

export const referenceActions = {
  getReference: () => async (dispatch) => {
    dispatch({ type: referenceTypes.REFERENCE_GET_REQUEST });
    Logger.info(`${LOG_PREFIX} getReference: Getting reference information`);
    try {
      const api = API.getInstance();
      const response: ReferenceResponse = await api.get(`/references`);
      Logger.debug(`${LOG_PREFIX} getReference: reference information received.`);
      dispatch({ type: referenceTypes.REFERENCE_GET_RESPONSE, error: false, payload: response });
      return response;
    } catch (e) {
      dispatch({
        type: referenceTypes.REFERENCE_GET_RESPONSE,
        payload: mapApiError(ErrorType.REFERENCE_ERROR, e),
        error: true,
      });
      throw e;
    }
  },
};

export default function reference(state = getInitialState(), action) {
  const { type, payload, error } = action;

  switch (type) {
    case referenceTypes.REFERENCE_GET_REQUEST: {
      return { ...state, loading: true, error: null };
    }

    case referenceTypes.REFERENCE_GET_RESPONSE: {
      if (error) {
        return { ...state, error: payload, loading: false, currentPerson: null };
      }
      const { bloodGroups } = payload.records;
      const { antibodies } = payload.records;
      const { methods } = payload.records;
      const antigens = payload.records.phenotypeAntigens;
      const probRhGenos = payload.records.phenotypeProbRhGenos;
      const rhds = payload.records.phenotypeRhds;
      const { phenotypeSubGroups } = payload.records;
      const { testLabs } = payload.records;
      const { titres } = payload.records;
      const { ahps } = payload.records;
      return {
        ...state,
        bloodGroups,
        antibodies,
        methods,
        antigens,
        probRhGenos,
        rhds,
        phenotypeSubGroups,
        testLabs,
        titres,
        ahps,
        loading: false,
        error: null,
      };
    }

    case ActionType.PURGE: {
      return getInitialState();
    }
  }

  return state;
}
