import _ from 'lodash';

// Provides space for app components to register checks to perform before performing a navigation action
// Giving the app time to ask the user if they really want to move away from the page
// Only the header component currently checks the list of actions
// Only the testTabs currently registers checks

// The idea is for components to register functions in navigationChecks when the component loads, and then removeCheck when unloading.
// Then the header or other navigation component that wants to check if it should proceed should call checkIsNavigationOk, with the action it wants to perform if the user confirms.
// Components that register checks, should also add code to test if there is a isPendingNavigationAction, and then runPendingNavigationAction when the user confirms they want to proceed.
// Components should also removePendingNavigationAction when the user declines to proceed.
export default class NavigationControl {
  private static instance: NavigationControl;

  private navigationChecks: (() => boolean)[] = [];
  private pendingNavigationAction: () => void = null;

  // when this is set, just perform the action. Used to allow the idle timeout to always log out the user
  public forceAllowNavigation = false;

  static getInstance(): NavigationControl {
    if (!NavigationControl.instance) {
      NavigationControl.instance = new NavigationControl();
    }
    return NavigationControl.instance;
  }

  /**
   *
   * @param navigationAction the action you wish to perform, will run the action now if it's ok, or store it for later.
   * @returns boolean
   */
  checkIsNavigationOk(navigationAction: () => void) {
    this.pendingNavigationAction = navigationAction;
    for (const check in this.navigationChecks) {
      if (this.navigationChecks[check]()) {
        return false;
      }
    }
    this.pendingNavigationAction = null;
    navigationAction();
    return true;
  }

  addCheck(check: () => boolean) {
    this.navigationChecks.push(check);
  }

  removeCheck(check: () => boolean) {
    _.pull(this.navigationChecks, check);
  }

  removePendingNavigationAction() {
    this.pendingNavigationAction = null;
  }

  isPendingNavigationAction() {
    return !!this.pendingNavigationAction;
  }

  runPendingNavigationAction() {
    this.pendingNavigationAction();
    this.pendingNavigationAction = null;
  }
}
