import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { ButtonPrimary, Confirm, ContainerCard, Spinner, Text } from '../../../components';
import {
  AntibodyTestResult,
  AntibodyTestTranslated,
  Method,
  Antigen,
  PhenotypeTestResult,
  PhenotypeTestTranslated,
  ConsentStatus,
  Permission,
  PersonDerived,
  PersonConsentStatus,
  TestStatus,
  Person,
  CardStatus,
  GenericErrorCode,
} from '../../../constants/Interfaces';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AntibodyTestTable from './AntibodyTestTable';
import PhenotypeTestTable from './PhenotypeTestTable';
import { buildAntibodyTestTableRows, buildPhenotypeTestTableRows } from '../../../constants/UtilsTests';
import ConsentTab from './ConsentTab';
import _ from 'lodash';
import CardTab from './CardTab';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import Logger from '../../../constants/Logger';
import { ScreenMode } from '../PersonScreen';
import ErrorTab from './ErrorTab';
import { personResultsActions, ResultFilter } from '../../../reducers/personResults';
import { selectPermission } from '../../../constants/Selectors';
import AddDonorTestPopup from './AddDonorTestPopup';
import NavigationControl from '../../../constants/NavigationControl';
import API from '../../../constants/API';
import { toast } from 'react-toastify';
import { Messages, UI } from '../../../constants/Messages';

interface Props {
  person: Person;
  setScrollMode: (value: boolean) => void;
  scrollMode: boolean;
  printMode: boolean;
}

enum TabEnum {
  ANTIBODY,
  PHENOTYPE,
  CONSENT,
  CARD,
}

const TestTabs = forwardRef((props: Props, ref) => {
  const { person, setScrollMode, scrollMode, printMode } = props;
  const [selectedTestTab, setSelectedTestTab] = useState<TabEnum>(TabEnum.ANTIBODY);
  const [loading, setLoading] = useState(false);
  const [loadingDonorPheno, setLoadingDonorPheno] = useState(false);
  const [focusTests, setFocusTests] = useState(false);

  const containerRef = useRef(null);
  const antibodyTestRef = useRef<any>();
  const phenotypeTestRef = useRef<any>();
  const cardTabRef = useRef<any>();

  const dispatch = useAppDispatch();

  const methodReference: Method[] = useAppSelector((state) => state.reference.methods);
  const phenotypeReference: Antigen[] = useAppSelector((state) => state.reference.antigens);

  const personResultsLoading: boolean = useAppSelector((state) => state.personResults.loading);
  const personResultsAntibodyError: boolean = useAppSelector((state) => !!state.personResults.errorAntibodies);
  const personResultsPhenotypeError: boolean = useAppSelector((state) => !!state.personResults.errorPhenotype);

  const canAddTest = useAppSelector(selectPermission(Permission.canAddTest));
  const canAddDonorTest = useAppSelector(selectPermission(Permission.canAddDonorTest));
  const canViewConsent = useAppSelector(selectPermission(Permission.canViewConsent));
  const canViewCard = useAppSelector(selectPermission(Permission.canViewCard));

  const personDerived: PersonDerived = useAppSelector((state) => state.person.currentPersonDerived);

  // external functions
  useImperativeHandle(ref, () => ({
    checkIfShouldNavigate() {
      return confirmTabSwitch(selectedTestTab, true);
    },
  }));

  // Antibody results. Kept above their actual components so they persist though tab changes
  const antibodyTestResults: AntibodyTestResult[] = useAppSelector(
    (state) => state.personResults.currentAntibodyTestResults
  );
  const [antibodyNeedsAttention, setAntibodyNeedsAttention] = useState(false);
  useEffect(() => {
    setTranslatedAntibodyTests(buildAntibodyTestTableRows(antibodyTestResults, methodReference));
    setAntibodyNeedsAttention(checkTestResultsNeedAction(antibodyTestResults));
  }, [antibodyTestResults, methodReference]);

  const [translatedAntibodyTests, setTranslatedAntibodyTests] = useState<AntibodyTestTranslated[]>([]);

  // Phenotype results.
  const phenotypeTestResults: PhenotypeTestResult[] = useAppSelector(
    (state) => state.personResults.currentPhenotypeTestResults
  );
  const [phenotypeNeedsAttention, setPhenotypeNeedsAttention] = useState(false);
  useEffect(() => {
    setTranslatedPhenotypeTests(buildPhenotypeTestTableRows(phenotypeTestResults, phenotypeReference));
    setPhenotypeNeedsAttention(checkTestResultsNeedAction(phenotypeTestResults));
  }, [phenotypeTestResults, phenotypeReference]);

  const [translatedPhenotypeTests, setTranslatedPhenotypeTests] = useState<PhenotypeTestTranslated[]>([]);

  // Comments
  const initialPersonAntibodyComments: string = useAppSelector((state) =>
    state.person.currentPerson ? state.person.currentPersonDerived.antibodyComments : null
  );
  const [personAntibodyComments, setPersonAntibodyComments] = useState(initialPersonAntibodyComments);

  const initialPersonPhenotypeComments: string = useAppSelector((state) =>
    state.person.currentPerson ? state.person.currentPersonDerived.phenotypeComments : null
  );
  const [personPhenotypeComments, setPersonPhenotypeComments] = useState(initialPersonPhenotypeComments);

  // Consent
  const initialConsentStatus: ConsentStatus[] = useAppSelector((state) => state.personConsent.currentConsentStatus);
  useEffect(() => {
    setEditableConsentStatus(initialConsentStatus ? initialConsentStatus.slice() : null);
  }, [initialConsentStatus]);
  const [editableConsentStatus, setEditableConsentStatus] = useState<ConsentStatus[]>([]);

  const initialConsentLetterStatus: ConsentStatus[] = useAppSelector(
    (state) => state.personConsent.currentLetterStatus
  );
  useEffect(() => {
    setEditableConsentLetterStatus(initialConsentLetterStatus ? initialConsentLetterStatus.slice() : null);
  }, [initialConsentLetterStatus]);
  const [editableConsentLetterStatus, setEditableConsentLetterStatus] = useState<ConsentStatus[]>([]);

  const initialConsentRequests: ConsentStatus[] = useAppSelector((state) => state.personConsent.currentRequestsSent);
  useEffect(() => {
    setEditableConsentRequests(initialConsentRequests ? initialConsentRequests.slice() : null);
  }, [initialConsentRequests]);
  const [editableConsentRequests, setEditableConsentRequests] = useState<ConsentStatus[]>([]);

  // Card
  const initialCardStatus: CardStatus[] = useAppSelector((state) => state.personCard.currentCardStatus);
  useEffect(() => {
    setEditableCardStatus(initialCardStatus ? initialCardStatus.slice() : null);
  }, [initialCardStatus]);
  const [editableCardStatus, setEditableCardStatus] = useState<CardStatus[]>([]);

  // new test id
  const [newTestId, setNewTestId] = useState(-1);

  // for use in snapping back ot the tests container after canceling/saving (when the screen shifts from edit mode to normal mode)
  useEffect(() => {
    if (focusTests) {
      setTimeout(() => {
        setFocusTests(false);
        containerRef.current && containerRef.current.scrollIntoView();
      });
    }
  }, [focusTests]);

  function onCancel() {
    switch (selectedTestTab) {
      case TabEnum.ANTIBODY: {
        const antibodyTable = antibodyTestRef.current;
        if (antibodyTable) {
          antibodyTable.cancelChanges(initialPersonAntibodyComments);
        }
        break;
      }
      case TabEnum.CONSENT:
        setIsConsentEditing(false);
        break;
      case TabEnum.CARD: {
        const cardTab = cardTabRef.current;
        if (cardTab) {
          cardTab.cancelChanges();
        }
        break;
      }
      case TabEnum.PHENOTYPE: {
        const phenotypeTable = phenotypeTestRef.current;
        if (phenotypeTable) {
          phenotypeTable.cancelChanges(initialPersonPhenotypeComments);
        }
        break;
      }
      default:
        Logger.error('unknown tab called cancel');
    }
    setFocusTests(true);
  }

  function onAddDonorAntibody(test: AntibodyTestResult) {
    const antibodyTable = antibodyTestRef.current;
    antibodyTable?.addTest(test);
  }

  function onAdd() {
    switch (selectedTestTab) {
      case TabEnum.ANTIBODY: {
        const antibodyTable = antibodyTestRef.current;
        if (antibodyTable) {
          antibodyTable.addTest();
        }
        break;
      }
      case TabEnum.CONSENT:
        Logger.error('consent tab should not call this');
        break;
      case TabEnum.CARD:
        Logger.error('card tab should not call this');
        break;
      case TabEnum.PHENOTYPE: {
        const phenotypeTable = phenotypeTestRef.current;
        if (phenotypeTable) {
          phenotypeTable.addTest();
        }
        break;
      }

      default:
        Logger.error('unknown tab called add');
    }
  }

  function openDonorAntibodyPopup() {
    setDonorAntibodyOpen(true);
  }

  async function doDonorPhenotypeTest() {
    setLoadingDonorPheno(true);
    const api = API.getInstance();
    try {
      const result = await api.get(`donor/${person.donorId}/results`);
      const phenotypeTable = phenotypeTestRef.current;
      phenotypeTable?.addTest(result);
    } catch (e: any) {
      if (e?.response?.status === GenericErrorCode.NOT_FOUND) {
        toast('No Test Result found for requested Donor ID');
      } else if (e?.response?.status === GenericErrorCode.PERMISSION_ERROR) {
        toast(Messages.ERROR_403_API);
      } else {
        toast(Messages.ERROR_GENERIC_API);
      }
    } finally {
      setLoadingDonorPheno(false);
    }
  }

  const [isAntibodyEditing, setIsAntibodyEditing] = useState(false);
  const [isPhenotypeEditing, setIsPhenotypeEditing] = useState(false);
  const [isConsentEditing, setIsConsentEditing] = useState(false);
  const [isCardEditing, setIsCardEditing] = useState(false);
  useEffect(() => {
    if (isAntibodyEditing || isPhenotypeEditing) {
      setScrollMode(true);
      return;
    }
    setScrollMode(false);
  }, [isAntibodyEditing, isPhenotypeEditing, setScrollMode]);

  function updateScrollMode(tab: TabEnum) {
    return function (currentTranslatedTests: AntibodyTestTranslated[] | PhenotypeTestTranslated[], forceUpdate?) {
      if (forceUpdate !== undefined) {
        switch (tab) {
          case TabEnum.ANTIBODY:
            setIsAntibodyEditing(forceUpdate);
            break;
          case TabEnum.PHENOTYPE:
            setIsPhenotypeEditing(forceUpdate);
            break;
          default:
            Logger.error('Unknown tab tried to control the scroll mode');
        }
        return;
      }
      if (currentTranslatedTests.length > 0) {
        const testsInEditMode = _.filter(
          currentTranslatedTests,
          (test: AntibodyTestTranslated | PhenotypeTestTranslated) => test.mode === ScreenMode.EDIT
        );
        if (testsInEditMode.length > 0) {
          switch (tab) {
            case TabEnum.ANTIBODY:
              setIsAntibodyEditing(true);
              break;
            case TabEnum.PHENOTYPE:
              setIsPhenotypeEditing(true);
              break;
            default:
              Logger.error('Unknown tab tried to control the scroll mode');
          }
        }
      }
    };
  }

  function renderCancelButton() {
    const cancelButton = (
      <div style={{ display: 'inline-block', float: 'right' }}>
        <ButtonPrimary title="Cancel" onClick={onCancel} />
      </div>
    );
    switch (selectedTestTab) {
      case TabEnum.ANTIBODY:
        if (isAntibodyEditing) {
          return cancelButton;
        }
        break;
      case TabEnum.CONSENT:
        break;
      case TabEnum.CARD:
        break;
      case TabEnum.PHENOTYPE:
        if (isPhenotypeEditing) {
          return cancelButton;
        }
        break;
      default:
        Logger.error('unknown tab selected');
    }
    return null;
  }

  function renderAddTestButton() {
    if (!canAddTest) {
      return null;
    }
    const addTestButton = (
      <div style={{ display: 'inline-block', float: 'right' }}>
        <ButtonPrimary title="Add Test Result" onClick={onAdd} containerStyle={{ paddingRight: 0 }} />
      </div>
    );
    if (_.includes([TabEnum.ANTIBODY, TabEnum.PHENOTYPE], selectedTestTab)) {
      return addTestButton;
    }
    return null;
  }

  function renderAddDonorTestButton() {
    if (!canAddDonorTest || !person.donorId) {
      return null;
    }
    if (_.includes([TabEnum.ANTIBODY], selectedTestTab)) {
      return (
        <div style={{ display: 'inline-block', float: 'right' }}>
          <ButtonPrimary
            title="Add Donor Result"
            onClick={openDonorAntibodyPopup}
            containerStyle={{ paddingRight: 0 }}
          />
        </div>
      );
    }
    if (_.includes([TabEnum.PHENOTYPE], selectedTestTab)) {
      if (loadingDonorPheno) {
        return <Spinner style={{ display: 'inline-block', float: 'right', marginRight: 109 }} />;
      }
      return (
        <div style={{ display: 'inline-block', float: 'right' }}>
          <ButtonPrimary title="Add Donor Result" onClick={doDonorPhenotypeTest} containerStyle={{ paddingRight: 0 }} />
        </div>
      );
    }
    return null;
  }

  function onTabSwitch(index) {
    if (index === selectedTestTab) {
      // you clicked on the same tab
      return;
    }
    confirmTabSwitch(index);
  }

  function confirmTabSwitch(index, onWindowClose = false): boolean {
    // check the tab you are leaving
    switch (selectedTestTab) {
      case TabEnum.ANTIBODY:
        if (isAntibodyEditing) {
          !onWindowClose &&
            checkIfYouWantToSwap(index, 'Would you like to leave Antibody Test Results without Saving?');
          return true;
        }
        break;
      case TabEnum.PHENOTYPE:
        if (isPhenotypeEditing) {
          !onWindowClose &&
            checkIfYouWantToSwap(index, 'Would you like to leave Phenotype Test Results without saving?');
          return true;
        }
        break;
      case TabEnum.CONSENT:
        if (isConsentEditing) {
          !onWindowClose && checkIfYouWantToSwap(index, 'Would you like to leave Consent TAB without saving?');
          return true;
        }
        break;
      case TabEnum.CARD:
        if (isCardEditing) {
          !onWindowClose && checkIfYouWantToSwap(index, 'Would you like to leave Card TAB without saving?');
          return true;
        }
        break;
    }
    setSelectedTestTab(index);
    return false;
  }

  // hande the confirm window popup for swapping away from a tab that is being editing .
  const [testConfirmOpen, setTestConfimOpen] = React.useState(false);

  const [donorAntibodyOpen, setDonorAntibodyOpen] = React.useState(false);

  const [testConfimMessage, setTestConfirmMessage] = React.useState('');
  const [testHandleAccept, setTestHandleAccept] = useState<() => void>(null);
  const [testHandleDecline, setTestHandleDecline] = useState<() => void>(null);
  // for tabs to share the use of the confirm window
  const confirmConfig = {
    setIsOpen: setTestConfimOpen,
    setMessage: setTestConfirmMessage,
    setHandleAccept: setTestHandleAccept,
    setHandleDecline: setTestHandleDecline,
  };
  // functions for showing and handing the tab switch warning
  function checkIfYouWantToSwap(index, message) {
    setTestConfirmMessage(message);
    const navControl = NavigationControl.getInstance();
    // if this warning was triggered by a navigation button, run the navigation command
    if (navControl.isPendingNavigationAction()) {
      setTestHandleAccept(() => () => navControl.runPendingNavigationAction());
    } else {
      setTestHandleAccept(() => () => checkIfYouWantToSwapAccept(index));
    }
    setTestHandleDecline(() => checkIfYouWantToSwapReject);
    setTestConfimOpen(true);
  }
  function checkIfYouWantToSwapAccept(index) {
    onCancel();
    setSelectedTestTab(index);
    setTestConfimOpen(false);
  }
  function checkIfYouWantToSwapReject() {
    const navControl = NavigationControl.getInstance();
    navControl.removePendingNavigationAction();
    setTestConfimOpen(false);
  }

  // global component unload handler
  // https://stackoverflow.com/questions/36355093/reactjs-browser-tab-close-event
  useEffect(() => {
    window.addEventListener('beforeunload', onBrowserClose);
    const navControl = NavigationControl.getInstance();
    navControl.addCheck(onNavigation);
    return () => {
      window.removeEventListener('beforeunload', onBrowserClose);
      navControl.removeCheck(onNavigation);
    };
  });

  function onBrowserClose(event) {
    const navControl = NavigationControl.getInstance();
    if (confirmTabSwitch(selectedTestTab, true) && !navControl.forceAllowNavigation) {
      event.preventDefault();
      // Note: modern browsers do not allow message customisation.
      // eslint-disable-next-line no-return-assign
      return (event.returnValue = 'Are you sure you want to close?');
    }
    return event;
  }

  function onNavigation() {
    return confirmTabSwitch(selectedTestTab);
  }

  function checkTestResultsNeedAction(tests: any[]) {
    return !tests.every((test) => test.status === TestStatus.VERIFIED);
  }
  function styleTab(isEditing, needsAttention) {
    let result = tabClassNames;
    if (isEditing) result = result.concat(tabClassNamesBold);
    if (needsAttention) result = result.concat(tabClassNamesAttention);
    return result;
  }

  const tabClassNames = ['react-tabs__tab', 'testTab'];
  const tabClassNamesAttention = ['attention'];
  const tabClassNamesBold = ['bold'];
  const consentNeedsAttention =
    personDerived?.latestConsentStatus === PersonConsentStatus.PENDING ||
    personDerived?.latestConsentStatus === PersonConsentStatus.WITHDRAWN;

  if (personResultsLoading || loading) {
    return (
      <div
        className="marginNormal"
        style={{ flexGrow: 1, flexDirection: 'row', justifyContent: 'center', marginTop: 0 }}
      >
        <ContainerCard>
          <Spinner />
        </ContainerCard>
      </div>
    );
  }

  return (
    <>
      {printMode && (
        <div>
          <Text className="heading4 printBreak">Antibody Tests</Text>
          <AntibodyTestTable
            personId={person.personId}
            abrNumber={person.abrNumber}
            translatedTests={translatedAntibodyTests}
            setTranslatedTests={setTranslatedAntibodyTests}
            antibodyTestResults={antibodyTestResults}
            personAntibodyComments={personAntibodyComments}
            newTestId={newTestId}
            setNewTestId={setNewTestId}
            viewOnly
          />
          <Text className="heading4 printBreak">Phenotype Tests</Text>
          <PhenotypeTestTable
            personId={person.personId}
            abrNumber={person.abrNumber}
            translatedTests={translatedPhenotypeTests}
            setTranslatedTests={setTranslatedPhenotypeTests}
            phenotypeTestResults={phenotypeTestResults}
            personPhenotypeComments={personPhenotypeComments}
            newTestId={newTestId}
            setNewTestId={setNewTestId}
            viewOnly
          />
        </div>
      )}
      {!printMode && (
        <div
          className="marginNormal"
          style={{ flexGrow: 1, flexDirection: 'row', justifyContent: 'center', marginTop: 0 }}
          ref={containerRef}
        >
          <ContainerCard>
            <Tabs onSelect={onTabSwitch} selectedIndex={selectedTestTab}>
              <TabList>
                <Tab className={styleTab(isAntibodyEditing, antibodyNeedsAttention)}>{UI.TAB_HEADER_ANTIBODY}</Tab>
                <Tab className={styleTab(isPhenotypeEditing, phenotypeNeedsAttention)}>{UI.TAB_HEADER_PHENOTYPE}</Tab>
                {canViewConsent && (
                  <Tab className={styleTab(isConsentEditing, consentNeedsAttention)}>{UI.TAB_HEADER_CONSENT}</Tab>
                )}
                {canViewCard && <Tab className={styleTab(isCardEditing, false)}>{UI.TAB_HEADER_CARD}</Tab>}
                {renderAddTestButton()}
                {renderAddDonorTestButton()}
                {renderCancelButton()}
              </TabList>

              <TabPanel>
                {personResultsAntibodyError ? (
                  <ErrorTab
                    retryFunction={() => {
                      dispatch(personResultsActions.getPersonResults(person.personId, ResultFilter.ANTIBODIES));
                    }}
                  />
                ) : (
                  <AntibodyTestTable
                    ref={antibodyTestRef}
                    scrollMode={scrollMode}
                    setLoading={setLoading}
                    isAntibodyEditing={isAntibodyEditing}
                    updateScrollMode={updateScrollMode(TabEnum.ANTIBODY)}
                    personId={person.personId}
                    abrNumber={person.abrNumber}
                    translatedTests={translatedAntibodyTests}
                    setTranslatedTests={setTranslatedAntibodyTests}
                    antibodyTestResults={antibodyTestResults}
                    personAntibodyComments={personAntibodyComments}
                    setPersonAntibodyComments={setPersonAntibodyComments}
                    newTestId={newTestId}
                    setNewTestId={setNewTestId}
                    confirmConfig={confirmConfig}
                  />
                )}
              </TabPanel>
              <TabPanel>
                {personResultsPhenotypeError ? (
                  <ErrorTab
                    retryFunction={() => {
                      dispatch(personResultsActions.getPersonResults(person.personId, ResultFilter.PHENOTYPES));
                    }}
                  />
                ) : (
                  <PhenotypeTestTable
                    ref={phenotypeTestRef}
                    scrollMode={scrollMode}
                    setLoading={setLoading}
                    isPhenotypeEditing={isPhenotypeEditing}
                    updateScrollMode={updateScrollMode(TabEnum.PHENOTYPE)}
                    personId={person.personId}
                    abrNumber={person.abrNumber}
                    translatedTests={translatedPhenotypeTests}
                    setTranslatedTests={setTranslatedPhenotypeTests}
                    phenotypeTestResults={phenotypeTestResults}
                    personPhenotypeComments={personPhenotypeComments}
                    setPersonPhenotypeComments={setPersonPhenotypeComments}
                    newTestId={newTestId}
                    setNewTestId={setNewTestId}
                    confirmConfig={confirmConfig}
                  />
                )}
              </TabPanel>
              {canViewConsent && (
                <TabPanel>
                  <ConsentTab
                    personId={person.personId}
                    abrNumber={person.abrNumber}
                    setIsConsentEditing={setIsConsentEditing}
                    editableConsentStatus={editableConsentStatus}
                    setEditableConsentStatus={setEditableConsentStatus}
                    editableConsentLetterStatus={editableConsentLetterStatus}
                    setEditableConsentLetterStatus={setEditableConsentLetterStatus}
                    editableConsentRequests={editableConsentRequests}
                    setEditableConsentRequests={setEditableConsentRequests}
                    confirmConfig={confirmConfig}
                  />
                </TabPanel>
              )}
              {canViewCard && (
                <TabPanel>
                  <CardTab
                    ref={cardTabRef}
                    editableCardStatus={editableCardStatus}
                    setEditableCardStatus={setEditableCardStatus}
                    personId={person.personId}
                    setIsCardEditing={setIsCardEditing}
                    confirmConfig={confirmConfig}
                  />
                </TabPanel>
              )}
            </Tabs>
          </ContainerCard>
          <Confirm
            isOpen={testConfirmOpen}
            title=""
            message={testConfimMessage}
            acceptTitle="Proceed"
            declineTitle="Back"
            handleAccept={testHandleAccept}
            handleDecline={testHandleDecline}
          />
          <AddDonorTestPopup
            person={person}
            onAddDonorAntibody={onAddDonorAntibody}
            isOpen={donorAntibodyOpen}
            setIsOpen={setDonorAntibodyOpen}
          />
        </div>
      )}
    </>
  );
});

export default TestTabs;
