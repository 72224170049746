import React, { CSSProperties } from 'react';
import { PersonConsentStatus, PersonConsentStatusOptions } from '../../constants/Interfaces';
import { Text } from '../../components';
import './ConsentLabel.scss';
import { getReferenceLabel } from '../../constants/Utils';

interface Props {
  consent: PersonConsentStatus;
  label?: string;
  style?: CSSProperties;
}

export default function ConsentLabel(props: Props) {
  const label = props.label || `Consent:\u00a0`;
  return (
    <Text className="label consent-label" style={{ ...{ marginLeft: 15 }, ...props.style }}>
      {label}
      <span className={props.consent}>{getReferenceLabel(PersonConsentStatusOptions, props.consent, 'value')} </span>
    </Text>
  );
}
