import moment from 'moment-timezone';
import { sanitizeUserInfo } from './Utils';

/**
 * Singleton class containing methods for calling firebase crashlytics/ general logging.
 */
// copied from donor mobile app, but still useful if something like firebase would be added.

export default class Logger {
  static debug(message: any) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.debug(`${moment().format('YYYY-MM-DD HH:mm:ss')} ${sanitizeUserInfo(message)}`);
    }
  }

  static info(message: string) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.info(`${moment().format('YYYY-MM-DD HH:mm:ss')} ${sanitizeUserInfo(message)}`);
    }
  }

  static warn(message: string) {
    // eslint-disable-next-line no-console
    console.warn(`${moment().format('YYYY-MM-DD HH:mm:ss')} ${sanitizeUserInfo(message)}`);
  }

  static error(message: string, e?: any) {
    // eslint-disable-next-line no-console
    console.warn(`${moment().format('YYYY-MM-DD HH:mm:ss')} ${sanitizeUserInfo(message)}`);
    // eslint-disable-next-line no-console
    console.warn(`${moment().format('YYYY-MM-DD HH:mm:ss')} ${e}`);
    let messageTitle: string = sanitizeUserInfo(Logger.getErrorTitle(message));
    let messageContent: string = message;

    if (messageTitle.length > 50) {
      messageTitle = `${messageTitle.substr(0, 50)}...`;
    }

    if (e && e.message) {
      messageContent = `${message} Detail: ${e.message}`;
    } else if (e && e.code) {
      messageContent = `${message} Code: ${e.code}`;
    } else if (e) {
      messageContent = `${message} Detail: ${JSON.stringify(e)}`;
    }

    const jsError = new Error(sanitizeUserInfo(messageContent));
    jsError.name = messageTitle;
    // eslint-disable-next-line no-console
    console.error(sanitizeUserInfo(messageContent));
  }

  static getErrorTitle(message: string) {
    let errorTitle = message;
    if (message.indexOf(':')) {
      errorTitle = message.split(':')[0];
    } else if (message.indexOf('-')) {
      errorTitle = message.split('-')[0];
    } else if (message.indexOf(' ')) {
      errorTitle = message.split(' ')[0];
    }
    return errorTitle;
  }
}
