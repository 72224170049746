import React from 'react';
import './PaginationButtons.scss';
import _ from 'lodash';

interface Props {
  currentPage?: number;
  totalRecords: number;
  recordsPerPage?: number;
  hidden?: boolean;
  onClick: (page) => void;
  className?: string;
}

export function PaginationButtons(props: Props) {
  const { hidden = false, onClick, currentPage = 1, totalRecords, recordsPerPage = 20, className = '' } = props;
  if (hidden) {
    return null;
  }
  const previousActive = currentPage > 1;
  const pages = Math.ceil(totalRecords / recordsPerPage);
  const nextActive = currentPage < pages;
  const pageArray = _.range(1, pages + 1);

  return (
    <div className={`paginationButtons ${className}`} style={{ flexDirection: 'row' }}>
      <div
        className={`text${previousActive ? '' : ' disabled'}`}
        onClick={() => {
          previousActive && onClick(currentPage - 1);
        }}
      >
        {'<'}
      </div>
      {pageArray.map((page) => renderPageinationButton(page, onClick, currentPage === page))}
      <div
        className={`text${nextActive ? '' : ' disabled'}`}
        onClick={() => {
          nextActive && onClick(currentPage + 1);
        }}
      >
        {'>'}
      </div>
    </div>
  );
}

const renderPageinationButton = (page, onClick: (page) => void, isCurrentPage?) => (
  <div className={`text${isCurrentPage ? ' selected' : ''}`} onClick={() => onClick(page)} key={`page-${page}`}>
    {page}
  </div>
);
