import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { DateInput, SelectInput } from '../../../components';
import {
  ValidationState,
  PersonConsentNotifiedBy,
  PersonConsentStatus,
  ConsentStatus,
  receivedActiveList,
  receivedWithdrawnList,
  receivedDeceasedList,
} from '../../../constants/Interfaces';
import './Consent.scss';
import _ from 'lodash';
import { StatusValidators, useValidatedField, validate, ValidatedField } from '../../../constants/Validators';
import { ConfirmConfig } from '../../../constants/UtilsTests';

// const LOG_PREFIX = 'Component -> ConsentTabEditRow ->';

const shouldShowNotifiedBy = (status: PersonConsentStatus) =>
  [PersonConsentStatus.ACTIVE, PersonConsentStatus.DECEASED, PersonConsentStatus.WITHDRAWN].includes(status);

export const consentTabNewRowControls = (
  setIsTabEditing: (result: boolean) => void,
  addRow: (value: ConsentStatus) => void,
  options: any[],
  ahpReference: any[],
  confirmConfig: ConfirmConfig,
  disableDate = false,
  showNotifiedBy = false,
  currentStatus = null
) => {
  const status = useValidatedField(null, 'status');
  const notifiedByStatus = useValidatedField(null, 'notifiedByStatus');
  const ahpRefId = useValidatedField(null, 'ahpRefId');
  const date = useValidatedField(moment().format('YYYY-MM-DD'), 'date');
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    setIsTabEditing(isEditing);
  }, [isEditing, setIsTabEditing]);

  const onChange = (field: ValidatedField) => (e) => {
    field.setValue(e);
    validate(field, e, StatusValidators);
    if (field.name === 'status') {
      notifiedByStatus.setValue(null);
      notifiedByStatus.setValidation(ValidationState.NULL);
    }
    if (field.name === 'notifiedByStatus') {
      ahpRefId.setValue(null);
      ahpRefId.setValidation(ValidationState.NULL);
    }
  };

  function showConfirmWarning(message) {
    confirmConfig.setMessage(message);
    confirmConfig.setHandleAccept(() => () => {
      confirmSave();
      confirmConfig.setIsOpen(false);
    });
    confirmConfig.setHandleDecline(() => () => {
      confirmConfig.setIsOpen(false);
    });
    confirmConfig.setIsOpen(true);
  }

  function shouldShowWarning() {
    if (PersonConsentStatus.DECEASED === status.value) {
      showConfirmWarning('Are you sure you want to mark this person as deceased?');
      return true;
    }
    if (PersonConsentStatus.WITHDRAWN === status.value) {
      showConfirmWarning(`Are you sure you want to Withdraw the person's consent?`);
      return true;
    }
    if (currentStatus !== PersonConsentStatus.PENDING && PersonConsentStatus.PENDING === status.value) {
      showConfirmWarning(`Are you sure you want to return Consent Status to Pending?`);
      return true;
    }
    return false;
  }

  async function save() {
    const fieldsToValidate = [status, date];
    if (shouldShowNotifiedBy(status.value)) {
      fieldsToValidate.push(notifiedByStatus);
    }
    if (notifiedByStatus.value === PersonConsentNotifiedBy.AHP) {
      fieldsToValidate.push(ahpRefId);
    }
    const validators: Promise<boolean>[] = [];
    _.each(fieldsToValidate, (field) => {
      validators.push(validate(field, field.value, StatusValidators));
    });
    const results: boolean[] = await Promise.all(validators);
    const firstFailedFieldIndex = _.indexOf(results, false);
    if (firstFailedFieldIndex > -1) {
      return;
    }

    if (shouldShowWarning()) {
      return;
    }
    confirmSave();
  }

  function confirmSave() {
    addRow({
      status: status.value,
      notifiedByStatus: notifiedByStatus.value,
      ahpRefId: ahpRefId.value,
      date: `${date.value} ${moment().format('HH:mm Z')}`,
      createdDate: moment().format('YYYY-MM-DD HH:mm:ss'), // only required if we wern't immediatly refreshing the table. And may have timezone issues
    });
    cancel();
  }

  function cancel() {
    status.setValue(null);
    status.setValidation(ValidationState.NULL);
    notifiedByStatus.setValue(null);
    notifiedByStatus.setValidation(ValidationState.NULL);
    ahpRefId.setValue(null);
    ahpRefId.setValidation(ValidationState.NULL);
    date.setValue(moment().format('YYYY-MM-DD'));
    date.setValidation(ValidationState.NULL);
    setIsEditing(false);
  }

  function renderNotifiedBy() {
    let notifiedByOptions = [];
    switch (status.value) {
      case 'ACTIVE':
        notifiedByOptions = receivedActiveList;
        break;
      case 'WITHDRAWN':
        notifiedByOptions = receivedWithdrawnList;
        break;
      case 'DECEASED':
        notifiedByOptions = receivedDeceasedList;
        break;
      default:
        return null;
    }
    return (
      <SelectInput
        value={notifiedByStatus.value}
        validationState={notifiedByStatus.validation}
        onValueChange={onChange(notifiedByStatus)}
        onValueChangeFormat={(result) => result?.id}
        getOptionValue={(option) => option.id}
        label=""
        shouldShowLabel={false}
        shouldShowIcon={false}
        options={notifiedByOptions}
        clearable={false}
        placeholder="Received by"
      />
    );
  }

  function editRow() {
    return (
      <tr style={{ flexDirection: 'row' }}>
        <td>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <SelectInput
              value={status.value}
              validationState={status.validation}
              onValueChange={onChange(status)}
              label=""
              shouldShowLabel={false}
              options={options}
              shouldShowIcon={false}
              clearable={false}
            />
          </div>
        </td>
        {showNotifiedBy && shouldShowNotifiedBy(status.value) && (
          <td>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              {renderNotifiedBy()}
              {notifiedByStatus.value === PersonConsentNotifiedBy.AHP && (
                <SelectInput
                  value={ahpRefId.value}
                  validationState={ahpRefId.validation}
                  onValueChange={onChange(ahpRefId)}
                  onValueChangeFormat={(result) => result?.id}
                  getOptionValue={(option) => option.id}
                  label=""
                  shouldShowLabel={false}
                  shouldShowIcon={false}
                  clearable={false}
                  options={ahpReference}
                />
              )}
            </div>
          </td>
        )}
        {showNotifiedBy && !shouldShowNotifiedBy(status.value) && <td />}
        <td>
          <DateInput
            value={date.value}
            validationState={date.validation}
            onValueChange={onChange(date)}
            label=""
            shouldShowLabel={false}
            shouldShowIcon={false}
            inputClassName="noPadding"
            style={{ width: 115, marginRight: 2 }}
            disabled={disableDate}
          />
        </td>
      </tr>
    );
  }
  function editRowActions() {
    return (
      <tr>
        <td>
          <div
            onClick={save}
            className="link"
            style={{ textAlign: 'center', margin: '0 auto', display: 'inline-flex' }}
          >
            Save
          </div>
        </td>
        {showNotifiedBy && <td />}
        <td>
          <div
            onClick={cancel}
            className="link"
            style={{ textAlign: 'center', margin: '0 auto', display: 'inline-flex' }}
          >
            Cancel
          </div>
        </td>
      </tr>
    );
  }
  function addRowActions() {
    return (
      <tr>
        <td>
          <div
            onClick={() => setIsEditing(true)}
            className="link"
            style={{ textAlign: 'center', margin: '0 auto', display: 'inline-flex' }}
          >
            Add
          </div>
        </td>
      </tr>
    );
  }

  if (isEditing) {
    return [editRow(), editRowActions()];
  }
  return [addRowActions()];
};
