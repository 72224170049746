import React from 'react';
import { Text, Spinner, Header } from '..';

interface Props {
  message?: string;
  showSpinner?: boolean;
}

export function SpinnerScreen(props: Props) {
  const { message, showSpinner = true } = props;

  return (
    <div className="background">
      <Header />
      <div
        className="marginNormal"
        style={{ flexGrow: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
      >
        {showSpinner && <Spinner />}
        <Text>{message}</Text>
      </div>
    </div>
  );
}
