/**
 * Defines messages used in the app
 */
export enum Messages {
  ERROR_GENERIC = "Sorry, we're currently experiencing issues.",
  ERROR_GENERIC_API = "Sorry, we're currently experiencing issues.",
  ERROR_GENERIC_AUTH = "Sorry, we're currently experiencing issues.",
  ERROR_GENERIC_NETWORK = 'Please check your network connection and try again.',
  ERROR_403_API = 'You do not have the permissions to perform that action.',
  ERROR_403_SCREEN = 'You do not have the permissions to view this page.',
  ERROR_404_SCREEN = '404, page not found',
  ERROR_LOGIN = 'Something went wrong while retrieving the permissions. Please try again',

  ERROR_EMPTY_STATUS = `Please enter a valid status`,
  ERROR_EMPTY_FIRST_NAME = `Please enter the persons's first name`,
  ERROR_EMPTY_LAST_NAME = `Please enter the persons's surname`,
  ERROR_EMPTY_DOB = `Please enter the persons's date of birth`,
  ERROR_EMPTY_SAMPLE_DATE = `Please enter the sample date`,
  ERROR_EMPTY_DATE = `Please enter the date`,
  ERROR_EMPTY_VALUE = `Please enter a value`,
  ERROR_DOB_FORMAT = `Please enter a valid date of birth`,
  ERROR_DOB_FUTURE = `The date of birth cannot be in the future`,
  ERROR_DATE_FORMAT = `Please enter a valid date`,
  ERROR_DATE_FUTURE = `The date cannot be in the future`,
  ERROR_EMPTY_GENDER = `Please enter the person's gender`,
  ERROR_INVALID_NAME = `Please enter valid name. Only alpha numeric and special characters are allowed`,
  ERROR_INCOMPLETE_EMAIL = 'Please enter a valid format',
  ERROR_INVALID_EMAIL = `Please enter a valid email address`,
  ERROR_INVALID_EMAIL_SEARCH = 'Please enter a valid format',
  ERROR_INVALID_ABR_ID = `Please enter an ABR number.`,
  ERROR_INVALID_DONOR_ID = `Donor ID is a seven digit number.`,
  ERROR_INVALID_HOSPITAL_NUMBER = `This is a single letter followed by a seven digit number.`,
  ERROR_INVALID_GENOTYPE_HASH_NAME = `Sample ID format is unacceptable`,
  ERROR_INVALID_GENOTYPE_LENGTH = `Incorrect number of columns have been added`,
  ERROR_INVALID_RHD_GENOTYPE_MISSING_FIELDS = `Chip, Sample and Variant values cannot be empty`,
  ERROR_INVALID_RHCE_GENOTYPE_MISSING_FIELDS = `Chip, Sample, C, c, E, and e values cannot be empty`,
  ERROR_DUPLICATE_HOSPITAL_NUMBER = `This hospital number has already been used in ABR.`,
  ERROR_PHENOTYPE_MINIMUM_FIELDS = `A test requires a Subgroup, Prob Rh Geno, RhD or Phenotype.`,
  ERROR_EXLUSIVE_HOSPITAL_DONATION_NUMBER = `Hospital number and donation number cannot be used in the same test.`,
  ERROR_DONATION_NUMBER = `Donation number should be 16 or less characters long.`,
  ERROR_GENERIC_FIELD_VALIDATION = `Validation failed for one or more fields.`,
  MSG_VALIDATING_EMAIL = 'Validating email address',
  ERROR_INVALID_DATA = 'Your input contains invalid data.',
  ERROR_INVALID_MOBILE = 'Please enter a valid mobile number',
  ERROR_EMPTY_ADDRESS = `Please enter the persons's primary address`,
  ERROR_API_VALIDATION_GENERIC = 'Error validating the data',
  MSG_VALIDATING_MOBILE = 'Validating mobile number',
  ERROR_INCOMPLETE_MOBILE = 'Please enter a 10 digit Australian mobile number',
  PERSON_EDIT_DONOR_ID_DUPLICATE = 'Donor ID number already exists, new donor record cannot be created',
  PERSON_EDIT_DONOR_ID_NOT_EXISTS = 'Donor ID number does not exist',
  PERSON_EDIT_DONOR_ID_DATE_ERROR = 'Invalid date of birth format for the requested donor',
  PERSON_EDIT_DONOR_ID_FINISH_SEARCH = 'You must complete and proceed with a Search for Donor to save Donor ID',
  PERSON_SCREEN_HOSPITAL_DUPLICATE = 'Hospital Number already exists',
  PERSON_VIEW_STATUS_CHANGE_ERROR = 'Person in incorrect status',
  PERSON_VIEW_STATUS_CHANGE_API_ERROR = 'Error updating status on person record, refreshing record',
  PERSON_EDIT_VALIDATION_ERROR = 'One or more fields have failed validation',
  TEST_STATUS_CHANGE_ERROR = 'Error updating the status on this test, refreshing table',
}

export enum PersonFieldLabels {
  LAST_NAME = 'Surname',
  FIRST_NAME = 'First name',
  MIDDLE_NAME = 'Middle name',
  GENDER = 'Gender',
  DATE_OF_BIRTH = 'Date of birth',
  PREVIOUS_NAME_1 = 'Previous surnames',
  PREVIOUS_NAME_2 = '',
  PREVIOUS_NAME_3 = '',
  HOSPITAL_NUMBER = 'WA hospital number',
  DONOR_ID = 'Donor ID',
  ADDRESS = 'Address',
  EMAIL = 'Email',
  MOBILE = 'Mobile',
  BLOOD_GROUP = 'Blood group',
  KNOWN_PHENOTYPE = 'Known phenotype',
  KNOWN_ANTIBODIES = 'Known antibodies',
  RECOMMENDED_BLOOD = 'Recommended blood for transfusion',
  RHD_GENOTYPE = 'RHD genotype',
  RHCE_GENOTYPE = 'RHCE genotype',
  HEA_GENOTYPE = 'HEA genotype',
  GENOTYPE_COMMENTS = 'Genotype comments',
  COMMENTS = 'Comments',
}

export enum AntibodyTestTableLabels {
  SAMPLE_DATE = 'Sample date*',
  ANTIBODY = 'Antibody*',
  METHOD = 'Method',
  TITRE = 'Titre',
  QUANT = 'Quant IU/mL',
  HOSPITAL_NUMBER = 'Hospital number',
  DONATION_NUMBER = 'Donation number',
  TESTED_BY = 'Tested by',
  // RECOMMENDED_BLOOD = 'Recommended blood for transfusion',
}

export enum PhenotypeTestTableLabels {
  SAMPLE_DATE = 'Sample date*',
  SUBGROUP = 'Subgroup',
  PROB_RH_GENO = 'Prob Rh Geno',
  RHD = 'RhD',
  PHENOTYPE = 'Phenotype',
  HOSPITAL_NUMBER = 'Hospital number',
  DONATION_NUMBER = 'Donation number',
  TESTED_BY = 'Tested by*',
}

export enum SearchTableLabels {
  ABR_NUMBER = 'ABR No',
  // personFieldLabels
}

export enum DashboardTableLabels {
  ABR_NUMBER = 'ABR No',
  STATUS = 'Person record status',
  CREATED_DATE = 'Creation date',
  CREATED_BY = 'Created by',
  MODIFIED_DATE = 'Submitted date',
  MODIFIED_BY = 'Submitted by',
  REQUESTED_ON = 'Requested on',
  EXPORTED_ON = 'Exported on',
}

export enum ReportConsentFieldLabels {
  DATE_TO = 'Date to',
  DATE_FROM = 'Date from',
  CONSENT_STATUS = 'Consent status',
}

export enum CardTabTableLabels {
  CARD_STATUS = 'Card / Cover letter status',
  CARD_DATE = 'Date',
  CARD_ANTIBODIES = 'Antibodies',
  CARD_SAMPLE_DATE = 'Sample date',
  CARD_MANUAL_CHANGES = 'Card manual changes',
}

export enum ConsentTabTableLabels {
  CONSENT_STATUS = 'Consent status',
  CONSENT_NOTIFIED_BY = 'Notified by',
  CONSENT_DATE = 'Date',
  CONSENT_LETTER_STATUS = 'Consent letter status',
  CONSENT_OTHER_STATUS = 'Other consent requests sent',
}

/**
 * Defines all strings used in the static UI
 */
export enum UI {
  HEADER_LOGOUT_BUTTON = 'Log Out',

  SEARCH_TYPE_TITLE = 'Search for:',
  SEARCH_ABR_NUMBER = 'ABR number',
  SEARCH_NO_RESULT = 'RECORD NOT FOUND',
  SEARCH_VIEW_LINK = 'View',
  SEARCH_RESULT_LIMITED_DUE_TO_FILTERING = 'A person(s) matching your search criteria was identified, ' +
    "however you don't have permission to view the record. Contact Red Cell Reference if further information is required",
  SEARCH_RESULT_LIMIT_EXCEEDED = '>10 records returned in search, refine search criteria or use Advanced Search',
  SEARCH_RESULT_ADVANCED_LIMIT_EXCEEDED = '>20 records returned in search, refine search criteria',
  SEARCH_CLEAR_SEARCH_BUTTON = 'Clear',
  SEARCH_CLEAR_SEARCH_LINK = 'Clear search',
  SEARCH_CREATE_NEW_LABEL = 'Create new:',
  SEARCH_CREATE_PATIENT_BUTTON = 'Patient',
  SEARCH_CREATE_DONOR_BUTTON = 'Donor',
  SEARCH_INCLUDE_PREVIOUS_SURNAMES_LABEL = 'Include previous surnames',

  ABR_NUMBER_SHORT = 'ABR No',
  STATUS_LABEL_DEFAULT = 'Person status:\u00a0',

  DASHBOARD_NO_LIST_SELECTED = 'Please select a list to display',
  DASHBOARD_GENERATE_BUTTON = 'Generate export',
  DASHBOARD_SENT_BUTTON = 'Sent',
  DASHBOARD_REGENERATE_BUTTON = 'Regenerate export',
  DASHBOARD_NO_RESULT = 'No records found',

  DASHBOARD_COUNT_PENDING_SUBMISSION = 'Pending submission',
  DASHBOARD_COUNT_PENDING_VERIFICATION = 'Pending verification',
  DASHBOARD_COUNT_CARD = 'Cards/Cover letter',
  DASHBOARD_COUNT_CONSENT = 'Consent letter requests',
  DASHBOARD_COUNT_PENDING_EXPORT = 'Pending export',
  DASHBOARD_COUNT_TO_PRINT = 'Exported - to print',
  DASHBOARD_SELECT_ALL = 'Select all',

  PERSON_SCREEN_RECORD_SUFFIX = 'record',
  PERSON_SCREEN_EDIT_BUTTON = 'Edit',
  PERSON_SCREEN_CANCEL_BUTTON = 'Cancel',
  PERSON_SCREEN_PRINT_PDF_BUTTON = 'Save/Print PDF',
  PERSON_VIEW_STATUS_SUBMIT_BUTTON = 'Submit',
  PERSON_VIEW_STATUS_VERIFY_BUTTON = 'Verify',
  PERSON_EDIT_CANCEL_BUTTON = 'Cancel',
  PERSON_EDIT_DONOR_SEARCH_BUTTON = 'Search for Donor',
  PERSON_EDIT_DELETE_LINK = 'Delete',
  PERSON_EDIT_SAVE_BUTTON = 'Save',
  PERSON_EDIT_SUBMIT_BUTTON = 'Submit',
  PERSON_EDIT_POPUP_DUPLICATE_MESSAGE = 'Possible existing record(s) detected',
  PERSON_EDIT_POPUP_DUPLICATE_PREFIX = 'ABR Numbers:',
  PERSON_EDIT_POPUP_DONOR_MESSAGE = 'Are you sure you want to overwrite existing record?',
  PERSON_EDIT_POPUP_DONOR_NON_MATCH = 'DEMOGRAPHICS DO NOT MATCH',
  PERSON_EDIT_POPUP_DONOR_EXISTING_COLUMN = 'Existing',
  PERSON_EDIT_POPUP_DONOR_NEW_COLUMN = 'New',
  PERSON_EDIT_POPUP_ACCEPT = 'Proceed',
  PERSON_EDIT_POPUP_DECLINE = 'Back',

  TAB_HEADER_ANTIBODY = 'Antibody test results',
  TAB_HEADER_PHENOTYPE = 'Phenotype test results',
  TAB_HEADER_CONSENT = 'Consent',
  TAB_HEADER_CARD = 'Card',

  REPORT_CONSENT_TITLE = 'Consent report',
  REPORT_GENERATE_BUTTON = 'Generate report',
}

// duration toast messages are shown in millis
export const ToastDuration = 5000;

// the number of dashboard results per page
export const dashboardRecordsPerPage = 20;

// TODO: unused?
export const ApiErrorsMap = {
  'Password attempts exceeded': Messages.ERROR_GENERIC_API,
};

// TODO: unused?
export const AuthErrorsMap = {
  UserNotFoundException: Messages.ERROR_GENERIC_API,
};

export const version = `V ${process.env.REACT_APP_VERSION}`;
