import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  PendingOperation,
  Antigen,
  PhenotypeTestLine,
  PhenotypeTestResult,
  PhenotypeTestTranslated,
  TestLab,
  Validator,
  PersonStatus,
  TestStatus,
  Permission,
  GenericErrorCode,
  PersonErrorCode,
  PersonErrorName,
} from '../../../constants/Interfaces';
import { ButtonPrimary, Table, TextArea } from '../../../components';
import { ScreenMode } from '../PersonScreen';
import _ from 'lodash';
import {
  checkOneFieldExistsOnObject,
  PhenotypeValidators,
  isHospitalAndDonationNumberPresent,
} from '../../../constants/Validators';
import { toast } from 'react-toastify';
import { personResultsActions, ResultFilter } from '../../../reducers/personResults';
import { personActions } from '../../../reducers/person';
import Logger from '../../../constants/Logger';
import { Messages, PhenotypeTestTableLabels } from '../../../constants/Messages';
import {
  buildPhenotypeTestTableRows,
  ConfirmConfig,
  deleteRow,
  editRow,
  executeTestUpdates,
  findListOfUpdates,
  handleTestUpdate,
  HandleTestUpdateParams,
  markFirstRows,
  TestResultType,
  TestTabEditStyle,
  TestTabViewStyle,
} from '../../../constants/UtilsTests';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { DateCell, DropdownCell, AntigenMultiDropdownCell, TextCell, renderSubRow } from './TestTableCells';
import moment from 'moment';
import { selectPermission } from '../../../constants/Selectors';
import { dashboardActions } from '../../../reducers/dashboard';

const LOG_PREFIX = 'Component -> PhenotypeTestTable ->';

interface Props {
  scrollMode?: boolean;
  setLoading?: (value: boolean) => void;
  isPhenotypeEditing?: boolean;
  updateScrollMode?: (translatedTests: PhenotypeTestTranslated[], value?: boolean) => void;
  personId: string;
  abrNumber: string;
  translatedTests: PhenotypeTestTranslated[];
  setTranslatedTests: (translatedTests: PhenotypeTestTranslated[]) => void;
  phenotypeTestResults: PhenotypeTestResult[];
  personPhenotypeComments: string;
  setPersonPhenotypeComments?: (value: string) => void;
  newTestId: number;
  setNewTestId: (value: number) => void;
  confirmConfig?: ConfirmConfig;
  viewOnly?: boolean;
}

const PhenotypeTestTable = forwardRef((props: Props, ref) => {
  const {
    scrollMode = false,
    setLoading = () => {},
    isPhenotypeEditing = false,
    updateScrollMode = () => {},
    personId,
    abrNumber,
    translatedTests,
    setTranslatedTests,
    phenotypeTestResults,
    personPhenotypeComments,
    setPersonPhenotypeComments = () => {},
    newTestId,
    setNewTestId,
    confirmConfig = {
      setIsOpen: () => {},
      setMessage: () => {},
      setHandleAccept: () => {},
      setHandleDecline: () => {},
    },
    viewOnly = false,
  } = props;

  const testLabs: TestLab[] = useAppSelector((state) => state.reference.testLabs);
  const antibodyReference: Antigen[] = useAppSelector((state) => state.reference.antigens);
  const subgroupReference: any[] = useAppSelector((state) => state.reference.phenotypeSubGroups);
  const genotypeReference: any[] = useAppSelector((state) => state.reference.probRhGenos);
  const rhdReference: any[] = useAppSelector((state) => state.reference.rhds);
  const personStatus: PersonStatus = useAppSelector((state) => state.person.currentPerson?.status);
  const dispatch = useAppDispatch();

  const canModifyPerson = useAppSelector(selectPermission(Permission.canModifyPerson));
  const canModifyTest = useAppSelector(selectPermission(Permission.canModifyTest));
  const canSubmitTest = useAppSelector(selectPermission(Permission.canSubmitTest));
  const canVerifyTest = useAppSelector(selectPermission(Permission.canVerifyTest));
  const canDeleteTestUptoVerify = useAppSelector(selectPermission(Permission.canDeleteTestUptoVerify));
  const canDeleteTestAfterFirstVerify = useAppSelector(selectPermission(Permission.canDeleteTestAfterFirstVerify));
  const canViewDonationNumber = useAppSelector(selectPermission(Permission.canViewDonationNumber));

  const [subrowPermissions, setSubrowPermissions] = useState({ canSubmitTest, canVerifyTest, viewOnly });
  useEffect(() => {
    setSubrowPermissions({ canSubmitTest, canVerifyTest, viewOnly });
  }, [canSubmitTest, canVerifyTest, viewOnly]);

  // record the list of tests deleted from the API, for cases when a save only goes part way through we do not want to delete the row again.
  const [deletedTestIds, setDeletedTestIds] = useState([]);
  useEffect(() => {
    setDeletedTestIds([]);
  }, [phenotypeTestResults]);

  /**
   * Handle recording if the tab is in an editable state or not
   */

  const [tabEditMode, setTabEditMode] = useState(false);

  function updateEditMode(currentTranslatedTests: PhenotypeTestTranslated[], updateCommentsInEditMode?) {
    // clear dashboard
    dispatch(dashboardActions.resetDashboardPage());
    // update the comment is being edited flag if passed in
    if (typeof updateCommentsInEditMode !== 'undefined') {
      setTabEditMode(updateCommentsInEditMode);
    }
    // if either the comment flag being passed or the state is true, we are editing untill the save button is hit
    // TODO: need to update this to a general flag to handle deletion
    if (updateCommentsInEditMode || tabEditMode) {
      updateScrollMode(currentTranslatedTests, updateCommentsInEditMode || tabEditMode);
      return;
    }
    updateScrollMode(currentTranslatedTests);
  }

  function editComment() {
    // pass true into the function, since if you just update the state here then call the function the function won't have the updated state
    updateEditMode(translatedTests, true);
  }

  /**
   *  Save functions
   */

  function saveStatus(status: TestStatus, testResultId) {
    return async function () {
      try {
        dispatch(dashboardActions.resetDashboardPage());
        await dispatch(personResultsActions.patchPersonResult(personId, testResultId, status));
        dispatch(personActions.getPersonDerived(abrNumber));
        // TODO - check if more optimised way to do this.
        await dispatch(personResultsActions.getPersonResults(personId, ResultFilter.PHENOTYPES));
      } catch (e: any) {
        if (e.response) {
          switch (e.response.status) {
            case GenericErrorCode.PERMISSION_ERROR: {
              Logger.error(`${LOG_PREFIX} saveStatus: 403 in status save`, e);
              // generic server error, show snack bar and let user try again.
              toast(Messages.ERROR_403_API);
              return;
            }
          }
        }
        Logger.error(`${LOG_PREFIX} submitVerification: Error updating the status on this test`);
        await dispatch(personResultsActions.getPersonResults(personId, ResultFilter.PHENOTYPES));
        toast(Messages.TEST_STATUS_CHANGE_ERROR);
      }
    };
  }

  const testUpdateOptions: HandleTestUpdateParams = {
    type: TestResultType.PHENOTYPE,
    translatedTests,
    setTranslatedTests,
    updateEditMode,
  };

  function saveTests(submitRequested?: boolean) {
    return async function () {
      // validation
      const result = await validateAllFields();
      if (!result) {
        // the messages are handed in validateAllFields
        return;
      }

      setLoading(true);
      setTabEditMode(false);

      const updateList: PhenotypeTestResult[] = findListOfUpdates(
        phenotypeTestResults,
        translatedTests,
        createTestObject,
        submitRequested
      );
      // translatedTests should have all the data at this point
      // phenotypeTestResults are the originals

      try {
        await executeTestUpdates(updateList, dispatch, testUpdateOptions, personId, deletedTestIds, submitRequested);
        updateScrollMode([], false);
        // fire a refresh of the tests to rerender the screen if all successful
        await dispatch(personResultsActions.getPersonResults(personId, ResultFilter.PHENOTYPES));
      } catch (e: any) {
        // if one reducer errors, catch it and display an error (hilight row? toast?)
        Logger.error(`${LOG_PREFIX} -> saveTests: error with phenotype test save`, e);
        if (e?.response?.status === GenericErrorCode.PERMISSION_ERROR) {
          toast(Messages.ERROR_403_API);
          return;
        }
        toast(Messages.ERROR_GENERIC);
        // TODO: At this point, pressing cancel will replace the table with the values before save.
        // However if some rows were saved before one errored this will be incorrect
        // But we can't simply run a GET, as it will blow away any unsaved changes (the line that errored and below)
      } finally {
        // lower spinner.
        setLoading(false);
        setDeletedTestIds(deletedTestIds.slice());
      }

      try {
        // save the phenotype comments in a person PATCH
        // send null instead of empty string
        await dispatch(personActions.patchPerson({ phenotypeComments: personPhenotypeComments || null, personId }));
      } catch (e: any) {
        Logger.error(`${LOG_PREFIX} -> saveTests: error with phenotype test comments save`, e);
        if (e?.response?.status === GenericErrorCode.PERMISSION_ERROR) {
          toast(Messages.ERROR_403_API);
          return;
        }
        if (
          e?.response.status === PersonErrorCode.BAD_DATA &&
          e?.response?.data?.errorCode === PersonErrorName.INVALID_REQUEST
        ) {
          return;
        }
        toast(Messages.ERROR_GENERIC);
      } finally {
        dispatch(personActions.getPersonDerived(abrNumber));
      }
    };
  }

  function createTestObject(tests: PhenotypeTestTranslated[], submitRequested?: boolean): PhenotypeTestResult {
    let isDirty = false;
    let isEditMode = false;
    let performSubmit = false;
    const phenotypeResults: PhenotypeTestLine[] = [];
    _.each(tests, (test: PhenotypeTestTranslated) => {
      if (test.isDirty) {
        isDirty = true;
      }
      if (test.mode === ScreenMode.EDIT) {
        isEditMode = true;
      }
      if (submitRequested && _.includes([TestStatus.DRAFT, TestStatus.UPDATED], test.status)) {
        performSubmit = true;
      }
      const phenotype = [];
      // translate the methods column to id's
      _.each(test.phenotype, (antigen: Antigen) => {
        phenotype.push(antigen.id);
      });
      phenotypeResults.push({
        phenotypeResultId: test.phenotypeResultId,
        phenotype,
        subgroupReference: test.subgroupReference,
        probRhGenoReference: test.probRhGenoReference,
        rhdReference: test.rhdReference,
      });
    });

    // ones with negative testResultId values need to be marked as CREATE
    // ones that do not have a negative testResultId, but have a row with an isDirty flag need to be UPDATE

    let pendingOperation = PendingOperation.NONE;
    if (tests[0].testResultId < 0) {
      pendingOperation = PendingOperation.CREATE;
    } else if (isEditMode && (isDirty || performSubmit)) {
      pendingOperation = PendingOperation.UPDATE;
    }

    return {
      testResultId: tests[0].testResultId,
      sampleDate: `${tests[0].sampleDate} ${moment().format('HH:mm Z')}`,
      phenotypeResult: phenotypeResults.length > 0 ? phenotypeResults[0] : null,
      donationNumber: tests[0].donationNumber ? tests[0].donationNumber.trim() : null,
      hospitalNumber: tests[0].hospitalNumber ? tests[0].hospitalNumber.trim() : null,
      testedByReference: tests[0].testedByReference,
      pendingOperation,
      status: tests[0].status,
    };
  }

  /**
   *  Row manipulation (adding rows or sub rows )
   */

  const newRowDefaultValues = {
    phenotype: [],
    isDirty: true,
    mode: ScreenMode.EDIT,
    status: TestStatus.DRAFT,
    subgroupReferenceOriginal: null,
    probRhGenoReferenceOriginal: null,
    rhdReferenceOriginal: null,
    phenotypeOriginal: [],
    testedByReferenceOriginal: null,
  };

  useImperativeHandle(ref, () => ({
    addTest(newTest?: PhenotypeTestResult) {
      if (newTest) {
        const newRows = buildPhenotypeTestTableRows([newTest], antibodyReference);
        // Now that tests are added to the start of the table, need to reverse the order each row is inserted.
        newRows.reverse();
        _.each(newRows, (row: PhenotypeTestTranslated) => {
          translatedTests.splice(0, 0, {
            ...row,
            isDirty: true,
            mode: ScreenMode.EDIT,
            status: TestStatus.DRAFT,
            testResultId: newTestId,
          });
        });
      } else {
        translatedTests.splice(0, 0, {
          ...newRowDefaultValues,
          testResultId: newTestId,
          phenotypeResultId: -1,
        });
      }

      // now that new rows are added on top of the stack, the multi select components fail to update
      markFirstRows(translatedTests);
      setTranslatedTests(translatedTests.slice());
      setNewTestId(newTestId - 1);
      updateEditMode(translatedTests);

      // focus on the new row after rerender
      setTimeout(() => {
        const element = document.getElementById(`date${newTestId}`);
        element && element.focus();
      });
    },

    cancelChanges(initialPhenotypeComments) {
      setPersonPhenotypeComments(initialPhenotypeComments);
      setTranslatedTests(buildPhenotypeTestTableRows(phenotypeTestResults, antibodyReference));
      updateScrollMode([], false);
      setDeletedTestIds([]);
    },
  }));

  /**
   * field validation
   */

  async function validateAllFields() {
    // Validating all the fields again.
    const validators: Promise<ValidationResult>[] = [];

    const fieldsToValidate = ['sampleDate', 'donationNumber', 'testedByReference'];

    // perform validation
    for (const test of translatedTests) {
      if (test.isDirty) {
        // extra check one of these exists subgroupReference, probRhGenoReference, rhdReference, phenotype
        const isOneValueFilledResult = checkOneFieldExistsOnObject(test, [
          'subgroupReference',
          'probRhGenoReference',
          'rhdReference',
          'phenotype',
        ]);
        if (!isOneValueFilledResult) {
          toast(`${Messages.ERROR_GENERIC_FIELD_VALIDATION} ${Messages.ERROR_PHENOTYPE_MINIMUM_FIELDS}`);
          // exit early
          return false;
        }

        const hospitalDonationResult = await isHospitalAndDonationNumberPresent(
          test.hospitalNumber,
          test.donationNumber
        );
        if (!hospitalDonationResult) {
          toast(`${Messages.ERROR_GENERIC_FIELD_VALIDATION} ${Messages.ERROR_EXLUSIVE_HOSPITAL_DONATION_NUMBER}`);
          return false;
        }

        _.each(fieldsToValidate, (field) => {
          validators.push(validateField(field, test[field], test));
        });
      }
    }
    const results: ValidationResult[] = await Promise.all(validators);

    // If any of the validation has failed, terminate the process immediately
    const firstFailedFieldIndex = _.findIndex(results, (result: ValidationResult) => !result.result);
    if (firstFailedFieldIndex > -1) {
      toast(`${Messages.ERROR_GENERIC_FIELD_VALIDATION} ${results[firstFailedFieldIndex].message}`);

      Logger.debug(
        `${LOG_PREFIX} -> register: ${Messages.ERROR_GENERIC_FIELD_VALIDATION} First failed field is ${firstFailedFieldIndex}`
      );
      return false;
    }
    return true;
  }

  interface ValidationResult {
    result: boolean;
    message?: string;
  }

  async function validateField(field, value, row?) {
    const validators: Validator[] = _.concat(PhenotypeValidators[field]);

    let validationResult: ValidationResult = { result: true };

    for (const validator of validators) {
      if (validator) {
        const result: boolean = await validator.fn(value, row);
        if (!result) {
          validationResult = { result: false, message: validator.errorMessage };
          break;
        }
      }
    }
    return validationResult;
  }

  /**
   * Rendering functions
   */

  function renderEditCell({ row }) {
    const { original } = row;
    if (original.mode === ScreenMode.EDIT) {
      if (
        (canDeleteTestUptoVerify && _.includes([TestStatus.DRAFT, TestStatus.PENDING_VERIFICATION], original.status)) ||
        (canDeleteTestAfterFirstVerify &&
          _.includes(
            [TestStatus.VERIFIED, TestStatus.UPDATED, TestStatus.UPDATED_PENDING_VERIFICATION],
            original.status
          ))
      ) {
        const deleteRowParams = {
          testResultId: original.testResultId,
          lineResultId: original.phenotypeResultId,
          type: TestResultType.PHENOTYPE,
          originalTestResults: phenotypeTestResults,
          translatedTests,
          setTranslatedTests,
          setTabEditMode,
          updateEditMode,
          confirmConfig,
        };
        return (
          <div
            onClick={deleteRow(deleteRowParams)}
            className="link"
            style={{ textAlign: 'center', margin: '0 auto', marginTop: 5 }}
          >
            Delete
          </div>
        );
      }
      return null;
    }
    if (original.first && canModifyTest && !viewOnly) {
      return (
        <div
          onClick={editRow(original.testResultId, translatedTests, setTranslatedTests, updateEditMode)}
          className="link"
          style={{ textAlign: 'center', margin: '0 auto', marginTop: 5 }}
        >
          Edit
        </div>
      );
    }

    return null;
  }

  /**
   * Table calumn layout
   */
  const columns = [
    {
      Header: PhenotypeTestTableLabels.SAMPLE_DATE,
      accessor: 'sampleDate',
      Cell: DateCell,
      style: { width: 115 },
      updateFunction: handleTestUpdate(testUpdateOptions),
      validators: PhenotypeValidators,
    },
    {
      Header: PhenotypeTestTableLabels.SUBGROUP,
      accessor: 'subgroupReference',
      Cell: DropdownCell,
      selectOptions: subgroupReference,
      style: { width: 150, textAlign: 'left' },
      className: 'widthAutoPrint',
      updateFunction: handleTestUpdate(testUpdateOptions),
      searchIgnoreCase: false,
      validators: PhenotypeValidators,
    },
    {
      Header: PhenotypeTestTableLabels.PROB_RH_GENO,
      accessor: 'probRhGenoReference',
      Cell: DropdownCell,
      selectOptions: genotypeReference,
      style: { width: 150, textAlign: 'left' },
      className: 'widthAutoPrint',
      updateFunction: handleTestUpdate(testUpdateOptions),
      searchIgnoreCase: false,
      validators: PhenotypeValidators,
    },
    {
      Header: PhenotypeTestTableLabels.RHD,
      accessor: 'rhdReference',
      Cell: DropdownCell,
      selectOptions: rhdReference,
      style: { width: 140, textAlign: 'left' },
      className: 'widthAutoPrint',
      updateFunction: handleTestUpdate(testUpdateOptions),
      searchIgnoreCase: false,
      validators: PhenotypeValidators,
    },
    {
      Header: PhenotypeTestTableLabels.PHENOTYPE,
      accessor: 'phenotype',
      Cell: AntigenMultiDropdownCell,
      selectOptions: antibodyReference,
      style: { minWidth: 200, textAlign: 'left' },
      className: 'widthAutoPrint',
      updateFunction: handleTestUpdate(testUpdateOptions),
      validators: PhenotypeValidators,
    },
    {
      Header: PhenotypeTestTableLabels.HOSPITAL_NUMBER,
      accessor: 'hospitalNumber',
      Cell: TextCell,
      style: { width: 135, textAlign: 'left' },
      updateFunction: handleTestUpdate(testUpdateOptions),
      maxLength: 20,
      validators: PhenotypeValidators,
    },
    // donation number (if user has permissions)
    {
      Header: PhenotypeTestTableLabels.TESTED_BY,
      accessor: 'testedByReference',
      Cell: DropdownCell,
      selectOptions: testLabs,
      style: { width: 150, textAlign: 'left' },
      updateFunction: handleTestUpdate(testUpdateOptions),
      validators: PhenotypeValidators,
      className: 'no-border-right',
    },
    {
      Header: '',
      accessor: 'delete',
      Cell: renderEditCell,
      style: { width: 55 },
      className: 'transparent-header printHide',
    },
  ];

  if (canViewDonationNumber) {
    columns.splice(6, 0, {
      Header: PhenotypeTestTableLabels.DONATION_NUMBER,
      accessor: 'donationNumber',
      Cell: TextCell,
      style: { width: 175, textAlign: 'left' },
      updateFunction: handleTestUpdate(testUpdateOptions),
      maxLength: 16,
      validators: PhenotypeValidators,
    });
  }

  function getRowId(row, relativeIndex, parent?) {
    return parent
      ? [parent.id, relativeIndex, row.testResultId, row.phenotypeResultId].join('.')
      : [relativeIndex, row.testResultId, row.phenotypeResultId].join('.');
  }

  const scrollModeStyle: any = scrollMode ? TestTabEditStyle : TestTabViewStyle;

  return (
    <div>
      <div id="antibody-table-scroll" className="printNoScroll" style={scrollModeStyle}>
        <Table
          style={{ width: '100%' }}
          data={translatedTests}
          columns={columns}
          renderRowSubComponent={renderSubRow(columns, isPhenotypeEditing, saveStatus, personStatus, subrowPermissions)}
          noDataMessage="No tests added"
          className="sticky brand"
          tableGetRowId={getRowId}
        />
      </div>
      <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
        {isPhenotypeEditing && <ButtonPrimary title="Save" buttonClass="clay-outline" onClick={saveTests()} />}
        {isPhenotypeEditing && <ButtonPrimary title="Submit" buttonClass="clay-outline" onClick={saveTests(true)} />}
        <TextArea
          label="Comments"
          onValueChange={setPersonPhenotypeComments}
          value={personPhenotypeComments || ''}
          labelStyle={{ marginRight: 10 }}
          style={{ marginTop: 0 }}
          inputStyle={{ height: 40, resize: 'vertical' }}
          disabled={!isPhenotypeEditing}
        />
        {!isPhenotypeEditing && canModifyPerson && !viewOnly ? (
          <div onClick={editComment} className="link" style={{ textAlign: 'center', margin: '0 auto' }}>
            Edit
          </div>
        ) : (
          <div style={{ width: 30 }} />
        )}
      </div>
    </div>
  );
});
export default PhenotypeTestTable;
