import React, { RefObject } from 'react';
import { Input } from './Input';
import Colors from '../../constants/Colors';
import { ElementLocation, ValidationState } from '../../constants/Interfaces';
import './SelectInput.scss';
import AsyncSelect from 'react-select/async';
import Logger from '../../constants/Logger';

// https://react-select.com/async

interface State {}

interface Props {
  disabled?: boolean;
  inputStyle?: object;
  labelStyle?: object;
  style?: object;
  label: string;
  onValueChange: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onValueChangeFormat?: (result) => string;
  getOptionLabel?: (option) => string;
  getOptionValue?: (option) => string;
  value: any;
  placeholder?: string;
  icon?: any;
  shouldShowIcon?: boolean;
  required?: boolean;
  validationState?: ValidationState;
  errorMessage?: string;
  inProgressMessage?: string;
  instructionMessage?: string;
  nextField?: RefObject<any>;
  searchCallback?: (searchValue: any) => any;
  labelContainerStyle?: object;
  menuPlacement?: 'auto' | 'top' | 'bottom';
}

export class SearchInput extends React.Component<Props, State> {
  private readonly inputRef: RefObject<HTMLInputElement>;
  private readonly viewRef: RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.viewRef = React.createRef();
  }

  getLocation() {
    const { label } = this.props;
    Logger.debug(`Component -> SearchInput -> getLocation: measuring location now for field ${label}`);
    if (this.viewRef && this.viewRef.current) {
      const rect = this.viewRef.current.getBoundingClientRect();
      Logger.debug(`Field ${label} location: ${rect.x}, ${rect.y}`);
      return {
        x: rect.x + window.scrollX,
        y: rect.y + window.scrollY,
      } as ElementLocation;
    }
    return null;
  }

  getInputRef() {
    return this.inputRef;
  }

  onValueChangeFormat(result) {
    if (result) {
      return result.value;
    }
    return result;
  }

  render() {
    const {
      disabled = false,
      inputStyle,
      label,
      labelStyle,
      style,
      required,
      validationState = ValidationState.NULL,
      errorMessage,
      inProgressMessage,
      instructionMessage,
      icon,
      shouldShowIcon = true,
      value,
      onValueChange,
      onBlur,
      onFocus,
      searchCallback,
      labelContainerStyle,
      getOptionLabel,
      getOptionValue,
      onValueChangeFormat,
      menuPlacement = 'auto',
    } = this.props;

    const valueColor = () => {
      if (validationState === ValidationState.FAILED) return Colors.red;
      return Colors.black;
    };

    return (
      <Input
        className="select-input"
        label={label}
        style={style}
        labelStyle={labelStyle}
        required={required}
        errorMessage={errorMessage}
        inProgressMessage={inProgressMessage}
        instructionMessage={instructionMessage}
        validationState={validationState}
        icon={icon}
        shouldShowIcon={shouldShowIcon}
        labelContainerStyle={labelContainerStyle}
        disabled={disabled}
      >
        <AsyncSelect
          className="select"
          classNamePrefix="select"
          onChange={(result) => {
            onValueChangeFormat
              ? onValueChange(onValueChangeFormat(result))
              : onValueChange(this.onValueChangeFormat(result));
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          isDisabled={disabled}
          placeholder={value}
          isLoading={inProgressMessage}
          isSearchable
          isClearable
          name="color"
          loadOptions={searchCallback}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          menuPlacement={menuPlacement}
          menuPosition="fixed" // allows menu to expand beyond overflow parents
          styles={{
            input: (base) => ({ ...base, ...inputStyle, ...{ color: valueColor() } }),
            control: () => {},
            indicatorSeparator: () => {},
          }}
        />
      </Input>
    );
  }
}
