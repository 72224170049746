import { Address, AppError, ErrorType, Person, PersonStatus, PersonType } from './Interfaces';
import { AxiosError } from 'axios';
import { ApiErrorsMap, AuthErrorsMap, Messages } from './Messages';
import _ from 'lodash';
import { useEffect, useRef } from 'react';
import moment from 'moment';

/**
 * This file contains static methods that are useful across the app.
 */

const emailPattern: RegExp =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
export const APPOINTMENT_CHANGE_MAXWAIT_DEFAULT = 20;

/**
 * Map the api error to a AppError object.
 * @param type the type of the error.
 * @param error the AxiosError object.
 */
export function mapApiError(type: ErrorType, error: AxiosError): AppError {
  const errorName: string = error && error.code ? `${error.code}` : 'GenericError';
  let errorMessage: string = Messages.ERROR_GENERIC_API;
  if (error) {
    if (ApiErrorsMap[error.message]) {
      // If the error name is the same and message is different, look for specific error message mapping first
      errorMessage = ApiErrorsMap[error.message];
    } else if (ApiErrorsMap[errorName]) {
      // If no specific error message mapping is present, look for error name mapping instead
      errorMessage = ApiErrorsMap[errorName];
    }
  }
  return {
    type,
    name: errorName,
    message: errorMessage,
    code: error.code || error?.response?.status.toString(),
  };
}

/**
 * Map the auth error to a AppError object.
 * @param type the type of the error.
 * @param error the CognitoError object.
 */
export function mapAuthError(type: ErrorType, error): AppError {
  const errorName: string = error && error.code ? error.code : 'GenericError';
  let errorMessage: string = Messages.ERROR_GENERIC_AUTH;

  if (error) {
    if (AuthErrorsMap[error.message]) {
      // If the error name is the same and message is different, look for specific error message mapping first
      errorMessage = AuthErrorsMap[error.message];
    } else if (AuthErrorsMap[errorName]) {
      // If no specific error message mapping is present, look for error name mapping instead
      errorMessage = AuthErrorsMap[errorName];
    }
  }
  return {
    type,
    name: errorName,
    message: errorMessage,
  };
}

/**
 * Determine if a string is a valid JSON or not.
 * @param input
 */
export function isValidJSON(input: string): boolean {
  try {
    JSON.parse(input);
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * Sanitize sensitive user information. Used for preprocess before sending
 * the logs to external destinations.
 * @param input string any string.
 * @returns sanitized string.
 */
export function sanitizeUserInfo(input: string): string {
  // Return non-string values as-is
  if (!input || typeof input !== 'string') {
    return input;
  }

  // Clear email addresses
  return input.replace(emailPattern, '***');
}

/**
 * Determine whether the user session should be terminated. This is based on the
 * standard OAuth response error invalid_grant, which results in error code 2002
 * in Android and error code -10 in iOS.
 * @param error
 */
export function shouldTerminateSession(error: any): boolean {
  return error && error.code && (error.code === '-10' || error.code === '2002');
}

/**
 * Join the parts of the address interface into a single string
 * @param address an address interface to format.
 */
export function getAddressString(address: Address, appendCountry?: boolean): string {
  if (!address.addressLine1) {
    return '';
  }
  let result = address.addressLine1;
  if (address.addressLine2) {
    result += `, ${address.addressLine2}`;
  }
  result += `, ${address.suburb.toUpperCase()}`;
  result += ` ${address.state.toUpperCase()}`;
  result += ` ${address.postcode}`;
  if (appendCountry) {
    result += `, Australia`;
  }
  return result;
}

/**
 * Reduce a reference list down to active options, or the option that is currently selected
 * @param references the list of references
 * @param ids an array of current values (to allow for multi select lists)
 * @param idField the id field of the references object (id)
 * @param isActiveField the active field of the references object (active)
 * @returns a lodash filtered list of reference options
 */
export function getActiveReferenceOptions(references, ids = [], idField = 'id', isActiveField = 'active') {
  const result = _.filter(references, (reference) => reference[isActiveField] || _.includes(ids, reference[idField]));
  return result;
}

/**
 * Get the label of an reference option by it's id
 * @param reference the reference table from the api
 * @param id The id of the thing you want to get the label for (string)
 * @param idField what the id field is called in the reference
 * @param labelField what the label field is called in the reference
 */
export function getReferenceLabel(reference: object[], id: any, idField = 'id', labelField = 'label'): string {
  if (!reference || !id) {
    return '';
  }
  const option = _.find(reference, (refOption) => refOption[idField].toString() === id.toString());
  if (option) {
    return option[labelField];
  }
  return '';
}

/**
 * Get the label of an reference option by it's id
 * @param reference the reference table from the api
 * @param values The array of the things you want to get the label for
 * @param idField what the id field is called in the reference
 * @param labelField what the label field is called in the reference
 */
export function getMultiReferenceLabel(
  reference: object[],
  values: any[],
  idField = 'id',
  labelField = 'label',
  orderField = 'order'
): string {
  const references = [];
  let result = '';
  _.each(values, (value) => {
    const option = _.find(reference, (refOption) => refOption[idField] === value.id);
    references.push(option);
  });

  const sortedReferences = _.sortBy(references, [orderField]);

  _.each(sortedReferences, (sortedReference) => {
    if (!result) {
      result = sortedReference[labelField];
    } else {
      result = `${result}, ${sortedReference[labelField]}`;
    }
  });

  return result;
}

/**
 * Join a persons previous names into a display string
 * @param person a person
 */
export function getPreviousNameLabel(person: Person): string {
  if (!person) {
    return '';
  }
  const previousNameFields = ['previousName1', 'previousName2', 'previousName3'];
  const results = [];
  previousNameFields.forEach((previousNameField) => {
    if (person[previousNameField]) {
      results.push(person[previousNameField]);
    }
  });
  if (results.length > 0) {
    return results.join(', ');
  }
  return '';
}

// functions to convert a mobile number between API format and frontend format.
export class MobileConvert {
  static parse(value: string) {
    if (value && value.length > 0) {
      return value.replace('+61', '0');
    }
    return null;
  }
  static format(value: string) {
    if (value && value.length > 0) {
      return value.replace('0', '+61');
    }
    return null;
  }
}

// Hook to setup a keypress handler.
// https://usehooks.com/useEventListener/
export function useEventListener(eventName, handler, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef(null);
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) {
        return () => {};
      }
      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);
      // Add event listener
      element.addEventListener(eventName, eventListener);
      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}

export async function LogoutUser() {
  const newLocation = window.location;
  newLocation.pathname = '/logout';
  // window.location = { ...newLocation, pathname: '/logout' };
}

// given a person, decide what ype of person they are.
// can be overwritten for when creating a new person (and no person exists yet)
export function determinePersonType(person: Person, type?: PersonType) {
  // If type is passed return the type,the invoker function will set the type
  if (type) {
    return type;
  }
  // After a person record is verified, it will always be a Person
  if (person && person.status === PersonStatus.VERIFIED) {
    return PersonType.PERSON;
  }
  // if the record has a donor id and not a hospital number, it's a donor
  if (person && person.donorId && !person.hospitalNumber) {
    return PersonType.DONOR;
  }
  // If you are not a donor and the record is draft, you must be a patient.
  return PersonType.PATIENT;
}

export function cleanObject(obj) {
  // trim object properties
  Object.keys(obj).forEach((k) => {
    obj[k] = typeof obj[k] == 'string' ? obj[k].trim() : obj[k];
  });
  // remove empty object properties
  for (const propName in obj) {
    if (!obj[propName] || obj[propName] === null || obj[propName] === '') {
      delete obj[propName];
    }
  }
  return obj;
}

export function getUsernameFromOkta() {
  const tokensString = window.sessionStorage.getItem('okta-token-storage');
  const parsedToken = tokensString ? JSON.parse(tokensString) : null;
  if (!parsedToken) {
    return null;
  }
  const email = parsedToken?.idToken?.claims?.email;
  // chop off the @ and everything after it
  return email?.split('@')[0];
}

export function getCurrentDateWithTimezone() {
  return moment().format('YYYY-MM-DD HH:mm Z');
}
