import React, { useCallback, useEffect, useState } from 'react';
import './PersonEdit.scss';
import './PersonScreen.scss';
import {
  ContainerCard,
  TextInput,
  SelectInput,
  DateInput,
  SearchInput,
  TextArea,
  ButtonPrimary,
  ButtonLink,
  Confirm,
  DetailText,
} from '../../components';
import '../../styles.scss';
import {
  ValidationState,
  GenderOptions,
  Person,
  Gender,
  BloodGroup,
  PersonSearchParams,
  PersonType,
  SearchPersonResponse,
  SearchDonorErrorCode,
  SearchOptionValues,
  PersonDerived,
  Permission,
  PersonStatus,
} from '../../constants/Interfaces';
import Logger from '../../constants/Logger';
import _ from 'lodash';
import {
  DonorValidators,
  syncIsDate,
  useValidatedField,
  PersonValidators,
  emailLength,
  validate,
} from '../../constants/Validators';
import { addressActions } from '../../reducers/address';
import { personActions } from '../../reducers/person';
import moment from 'moment';
// https://fkhadra.github.io/react-toastify/introduction/
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Messages, PersonFieldLabels, UI } from '../../constants/Messages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getAddressString, getActiveReferenceOptions } from '../../constants/Utils';
import { selectPermission } from '../../constants/Selectors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { searchDonorId } from '../components/PersonDonorSearch';

const LOG_PREFIX = 'Screen -> PersonEdit ->';

interface Props {
  person?: Person;
  type?: PersonType;
  bloodGroups: BloodGroup[];
  handleSave: (abrNumber?) => void;
  handleSaveError: (error, fields) => void;
  scrollMode: boolean;
}

export default function PersonEdit(props: Props) {
  const { person, scrollMode, type } = props;
  const [loading, setLoading] = useState(false);
  const [donorIDLoading, setDonorIDLoading] = useState(false);

  // cheating with non state variable
  let submitRequested = false;

  // work around for searching for an address, then performing a donor id search which replaces the address value.
  const [addressRerender, setAddressRerender] = useState(false);
  useEffect(() => {
    if (addressRerender) {
      setAddressRerender(false);
    }
  }, [addressRerender]);

  const dispatch = useAppDispatch();

  const canViewDonorId = useAppSelector(selectPermission(Permission.canViewDonorId));
  const canViewMobile = useAppSelector(selectPermission(Permission.canViewMobile));
  const canViewEmail = useAppSelector(selectPermission(Permission.canViewEmail));
  const canViewAddress = useAppSelector(selectPermission(Permission.canViewAddress));

  const currentSearchParams: PersonSearchParams = useAppSelector((state) => state.person.currentSearchParams);
  const personDerived: PersonDerived = useAppSelector((state) => state.person.currentPersonDerived);

  // is awaiting donor id means the donor id search controls are visible (search has not been performed)
  const [isAwaitingDonorId, setIsAwaitingDonorId] = useState(
    (type === PersonType.DONOR && !person) || type === PersonType.PERSON
  );
  // The special case for disabling the editable fields before a search on a new donor (previous name etc)
  const [isNewDonorFieldsDisabled, setIsNewDonorFieldsDisabled] = useState(
    isAwaitingDonorId && type === PersonType.DONOR
  );
  useEffect(() => {
    setIsNewDonorFieldsDisabled(isAwaitingDonorId && type === PersonType.DONOR);
  }, [isAwaitingDonorId, type]);
  // the donor id field is editable for a new donor or the person is verifed and the original donor id is empty.
  const [isDonorIdEditable, setIsDonorIdEditable] = useState(
    (type === PersonType.DONOR && !person) || (type === PersonType.PERSON && person && !person.donorId)
  );
  const isDonor = type === PersonType.DONOR;

  // copy initial values, if editing use the values from the api, if not editing use search field values if applicable.
  let initialDonorId = null;
  if (person) {
    initialDonorId = person.donorId;
  } else if (currentSearchParams && isAwaitingDonorId) {
    initialDonorId = currentSearchParams.donorId;
  }
  let initialFirstName = null;
  if (person) {
    initialFirstName = person.firstName;
  } else if (currentSearchParams && !isAwaitingDonorId) {
    initialFirstName = currentSearchParams.firstName;
  }
  let initialMiddleName = null;
  if (person) {
    initialMiddleName = person.middleName;
  } else if (currentSearchParams && !isAwaitingDonorId) {
    initialMiddleName = currentSearchParams.middleName;
  }
  let initialLastName = null;
  if (person) {
    initialLastName = person.lastName;
  } else if (currentSearchParams && !isAwaitingDonorId) {
    initialLastName = currentSearchParams.lastName || currentSearchParams.lastNames;
  }
  let initialPreviousName = null;
  if (person) {
    initialPreviousName = person.previousName1;
  } else if (currentSearchParams && !isAwaitingDonorId) {
    initialPreviousName = currentSearchParams.previousLastName;
  }
  let initialDob = null;
  if (person) {
    initialDob = moment(person.dob).format('YYYY-MM-DD');
  } else if (currentSearchParams && !isAwaitingDonorId) {
    initialDob =
      currentSearchParams.dob && syncIsDate(currentSearchParams.dob)
        ? moment(currentSearchParams.dob).format('YYYY-MM-DD')
        : null;
  }
  // setup space for on screen fields
  const fields = {
    hospitalNumber: useValidatedField(person ? person.hospitalNumber : null, 'hospitalNumber'),
    donorId: useValidatedField(initialDonorId, 'donorId'),
    firstName: useValidatedField(initialFirstName, 'firstName'),
    middleName: useValidatedField(initialMiddleName, 'middleName'),
    lastName: useValidatedField(initialLastName, 'lastName'),
    previousName1: useValidatedField(initialPreviousName, 'previousName1'),
    previousName2: useValidatedField(person ? person.previousName2 : null, 'previousName2'),
    previousName3: useValidatedField(person ? person.previousName3 : null, 'previousName3'),
    gender: useValidatedField<Gender>(person ? person.gender : null, 'gender'),
    dob: useValidatedField(initialDob, 'dob'),
    address: useValidatedField(person && person.address ? getAddressString(person.address) : null, 'address'),
    // currently used for importing donors
    addressObject: useValidatedField(person && person.address),
    email: useValidatedField(person ? person.email : null, 'email'),
    mobile: useValidatedField(person ? person.mobile : null, 'mobile'),
    bloodGroupRefId: useValidatedField(person ? person.bloodGroupRefId : null, 'bloodGroupRefId'),
    rhdGenotype: useValidatedField(person ? person.rhdGenotype : null, 'rhdGenotype'),
    rhceGenotype: useValidatedField(person ? person.rhceGenotype : null, 'rhceGenotype'),
    heaGenotype: useValidatedField(person ? person.heaGenotype : null, 'heaGenotype'),
    personComments: useValidatedField(person ? person.personComments : null, 'personComments'),
    genotypeComments: useValidatedField(person ? person.genotypeComments : null, 'genotypeComments'),
  };
  const personTypeValidators = isDonor ? DonorValidators : PersonValidators;

  // Address search
  const getAddresses = useCallback((searchValue) => dispatch(addressActions.getAddresses(searchValue)), [dispatch]);

  // if you load the edit mode (by clicking edit or create new) wipe the stored search
  useEffect(() => {
    dispatch(personActions.resetSearch());
    // also sneak in a donor id / date validation when the screen loads
    if ((type === PersonType.DONOR && isAwaitingDonorId) || person?.donorId) {
      validate(fields.donorId, initialDonorId, personTypeValidators);
    }
    if (type !== PersonType.DONOR && initialDob) {
      validate(fields.dob, initialDob, personTypeValidators);
    }
    // We only want to run this when the component loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  //
  // ** Data validation
  //
  function handleFieldUpdate(field: string, validateNow: boolean = false) {
    return function (value: string) {
      fields[field].setValue(value);

      if (field === 'donorId' && value.length === 7) {
        validate(fields[field], value, personTypeValidators);
        return;
      }
      // if the user did a donor id search, then updated the address, remove the stored searched donor address
      if (field === 'address') {
        fields.addressObject.setValue(null);
      }

      if (validateNow) {
        validate(fields[field], value, personTypeValidators);
      } else {
        fields[field].setValidation(ValidationState.NULL);
      }
    };
  }

  function validateField(field: string) {
    return async function () {
      const { value } = fields[field];
      const result = await validate(fields[field], value, personTypeValidators);
      return result;
    };
  }

  // function to delete a field value. Special case if called for the donor id field to wipe all ineditable fields.
  function deleteField(field) {
    return function () {
      if (field === 'donorId') {
        // reset form to blank
        setIsAwaitingDonorId(true);
        setIsDonorIdEditable(true);
        handleFieldUpdate('firstName')('');
        handleFieldUpdate('lastName')('');
        handleFieldUpdate('middleName')('');
        handleFieldUpdate('gender')('');
        handleFieldUpdate('dob')('');
        handleFieldUpdate('address')('');
        handleFieldUpdate('email')('');
        handleFieldUpdate('mobile')('');
        handleFieldUpdate('bloodGroupRefId')('');
        // even the user editable fields
        handleFieldUpdate('personComments')('');
        handleFieldUpdate('genotypeComments')('');
        handleFieldUpdate('previousName1')('');
        handleFieldUpdate('previousName2')('');
        handleFieldUpdate('previousName3')('');
      }
      handleFieldUpdate(field)('');
    };
  }

  //
  // ** Format data for API
  //
  async function getAddressObject() {
    if (fields.address.value) {
      // this is for patch, if the address is an address string. Then don't try to format it. Because the user didn't change it.
      if (person && person.address && fields.address.value === getAddressString(person.address)) {
        return person.address;
      }
      // if the user did a donor pull, the address will be in the address object.
      if (fields.addressObject.value) {
        return fields.addressObject.value;
      }
      // in other cases where this is a new address via QAS, do the address lookup to get the properly formatted address.
      return addressActions.getAddressById(fields.address.value);
    }
    return null;
  }

  // create a person object from the on screen fields to be sent to the api.
  async function getPerson() {
    const address = await getAddressObject();
    // Unfortuantly just using the shorter: fields.firstName.value?.toUpperCase() will return undefined if the value is null
    // Undefined values are eaten by api gateway(?), so you can't use them to blank fields. They have to be null.
    return {
      hospitalNumber: fields.hospitalNumber.value,
      donorId: fields.donorId.value,
      firstName: fields.firstName.value ? fields.firstName.value.toUpperCase() : null,
      middleName: fields.middleName.value ? fields.middleName.value.toUpperCase() : null,
      lastName: fields.lastName.value ? fields.lastName.value.toUpperCase() : null,
      previousName1: fields.previousName1.value ? fields.previousName1.value.toUpperCase() : null,
      previousName2: fields.previousName2.value ? fields.previousName2.value.toUpperCase() : null,
      previousName3: fields.previousName3.value ? fields.previousName3.value.toUpperCase() : null,
      gender: fields.gender.value,
      dob: fields.dob.value,
      email: fields.email.value,
      mobile: fields.mobile.value,
      address,
      bloodGroupRefId: fields.bloodGroupRefId.value,
      personComments: fields.personComments.value,
      genotypeComments: fields.genotypeComments.value,
      rhdGenotype: fields.rhdGenotype.value,
      rhceGenotype: fields.rhceGenotype.value,
      heaGenotype: fields.heaGenotype.value,
    };
  }

  async function validateAllFields() {
    // Validating all the fields again.
    const validators: Promise<boolean>[] = [];
    if (fields.donorId.value && fields.donorId.value !== person?.donorId && isAwaitingDonorId) {
      // need to ensure that we have completed the search for a donor
      toast(Messages.PERSON_EDIT_DONOR_ID_FINISH_SEARCH);
      return false;
    }
    _.each(Object.keys(fields), (field: string) => {
      validators.push(validateField(field)());
    });

    const results: boolean[] = await Promise.all(validators);

    // If any of the validation has failed, terminate the process immediately and scroll to the the respective field.
    const firstFailedFieldIndex = _.indexOf(results, false);
    if (firstFailedFieldIndex > -1) {
      Logger.debug(
        `${LOG_PREFIX} -> validateAllFields: Validation failed for one or more fields. First failed field is ${firstFailedFieldIndex}`
      );
      const firstFailedField = Object.keys(fields)[firstFailedFieldIndex];
      if (fields[firstFailedField].inputRef.current && fields[firstFailedField].inputRef.current.getLocation) {
        const location = fields[firstFailedField].inputRef.current.getLocation();
        if (location) {
          window.scroll(0, location.y - 100);
        }
      }
      return false;
    }
    return true;
  }

  //
  // ** Screen events
  //
  function onKeyDownDonor(event) {
    if (event && event.key === 'Enter' && isAwaitingDonorId && fields.donorId.validation === ValidationState.PASSED) {
      callSearchDonorId();
    }
  }

  function callSearchDonorId() {
    searchDonorId(
      validateField,
      setDonorIDLoading,
      dispatch,
      setConfirmTitle,
      setConfirmChildren,
      setConfirmAccept,
      setConfirmOpen,
      person,
      fields,
      props.bloodGroups,
      confirmDonorIdSearch
    );
  }

  function confirmDonorIdSearch(donorDetails) {
    return function () {
      storeRetrivedDonorInformation(donorDetails);
      setIsAwaitingDonorId(false);
      setIsDonorIdEditable(false);
      fields.donorId.setValue(fields.donorId.value);
      setConfirmOpen(false);
    };
  }

  // save retrived donor information into the on screen fields.
  function storeRetrivedDonorInformation(donorDetails: Person) {
    fields.firstName.setValue(donorDetails.firstName ? donorDetails.firstName.toUpperCase() : null);
    fields.lastName.setValue(donorDetails.lastName ? donorDetails.lastName.toUpperCase() : null);
    fields.middleName.setValue(donorDetails.middleName ? donorDetails.middleName.toUpperCase() : null);
    fields.dob.setValue(donorDetails.dob || null);
    fields.gender.setValue(donorDetails.gender || null);
    fields.email.setValue(donorDetails.email || null);
    fields.mobile.setValue(donorDetails.mobile || null);
    fields.address.setValue(donorDetails.address ? getAddressString(donorDetails.address) : null);
    fields.addressObject.setValue(
      donorDetails.address ? { ...donorDetails.address, postcode: donorDetails.address.postcode.toString() } : null
    );
    fields.bloodGroupRefId.setValue(donorDetails.bloodGroupRefId || null);
    // force a refresh of the address select list
    setAddressRerender(true);
  }

  // perform a search for a duplicate person records (based on the core details)
  async function searchDuplicate() {
    try {
      // split on the first non character, we only want to search up to that point.
      const firstNameSplit = fields.firstName.value.split(/[^a-zA-Z]/);
      const duplicateCheck: SearchPersonResponse = await dispatch(
        personActions.search(
          {
            firstName: firstNameSplit[0],
            firstNameSearchBy: SearchOptionValues.StartsWith,
            lastName: fields.lastName.value,
            lastNameSearchBy: SearchOptionValues.ExactMatch,
            dob: fields.dob.value,
            maxRecordCount: 20,
          },
          false
        )
      );
      return duplicateCheck.records;
    } catch (e: any) {
      Logger.error(`${LOG_PREFIX} searchDuplicate: Error occurred while performing a duplicate search`, e);
      if (e.response && e.response.status === SearchDonorErrorCode.NO_DONOR) {
        fields.donorId.setValidation(ValidationState.FAILED);
        fields.donorId.setErrorMessage(Messages.PERSON_EDIT_DONOR_ID_NOT_EXISTS);
        toast(Messages.PERSON_EDIT_DONOR_ID_NOT_EXISTS);
      } else {
        toast(Messages.ERROR_GENERIC);
      }
    }
    return null;
  }

  function openNewTab(abrNumber) {
    return function () {
      window.open(`/person/${abrNumber}`, '_blank');
    };
  }

  function renderDuplicateLink(d: Person, index, isLast) {
    return (
      <ButtonLink
        key={`alert-dialog-link-${index}`}
        title={isLast ? d.abrNumber : `${d.abrNumber},`}
        onClick={openNewTab(d.abrNumber)}
        style={{ margin: 0, marginTop: 0, marginLeft: 10 }}
      />
    );
  }

  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmTitle, setConfirmTitle] = React.useState('');
  const [confirmChildren, setConfirmChildren] = React.useState(null);
  const [confirmAccept, setConfirmAccept] = React.useState<() => void>(null);
  // save button hander
  async function saveForm() {
    const result = await validateAllFields();
    if (result) {
      // duplicate check if we are saving a new person
      if (!person) {
        setLoading(true);
        const duplicates = await searchDuplicate();
        if (duplicates?.length > 0) {
          setConfirmTitle(UI.PERSON_EDIT_POPUP_DUPLICATE_MESSAGE);
          setConfirmChildren(
            <div style={{ flexDirection: 'row' }}>
              {UI.PERSON_EDIT_POPUP_DUPLICATE_PREFIX}&nbsp;
              {duplicates.map((d, index) => renderDuplicateLink(d, index, index === duplicates.length - 1))}
            </div>
          );
          setConfirmAccept(() => duplicateModalProceed);
          setConfirmOpen(true);
          return;
        }
      }
      getPerson().then((newPerson) => {
        save({ ...newPerson });
      });
    } else {
      toast(Messages.PERSON_EDIT_VALIDATION_ERROR);
    }
  }
  // save + submit hander
  function saveAndSubmit() {
    submitRequested = true;
    saveForm();
  }

  function duplicateModalCancel() {
    setConfirmOpen(false);
    setLoading(false);
  }
  function duplicateModalProceed() {
    setConfirmOpen(false);
    getPerson().then((newPerson) => {
      save({ ...newPerson });
    });
  }

  // call api for save
  async function save(newPerson: Person) {
    try {
      setLoading(true);
      if (person && person.personId) {
        // saving an edit
        const diff = _.pickBy(newPerson, (v, k) => person[k] !== v);

        await dispatch(personActions.patchPerson({ ...diff, personId: person.personId }));
        if (submitRequested) {
          await dispatch(
            personActions.patchPerson({
              personId: person.personId,
              status: PersonStatus.PENDING_VERIFICATION,
              canVerify: 0,
            })
          );
        }
        await dispatch(personActions.getPerson(person.abrNumber));
        props.handleSave(); // id not required for edits
      } else {
        // saving a new person
        const result = await dispatch(personActions.postPerson(newPerson));
        if (submitRequested) {
          dispatch(
            personActions.patchPerson({
              personId: result.id,
              status: PersonStatus.PENDING_VERIFICATION,
              canVerify: 0,
            })
          );
        }
        props.handleSave(result.abrNumber);
      }
    } catch (e) {
      Logger.error(`${LOG_PREFIX} submit: Error occurred while saving the person`, e);
      props.handleSaveError(e, fields);
      return;
    } finally {
      setLoading(false);
      submitRequested = false;
    }
  }

  const scollModeStyle: any = scrollMode ? { maxHeight: '30vh', overflowY: 'scroll' } : {};

  return (
    <div
      className="person-details personEdit"
      style={{ flexGrow: 1, flexDirection: 'row', justifyContent: 'center', marginTop: 0 }}
    >
      <ContainerCard style={{ ...scollModeStyle, paddingTop: 10 }}>
        <div style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <div
            style={{
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <TextInput
              label={PersonFieldLabels.LAST_NAME}
              required
              onValueChange={handleFieldUpdate('lastName')}
              value={fields.lastName.value}
              validationState={fields.lastName.validation}
              errorMessage={fields.lastName.errorMessage}
              onBlur={validateField('lastName')}
              ref={fields.lastName.inputRef}
              maxLength={60}
              labelContainerStyle={{ minWidth: 180 }}
              inputStyle={{ textTransform: 'uppercase' }}
              disabled={isDonor}
            />
            <TextInput
              label={PersonFieldLabels.FIRST_NAME}
              required
              onValueChange={handleFieldUpdate('firstName')}
              value={fields.firstName.value}
              validationState={fields.firstName.validation}
              errorMessage={fields.firstName.errorMessage}
              onBlur={validateField('firstName')}
              ref={fields.firstName.inputRef}
              maxLength={60}
              labelContainerStyle={{ minWidth: 180 }}
              inputStyle={{ textTransform: 'uppercase' }}
              disabled={isDonor}
            />
            <TextInput
              label={PersonFieldLabels.MIDDLE_NAME}
              onValueChange={handleFieldUpdate('middleName')}
              value={fields.middleName.value}
              validationState={fields.middleName.validation}
              errorMessage={fields.middleName.errorMessage}
              onBlur={validateField('middleName')}
              ref={fields.middleName.inputRef}
              labelContainerStyle={{ minWidth: 180 }}
              inputStyle={{ textTransform: 'uppercase' }}
              maxLength={60}
              disabled={isDonor}
            />
            <SelectInput
              label={PersonFieldLabels.GENDER}
              onValueChange={handleFieldUpdate('gender', true)}
              value={fields.gender.value}
              validationState={fields.gender.validation}
              errorMessage={fields.gender.errorMessage}
              options={GenderOptions}
              ref={fields.gender.inputRef}
              labelContainerStyle={{ minWidth: 180 }}
              disabled={isDonor}
            />
            <DateInput
              label={PersonFieldLabels.DATE_OF_BIRTH}
              required
              onValueChange={handleFieldUpdate('dob')}
              value={fields.dob.value}
              validationState={fields.dob.validation}
              errorMessage={fields.dob.errorMessage}
              ref={fields.dob.inputRef}
              onBlur={validateField('dob')}
              labelContainerStyle={{ minWidth: 180 }}
              disabled={isDonor}
            />
            <TextInput
              label={PersonFieldLabels.PREVIOUS_NAME_1}
              onValueChange={handleFieldUpdate('previousName1')}
              value={fields.previousName1.value}
              validationState={fields.previousName1.validation}
              errorMessage={fields.previousName1.errorMessage}
              onBlur={validateField('previousName1')}
              ref={fields.previousName1.inputRef}
              labelContainerStyle={{ minWidth: 180 }}
              maxLength={60}
              inputStyle={{ textTransform: 'uppercase' }}
              disabled={isNewDonorFieldsDisabled}
            />
            <TextInput
              label={PersonFieldLabels.PREVIOUS_NAME_2}
              onValueChange={handleFieldUpdate('previousName2')}
              value={fields.previousName2.value}
              validationState={fields.previousName2.validation}
              errorMessage={fields.previousName2.errorMessage}
              onBlur={validateField('previousName2')}
              ref={fields.previousName2.inputRef}
              labelContainerStyle={{ minWidth: 180 }}
              maxLength={60}
              inputStyle={{ textTransform: 'uppercase' }}
              disabled={isNewDonorFieldsDisabled}
            />
            <TextInput
              label={PersonFieldLabels.PREVIOUS_NAME_3}
              onValueChange={handleFieldUpdate('previousName3')}
              value={fields.previousName3.value}
              validationState={fields.previousName3.validation}
              errorMessage={fields.previousName3.errorMessage}
              onBlur={validateField('previousName3')}
              ref={fields.previousName3.inputRef}
              labelContainerStyle={{ minWidth: 180 }}
              maxLength={60}
              inputStyle={{ textTransform: 'uppercase' }}
              disabled={isNewDonorFieldsDisabled}
            />
          </div>
          <div style={{ flexDirection: 'column', flex: 1 }}>
            {type !== PersonType.DONOR && (
              <TextInput
                label={PersonFieldLabels.HOSPITAL_NUMBER}
                onValueChange={handleFieldUpdate('hospitalNumber')}
                value={fields.hospitalNumber.value}
                validationState={fields.hospitalNumber.validation}
                errorMessage={fields.hospitalNumber.errorMessage}
                onBlur={validateField('hospitalNumber')}
                ref={fields.hospitalNumber.inputRef}
                labelContainerStyle={{ minWidth: 173 }}
                maxLength={8}
              />
            )}
            {canViewDonorId && type !== PersonType.PATIENT && (
              <div style={{ flexDirection: 'row' }}>
                <TextInput
                  label={PersonFieldLabels.DONOR_ID}
                  required={type === PersonType.DONOR && !person}
                  onValueChange={handleFieldUpdate('donorId')}
                  value={fields.donorId.value}
                  validationState={fields.donorId.validation}
                  errorMessage={fields.donorId.errorMessage}
                  onBlur={validateField('donorId')}
                  ref={fields.donorId.inputRef}
                  maxLength={7}
                  labelContainerStyle={{ minWidth: 173 }}
                  disabled={!isDonorIdEditable}
                  onKeyDown={onKeyDownDonor}
                />
                {isAwaitingDonorId && (
                  <div>
                    {donorIDLoading ? (
                      <div style={{ width: 247, height: 45, justifyContent: 'center' }}>
                        <CircularProgress />
                      </div>
                    ) : (
                      <ButtonPrimary
                        title={UI.PERSON_EDIT_DONOR_SEARCH_BUTTON}
                        disabled={fields.donorId.validation !== ValidationState.PASSED}
                        buttonStyle={{ marginRight: 20, width: 200 }}
                        onClick={callSearchDonorId}
                      />
                    )}
                  </div>
                )}
                {!person && !isAwaitingDonorId && (
                  <ButtonLink
                    onClick={deleteField('donorId')}
                    title={UI.PERSON_EDIT_CANCEL_BUTTON}
                    style={{ marginRight: 30, marginTop: 7 }}
                  />
                )}
              </div>
            )}
            {canViewAddress && !addressRerender && (
              <SearchInput
                label={PersonFieldLabels.ADDRESS}
                onValueChange={handleFieldUpdate('address', true)}
                value={fields.address.value}
                validationState={fields.address.validation}
                errorMessage={fields.address.errorMessage}
                inProgressMessage={fields.address.inProgressMessage}
                searchCallback={getAddresses}
                ref={fields.address.inputRef}
                onValueChangeFormat={(result) => result?.addressId}
                getOptionLabel={(option) => option.address}
                getOptionValue={(option) => option.addressId}
                labelContainerStyle={{ minWidth: 173 }}
                disabled={isDonor}
              />
            )}
            {canViewEmail && (
              <TextInput
                label={PersonFieldLabels.EMAIL}
                onValueChange={handleFieldUpdate('email')}
                value={fields.email.value}
                validationState={fields.email.validation}
                errorMessage={fields.email.errorMessage}
                inProgressMessage={fields.email.inProgressMessage}
                onBlur={validateField('email')}
                ref={fields.email.inputRef}
                maxLength={emailLength}
                labelContainerStyle={{ minWidth: 173 }}
                disabled={isDonor}
                textChildren={
                  isDonor &&
                  fields.email.validation === ValidationState.FAILED && (
                    <ButtonLink
                      onClick={deleteField('email')}
                      title={UI.PERSON_EDIT_DELETE_LINK}
                      style={{ marginRight: 0, marginTop: 0 }}
                    />
                  )
                }
              />
            )}

            {canViewMobile && (
              <TextInput
                label={PersonFieldLabels.MOBILE}
                onValueChange={handleFieldUpdate('mobile')}
                value={fields.mobile.value}
                validationState={fields.mobile.validation}
                errorMessage={fields.mobile.errorMessage}
                inProgressMessage={fields.mobile.inProgressMessage}
                onBlur={validateField('mobile')}
                ref={fields.mobile.inputRef}
                labelContainerStyle={{ minWidth: 173 }}
                disabled={isDonor}
                textChildren={
                  isDonor &&
                  fields.mobile.validation === ValidationState.FAILED && (
                    <ButtonLink
                      onClick={deleteField('mobile')}
                      title={UI.PERSON_EDIT_DELETE_LINK}
                      style={{ marginRight: 0, marginTop: 0 }}
                    />
                  )
                }
              />
            )}
          </div>
        </div>
        <div className="divider" style={{ marginTop: 10 }} />
        <div style={{ marginTop: 10 }}>
          <SelectInput
            label={PersonFieldLabels.BLOOD_GROUP}
            onValueChange={handleFieldUpdate('bloodGroupRefId', true)}
            value={fields.bloodGroupRefId.value}
            validationState={fields.bloodGroupRefId.validation}
            errorMessage={fields.bloodGroupRefId.errorMessage}
            options={getActiveReferenceOptions(props.bloodGroups, [person?.bloodGroupRefId])}
            onValueChangeFormat={(result: BloodGroup) => result?.id}
            getOptionValue={(option: BloodGroup) => option?.id}
            findValueField="id"
            ref={fields.bloodGroupRefId.inputRef}
            labelContainerStyle={{ minWidth: 180 }}
            style={{ maxWidth: 400 }}
            disabled={isDonor}
          />
          {person && (
            <>
              <DetailText
                label={PersonFieldLabels.KNOWN_PHENOTYPE}
                detail={personDerived ? personDerived.knownPhenotypes : ''}
                labelStyle={{ minWidth: 180 }}
              />
              <DetailText
                label={PersonFieldLabels.KNOWN_ANTIBODIES}
                detail={personDerived ? personDerived.knownAntibodies : ''}
                labelStyle={{ minWidth: 180 }}
              />
              <DetailText
                label={PersonFieldLabels.RECOMMENDED_BLOOD}
                detail={personDerived ? personDerived.recommendBloodTransfusion : ''}
                labelStyle={{ width: 180 }}
              />
            </>
          )}
        </div>
        {person && (
          <div style={{ marginTop: 10 }}>
            <div style={{ flexGrow: 1, flexDirection: 'row' }}>
              <TextInput
                label={PersonFieldLabels.RHD_GENOTYPE}
                onValueChange={handleFieldUpdate('rhdGenotype')}
                value={fields.rhdGenotype.value}
                validationState={fields.rhdGenotype.validation}
                errorMessage={fields.rhdGenotype.errorMessage}
                inProgressMessage={fields.rhdGenotype.inProgressMessage}
                onBlur={validateField('rhdGenotype')}
                ref={fields.rhdGenotype.inputRef}
                labelContainerStyle={{ minWidth: 180 }}
                style={{ flex: 1 }}
                textChildren={
                  <ButtonLink
                    onClick={deleteField('rhdGenotype')}
                    title={UI.PERSON_EDIT_DELETE_LINK}
                    style={{ marginRight: 28, marginTop: 0 }}
                  />
                }
              />
              <TextInput
                label={PersonFieldLabels.RHCE_GENOTYPE}
                onValueChange={handleFieldUpdate('rhceGenotype')}
                value={fields.rhceGenotype.value}
                validationState={fields.rhceGenotype.validation}
                errorMessage={fields.rhceGenotype.errorMessage}
                inProgressMessage={fields.rhceGenotype.inProgressMessage}
                onBlur={validateField('rhceGenotype')}
                ref={fields.rhceGenotype.inputRef}
                labelContainerStyle={{ minWidth: 180 }}
                style={{ flex: 1 }}
                textChildren={
                  <ButtonLink
                    onClick={deleteField('rhceGenotype')}
                    title={UI.PERSON_EDIT_DELETE_LINK}
                    style={{ marginRight: 28, marginTop: 0 }}
                  />
                }
              />
            </div>

            <TextInput
              label={PersonFieldLabels.HEA_GENOTYPE}
              onValueChange={handleFieldUpdate('heaGenotype')}
              value={fields.heaGenotype.value}
              validationState={fields.heaGenotype.validation}
              errorMessage={fields.heaGenotype.errorMessage}
              inProgressMessage={fields.heaGenotype.inProgressMessage}
              onBlur={validateField('heaGenotype')}
              ref={fields.heaGenotype.inputRef}
              labelContainerStyle={{ minWidth: 180 }}
              textChildren={
                <ButtonLink
                  onClick={deleteField('heaGenotype')}
                  title={UI.PERSON_EDIT_DELETE_LINK}
                  style={{ marginRight: 28, marginTop: 0 }}
                />
              }
            />
            <TextArea
              label={PersonFieldLabels.GENOTYPE_COMMENTS}
              onValueChange={handleFieldUpdate('genotypeComments')}
              value={fields.genotypeComments.value}
              validationState={fields.genotypeComments.validation}
              errorMessage={fields.genotypeComments.errorMessage}
              onBlur={validateField('genotypeComments')}
              ref={fields.genotypeComments.inputRef}
              inputStyle={{ height: 40 }}
              style={{ marginTop: 0 }}
            />
          </div>
        )}
        <div>
          <TextArea
            label={PersonFieldLabels.COMMENTS}
            onValueChange={handleFieldUpdate('personComments')}
            value={fields.personComments.value}
            validationState={fields.personComments.validation}
            errorMessage={fields.personComments.errorMessage}
            onBlur={validateField('personComments')}
            ref={fields.personComments.inputRef}
            disabled={isNewDonorFieldsDisabled}
          />
        </div>
        {loading ? (
          <div style={{ marginLeft: 40, marginTop: 20, height: 57, justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <div style={{ marginTop: 20, flexDirection: 'row' }}>
            <ButtonPrimary
              title={UI.PERSON_EDIT_SAVE_BUTTON}
              buttonClass="clay-outline"
              onClick={saveForm}
              containerStyle={{ paddingLeft: 0 }}
            />
            {person?.status !== PersonStatus.VERIFIED && (
              <ButtonPrimary title={UI.PERSON_EDIT_SUBMIT_BUTTON} buttonClass="clay-outline" onClick={saveAndSubmit} />
            )}
          </div>
        )}
      </ContainerCard>
      <Confirm
        isOpen={confirmOpen}
        title={confirmTitle}
        acceptTitle={UI.PERSON_EDIT_POPUP_ACCEPT}
        declineTitle={UI.PERSON_EDIT_POPUP_DECLINE}
        handleAccept={confirmAccept}
        handleDecline={duplicateModalCancel}
      >
        {confirmChildren}
      </Confirm>
    </div>
  );
}
