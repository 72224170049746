// Docos: https://developer.okta.com/code/react/okta_react_sign-in_widget/
// https://github.com/okta/okta-auth-js

export const oktaAuthConfig = {
  // Note: If your app is configured to use the Implicit Flow
  // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
  // you will need to add `pkce: false`
  // issuer: 'https://dev-02593711.okta.com/oauth2/default',
  issuer: `${process.env.REACT_APP_OKTA_DOMAIN}${process.env.REACT_APP_OKTA_API}`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/redirect`,
  // postLogoutRedirectUri: window.location.origin + '/logout',
  authorizeUrl: `${process.env.REACT_APP_OKTA_DOMAIN}${process.env.REACT_APP_OKTA_API}/v1/authorize`,
  tokenUrl: `${process.env.REACT_APP_OKTA_DOMAIN}${process.env.REACT_APP_OKTA_API}/v1/token`,
  userinfoUrl: `${process.env.REACT_APP_OKTA_DOMAIN}${process.env.REACT_APP_OKTA_API}/v1/userinfo`,
  tokenManager: {
    storage: 'sessionStorage',
  },
};

export const oktaSignInConfig = {
  baseUrl: process.env.REACT_APP_OKTA_DOMAIN,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/redirect`,
  authParams: {
    ...oktaAuthConfig,
    // If your app is configured to use the Implicit Flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to uncomment the below line
    // pkce: false
  },
};
