import { useTable } from 'react-table';
// https://react-table.tanstack.com/docs/overview
import React, { CSSProperties } from 'react';
import { Text } from '../text/Text';
import Colors from '../../constants/Colors';
import './Table.scss';

interface Props {
  data: object[];
  columns: object[];
  noDataMessage?: string;
  noDataChildren?: any;
  className?: string;
  style?: CSSProperties;
  headerStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
  renderRowSubComponent?: any;
  showNoDataMessage?: boolean;
  preElements?: JSX.Element[];
  postElements?: JSX.Element[];
  testID?: string;
  tableGetRowId?: (row, relativeIndex, parent?) => string;
  tableHeightEvent?: (height) => void;
}

export function Table(props: Props) {
  const {
    data = [],
    columns,
    noDataMessage = 'No data to display',
    noDataChildren,
    style,
    headerStyle,
    bodyStyle,
    className = '',
    renderRowSubComponent,
    showNoDataMessage = true,
    preElements,
    postElements,
    testID,
    tableGetRowId,
  } = props;

  /// just using data = [] above does not seem to work
  const finalData = data || [];
  const tableInstance = useTable({ columns, data: finalData, getRowId: tableGetRowId });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  if (finalData.length === 0 && showNoDataMessage) {
    if (noDataChildren) {
      return (
        <div className={className} style={style}>
          {noDataChildren}
        </div>
      );
    }
    return (
      <Text className={`input ${className}`} style={{ color: Colors.charcoal, ...style }}>
        {noDataMessage}
      </Text>
    );
  }
  return (
    <table {...getTableProps()} className={`table ${className}`} style={style} data-testid={testID || 'table'}>
      <thead className="header" style={headerStyle}>
        {
          // Loop over the header rows
          headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column) => (
                  // Apply the header cell props
                  <th
                    {...column.getHeaderProps([
                      {
                        className: column.className,
                        style: { ...column.style, ...column.styleHeader },
                      },
                    ])}
                  >
                    {
                      // Render the header
                      column.render('Header')
                    }
                  </th>
                ))
              }
            </tr>
          ))
        }
      </thead>
      {/* Apply the table body props */}
      <tbody {...getTableBodyProps()} style={bodyStyle}>
        {preElements &&
          preElements.map((element, index) => (
            <React.Fragment key={`pre-rows-element ${index}`}>{element}</React.Fragment>
          ))}
        {
          // Loop over the table rows
          rows.map((row) => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <React.Fragment key={row.id}>
                <tr {...row.getRowProps([{ className: row.original.className, style: row.original.style }])}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => (
                      // Apply the cell props
                      <td
                        {...cell.getCellProps([
                          {
                            className: cell.column.className,
                            style: cell.column.style,
                          },
                        ])}
                      >
                        {
                          // Render the cell contents
                          cell.render('Cell')
                        }
                      </td>
                    ))
                  }
                </tr>
                {renderRowSubComponent && (
                  <React.Fragment key={`subrow${row.id}`}>{renderRowSubComponent({ row })}</React.Fragment>
                )}
              </React.Fragment>
            );
          })
        }
        {postElements && postElements.map((element) => element)}
      </tbody>
    </table>
  );
}
