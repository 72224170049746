import './App.css';
import React, { useEffect } from 'react';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom';
import { Permission, PersonType, Scope } from './constants/Interfaces';
import LoginScreen from './screens/LoginScreen';
import Search from './screens/Search';
import PersonScreen, { ScreenMode } from './screens/Person/PersonScreen';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, useOktaAuth, LoginCallback } from '@okta/okta-react';
import { oktaAuthConfig } from './config/AuthConfig';
import LogoutScreen from './screens/LogoutScreen';
import Dashboard from './screens/Dashboard';
import ErrorScreen from './screens/ErrorScreen';
import { Messages } from './constants/Messages';
import { LogoutUser, getUsernameFromOkta } from './constants/Utils';
import { selectPermission } from './constants/Selectors';
import { SpinnerScreen } from './components';
import API from './constants/API';
import moment from 'moment';
import Logger from './constants/Logger';
import { referenceActions } from './reducers/reference';
import { permissionsActions } from './reducers/permissions';
import { personActions } from './reducers/person';
import { useIdleTimerWrapper } from './constants/IdleTimer';
import CrossTabMessage from './constants/CrossTabMessage';
import RedirectScreen from './screens/RedirectScreen';
import Report from './screens/Report';
import { MaintenanceWindow } from './screens/Person/MaintenanceScreen';
import { configActions } from './reducers/config';

const LOG_PREFIX = 'App -> ';

// TODO: yooo, should this be stored here? maybe bring back the authentication reducer?
// export to allow access for other code to check if tokens should be renewed just before an api call happens.
export const oktaAuth = new OktaAuth(oktaAuthConfig);

export default function App() {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const isMaintenanceMode: boolean = useAppSelector((state) => state.config.maintenance);

  /**
   * Okta functions
   */
  const customAuthHandler = () => {
    dispatch(permissionsActions.clearPermissions());
    window.sessionStorage.removeItem('okta-token-storage');
    history.push('/login');
  };
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  /** Permissions */
  const canCreateDonor = useAppSelector(selectPermission(Permission.canCreateDonor));
  const canCreatePatient = useAppSelector(selectPermission(Permission.canCreatePatient));
  const canViewDashboard = useAppSelector(selectPermission(Permission.canViewDashboard));
  const canViewReport = useAppSelector(selectPermission(Permission.canViewReport));
  // activate idle timer
  useIdleTimerWrapper();

  // activate cross tab logout
  function handleCrossTabLogout(event) {
    if (event.data === 'logout' && getUsernameFromOkta()) {
      Logger.debug('recieved logout command');
      if (getUsernameFromOkta()) {
        LogoutUser();
      }
    }
  }

  const messager = CrossTabMessage.getInstance();
  messager?.setHandler(handleCrossTabLogout);

  useEffect(() => {
    // check when the app loads
    dispatch(configActions.getConfig());
    // recheck the config on a timer
    setInterval(() => {
      dispatch(configActions.getConfig());
    }, 60000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isMaintenanceMode) {
    return (
      <div className="App">
        <MaintenanceWindow />
      </div>
    );
  }

  return (
    <div className="App">
      <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
        <Switch>
          <Route path="/login/redirect">
            <RedirectComponent />
          </Route>
          <Route path="/login">
            <LoginScreen />
          </Route>
          <Route path="/logout">
            <LogoutScreen />
          </Route>
          <Route path="/loginError">
            <ErrorScreen message={Messages.ERROR_LOGIN} noHeader customAction={LogoutUser} />
          </Route>

          <SecureRoute path="/person/:abrNumber">
            <PermissionsLoadedRoute>
              <PersonScreen mode={ScreenMode.VIEW} />
            </PermissionsLoadedRoute>
          </SecureRoute>
          <SecureRoute path="/patient">
            <PermissionsLoadedRoute>
              {canCreatePatient ? (
                <PersonScreen mode={ScreenMode.EDIT} type={PersonType.PATIENT} />
              ) : (
                <ErrorScreen message={Messages.ERROR_403_SCREEN} hideButton />
              )}
            </PermissionsLoadedRoute>
          </SecureRoute>
          <SecureRoute path="/donor">
            <PermissionsLoadedRoute>
              {canCreateDonor ? (
                <PersonScreen mode={ScreenMode.EDIT} type={PersonType.DONOR} />
              ) : (
                <ErrorScreen message={Messages.ERROR_403_SCREEN} hideButton />
              )}
            </PermissionsLoadedRoute>
          </SecureRoute>

          <SecureRoute path="/dashboard">
            <PermissionsLoadedRoute>
              {canViewDashboard ? <Dashboard /> : <ErrorScreen message={Messages.ERROR_403_SCREEN} hideButton />}
            </PermissionsLoadedRoute>
          </SecureRoute>
          <SecureRoute path="/report">
            <PermissionsLoadedRoute>
              {canViewReport ? <Report /> : <ErrorScreen message={Messages.ERROR_403_SCREEN} hideButton />}
            </PermissionsLoadedRoute>
          </SecureRoute>
          <Route path="/redirect">
            <RedirectScreen />
          </Route>

          <SecureRoute path="/" exact>
            <PermissionsLoadedRoute>
              <Search />
            </PermissionsLoadedRoute>
          </SecureRoute>

          {/* If none of the previous routes render anything,
              this route acts as a fallback.

              Important: A route with path="/" will *always* match
              the URL because all URLs begin with a /. So that's
              why we put this one last of all */}
          <SecureRoute path="/">
            <PermissionsLoadedRoute>
              <ErrorScreen message={Messages.ERROR_404_SCREEN} hideButton hideHeaderButtons />
            </PermissionsLoadedRoute>
          </SecureRoute>
        </Switch>
      </Security>
    </div>
  );
}

/**
 * When rendered, will set user details then redirect the user to the home page
 * Should be rendered on okta callback
 */
function RedirectComponent() {
  // const { authState } = useOktaAuth(); seems unreliable. Checking if a token exists
  const isUserLoggedIn = getUsernameFromOkta();

  // on login, clear the search
  const dispatch = useAppDispatch();
  dispatch(personActions.resetSearch());

  return isUserLoggedIn ? <Redirect to="/" /> : <LoginCallback />;
}

// Wrap this component around a screen to ensure the okta token is loaded and the users permissions / reference data is gathered before showing the screen.
function PermissionsLoadedRoute({ children, ...rest }) {
  const permissions: any = useAppSelector((state) => state.permissions.permissions);
  const { authState } = useOktaAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const storeToken = () => {
      const api = API.getInstance();

      // store tokens for API use
      api.setToken(Scope.API, authState.accessToken.accessToken, moment.unix(authState.accessToken.expiresAt).toDate());
    };
    const getPermissions = async () => {
      // gather permissions from API
      try {
        await Promise.all([dispatch(referenceActions.getReference()), dispatch(permissionsActions.getPermissions())]);
        // should not be required, as okta should already drop us back at /
        if (window.location.pathname !== '/') {
          window.location.replace('/');
        }
      } catch (e) {
        Logger.error(`${LOG_PREFIX} PermissionsLoadedRoute: Error occurred while getting the users permissions.`, e);
        window.location.replace('/loginError');
      }
    };
    if (authState?.accessToken) {
      storeToken();
    }
    if (!permissions) {
      getPermissions();
    }
    if (authState && !authState.isAuthenticated) {
      Logger.debug('DEBUG authstate logged out');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, permissions]);

  const message = 'Gathering user permissions';
  return <Route {...rest} render={() => (permissions ? children : <SpinnerScreen message={message} />)} />;
}
