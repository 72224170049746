import React from 'react';
import { Text } from '../text/Text';
import Colors from '../../constants/Colors';
import { ValidationState } from '../../constants/Interfaces';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import './RadioInput.scss';
// import Logger from '../../constants/Logger';

interface State {}

export interface RadioOption {
  label: string;
  value: any;
}

interface Props {
  testID?: string;
  disabled?: boolean;
  labelStyle?: object;
  style?: object;
  label: string;
  onValueChange: (value: any) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  value: any;
  icon?: any;
  shouldShowIcon?: boolean;
  required?: boolean;
  validationState?: ValidationState;
  errorMessage?: string;
  inProgressMessage?: string;
  instructionMessage?: string;
  options: RadioOption[];
  radioName: string;
  labelColor?: string;
}

export class RadioInput extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.renderOption = this.renderOption.bind(this);
    this.labelColor = this.labelColor.bind(this);
  }

  labelColor() {
    const { validationState, labelColor = Colors.darkStone } = this.props;
    if (validationState === ValidationState.FAILED) return Colors.red;
    return labelColor;
  }

  renderOption(optionValue, valueLabel) {
    const { disabled = false, onBlur, onFocus, onValueChange, radioName, value, testID } = this.props;

    const checked = optionValue === value;
    return (
      <div style={{ flexDirection: 'row', alignItems: 'baseline', marginLeft: 10 }} key={valueLabel}>
        <input
          data-testid={testID ? `${testID}-${radioName}-${optionValue}` : `${radioName}-${optionValue}`}
          disabled={disabled}
          type="radio"
          name={radioName}
          value={optionValue}
          onChange={(result) => onValueChange(result.target.value)}
          onBlur={onBlur}
          onFocus={onFocus}
          checked={checked}
        />
        <Text className="label" style={{ ...{ color: this.labelColor() } }} onClick={() => onValueChange(optionValue)}>
          {valueLabel}
        </Text>
      </div>
    );
  }

  render() {
    const {
      label,
      labelStyle,
      style,
      required,
      validationState = ValidationState.NULL,
      errorMessage,
      inProgressMessage,
      instructionMessage,
      icon,
      shouldShowIcon = true,
      options,
    } = this.props;

    const rightIcon = () => {
      // if should show icon is disabled, do not show icon
      if (!shouldShowIcon) {
        return null;
      }

      let iconColor = Colors.black;
      if (validationState === ValidationState.FAILED) {
        iconColor = Colors.red;
      }
      if (validationState === ValidationState.PASSED) {
        iconColor = Colors.green;
      }

      // if icon is specified, use that but override the icon color
      // TODO: does this work
      if (icon) {
        return {
          size: 20,
          ...icon,
          color: iconColor,
        };
      }

      if (validationState === ValidationState.PASSED) {
        return <AiOutlineCheckCircle color={Colors.green} size={20} />;
      }
      if (validationState === ValidationState.FAILED) {
        return <AiOutlineCloseCircle color={Colors.red} size={20} />;
      }

      // default no icon
      return null;
    };

    return (
      <div className="radio-input" style={{ ...styles.container, ...style }}>
        <div className="label-container" style={styles.labelContainer}>
          <Text className="label" style={{ ...labelStyle, ...{ color: this.labelColor() } }}>
            {label}
            {required ? (
              <Text className="label" style={{ ...{ color: this.labelColor() } }}>
                *
              </Text>
            ) : null}
          </Text>
        </div>
        <div className="container">
          <div style={{ flexDirection: 'row', paddingRight: 15 }}>
            {options.map((option) => this.renderOption(option.value, option.label))}
          </div>
          {rightIcon()}
        </div>
        {instructionMessage && !inProgressMessage && validationState !== ValidationState.FAILED && (
          <Text className="instruction-text" style={{ ...styles.instructionMessage }}>
            {instructionMessage}
          </Text>
        )}
        {inProgressMessage && validationState === ValidationState.NULL && (
          <Text className="instruction-text" style={{ ...styles.instructionMessage }}>
            {inProgressMessage}
          </Text>
        )}
        {errorMessage && validationState === ValidationState.FAILED && (
          <Text className="instruction-text" style={{ ...styles.errorMessage }}>
            {errorMessage}
          </Text>
        )}
      </div>
    );
  }
}

const styles = {
  container: {
    opacity: 1,
  },
  labelContainer: {
    flexDirection: 'row' as 'row',
  },
  instructionMessage: {
    marginTop: 4,
    lineHeight: '14px',
    color: Colors.darkStone,
  },
  errorMessage: {
    marginTop: 4,
    lineHeight: '14px',
    color: Colors.red,
  },
};
