import React from 'react';
import { Input } from './Input';
import Colors from '../../constants/Colors';
import { ValidationState } from '../../constants/Interfaces';
import './SelectInput.scss';
import Select, { OptionsType, createFilter } from 'react-select';
// https://react-select.com/props#select-props
import _ from 'lodash';

interface State {}

interface Props {
  disabled?: boolean;
  clearable?: boolean;
  inputStyle?: object;
  labelStyle?: object;
  labelContainerStyle?: object;
  style?: object;
  label: string;
  onValueChange: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  defaultValue?: any; // required for initial vlaue for multiSelect
  value: any;
  placeholder?: string;
  icon?: any;
  shouldShowIcon?: boolean;
  shouldShowLabel?: boolean;
  required?: boolean;
  validationState?: ValidationState;
  errorMessage?: string;
  inProgressMessage?: string;
  instructionMessage?: string;
  options: OptionsType;
  onValueChangeFormat?: (result) => string;
  getOptionLabel?: (option) => string;
  getOptionValue?: (option) => string;
  findValueField?: string;
  searchIgnoreCase?: boolean;
  isMulti?: boolean;
  menuPlacement?: 'auto' | 'top' | 'bottom';
  id?: string;
  maxMenuHeight?: number;
  filterOption?: any;
}

export class SelectInput extends React.Component<Props, State> {
  onValueChangeFormat(result) {
    if (result) {
      if (this.props.isMulti) {
        return result;
      }
      return result.value;
    }
    return result;
  }

  render() {
    const {
      disabled = false,
      clearable = true,
      inputStyle,
      label,
      labelStyle,
      style,
      labelContainerStyle,
      placeholder,
      required,
      validationState = ValidationState.NULL,
      errorMessage,
      inProgressMessage,
      instructionMessage,
      icon,
      shouldShowIcon,
      shouldShowLabel,
      defaultValue,
      value,
      onValueChange,
      onBlur,
      onFocus,
      options,
      onValueChangeFormat,
      getOptionLabel,
      getOptionValue,
      findValueField,
      searchIgnoreCase = true,
      isMulti = false,
      menuPlacement = 'auto',
      id,
      maxMenuHeight,
      filterOption = createFilter({ ignoreCase: searchIgnoreCase, matchFrom: 'start' }),
    } = this.props;

    const valueColor = () => {
      if (validationState === ValidationState.FAILED) return Colors.red;
      return Colors.black;
    };

    const findValue = (valueField = 'value') => {
      if (value) {
        return _.find(options, (option) => option[valueField] && option[valueField].toString() === value.toString());
      }
      return { value: '', label: '' };
    };

    return (
      <Input
        className="select-input"
        label={label}
        style={style}
        labelStyle={labelStyle}
        required={required}
        errorMessage={errorMessage}
        inProgressMessage={inProgressMessage}
        instructionMessage={instructionMessage}
        validationState={validationState}
        icon={icon}
        shouldShowIcon={shouldShowIcon}
        shouldShowLabel={shouldShowLabel}
        labelContainerStyle={labelContainerStyle}
        disabled={disabled}
      >
        <Select
          className="select"
          classNamePrefix="select"
          onChange={(result) => {
            onValueChangeFormat
              ? onValueChange(onValueChangeFormat(result))
              : onValueChange(this.onValueChangeFormat(result));
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          isDisabled={disabled}
          placeholder={placeholder}
          isSearchable
          isClearable={clearable}
          isMulti={isMulti}
          backspaceRemovesValue
          filterOption={filterOption}
          defaultValue={defaultValue}
          value={findValue(findValueField)}
          options={options}
          menuPlacement={menuPlacement}
          menuPosition="fixed" // allows menu to expand beyond overflow parents
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          maxMenuHeight={maxMenuHeight}
          styles={{
            input: (base) => ({ ...base, ...inputStyle, ...{ color: valueColor() } }),
            placeholderText: (base) => ({ ...base, ...inputStyle, ...{ color: valueColor() } }),
            control: () => {},
            indicatorSeparator: () => {},
          }}
          id={id}
        />
      </Input>
    );
  }
}
