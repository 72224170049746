import React, { useState } from 'react';
import Colors from '../../constants/Colors';
import { IconContext } from 'react-icons';
// import { IoIosArrowDown } from 'react-icons/io';
import logo from '../../assets/logos/logo.svg';
import './header.scss';
import { useHistory } from 'react-router-dom';
import { personActions } from '../../reducers/person';
import { getUsernameFromOkta, LogoutUser } from '../../constants/Utils';
import { selectPermission } from '../../constants/Selectors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { dashboardActions } from '../../reducers/dashboard';
import { permissionTypes } from '../../reducers/permissions';
import { Permission } from '../../constants/Interfaces';
import { useOktaAuth } from '@okta/okta-react';
import { Text } from '..';
import NavigationControl from '../../constants/NavigationControl';
import { version } from '../../constants/Messages';

interface Props {
  title?: string;
  titleClass?: string;
  hideButtons?: boolean;
  hideVersion?: boolean;
  children?: any;
  selectedButton?: HeaderButton;
}

export enum HeaderButton {
  Search,
  Dashboard,
  Reports,
}

// const LOG_PREFIX = 'Component -> Header ->';

export function Header(props: Props) {
  const {
    title = process.env.REACT_APP_SITE_TITLE,
    titleClass = 'title',
    hideButtons,
    hideVersion,
    children,
    selectedButton,
  } = props;

  // https://stackoverflow.com/questions/31079081/programmatically-navigate-using-react-router
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { authState } = useOktaAuth();

  const [username] = useState(getUsernameFromOkta());
  const debugPermissions: boolean = useAppSelector((state) => state.permissions?.permissionsDebug);

  const canViewDashboard = useAppSelector(selectPermission(Permission.canViewDashboard));
  const canViewReport = useAppSelector(selectPermission(Permission.canViewReport));

  function handlePermissions() {
    dispatch({ type: permissionTypes.PERMISSIONS_SET_DEBUG, payload: !debugPermissions });
  }

  const handleAction = (action: () => void) => () => {
    const navControl = NavigationControl.getInstance();
    navControl.checkIsNavigationOk(action);
  };

  function performSearchNavigation() {
    dispatch(personActions.resetSearch());
    // TODO: cancel any pending searches
    history.push('/');
  }

  function handleDashboard() {
    const navControl = NavigationControl.getInstance();
    navControl.checkIsNavigationOk(performDashboardNavigation);
  }
  function handleReports() {
    const navControl = NavigationControl.getInstance();
    navControl.checkIsNavigationOk(performReportNavigation);
  }

  function performDashboardNavigation() {
    dispatch(dashboardActions.clearDashboard());
    history.push('/dashboard');
  }

  function performReportNavigation() {
    history.push('/report');
  }

  function handleLogout() {
    const navControl = NavigationControl.getInstance();
    navControl.checkIsNavigationOk(LogoutUser);
  }

  return (
    <IconContext.Provider value={{ color: Colors.lightGrey, size: '20' }}>
      <div
        className="header printHide"
        style={{
          flexDirection: 'row',
          backgroundColor: 'white',
          height: 80,
          justifyContent: 'center',
          alignItems: 'center',
          borderBottomColor: Colors.lightGrey,
          borderBottomWidth: 1,
        }}
      >
        <div className="lockup" style={{ cursor: 'pointer' }} onClick={handleAction(performSearchNavigation)}>
          <div className="curve" />
          <img className="brand-logo" src={logo} alt="Logo" />
        </div>

        <div
          className="padding"
          style={{ flexGrow: 1, alignItems: 'flex-end', cursor: 'pointer' }}
          onClick={handleAction(performSearchNavigation)}
        >
          <Text className={titleClass}>{title}</Text>
          {!hideVersion && <div className="version">{version}</div>}
        </div>
        {!hideButtons && authState?.isAuthenticated && (
          <>
            {process.env.REACT_APP_ALLOW_PERMISSION_DEBUG && (
              <div
                className={`padding title-link${debugPermissions ? ' red' : ''}`}
                style={{ cursor: 'pointer' }}
                onClick={handlePermissions}
              >
                DebugPer
              </div>
            )}
            <div
              className={`padding title-link${selectedButton === HeaderButton.Search ? ' selected' : ''}`}
              style={{ cursor: 'pointer' }}
              onClick={handleAction(performSearchNavigation)}
            >
              Search for a patient
            </div>
            {canViewDashboard && (
              <div
                className={`padding title-link${selectedButton === HeaderButton.Dashboard ? ' selected' : ''}`}
                style={{ cursor: 'pointer' }}
                onClick={handleDashboard}
              >
                Dashboard
              </div>
            )}
            {canViewReport && (
              <div
                className={`padding title-link${selectedButton === HeaderButton.Reports ? ' selected' : ''}`}
                style={{ cursor: 'pointer' }}
                onClick={handleReports}
              >
                Reports
              </div>
            )}
          </>
        )}
        {authState?.isAuthenticated && (
          <>
            <div className="padding title-link">{username}</div>
            <div
              className="padding title-link"
              style={{ cursor: 'pointer' }}
              data-testid="Logout"
              onClick={handleLogout}
            >
              Log out
            </div>
          </>
        )}
      </div>
      <div className="printHide" style={{ marginLeft: 200, minHeight: 30 }}>
        {children}
      </div>
    </IconContext.Provider>
  );
}
