import { ActionType, AppError, ErrorType } from '../constants/Interfaces';
import { mapApiError } from '../constants/Utils';
import Logger from '../constants/Logger';
import axios from 'axios';

interface State {
  maintenance: boolean;
  loading: boolean;
  error: AppError;
}

export function getInitialState(): State {
  return {
    maintenance: null,
    loading: false,
    error: null,
  };
}

export const configTypes = {
  CONFIG_GET_REQUEST: 'CONFIG_GET_REQUEST',
  CONFIG_GET_RESPONSE: 'CONFIG_GET_RESPONSE',
};

const LOG_PREFIX = `Reducer: config ->`;

export const configActions = {
  getConfig: () => async (dispatch) => {
    dispatch({ type: configTypes.CONFIG_GET_REQUEST });
    Logger.info(`${LOG_PREFIX} getConfig: Getting config json file`);

    try {
      const response = await axios.get(`/config.json`);
      const configJson = response.data;
      dispatch({
        type: configTypes.CONFIG_GET_RESPONSE,
        payload: configJson,
      });
      return configJson;
    } catch (e) {
      Logger.error(`${LOG_PREFIX} getConfig: failed to retrive config`, e);
      dispatch({
        type: configTypes.CONFIG_GET_RESPONSE,
        payload: mapApiError(ErrorType.CONFIG_ERROR, e),
        error: true,
      });
      // throw e;
      // consume the error, if the mainteance mode call fails, assume we are not in maintenance mode.
      return {
        maintenance: false,
      };
    }
  },
};

export default function config(state = getInitialState(), action) {
  const { type, payload, error } = action;

  switch (type) {
    case configTypes.CONFIG_GET_REQUEST: {
      return { ...state, loading: true, error: null };
    }

    case configTypes.CONFIG_GET_RESPONSE: {
      if (error) {
        return { ...getInitialState(), error: payload, loading: false };
      }
      return {
        ...state,
        ...payload,
        loading: false,
        error: null,
      };
    }

    case ActionType.PURGE: {
      return getInitialState();
    }
  }

  return state;
}
