import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { ContainerCard, Spinner } from '../../components';
import { TaskType } from '../../constants/Interfaces';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { dashboardActions } from '../../reducers/dashboard';
import './DashboardCount.scss';
import { UI } from '../../constants/Messages';

// const LOG_PREFIX = 'Component -> DashboardCount ->';

interface Props {
  handleFilter?: (type: TaskType) => void;
}

const DashboardCount = forwardRef((props: Props, ref) => {
  const { handleFilter } = props;

  // the currently selected search type
  const taskType: TaskType = useAppSelector((state) => state.dashboard.searchType);

  // state of the tile counts and if they are currently performing the initial load
  const [pendingSubmissionCount, setPendingSubmissionCount] = useState<string>(null);
  const [pendingSubmissionLoading, setPendingSubmissionLoading] = useState(true);

  const [pendingVerificationCount, setPendingVerificationCount] = useState<string>(null);
  const [pendingVerificationLoading, setPendingVerificationLoading] = useState(true);

  const [cardPendingCount, setCardPendingCount] = useState<string>(null);
  const [cardExportedCount, setCardExportedCount] = useState<string>(null);
  const [cardLoading, setCardLoading] = useState(true);

  const [consentPendingCount, setConsentPendingCount] = useState<string>(null);
  const [consentExportedCount, setConsentExportedCount] = useState<string>(null);
  const [consentLoading, setConsentLoading] = useState(true);

  /**
   * Initial get data
   */
  const dispatch = useAppDispatch();
  useEffect(() => {
    async function getPendingSubmission() {
      setPendingSubmissionCount(await dispatch(dashboardActions.getCount(TaskType.PENDING_SUBMISSION)));
    }
    async function getPendingVerification() {
      setPendingVerificationCount(await dispatch(dashboardActions.getCount(TaskType.PENDING_VERIFICATION)));
    }
    async function getCardPending() {
      setCardPendingCount(await dispatch(dashboardActions.getCount(TaskType.CARDS_PENDING)));
    }
    async function getCardExported() {
      setCardExportedCount(await dispatch(dashboardActions.getCount(TaskType.CARDS_EXPORTED)));
    }
    async function getConsentPending() {
      setConsentPendingCount(await dispatch(dashboardActions.getCount(TaskType.CONSENT_PENDING)));
    }
    async function getConsentExported() {
      setConsentExportedCount(await dispatch(dashboardActions.getCount(TaskType.CONSENT_EXPORTED)));
    }
    getPendingSubmission().then(() => setPendingSubmissionLoading(false));
    getPendingVerification().then(() => setPendingVerificationLoading(false));
    Promise.all([getCardPending(), getCardExported()]).then(() => setCardLoading(false));
    Promise.all([getConsentPending(), getConsentExported()]).then(() => setConsentLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Functions that can be called from parent components
  useImperativeHandle(ref, () => ({
    updateCount(type: TaskType, count) {
      switch (type) {
        case TaskType.PENDING_SUBMISSION:
          setPendingSubmissionCount(count);
          break;
        case TaskType.PENDING_VERIFICATION:
          setPendingVerificationCount(count);
          break;
        case TaskType.CARDS_PENDING:
          setCardPendingCount(count);
          break;
        case TaskType.CARDS_EXPORTED:
          setCardExportedCount(count);
          break;
        case TaskType.CONSENT_PENDING:
          setConsentPendingCount(count);
          break;
        case TaskType.CONSENT_EXPORTED:
          setConsentExportedCount(count);
          break;
      }
    },
  }));

  return (
    <div className="dashboard-count">
      <div className="marginLarge" style={{ marginTop: 0, flexDirection: 'row' }}>
        <ContainerCard
          title={UI.DASHBOARD_COUNT_PENDING_SUBMISSION}
          style={{ marginRight: 16, cursor: 'pointer', alignItems: 'center' }}
          onContainerClick={() => handleFilter(TaskType.PENDING_SUBMISSION)}
          className={taskType === TaskType.PENDING_SUBMISSION ? taskType : null}
        >
          {pendingSubmissionLoading ? (
            <Spinner />
          ) : (
            <div className="heading2" style={{ alignItems: 'center' }}>
              {pendingSubmissionCount}
            </div>
          )}
        </ContainerCard>
        <ContainerCard
          title={UI.DASHBOARD_COUNT_PENDING_VERIFICATION}
          style={{ marginLeft: 16, cursor: 'pointer', alignItems: 'center' }}
          onContainerClick={() => handleFilter(TaskType.PENDING_VERIFICATION)}
          className={taskType === TaskType.PENDING_VERIFICATION ? taskType : null}
        >
          {pendingVerificationLoading ? (
            <Spinner />
          ) : (
            <div className="heading2" style={{ alignItems: 'center' }}>
              {pendingVerificationCount}
            </div>
          )}
        </ContainerCard>
      </div>
      <div className="marginLarge" style={{ marginTop: 20, flexDirection: 'row' }}>
        <ContainerCard
          title={UI.DASHBOARD_COUNT_CARD}
          style={{ marginRight: 16, alignItems: 'center' }}
          titleStyle={{ cursor: 'pointer', width: '100%', justifyContent: 'center' }}
          onTitleClick={() => handleFilter(TaskType.CARDS_PENDING)}
          className={taskType === TaskType.CARDS_PENDING || taskType === TaskType.CARDS_EXPORTED ? taskType : null}
        >
          {cardLoading ? (
            <Spinner />
          ) : (
            <div style={{ width: '100%' }}>
              <div className="line" onClick={() => handleFilter(TaskType.CARDS_PENDING)}>
                <div className={`subline heading3${taskType === TaskType.CARDS_PENDING ? ' selected' : ''}`}>
                  {cardPendingCount}
                </div>
                <div className={`subline heading3${taskType === TaskType.CARDS_PENDING ? ' selected' : ''}`}>
                  {UI.DASHBOARD_COUNT_PENDING_EXPORT}
                </div>
              </div>

              <div className="line" onClick={() => handleFilter(TaskType.CARDS_EXPORTED)}>
                <div className={`subline heading3${taskType === TaskType.CARDS_EXPORTED ? ' selected' : ''}`}>
                  {cardExportedCount}
                </div>
                <div className={`subline heading3${taskType === TaskType.CARDS_EXPORTED ? ' selected' : ''}`}>
                  {UI.DASHBOARD_COUNT_TO_PRINT}
                </div>
              </div>
            </div>
          )}
        </ContainerCard>
        <ContainerCard
          title={UI.DASHBOARD_COUNT_CONSENT}
          style={{ marginLeft: 16, alignItems: 'center' }}
          titleStyle={{ cursor: 'pointer', width: '100%', justifyContent: 'center' }}
          onTitleClick={() => handleFilter(TaskType.CONSENT_PENDING)}
          className={taskType === TaskType.CONSENT_PENDING || taskType === TaskType.CONSENT_EXPORTED ? taskType : null}
        >
          {consentLoading ? (
            <Spinner />
          ) : (
            <div style={{ width: '100%' }}>
              <div className="line" onClick={() => handleFilter(TaskType.CONSENT_PENDING)}>
                <div className={`subline heading3${taskType === TaskType.CONSENT_PENDING ? ' selected' : ''}`}>
                  {consentPendingCount}
                </div>
                <div className={`subline heading3${taskType === TaskType.CONSENT_PENDING ? ' selected' : ''}`}>
                  {UI.DASHBOARD_COUNT_PENDING_EXPORT}
                </div>
              </div>

              <div className="line" onClick={() => handleFilter(TaskType.CONSENT_EXPORTED)}>
                <div className={`subline heading3${taskType === TaskType.CONSENT_EXPORTED ? ' selected' : ''}`}>
                  {consentExportedCount}
                </div>
                <div className={`subline heading3${taskType === TaskType.CONSENT_EXPORTED ? ' selected' : ''}`}>
                  {UI.DASHBOARD_COUNT_TO_PRINT}
                </div>
              </div>
            </div>
          )}
        </ContainerCard>
      </div>
    </div>
  );
});

export default DashboardCount;
