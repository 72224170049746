import React from 'react';
import maintenanceLogo from '../../assets/logos/Noun_Project_maintenance_icon_943595_cc.svg';

export function MaintenanceWindow() {
  return (
    <div
      id="root"
      style={{
        width: '100%',
        flex: 1,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <img src={maintenanceLogo} alt="Maintenance Logo" />
      <h1>Antibody Register is down for maintenance</h1>
    </div>
  );
}
