import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Spinner, Table } from '../../../components';
import {
  PersonConsentStatusOptions,
  ConsentStatus,
  ConsentTypes,
  PersonConsentRequestOptions,
  PersonConsentNotifiedByOptions,
  PersonConsentLetterOptions,
  Permission,
  GenericErrorCode,
  PersonConsentErrorCode,
  AppError,
} from '../../../constants/Interfaces';
import './Consent.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { personConsentActions } from '../../../reducers/personConsent';
import _ from 'lodash';
import Logger from '../../../constants/Logger';
import { getReferenceLabel } from '../../../constants/Utils';
import { selectPermission } from '../../../constants/Selectors';
import { consentTabNewRowControls } from './ConsentTabEditRow';
import { toast } from 'react-toastify';
import { ConsentTabTableLabels, Messages } from '../../../constants/Messages';
import { personActions } from '../../../reducers/person';
import ErrorTab from './ErrorTab';
import { ConfirmConfig, StatusTabStyle } from '../../../constants/UtilsTests';

const LOG_PREFIX = 'Component -> Consent ->';

interface Props {
  editableConsentStatus: ConsentStatus[];
  setEditableConsentStatus: (value: ConsentStatus[]) => void;
  editableConsentLetterStatus: ConsentStatus[];
  setEditableConsentLetterStatus: (value: ConsentStatus[]) => void;
  editableConsentRequests: ConsentStatus[];
  setEditableConsentRequests: (value: ConsentStatus[]) => void;
  personId: string;
  abrNumber: string;
  confirmConfig: ConfirmConfig;
  setIsConsentEditing: (value: boolean) => void;
}

export default function ConsentTab(props: Props) {
  const {
    editableConsentStatus,
    setEditableConsentStatus,
    editableConsentLetterStatus,
    setEditableConsentLetterStatus,
    editableConsentRequests,
    setEditableConsentRequests,
    personId,
    abrNumber,
    confirmConfig,
    setIsConsentEditing,
  } = props;

  const isLoading: boolean = useAppSelector((state) => state.personConsent.loading);
  const isError: AppError = useAppSelector((state) => state.personConsent.error);

  const ahpReference: any[] = useAppSelector((state) => state.reference.ahps);

  const canAddConsent = useAppSelector(selectPermission(Permission.canAddConsent));
  // these need to not be directly in the return of the component. Or you are asking for more/less hook errors when refreshing the tables.
  const [consentStatusEditing, setIsConsentStatusEditing] = useState(false);
  const consentStatusNewRow = consentTabNewRowControls(
    setIsConsentStatusEditing,
    addNewRow(ConsentTypes.STATUS),
    PersonConsentStatusOptions,
    ahpReference,
    confirmConfig,
    true,
    true,
    editableConsentStatus?.length > 0 ? editableConsentStatus[0].status : null
  );

  const [consentRequestEditing, setIsConsentRequestEditing] = useState(false);
  const requestSentNewRow = consentTabNewRowControls(
    setIsConsentRequestEditing,
    addNewRow(ConsentTypes.REQUESTS_SENT),
    PersonConsentRequestOptions,
    ahpReference,
    confirmConfig,
    false
  );

  const [consentLetterEditing, setIsConsentLetterEditing] = useState(false);
  const letterStatusNewRow = consentTabNewRowControls(
    setIsConsentLetterEditing,
    addNewRow(ConsentTypes.LETTER_STATUS),
    PersonConsentLetterOptions,
    ahpReference,
    confirmConfig,
    true
  );

  // if any of the tables are being edited, the tab is being edited
  useEffect(() => {
    if (consentStatusEditing || consentRequestEditing || consentLetterEditing) {
      setIsConsentEditing(true);
    } else {
      setIsConsentEditing(false);
    }
  }, [consentLetterEditing, consentRequestEditing, consentStatusEditing, setIsConsentEditing]);

  /**
   * Initial get data
   */
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!editableConsentStatus) {
      dispatch(personConsentActions.getPersonConsent(personId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Logic funcitons
   */

  function addNewRow(type: ConsentTypes) {
    return async function (row: ConsentStatus) {
      try {
        switch (type) {
          case ConsentTypes.STATUS:
            if (editableConsentStatus) editableConsentStatus.splice(0, 0, row);
            await dispatch(
              personConsentActions.postPersonConsent(
                {
                  consent: [
                    {
                      status: row.status,
                      notifiedByStatus: row.notifiedByStatus,
                      ahpRefId: row.ahpRefId,
                    },
                  ],
                },
                personId
              )
            );
            setEditableConsentStatus(
              _.orderBy(editableConsentStatus, ['date', 'createdDate'], ['desc', 'desc']).slice()
            );
            dispatch(personActions.getPersonDerived(abrNumber));
            break;
          case ConsentTypes.REQUESTS_SENT:
            if (editableConsentRequests) editableConsentRequests.splice(0, 0, row);
            await dispatch(
              personConsentActions.postPersonConsent(
                { consentOtherRequest: [{ status: row.status, date: row.date }] },
                personId
              )
            );
            setEditableConsentRequests(
              _.orderBy(editableConsentRequests, ['date', 'createdDate'], ['desc', 'desc']).slice()
            );
            break;
          case ConsentTypes.LETTER_STATUS:
            if (editableConsentLetterStatus) editableConsentLetterStatus.splice(0, 0, row);
            await dispatch(
              personConsentActions.postPersonConsent({ consentLetter: [{ status: row.status }] }, personId)
            );
            setEditableConsentLetterStatus(
              _.orderBy(editableConsentLetterStatus, ['date', 'createdDate'], ['desc', 'desc']).slice()
            );
            break;

          default:
            break;
        }
      } catch (e: any) {
        Logger.error(`${LOG_PREFIX} addNewRow: Error occurred while saving a new row`, e);
        if (e.response && e.response.status === GenericErrorCode.PERMISSION_ERROR) {
          toast(Messages.ERROR_403_API);
        } else if (
          e.response &&
          e.response.status === PersonConsentErrorCode.INVALID_REQUEST &&
          e.response.data?.error
        ) {
          toast(e.response.data?.error);
        } else {
          toast(Messages.ERROR_GENERIC);
        }
      }

      dispatch(personConsentActions.getPersonConsent(personId));
    };
  }

  const consentStatusColumns = [
    {
      Header: ConsentTabTableLabels.CONSENT_STATUS,
      accessor: statusCellFormatter(PersonConsentStatusOptions),
    },
    {
      Header: ConsentTabTableLabels.CONSENT_NOTIFIED_BY,
      accessor: notifiedByCellFormatter(PersonConsentNotifiedByOptions, ahpReference),
    },
    {
      Header: ConsentTabTableLabels.CONSENT_DATE,
      accessor: dateCellUTCFormatter,
      style: { width: 115 },
    },
  ];

  const consentLetterStatusColumns = [
    {
      Header: ConsentTabTableLabels.CONSENT_LETTER_STATUS,
      accessor: statusCellFormatter(PersonConsentLetterOptions),
    },
    {
      Header: ConsentTabTableLabels.CONSENT_DATE,
      accessor: dateCellUTCFormatter,
      style: { width: 115 },
    },
  ];

  const consentRequestColumns = [
    {
      Header: ConsentTabTableLabels.CONSENT_OTHER_STATUS,
      accessor: statusCellFormatter(PersonConsentRequestOptions),
    },
    {
      Header: ConsentTabTableLabels.CONSENT_DATE,
      accessor: dateCellFormatter,
      style: { width: 115 },
    },
  ];

  const scrollModeStyle = StatusTabStyle;

  if (isLoading) {
    return (
      <div className="consent" style={{ justifyContent: 'center', minHeight: '30vh' }}>
        <Spinner />
      </div>
    );
  }

  if (isError) {
    // if a 403 error is in the error object.
    if (isError.code === '403') {
      return <ErrorTab message={Messages.ERROR_403_SCREEN} />;
    }
    return (
      <ErrorTab
        retryFunction={() => {
          dispatch(personConsentActions.getPersonConsent(personId));
        }}
      />
    );
  }

  return (
    <div className="consent">
      <div className="row">
        <div style={{ width: '100%', flex: 1.5, ...scrollModeStyle }} className="column">
          <Table
            data={editableConsentStatus}
            columns={consentStatusColumns}
            noDataMessage="No consent status results to display"
            style={{ width: '99%' }}
            className="sticky brand altRowBackground"
            showNoDataMessage={false}
            preElements={canAddConsent ? consentStatusNewRow : null}
          />
        </div>
        <div style={{ width: '100%', flex: 1, ...scrollModeStyle }} className="column">
          <Table
            data={editableConsentRequests}
            columns={consentRequestColumns}
            noDataMessage="No other consent requests to display"
            style={{ width: '99%' }}
            className="sticky brand altRowBackground"
            showNoDataMessage={false}
            preElements={canAddConsent ? requestSentNewRow : null}
          />
        </div>
        <div style={{ width: '100%', flex: 1, ...scrollModeStyle }} className="column">
          <Table
            data={editableConsentLetterStatus}
            columns={consentLetterStatusColumns}
            noDataMessage="No other consent letters to display"
            style={{ width: '99%' }}
            className="sticky brand altRowBackground"
            showNoDataMessage={false}
            preElements={canAddConsent ? letterStatusNewRow : null}
          />
        </div>
      </div>
    </div>
  );
}

const statusCellFormatter = (options) => (originalRow: ConsentStatus) =>
  originalRow.status ? getReferenceLabel(options, originalRow.status, 'value') : null;

const notifiedByCellFormatter = (options, ahpReference) => (originalRow: ConsentStatus) => {
  const { notifiedByStatus, ahpRefId } = originalRow;
  const notifiedBy = notifiedByStatus ? getReferenceLabel(options, notifiedByStatus, 'value') : null;
  const ahp = ahpRefId ? getReferenceLabel(ahpReference, ahpRefId) : null;
  if (notifiedBy) {
    return ahp ? `${notifiedBy} - ${ahp}` : notifiedBy;
  }
  return null;
};

const dateCellUTCFormatter = (originalRow) =>
  originalRow.date ? moment.utc(originalRow.date).local().format('DD/MM/YYYY') : '';

const dateCellFormatter = (originalRow) => (originalRow.date ? moment(originalRow.date).format('DD/MM/YYYY') : '');
