/* eslint-disable @typescript-eslint/no-shadow */
import { ActionType, AppError, ErrorType, TaskType } from '../constants/Interfaces';
import API from '../constants/API';
import { mapApiError } from '../constants/Utils';
import Logger from '../constants/Logger';
import { dashboardRecordsPerPage } from '../constants/Messages';

interface State {
  searchType: TaskType;
  currentPage: number;
  searchLoading: boolean;
  countLoading: boolean;
  error: AppError;
}

export function getInitialState(): State {
  return {
    searchType: null,
    currentPage: 1,
    searchLoading: false,
    countLoading: false,
    error: null,
  };
}

export const dashboardTypes = {
  DASHBOARD_CLEAR: 'DASHBOARD_CLEAR',
  DASHBOARD_RESET: 'DASHBOARD_RESET',
  DASHBOARD_COUNT_GET_REQUEST: 'DASHBOARD_COUNT_GET_REQUEST',
  DASHBOARD_COUNT_GET_RESPONSE: 'DASHBOARD_COUNT_GET_RESPONSE',
  DASHBOARD_LIST_GET_REQUEST: 'DASHBOARD_LIST_GET_REQUEST',
  DASHBOARD_LIST_GET_RESPONSE: 'DASHBOARD_LIST_GET_RESPONSE',
};

const LOG_PREFIX = `Reducer: dashboard ->`;

export const dashboardActions = {
  getCount: (type: TaskType) => async (dispatch) => {
    dispatch({ type: dashboardTypes.DASHBOARD_COUNT_GET_REQUEST });
    Logger.info(`${LOG_PREFIX} getCount: Getting task counts`);

    const api = API.getInstance();
    let response;

    try {
      switch (type) {
        case TaskType.PENDING_SUBMISSION:
        case TaskType.PENDING_VERIFICATION:
          response = await api.head(`/results`, { status: type });
          break;
        case TaskType.CARDS_PENDING:
        case TaskType.CARDS_EXPORTED:
          response = await api.head(`/card`, {
            status: type === TaskType.CARDS_PENDING ? 'pendingExport' : 'exportedToPrint',
          });
          break;
        case TaskType.CONSENT_PENDING:
        case TaskType.CONSENT_EXPORTED:
          response = await api.head(`/consent`, {
            status: type === TaskType.CONSENT_PENDING ? 'pendingExport' : 'exportedToPrint',
          });
          break;
      }

      Logger.debug(`${LOG_PREFIX} getCount: information received.`);
      dispatch({ type: dashboardTypes.DASHBOARD_COUNT_GET_RESPONSE, error: false });
      return response['x-total-count'];
    } catch (e: any) {
      dispatch({
        type: dashboardTypes.DASHBOARD_COUNT_GET_RESPONSE,
        payload: mapApiError(ErrorType.PERSON_ERROR, e),
        error: true,
      });
      throw e;
    }
  },

  getList:
    (searchType: TaskType, page = 1) =>
    async (dispatch) => {
      const limit = dashboardRecordsPerPage;
      dispatch({ type: dashboardTypes.DASHBOARD_LIST_GET_REQUEST, payload: searchType });
      Logger.info(`${LOG_PREFIX} getList: Getting task information ${searchType}`);

      let response;

      try {
        const api = API.getInstance();
        switch (searchType) {
          case TaskType.PENDING_SUBMISSION:
          case TaskType.PENDING_VERIFICATION:
            response = await api.get(`/results`, { status: searchType, limit, offset: (page - 1) * limit });
            break;
          case TaskType.CARDS_PENDING:
          case TaskType.CARDS_EXPORTED:
            response = await api.get(`/card`, {
              status: searchType === TaskType.CARDS_PENDING ? 'pendingExport' : 'exportedToPrint',
              limit,
              offset: (page - 1) * limit,
            });
            break;
          case TaskType.CONSENT_PENDING:
          case TaskType.CONSENT_EXPORTED:
            response = await api.get(`/consent`, {
              status: searchType === TaskType.CONSENT_PENDING ? 'pendingExport' : 'exportedToPrint',
              limit,
              offset: (page - 1) * limit,
            });
            break;
        }
        Logger.debug(`${LOG_PREFIX} getList: information received.`);
        dispatch({ type: dashboardTypes.DASHBOARD_LIST_GET_RESPONSE, error: false, payload: page });
        return { records: response.records, totalCount: response.headers['x-total-count'] };
      } catch (e) {
        dispatch({
          type: dashboardTypes.DASHBOARD_LIST_GET_RESPONSE,
          payload: mapApiError(ErrorType.PERSON_ERROR, e),
          error: true,
        });
        throw e;
      }
    },

  clearDashboard: () => async (dispatch) => {
    dispatch({ type: dashboardTypes.DASHBOARD_CLEAR });
  },

  resetDashboardPage: () => async (dispatch) => {
    dispatch({ type: dashboardTypes.DASHBOARD_RESET });
  },
};

export default function person(state = getInitialState(), action) {
  const { type, payload, error } = action;

  switch (type) {
    case dashboardTypes.DASHBOARD_COUNT_GET_REQUEST: {
      return { ...state, countLoading: true, error: null };
    }

    case dashboardTypes.DASHBOARD_LIST_GET_REQUEST: {
      return { ...state, searchLoading: true, error: null, searchType: payload };
    }

    case dashboardTypes.DASHBOARD_COUNT_GET_RESPONSE: {
      if (error) {
        return { ...state, error: payload, countLoading: false };
      }
      return { ...state, countLoading: false, error: null };
    }

    case dashboardTypes.DASHBOARD_LIST_GET_RESPONSE: {
      if (error) {
        return { ...state, error: payload, searchLoading: false };
      }
      return { ...state, searchLoading: false, error: null, currentPage: payload };
    }

    case dashboardTypes.DASHBOARD_RESET: {
      return { ...state, currentPage: 1 };
    }

    case dashboardTypes.DASHBOARD_CLEAR:
    case ActionType.PURGE: {
      return getInitialState();
    }
  }

  return state;
}
