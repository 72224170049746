/**
 * Define all data models used in the app
 */
// enums

export enum ActionType {
  PURGE,
}

export enum PersonType {
  PATIENT = 'Patient',
  DONOR = 'Donor',
  PERSON = 'Person',
}

export enum GenericErrorCode {
  PERMISSION_ERROR = 403,
  NOT_FOUND = 404,
  BAD_DATA = 400,
}

export enum PersonErrorCode {
  BAD_DATA = 400,
  MATCHING_ERROR = 409,
  SERVER_ERROR = 500,
}

export enum SearchDonorErrorCode {
  BAD_DATA = 400,
  NO_DONOR = 404,
  SERVER_ERROR = 500,
}

export enum PersonConsentErrorCode {
  INVALID_REQUEST = 400,
  SERVER_ERROR = 500,
}

export enum ReportErrorCode {
  INVALID_REQUEST = 400,
  SERVER_ERROR = 500,
}

export enum PersonErrorName {
  DUPLICATE_HOSPITAL_NUMBER = 'DUPLICATE_HOSPITAL_NUMBER', // post
  INTERNAL_ERROR = 'INTERNAL_ERROR', // post
  INVALID_REQUEST = 'INVALID_REQUEST', // patch
}

export enum ErrorType {
  PERSON_ERROR,
  PERSON_RESULT_ERROR,
  ADDRESS_ERROR,
  USER_ERROR,
  REFERENCE_ERROR,
  CONFIG_ERROR,
}

export enum Scope {
  API = 'api',
  ADDRESS = 'address',
}

export enum Gender {
  Female = 'F',
  Male = 'M',
  NonBinary = 'X',
}

export enum SearchOptionValues {
  ExactMatch = 'EXACT',
  StartsWith = 'STARTS',
  Contains = 'CONTAINS',
}

export enum PendingOperation {
  CREATE,
  UPDATE,
  DELETE,
  NONE,
}

export enum PersonStatus {
  DRAFT = 'DRAFT',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  VERIFIED = 'VERIFIED',
}

export enum TestStatus {
  DRAFT = 'DRAFT',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  VERIFIED = 'VERIFIED',
  UPDATED = 'UPDATED',
  UPDATED_PENDING_VERIFICATION = 'UPDATED_PENDING_VERIFICATION',
}

export const PersonStatusLabel = new Map<string, string>([
  [PersonStatus.DRAFT, 'Draft'],
  [PersonStatus.PENDING_VERIFICATION, 'Pending verification'],
  [PersonStatus.VERIFIED, 'Verified'],
]);

export enum UserRole {
  REDX,
  HOSPITAL,
}

export const GenderOptions = [
  { value: Gender.Female, label: 'Female' },
  { value: Gender.Male, label: 'Male' },
  { value: Gender.NonBinary, label: 'Non-binary' },
];

export enum SearchType {
  NAME = 'Name',
  DONORID = 'Donor',
  ADVANCED = 'Advanced',
}

export const SearchTypeOptions = [
  { value: SearchType.NAME, label: 'Basic search' },
  { value: SearchType.DONORID, label: 'Donor ID' },
  { value: SearchType.ADVANCED, label: 'Advanced search' },
];

export const SearchOptions = [
  { value: SearchOptionValues.ExactMatch, label: 'Exact match' },
  { value: SearchOptionValues.StartsWith, label: 'Starts with' },
];

export enum ConsentTypes {
  STATUS = 'STATUS',
  LETTER_STATUS = 'LETTER_STATUS',
  REQUESTS_SENT = 'REQUESTS_SENT',
}

export enum PersonConsentStatus {
  PENDING = 'PENDING',
  WITHDRAWN = 'WITHDRAWN',
  DECEASED = 'DECEASED',
  ACTIVE = 'ACTIVE',
}
export const PersonConsentStatusOptions = [
  { value: PersonConsentStatus.PENDING, label: 'Pending' },
  { value: PersonConsentStatus.WITHDRAWN, label: 'Withdrawn' },
  { value: PersonConsentStatus.DECEASED, label: 'Deceased' },
  { value: PersonConsentStatus.ACTIVE, label: 'Active' },
];

export const ReportConsentStatusOptions = [
  { value: PersonConsentStatus.PENDING, label: 'Pending' },
  { value: PersonConsentStatus.WITHDRAWN, label: 'Withdrawn' },
  { value: PersonConsentStatus.DECEASED, label: 'Deceased' },
];

export enum PersonConsentRequest {
  SMS = 'TEXT',
  EMAIL = 'EMAIL',
  DONOR_LETTER = 'DONOR_LETTER_LIFEBLOOD',
}
export const PersonConsentRequestOptions = [
  { value: PersonConsentRequest.SMS, label: 'SMS' },
  { value: PersonConsentRequest.EMAIL, label: 'Email' },
  { value: PersonConsentRequest.DONOR_LETTER, label: 'Donor letter - Lifeblood' },
];

export enum PersonConsentLetter {
  PENDING_EXPORT = 'PENDING_EXPORT',
  EXPORTED_TO_PRINT = 'EXPORTED_TO_PRINT',
  SENT = 'SENT',
  RETURNED = 'RETURNED',
  REQUEST_CANCELLED = 'REQUEST_CANCELLED',
}
export const PersonConsentLetterOptions = [
  { value: PersonConsentLetter.PENDING_EXPORT, label: 'Pending export' },
  { value: PersonConsentLetter.EXPORTED_TO_PRINT, label: 'Exported - to print' },
  { value: PersonConsentLetter.SENT, label: 'Sent' },
  { value: PersonConsentLetter.RETURNED, label: 'Returned' },
  { value: PersonConsentLetter.REQUEST_CANCELLED, label: 'Cancelled' },
];

export enum PersonConsentNotifiedBy {
  LETTER = 'LETTER',
  SMS = 'SMS',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  AHP = 'AHP',
  FORM = 'FORM',
  RELATIVE = 'RELATIVE',
  MIGRATED = 'MIGRATED',
}
export const PersonConsentNotifiedByOptions = [
  { value: PersonConsentNotifiedBy.LETTER, label: 'Letter' },
  { value: PersonConsentNotifiedBy.FORM, label: 'Form' },
  { value: PersonConsentNotifiedBy.SMS, label: 'SMS' },
  { value: PersonConsentNotifiedBy.PHONE, label: 'Phone' },
  { value: PersonConsentNotifiedBy.EMAIL, label: 'Email' },
  { value: PersonConsentNotifiedBy.RELATIVE, label: 'Relative' },
  { value: PersonConsentNotifiedBy.AHP, label: 'AHP' },
  { value: PersonConsentNotifiedBy.MIGRATED, label: 'Migrated' },
];
export const receivedActiveList = [
  { id: PersonConsentNotifiedBy.LETTER, label: 'Letter' },
  { id: PersonConsentNotifiedBy.FORM, label: 'Form' },
  { id: PersonConsentNotifiedBy.SMS, label: 'SMS' },
  { id: PersonConsentNotifiedBy.EMAIL, label: 'Email' },
  { id: PersonConsentNotifiedBy.AHP, label: 'AHP' },
];
export const receivedWithdrawnList = [
  { id: PersonConsentNotifiedBy.PHONE, label: 'Phone' },
  { id: PersonConsentNotifiedBy.LETTER, label: 'Letter' },
  { id: PersonConsentNotifiedBy.FORM, label: 'Form' },
  { id: PersonConsentNotifiedBy.EMAIL, label: 'Email' },
  { id: PersonConsentNotifiedBy.SMS, label: 'SMS' },
];
export const receivedDeceasedList = [
  { id: PersonConsentNotifiedBy.LETTER, label: 'Letter' },
  { id: PersonConsentNotifiedBy.AHP, label: 'AHP' },
  { id: PersonConsentNotifiedBy.RELATIVE, label: 'Relative' },
  { id: PersonConsentNotifiedBy.EMAIL, label: 'Email' },
];

export enum PersonCard {
  PENDING_EXPORT = 'PENDING_EXPORT',
  EXPORTED_TO_PRINT = 'EXPORTED_TO_PRINT',
  SENT = 'SENT',
  RETURNED = 'RETURNED',
  REQUEST_CANCELLED = 'REQUEST_CANCELLED',
}
export const PersonCardOptions = [
  { value: PersonCard.PENDING_EXPORT, label: 'Pending export' },
  { value: PersonCard.EXPORTED_TO_PRINT, label: 'Exported - to print' },
  { value: PersonCard.SENT, label: 'Sent' },
  { value: PersonCard.RETURNED, label: 'Returned' },
  { value: PersonCard.REQUEST_CANCELLED, label: 'Cancelled' },
];

export enum TaskType {
  PENDING_SUBMISSION = 'pendingSubmission',
  PENDING_VERIFICATION = 'pendingVerification',
  CARDS_PENDING = 'cardsPending',
  CARDS_EXPORTED = 'cardsExported',
  CONSENT_PENDING = 'consentPending',
  CONSENT_EXPORTED = 'consentExported',
}

export enum Permission {
  canViewDonorId = 'canViewDonorId',
  canViewMobile = 'canViewMobile',
  canViewEmail = 'canViewEmail',
  canViewAddress = 'canViewAddress',
  canCreateDonor = 'canCreateDonor',
  canCreatePatient = 'canCreatePatient',
  canModifyPerson = 'canModifyPerson',
  canSubmitPerson = 'canSubmitPerson',
  canVerifyPerson = 'canVerifyPerson',
  canAddTest = 'canAddTest',
  canAddDonorTest = 'canAddDonorTest',
  canViewDonationNumber = 'canViewDonationNumber',
  canViewTestStatus = 'canViewTestStatus',
  canViewVerifiedOnly = 'canViewVerifiedOnly',
  canModifyTest = 'canModifyTest',
  canSubmitTest = 'canSubmitTest',
  canVerifyTest = 'canVerifyTest',
  canDeleteTestUptoVerify = 'canDeleteTestUptoVerify',
  canDeleteTestAfterFirstVerify = 'canDeleteTestAfterFirstVerify',
  canViewDashboard = 'canViewDashboard',
  canViewConsent = 'canViewConsent',
  canAddConsent = 'canAddConsent',
  canPrintPDF = 'canPrintPDF',
  canViewCard = 'canViewCard',
  canAddCard = 'canAddCard',
  canViewReport = 'canViewReport',
}

// interfaces
// some values are marked with // api returns number
// however this doesn't really seem to matter much, since they are immutable id values, we never need to perform math on them
// and some components use them as strings, so may as well treat them like one.
export interface AccessToken {
  token: string;
  expiry: Date;
}

export interface Address {
  addressLine1: string;
  addressLine2: string;
  suburb: string;
  state: string;
  postcode: string;
}

export interface AddressResponse {
  address: [
    { addressLine1: string },
    { addressLine2: string },
    { addressLine3: string },
    { locality: string }, // suburb
    { province: string }, // state
    { postalCode: string },
    { country: string }
  ];
}

export interface AddressCandidate {
  addressId: string;
  address: string;
}

export interface AddressSearchResponse {
  count: number;
  results: AddressResult[];
}

export interface AddressResult {
  suggestion: string;
  matched: any[];
  format: string;
}
export interface ApiError {
  message: string;
  name?: string;
  statusCode?: number;
}

export interface AppError extends Error {
  type?: ErrorType;
  attributes?: object;
  code?: string;
}

export interface Antibody {
  id: string; // api returns number
  order: number;
  label: string;
  active: boolean;
}
export interface BloodGroup {
  id: string; // api returns number
  label: string;
  order: number;
  active: boolean;
}

export interface RecordStatus {
  id: string; // api returns number
  statusCode: string;
  label: string;
}

export interface Method {
  id: string; // api returns number
  order: number;
  label: string;
  active: boolean;
}

export interface TestLab {
  id: string; // api returns number
  order: number;
  label: string;
  active: boolean;
}

export interface AHP {
  id: string; // api returns number
  order: number;
  label: string;
  active: boolean;
}

export interface Titre {
  id: string; // api returns number
  order: number;
  label: string;
  active: boolean;
}

export interface Antigen {
  id: string; // api returns number
  order: number;
  antigen: string;
  label: string;
  active: boolean;
}

export interface ProbRhGeno {
  id: string; // api returns number
  order: number;
  label: string;
  active: boolean;
}

export interface Rhd {
  id: string; // api returns number
  order: number;
  label: string;
  active: boolean;
}

export interface PhenotypeSubgroup {
  id: string; // api returns number
  order: number;
  label: string;
  active: boolean;
}

export interface ReferenceResponse {
  records: {
    bloodGroups: BloodGroup[];
    recordStatuses: RecordStatus[];
  };
  maxAllowedRecordLimit: boolean;
}

export interface EmailVerifyResponse {
  transaction_id: string;
  result: {
    email: string;
    confidence: string;
    verbose: string;
  };
}

export interface ElementLocation {
  x: number;
  y: number;
}

export interface MobileVerifyResponse {
  result: {
    number: string;
    validated_phone_number: string;
    formatted_phone_number: string;
    phone_type: string;
    confidence: string;
  };
}

export interface Person {
  personId?: string; // api returns number
  abrNumber?: string; // api returns number
  status?: PersonStatus;
  hospitalNumber?: string;
  donorId?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  previousName1?: string;
  previousName2?: string;
  previousName3?: string;
  dob: string;
  gender?: Gender;
  email?: string;
  mobile?: string;
  address?: Address;
  aboRhType?: string;
  bloodGroupRefId?: string;
  rhdGenotype?: string;
  rhceGenotype?: string;
  heaGenotype?: string;
  personComments?: string;
  genotypeComments?: string;
  antibodyComments?: string;
  phenotypeComments?: string;
  recommendBloodTransfusion?: string;
  knownAntibodies?: string;
  knownPhenotypes?: string;
  latestConsentStatus?: PersonConsentStatus;
  canVerify?: number;
}

export interface PersonDerived {
  personId: string; // api returns number
  antibodyComments?: string;
  phenotypeComments?: string;
  recommendBloodTransfusion?: string;
  knownAntibodies?: string;
  knownPhenotypes?: string;
  latestConsentStatus?: PersonConsentStatus;
}

export interface PersonStatusUpdatePayload {
  personId: string; // api returns number
  status: PersonStatus;
  dob?: null;
  mobile?: null;
}

export interface PersonCommentUpdatePayload {
  personId: string; // api returns number
  antibodyComments?: string;
  phenotypeComments?: string;
  dob?: null;
  mobile?: null;
  status?: PersonStatus;
}

// Work around for address inconsistency
export interface PersonResponse {
  personId?: string; // api returns number
  abrNumber?: string; // api returns number
  status?: PersonStatus;
  hospitalNumber?: string;
  donorId?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  previousName1?: string;
  previousName2?: string;
  previousName3?: string;
  dob: string;
  gender?: Gender;
  email?: string;
  mobile?: string;
  aboRhType?: string;
  bloodGroupRefId?: string;
  rhdGenotype?: string;
  rhceGenotype?: string;
  heaGenotype?: string;
  personComments?: string;
  antibodyComments?: string;
  phenotypeComments?: string;
  genotypeComments?: string;
  address?: Address;
  // Work around for address inconsistency
  addressLine1?: string;
  addressLine2?: string;
  suburb?: string;
  state?: string;
  postcode?: string;
}

export interface PersonSearchParams {
  firstName?: string;
  lastNames?: string;
  lastNameCheckbox?: boolean;
  abrNumber?: string;
  donorId?: string;
  firstNameSearchBy?: SearchOptionValues;
  lastName?: string;
  lastNameSearchBy?: SearchOptionValues;
  middleName?: string;
  middleNameSearchBy?: SearchOptionValues;
  previousLastName?: string;
  previousLastNameSearchBy?: SearchOptionValues;
  dob?: string;
  hospitalNumber?: string;
  mobile?: string;
  email?: string;
  maxRecordCount?: number;
}

export interface ReportSearchParams {
  dateFrom: string;
  dateTo: string;
  consentStatus: string;
}

export interface PostPersonResponse {
  id: string;
}

export interface GenericTestResult {
  testResultId: number;
  sampleDate: string;
  recommendedBloodForTransfusion?: string;
  antibodyResults?: AntibodyTestAntibody[];
  phenotypeResults?: PhenotypeTestLine[];
  pendingOperation?: PendingOperation;
}
export interface AntibodyTestResult {
  testResultId: number;
  sampleDate: string;
  hospitalNumber?: string;
  donationNumber?: string;
  testedByReference?: string;
  recommendedBloodForTransfusion?: string;
  antibodyResults: AntibodyTestAntibody[];
  pendingOperation?: PendingOperation;
  status?: TestStatus;
  canVerify?: number;
}

export interface AntibodyTestAntibody {
  antiBodyResultId: number;
  antibodyReference: string;
  methods?: string[];
  titreReference?: string;
  quantity?: number;
}

export interface AntibodyTestTranslated {
  testResultId: number;
  sampleDate?: string;
  antiBodyResultId: number;
  antibodyReference?: string;
  methods?: any[];
  titreReference?: string;
  quantity?: number;
  hospitalNumber?: string;
  donationNumber?: string;
  testedByReference?: string;
  recommendedBloodForTransfusion?: string;
  status: TestStatus;
  canVerify?: number;

  // internal table fields
  mode?: number;
  first?: boolean;
  last?: boolean;
  isDirty: boolean;

  // used for deciding to filter out inactive options
  antibodyReferenceOriginal: string;
  methodsOriginal: string[];
  titreReferenceOriginal: string;
  testedByReferenceOriginal: string;
}

export interface PhenotypeTestResult {
  testResultId: number;
  sampleDate: string;
  donationNumber?: string;
  hospitalNumber?: string;
  testedByReference: string;
  phenotypeResult: PhenotypeTestLine;
  pendingOperation?: PendingOperation;
  status?: TestStatus;
  canVerify?: number;
}

export interface PhenotypeTestLine {
  phenotypeResultId: number;
  subgroupReference?: string;
  probRhGenoReference?: string;
  rhdReference?: string;
  phenotype?: string[];
}

export interface PhenotypeTestTranslated {
  testResultId: number;
  sampleDate?: string;
  phenotypeResultId: number;
  subgroupReference?: string;
  probRhGenoReference?: string;
  rhdReference?: string;
  phenotype?: string[];
  hospitalNumber?: string;
  donationNumber?: string;
  testedByReference?: string;
  status: TestStatus;
  canVerify?: number;

  // internal table columns
  mode?: number;
  first?: boolean;
  last?: boolean;
  isDirty: boolean;

  // Original values for reference list filtering
  subgroupReferenceOriginal: string;
  probRhGenoReferenceOriginal: string;
  rhdReferenceOriginal: string;
  phenotypeOriginal: string[];
  testedByReferenceOriginal: string;
}

export interface ConsentStatus {
  status: string;
  notifiedByStatus?: string;
  date: string;
  createdDate: string;
  ahpRefId?: number;
}

export interface CardStatus {
  cardHistoryId: number;
  status: string;
  date: string;
  firstName?: string;
  lastName?: string;
  bloodGroup?: string;
  knownAntibodies?: string;
  recommendedBloodTransfusion?: string;
  sampleDate?: string;
  comment?: string;

  mode?: number;
  commentOriginal?: string;
}

export interface ConsentStatusApi {
  consent?: [{ status: string; date?: string; notifiedByStatus?: string; ahpRefId?: number }];
  consentLetter?: [{ status: string; date?: string }];
  consentOtherRequest?: [{ status: string; date: string }];
}

export interface CardStatusApi {
  card?: [{ status: string; date?: string }];
}

export interface SearchPersonResponse {
  records: Person[];
  maxAllowedRecordLimit: boolean;
  searchResultsLimitedDueToFiltering: boolean;
}

export interface JwtResponse {
  jwt: string;
}

export interface User {
  firstName: string;
  lastName: string;
  dob: string;
  email: string;
  address: Address;
  team: string;
}

export interface UserResponse {
  user: User;
}

export interface DashboardResultsGet {
  records: [];
  totalCount: number;
}

export interface PermissionsResponse {
  records: string[];
}

export enum ValidationState {
  FAILED,
  NULL,
  PASSED,
}
export interface Validator {
  readonly fn: (value: any, row?: any) => Promise<boolean>;
  errorMessage?: string; // this message will be displayed when the validation has failed.
  inProgressMessage?: string; // this message will be displayed while the validator function is running.
}
