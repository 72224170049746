import React, { CSSProperties } from 'react';
import './ButtonPrimary.scss';

interface Props {
  buttonStyle?: object;
  buttonClass?: string;
  titleStyle?: object;
  className?: string;
  containerStyle?: CSSProperties;
  disabled?: boolean;
  hidden?: boolean;
  // icon?: IconProps;
  onClick: () => void;
  title: string;
  tooltip?: string;
}

export function ButtonPrimary(props: Props) {
  const {
    buttonStyle,
    buttonClass,
    containerStyle,
    titleStyle,
    hidden = false,
    onClick,
    disabled,
    title,
    className,
    tooltip,
  } = props;

  const finalContainerStyle = { ...containerStyle, display: hidden ? 'none' : 'flex' };
  const finalTitleStyle = {
    ...titleStyle,
  };

  return (
    <div className={`button-container ${className || ''}`} style={finalContainerStyle}>
      <div
        className={`button ${buttonClass || ''} ${disabled ? 'disabled' : ''}`}
        style={buttonStyle}
        onClick={disabled ? null : onClick}
        data-testid={title}
        title={tooltip}
      >
        <div className="text button-inner">
          <div style={finalTitleStyle}>{title}</div>
        </div>
      </div>
    </div>
  );
}
