import React, { CSSProperties } from 'react';

interface Props {
  hidden?: boolean;
  onClick: () => void;
  title: string;
  style?: CSSProperties;
  className?: string;
}

export function ButtonLink(props: Props) {
  const { hidden = false, onClick, title, style = {}, className } = props;
  if (hidden) {
    return null;
  }
  const defaultStyle: CSSProperties = { textAlign: 'center', margin: '0 auto', marginTop: 5 };
  const finalStyle = { ...defaultStyle, ...style };
  return (
    <div onClick={onClick} className={className ? `link ${className}` : 'link'} style={finalStyle}>
      {title}
    </div>
  );
}
