import {
  AntibodyTestAntibody,
  AntibodyTestResult,
  AntibodyTestTranslated,
  Method,
  Antigen,
  PhenotypeTestResult,
  PhenotypeTestTranslated,
  PendingOperation,
  TestStatus,
} from './Interfaces';
import _ from 'lodash';
import { ScreenMode } from '../screens/Person/PersonScreen';
import { TestUpdate } from '../screens/Person/components/TestTableCells';
import { getReferenceLabel } from './Utils';
import { personResultsActions } from '../reducers/personResults';
import { toast } from 'react-toastify';
import { CSSProperties } from 'react';
import { decimalValidator } from './Validators';

// Moving clutter from TestTabs into this file.

function getMultSelectValue(valueArray, reference) {
  const result = _.filter(reference, (refItem) => _.includes(valueArray, refItem.id));
  return result || [];
}

export const TestTabEditStyle: CSSProperties = { maxHeight: '50vh', overflowY: 'auto' };
export const TestTabViewStyle: CSSProperties = { maxHeight: '80vh', overflowY: 'auto' };
export const StatusTabStyle: CSSProperties = { minHeight: '30vh', maxHeight: '50vh', overflowY: 'auto' };

export enum TestResultType {
  ANTIBODY = 'ANTIBODY',
  PHENOTYPE = 'PHENOTYPE',
}

export const TestResultTypeDetails = [
  { type: TestResultType.ANTIBODY, subrowIdField: 'antiBodyResultId' },
  { type: TestResultType.PHENOTYPE, subrowIdField: 'phenotypeResultId' },
];

// convert api object to a table object
export const editQuant = (value) => {
  if (!decimalValidator.test(value)) {
    return value;
  }
  let number = value;
  if (!number || number.length === 0) {
    return '';
  }

  // 1000000 is too much
  if (+number >= 999999.9) {
    number = 999999.9;
  }

  // anything below 0.1 is rounded to 2 places
  if (+number < 0.1) {
    number = (Math.round(number * 100) / 100).toFixed(2);
  } else {
    number = (Math.round(number * 100) / 100).toFixed(1);
  }

  return number;
};

/**
 * Converts a set of antibody tests from the api into rows to be used in the table component
 * @param tests the tests from the api
 * @param methodReference the method references from the api
 * @returns
 */
export function buildAntibodyTestTableRows(
  tests: AntibodyTestResult[],
  methodReference: Method[]
): AntibodyTestTranslated[] {
  const results: AntibodyTestTranslated[] = [];
  let newAntibodyResultId = 1;
  if (tests && tests.length > 0) {
    _.each(tests, (testResult: AntibodyTestResult) => {
      _.each(testResult.antibodyResults, (antibodyResult: AntibodyTestAntibody) => {
        const methods = getMultSelectValue(antibodyResult.methods, methodReference);
        results.push({
          ...antibodyResult,
          quantity: editQuant(antibodyResult.quantity),
          antiBodyResultId: newAntibodyResultId,
          sampleDate: testResult.sampleDate,
          testResultId: testResult.testResultId,
          recommendedBloodForTransfusion: testResult.recommendedBloodForTransfusion,
          hospitalNumber: testResult.hospitalNumber,
          donationNumber: testResult.donationNumber,
          testedByReference: testResult.testedByReference,
          methods,
          mode: ScreenMode.VIEW,
          isDirty: false,
          status: testResult.status,
          canVerify: testResult.canVerify,
          antibodyReferenceOriginal: antibodyResult.antibodyReference,
          methodsOriginal: antibodyResult.methods,
          titreReferenceOriginal: antibodyResult.titreReference,
          testedByReferenceOriginal: testResult.testedByReference,
        });
        newAntibodyResultId += 1;
      });
    });
  }
  return markFirstRows(results);
}

/**
 * Converts a set of phenotype tests from the api into rows to be used in the table component
 * @param tests the phenotype tests from the api
 * @param antigenReference the antigens reference list from the api
 * @returns
 */
export function buildPhenotypeTestTableRows(
  tests: PhenotypeTestResult[],
  antigenReference: Antigen[]
): PhenotypeTestTranslated[] {
  const results: PhenotypeTestTranslated[] = [];
  let newPhenotypeResultId = 1;
  if (tests && tests.length > 0) {
    _.each(tests, (testResult: PhenotypeTestResult) => {
      // no array on the sublines unlike antibodies
      const phenotype = getMultSelectValue(testResult.phenotypeResult.phenotype, antigenReference);
      results.push({
        ...testResult.phenotypeResult,
        phenotypeResultId: newPhenotypeResultId,
        sampleDate: testResult.sampleDate,
        donationNumber: testResult.donationNumber,
        hospitalNumber: testResult.hospitalNumber,
        testedByReference: testResult.testedByReference,
        testResultId: testResult.testResultId,
        phenotype,
        mode: ScreenMode.VIEW,
        isDirty: false,
        status: testResult.status,
        canVerify: testResult.canVerify,
        subgroupReferenceOriginal: testResult.phenotypeResult.subgroupReference,
        probRhGenoReferenceOriginal: testResult.phenotypeResult.probRhGenoReference,
        rhdReferenceOriginal: testResult.phenotypeResult.rhdReference,
        phenotypeOriginal: testResult.phenotypeResult.phenotype,
        testedByReferenceOriginal: testResult.testedByReference,
      });
      newPhenotypeResultId += 1;
    });
  }
  return markFirstRows(results);
}

export interface HandleTestUpdateParams {
  type: TestResultType;
  translatedTests: AntibodyTestTranslated[] | PhenotypeTestTranslated[];
  setTranslatedTests: (translatedTests: any) => void;
  updateEditMode: (translatedTests: any) => void;
}

/**
 * update a translated test row with new data from a cell
 * @param updateOptions
 */
export function handleTestUpdate(updateOptions: HandleTestUpdateParams) {
  return function ({ testResultId, lineResultId, field }: TestUpdate, markDirty = true) {
    return function (value: any) {
      let testIndex = null;
      if (typeof lineResultId === 'undefined') {
        // this is a field that spans across all subrows
        updateOptions.translatedTests.forEach((test) => {
          if (test.testResultId === testResultId) {
            test[field] = value;
            if (markDirty) {
              test.isDirty = true;
            }
          }
        });
      } else {
        // this field only needs to update the one sub row
        const subRowColumn = getReferenceLabel(TestResultTypeDetails, updateOptions.type, 'type', 'subrowIdField');
        testIndex = _.findIndex(
          updateOptions.translatedTests,
          (test) => test.testResultId === testResultId && test[subRowColumn] === lineResultId
        );
        updateOptions.translatedTests[testIndex][field] = value;
        if (markDirty) {
          updateOptions.translatedTests[testIndex].isDirty = true;
        }
      }
      if (field === 'mode') {
        updateOptions.updateEditMode(updateOptions.translatedTests);
      }
      updateOptions.setTranslatedTests(updateOptions.translatedTests.slice());
    };
  };
}

/**
 * Given a set of translated tests, figure out which subrows are the first and last of their main row
 * the test tables have special handing for the first and last rows of each test. And look for these flags.
 * @param tests either a set of translated antibody or phenotype tests
 * @returns the test table, with first and last rows correctly marked
 */
export function markFirstRows(tests: any[]): any[] {
  let lastId = 0;
  const testResultIds = [];
  let applyEvenClass = true;
  _.each(tests, (test: any) => {
    const { testResultId } = test;
    if (testResultId !== lastId) {
      testResultIds.push(testResultId);
      applyEvenClass = !applyEvenClass;
    }
    test.className = applyEvenClass ? 'even' : 'odd';
    if (test.status) {
      test.className += ` ${test.status}`;
    }

    test.first = testResultId !== lastId;
    test.last = false;
    lastId = testResultId;
  });
  _.each(testResultIds, (testResultId) => {
    const lastIndex = _.findLastIndex(tests, (test) => test.testResultId === testResultId);
    tests[lastIndex].last = true;
  });
  return tests;
}

/**
 * filters down the list of antigens, based on which antigens are currently selected.
 * @param fieldValue the value of the antigen field
 * @param options the references list of the antigens
 * @returns
 */
export function filterAntigenOptions(fieldValue: Antigen[], options: Antigen[]) {
  const selectedValues: string[] = [];
  _.each(fieldValue, (value) => {
    selectedValues.push(value.antigen);
  });
  const result: Antigen[] = [];
  _.each(options, (option) => {
    result.push({ ...option, isDisabled: _.includes(selectedValues, option.antigen) });
  });
  return result;
}

/**
 * figure out if a set of tests need api calls or not to save.
 * @param originalTestResults the original set of tests from the api
 * @param translatedTests a set of antibody or phenotype tests that have been edited, maybe
 * @param createTestObject a function to convert the edited test to an object for the api call.
 * @returns a set of test objects ready for api calls
 */
export function findListOfUpdates(
  originalTestResults,
  translatedTests,
  createTestObject: (test: any, submitRequested?: boolean) => any,
  submitRequested?: boolean
) {
  const updateList: any = [];
  // gather the list of testResultId's from the original antibodyTestResults. Check all those id's still exist in the translatedTests
  const originalTestIds = [];
  _.each(originalTestResults, (test) => {
    if (!_.includes(originalTestIds, test.testResultId)) {
      originalTestIds.push(test.testResultId);
    }
  });

  // gather the test ids to operate on
  const testIds = [];
  _.each(translatedTests, (test) => {
    if (!_.includes(testIds, test.testResultId)) {
      testIds.push(test.testResultId);
    }
  });

  // begin outputting api compatible test objects
  _.each(testIds, (testId) => {
    const relevantTests = _.filter(translatedTests, (translatedTest) => translatedTest.testResultId === testId);
    const testObject = createTestObject(relevantTests, submitRequested);
    if (testObject) {
      updateList.push(testObject);
    }
  });

  // if an id is missing from the original id list, add a row with the testResultId to DELETE
  const missingTestIds = _.difference(originalTestIds, testIds);
  _.each(missingTestIds, (testIdtoDelete) => {
    updateList.push({
      testResultId: testIdtoDelete,
      sampleDate: null,
      antibodyResults: null,
      pendingOperation: PendingOperation.DELETE,
    });
  });
  return updateList;
}

/**
 * Given an update list, run the correct api calls in sequence.
 * @param updateList the list of updates from findListOfUpdates
 * @param dispatch the redux dispatch
 * @param testUpdateOptions the update options for running handleTestUpdate if required
 * @param personId the person id of the tests
 * @param deletedTestIds an array of deleted test ids
 */
export async function executeTestUpdates(
  updateList: any[],
  dispatch,
  testUpdateOptions: HandleTestUpdateParams,
  personId,
  deletedTestIds,
  submitRequested?: boolean
) {
  // now that new tests are added at the top, save records bottom up.
  updateList.reverse();
  // iterate over the list of objects and fire the appropriate reducer method based on its flag. CREATE needs to retrive an id to cater for when some rows error.
  for (const update of updateList) {
    switch (update.pendingOperation) {
      case PendingOperation.CREATE: {
        const testResultId = await dispatch(personResultsActions.postPersonResult(update, personId));
        if (submitRequested) {
          await dispatch(
            personResultsActions.patchPersonResult(personId, testResultId, TestStatus.PENDING_VERIFICATION)
          );
          handleTestUpdate(testUpdateOptions)({ testResultId: update.testResultId, field: 'status' }, false)(
            TestStatus.PENDING_VERIFICATION
          );
        }
        // force a one second break so no two records are created at the exact same time in the database
        await new Promise((resolve) => setTimeout(resolve, 200));
        handleTestUpdate(testUpdateOptions)({ testResultId: update.testResultId, field: 'mode' }, false)(
          ScreenMode.VIEW
        );
        // update appropriate translated tests with new id
        // do this last
        handleTestUpdate(testUpdateOptions)({ testResultId: update.testResultId, field: 'testResultId' }, false)(
          testResultId
        );
        break;
      }
      case PendingOperation.UPDATE:
        await dispatch(personResultsActions.putPersonResult(update, personId));
        // if a submit was requested, and the test was in the draft or pending verification status, then it would get the pending verification status
        if (submitRequested && _.includes([TestStatus.DRAFT, TestStatus.PENDING_VERIFICATION], update.status)) {
          await dispatch(
            personResultsActions.patchPersonResult(personId, update.testResultId, TestStatus.PENDING_VERIFICATION)
          );
          handleTestUpdate(testUpdateOptions)({ testResultId: update.testResultId, field: 'status' }, false)(
            TestStatus.PENDING_VERIFICATION
          );
        }
        // if a submit was requested, and the test was verified or above, then it would get the updated pending verification status
        if (
          submitRequested &&
          _.includes([TestStatus.VERIFIED, TestStatus.UPDATED, TestStatus.UPDATED_PENDING_VERIFICATION], update.status)
        ) {
          await dispatch(
            personResultsActions.patchPersonResult(
              personId,
              update.testResultId,
              TestStatus.UPDATED_PENDING_VERIFICATION
            )
          );
          handleTestUpdate(testUpdateOptions)({ testResultId: update.testResultId, field: 'status' }, false)(
            TestStatus.UPDATED_PENDING_VERIFICATION
          );
        }
        handleTestUpdate(testUpdateOptions)({ testResultId: update.testResultId, field: 'mode' }, false)(
          ScreenMode.VIEW
        );
        break;
      case PendingOperation.DELETE:
        if (_.includes(deletedTestIds, update.testResultId)) {
          break;
        }
        await dispatch(personResultsActions.deletePersonResult(update, personId));
        handleTestUpdate(testUpdateOptions)({ testResultId: update.testResultId, field: 'mode' }, false)(
          ScreenMode.VIEW
        );
        deletedTestIds.push(update.testResultId);
        break;
      case PendingOperation.NONE:
        handleTestUpdate(testUpdateOptions)({ testResultId: update.testResultId, field: 'mode' }, false)(
          ScreenMode.VIEW
        );
        break;
    }
    // mark the translatedTests row(s) as view after it's call succeeds.
  }
}

// Table row button actions
export interface DeleteRowParams {
  testResultId;
  lineResultId;
  type: TestResultType;
  originalTestResults;
  translatedTests;
  setTranslatedTests: (tests) => void;
  setTabEditMode: (value: boolean) => void;
  updateEditMode: (translatedTests: any) => void;
  confirmConfig: ConfirmConfig;
}

export interface ConfirmConfig {
  setIsOpen: (value: boolean) => void;
  setMessage: (value: string) => void;
  setHandleAccept: (value: () => void) => void;
  setHandleDecline: (value: () => void) => void;
}

/**
 * Delete a row from a test table.
 * @param deleteRowParams params for deleting a row. there are a lot
 * @returns
 */
export function deleteRow(deleteRowParams: DeleteRowParams) {
  const { testResultId, lineResultId, translatedTests, confirmConfig } = deleteRowParams;
  return function () {
    if (testResultId && lineResultId !== null) {
      // delete warning, if this a real saved test
      if (testResultId > -1) {
        let count = 0;
        // check how many rows exist for this id
        translatedTests.forEach((test) => {
          if (test.testResultId === testResultId) {
            count += 1;
          }
        });
        // if this is the last row, show a message
        if (count === 1) {
          confirmConfig.setMessage(
            'Are you sure you want to delete the test record? You must also save your changes if you proceed.'
          );
          confirmConfig.setHandleAccept(() => () => {
            deleteRowConfirm(deleteRowParams);
            confirmConfig.setIsOpen(false);
          });
          confirmConfig.setHandleDecline(() => () => {
            confirmConfig.setIsOpen(false);
          });
          confirmConfig.setIsOpen(true);
          return;
        }
      }
      // if no message required, proceed with the delete
      deleteRowConfirm(deleteRowParams);
    }
  };
}

function deleteRowConfirm({
  testResultId,
  lineResultId,
  type,
  originalTestResults,
  translatedTests,
  setTranslatedTests,
  setTabEditMode,
  updateEditMode,
}: DeleteRowParams) {
  const subRowColumn = getReferenceLabel(TestResultTypeDetails, type, 'type', 'subrowIdField');
  const testIndex = _.findIndex(
    translatedTests,
    (test) => test.testResultId === testResultId && test[subRowColumn] === lineResultId
  );
  if (testIndex === -1) {
    toast('Error: delete button unable to find row to delete');
    return;
  }
  // delete the row
  translatedTests.splice(testIndex, 1);

  let deletedAllRows = true;
  translatedTests.forEach((test) => {
    if (test.testResultId === testResultId) {
      test.isDirty = true;
      deletedAllRows = false;
    }
  });

  // if you deleted all the rows from a real test, hold the save mode open
  // or if there are real tests but no rows left in translatedTests
  if ((deletedAllRows && testResultId >= 0) || (originalTestResults.length > 0 && translatedTests.length === 0)) {
    setTabEditMode(true);
  }
  markFirstRows(translatedTests);
  setTranslatedTests(translatedTests.slice());
  updateEditMode(translatedTests);
}

/**
 * Create a function to be run when clicking on the edit button on a row
 * @param testResultId
 * @param translatedTests the full list of translated tests
 * @param setTranslatedTests function update the translated tests
 * @param updateEditMode function to update the tabs edit mode
 * @returns function to handle opening a test row for editing
 */
export function editRow(
  testResultId,
  translatedTests,
  setTranslatedTests: (translatedTests: any) => void,
  updateEditMode: (translatedTests: any) => void
) {
  return function () {
    if (testResultId != null) {
      translatedTests.forEach((test) => {
        if (test.testResultId === testResultId) {
          test.mode = ScreenMode.EDIT;
        }
      });
      setTranslatedTests(translatedTests.slice());
      updateEditMode(translatedTests);
    }
  };
}
