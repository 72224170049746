import {
  ActionType,
  Address,
  AddressCandidate,
  AddressResponse,
  AddressResult,
  AddressSearchResponse,
  AppError,
  ErrorType,
  JwtResponse,
  Scope,
} from '../constants/Interfaces';
import Base64 from 'Base64';
import API from '../constants/API';
import axios, { Canceler } from 'axios';
import { mapApiError } from '../constants/Utils';
import moment from 'moment-timezone';
import { Messages } from '../constants/Messages';
import Logger from '../constants/Logger';
import _ from 'lodash';

interface State {
  loading: boolean;
  error: AppError;
}

export function getInitialState(): State {
  return {
    loading: false,
    error: null,
  };
}

export const addressTypes = {
  ADDRESS_TOKEN_GET_REQUEST: 'ADDRESS_GET_TOKEN_REQUEST',
  ADDRESS_TOKEN_GET_RESPONSE: 'ADDRESS_TOKEN_GET_RESPONSE',
};

const { CancelToken } = axios;
let cancelRequest: Canceler;

export const addressActions = {
  /**
   * Get the temporary token to call address APIs
   */
  getToken: () => async (dispatch) => {
    dispatch({ type: addressTypes.ADDRESS_TOKEN_GET_REQUEST });
    Logger.info(`Reducer: address -> getToken: Getting the temporary token to call Address APIs`);
    try {
      const api = API.getInstance();
      const response: JwtResponse = await api.get('/address/token', {}, {}, null);
      const token: string = response.jwt;
      const parts = token.split('.');
      if (parts.length === 3) {
        const tokenSubject = JSON.parse(Base64.atob(parts[1]));
        const tokenExpiryTime = moment.unix(tokenSubject.exp).toDate();
        Logger.debug(`Address token: ${token}. Expires at: ${moment(tokenExpiryTime).format('YYYY-MM-DD HH:mm:ss')}`);
        api.setToken(Scope.ADDRESS, token, tokenExpiryTime);
        dispatch({ type: addressTypes.ADDRESS_TOKEN_GET_RESPONSE, error: false });
        return token;
      }
      dispatch({
        type: addressTypes.ADDRESS_TOKEN_GET_RESPONSE,
        error: true,
        payload: {
          type: ErrorType.ADDRESS_ERROR,
          name: 'MalformedToken',
          message: Messages.ERROR_GENERIC_API,
        } as AppError,
      });
      return null;
    } catch (e) {
      dispatch({
        type: addressTypes.ADDRESS_TOKEN_GET_RESPONSE,
        payload: mapApiError(ErrorType.ADDRESS_ERROR, e),
        error: true,
      });
      throw e;
    }
  },

  /**
   * Get a list of addresses by search query from the Experian API. All the requests can be cancelled
   * if a subsequent request has been made.
   * @param query the query string. The query must be longer than 4 characters.
   */
  getAddresses: (query: string) => async () => {
    // Just return nothing if query is invalid.
    if (!query || _.trim(query).length < 4) {
      return [];
    }

    // Cancel previous requests if any.
    if (cancelRequest) {
      Logger.debug(`reducer -> address -> getAddresses: Cancelling previous requests`);
      cancelRequest();
    }

    const api = API.getInstance();
    try {
      const response: AddressSearchResponse = await api.get(
        '/address/search',
        {
          query,
        },
        {
          cancelToken: new CancelToken((canceler: Canceler) => {
            // An executor function receives a cancel function as a parameter
            cancelRequest = canceler;
          }),
        }
      );
      const addressResults: AddressResult[] = response.results;
      const addressCandidates: AddressCandidate[] = [];
      _.each(addressResults, (result: AddressResult) => {
        const addressParts = _.split(result.format, 'id=', 2);
        addressCandidates.push({
          address: result.suggestion,
          addressId: addressParts[1],
        } as AddressCandidate);
      });
      return addressCandidates;
    } catch (e) {
      if (axios.isCancel(e)) {
        Logger.debug(`reducer -> address -> getAddresses: Request has been canceled`);
      } else {
        // handle error silently
        Logger.debug(`reducer -> address -> getAddresses: Error occurred in getting addresses. Details: ${e.message}`);
      }
      return [];
    }
  },

  /**
   * Get a list of addresses by search query from the Experian API. All the requests can be cancelled
   * if a subsequent request has been made.
   * @param query the query string. The query must be longer than 4 characters.
   */
  getAddressById: async (addressId: string) => {
    // Just return nothing if query is invalid.
    Logger.debug(`reducer -> address -> getAddress: ${addressId}`);
    if (!addressId) {
      return null;
    }

    const api = API.getInstance();
    try {
      const response: AddressResponse = await api.get('/address/format', {
        id: addressId,
      });
      const addressResult: Address = {
        addressLine1: response.address[0].addressLine1,
        addressLine2: response.address[1].addressLine2,
        suburb: response.address[3].locality,
        state: response.address[4].province,
        postcode: response.address[5].postalCode,
      };
      Logger.debug(`reducer -> address -> getAddress: address result returned: ${JSON.stringify(addressResult)}`);
      return addressResult;
    } catch (e) {
      // handle error silently
      Logger.debug(`reducer -> address -> getAddress: Error occurred in getting addresses. Details: ${e.message}`);
      return null;
    }
  },
};

export default function address(state = getInitialState(), action) {
  const { type, payload, error } = action;

  switch (type) {
    case addressTypes.ADDRESS_TOKEN_GET_REQUEST: {
      return { ...state, loading: true, error: null };
    }

    case addressTypes.ADDRESS_TOKEN_GET_RESPONSE: {
      if (error) {
        return { ...state, error: payload, loading: false };
      }
      return { ...state, loading: false, error: null };
    }

    case ActionType.PURGE: {
      // currently don't clear anything upon purge as this is not user specific
      return state;
    }
  }

  return state;
}
