import {
  ActionType,
  AntibodyTestResult,
  AppError,
  ErrorType,
  GenericTestResult,
  PhenotypeTestResult,
  TestStatus,
} from '../constants/Interfaces';
import API from '../constants/API';
import { mapApiError } from '../constants/Utils';
import Logger from '../constants/Logger';

// TODO: unsure if maintaining two error states for each tab is correct.
// Possibly this reducer should be split in two (one for each tab), however where to store the common api call code?
interface State {
  currentAntibodyTestResults: AntibodyTestResult[];
  currentPhenotypeTestResults: PhenotypeTestResult[];
  loading: boolean;
  errorAntibodies: AppError;
  errorPhenotype: AppError;
}

export function getInitialState(): State {
  return {
    currentAntibodyTestResults: [],
    currentPhenotypeTestResults: [],
    loading: false,
    errorAntibodies: null,
    errorPhenotype: null,
  };
}

export const personResultTypes = {
  PERSON_GET_RESULTS_REQUEST: 'PERSON_GET_RESULTS_REQUEST',
  PERSON_GET_RESULTS_RESPONSE: 'PERSON_GET_RESULTS_RESPONSE',
  PERSON_POST_RESULT_REQUEST: 'PERSON_POST_RESULT_REQUEST',
  PERSON_POST_RESULT_RESPONSE: 'PERSON_POST_RESULT_RESPONSE',
  PERSON_PUT_RESULT_REQUEST: 'PERSON_PUT_RESULT_REQUEST',
  PERSON_PATCH_RESULT_REQUEST: 'PERSON_PATCH_RESULT_REQUEST',
  PERSON_PUT_RESULT_RESPONSE: 'PERSON_PUT_RESULT_RESPONSE',
  PERSON_PATCH_RESULT_RESPONSE: 'PERSON_PATCH_RESULT_RESPONSE',
  PERSON_DELETE_RESULT_REQUEST: 'PERSON_DELETE_RESULT_REQUEST',
  PERSON_DELETE_RESULT_RESPONSE: 'PERSON_DELETE_RESULT_RESPONSE',
};

export enum ResultFilter {
  PHENOTYPES = 'phenotypes',
  ANTIBODIES = 'antibodies',
}

const LOG_PREFIX = `Reducer: personResults ->`;

export const personResultsActions = {
  /**
   * Get the person data by id
   */
  getPersonResults: (personId: string, filter?: ResultFilter) => async (dispatch) => {
    dispatch({ type: personResultTypes.PERSON_GET_RESULTS_REQUEST });
    Logger.info(`${LOG_PREFIX} getPersonResults: Getting person results ${personId}`);
    try {
      const api = API.getInstance();
      const filterParam = filter ? { type: filter } : {};
      const response = await api.get(`/person/${personId}/results`, filterParam);
      const { records } = response;

      Logger.debug(`${LOG_PREFIX} getPersonResults: Person results received.`);
      dispatch({
        type: personResultTypes.PERSON_GET_RESULTS_RESPONSE,
        error: false,
        payload: { ...records, filter },
      });
      return records;
    } catch (e) {
      dispatch({
        type: personResultTypes.PERSON_GET_RESULTS_RESPONSE,
        payload: mapApiError(ErrorType.PERSON_RESULT_ERROR, e),
        error: true,
      });
      throw e;
    }
  },

  /**
   * Get the person data by id
   */
  postPersonResult: (test: GenericTestResult, personId) => async (dispatch) => {
    dispatch({ type: personResultTypes.PERSON_POST_RESULT_REQUEST });
    Logger.info(`${LOG_PREFIX} getPersonResults: post person result ${test.sampleDate}`);
    try {
      const api = API.getInstance();
      const response = await api.post(`/person/${personId}/results`, test);
      const testId = response.id;
      Logger.debug(`${LOG_PREFIX} getPersonResults: post result complete.`);
      dispatch({ type: personResultTypes.PERSON_POST_RESULT_RESPONSE, error: false });
      return testId;
    } catch (e) {
      dispatch({
        type: personResultTypes.PERSON_POST_RESULT_RESPONSE,
        payload: mapApiError(ErrorType.PERSON_RESULT_ERROR, e),
        error: true,
      });
      throw e;
    }
  },

  /**
   * replace a test request with an updated version
   */
  putPersonResult: (test: GenericTestResult, personId) => async (dispatch) => {
    dispatch({ type: personResultTypes.PERSON_PUT_RESULT_REQUEST });
    Logger.info(`${LOG_PREFIX} getPersonResults: put person result ${test.sampleDate}`);
    try {
      const api = API.getInstance();
      const response = await api.put(`/person/${personId}/results/${test.testResultId}`, {
        ...test,
        status: undefined,
      });
      const testId = response.id;
      Logger.debug(`${LOG_PREFIX} getPersonResults: put result complete.`);
      dispatch({ type: personResultTypes.PERSON_PUT_RESULT_RESPONSE, error: false });
      return testId;
    } catch (e) {
      dispatch({
        type: personResultTypes.PERSON_PUT_RESULT_RESPONSE,
        payload: mapApiError(ErrorType.PERSON_RESULT_ERROR, e),
        error: true,
      });
      throw e;
    }
  },

  /**
   * Update result status
   */
  patchPersonResult: (personId, testResultId, status: TestStatus) => async (dispatch) => {
    dispatch({ type: personResultTypes.PERSON_PATCH_RESULT_REQUEST });
    Logger.info(`${LOG_PREFIX} patchPersonResult: patch person result ${testResultId}`);
    try {
      const api = API.getInstance();
      await api.patch(`/person/${personId}/results/${testResultId}`, { status });
      Logger.debug(`${LOG_PREFIX} patchPersonResult: patch result complete.`);
      dispatch({ type: personResultTypes.PERSON_PATCH_RESULT_RESPONSE, error: false });
      return status;
    } catch (e) {
      dispatch({
        type: personResultTypes.PERSON_PATCH_RESULT_RESPONSE,
        payload: mapApiError(ErrorType.PERSON_RESULT_ERROR, e),
        error: true,
      });
      throw e;
    }
  },

  /**
   * Get the person data by id
   */
  deletePersonResult: (test: GenericTestResult, personId) => async (dispatch) => {
    dispatch({ type: personResultTypes.PERSON_DELETE_RESULT_REQUEST });
    Logger.info(`${LOG_PREFIX} getPersonResults: delete person result ${test.sampleDate}`);
    try {
      const api = API.getInstance();
      const response = await api.delete(`/person/${personId}/results/${test.testResultId}`);
      const testId = response.id;
      Logger.debug(`${LOG_PREFIX} getPersonResults: delete result complete.`);
      dispatch({ type: personResultTypes.PERSON_DELETE_RESULT_RESPONSE, error: false });
      return testId;
    } catch (e) {
      dispatch({
        type: personResultTypes.PERSON_DELETE_RESULT_RESPONSE,
        payload: mapApiError(ErrorType.PERSON_RESULT_ERROR, e),
        error: true,
      });
      throw e;
    }
  },
};

export default function person(state = getInitialState(), action) {
  const { type, payload, error } = action;

  switch (type) {
    case personResultTypes.PERSON_GET_RESULTS_REQUEST: {
      return { ...state, loading: true, errorAntibodies: null, errorPhenotype: null };
    }
    case personResultTypes.PERSON_GET_RESULTS_RESPONSE: {
      if (error) {
        const currentAntibodyTestResults =
          payload.filter === ResultFilter.PHENOTYPES ? state.currentAntibodyTestResults : [];
        const errorAntibodies = payload.filter === ResultFilter.PHENOTYPES ? state.errorAntibodies : payload;
        const currentPhenotypeTestResults =
          payload.filter === ResultFilter.ANTIBODIES ? state.currentPhenotypeTestResults : [];
        const errorPhenotype = payload.filter === ResultFilter.ANTIBODIES ? state.errorPhenotype : payload;
        return {
          ...state,
          errorAntibodies,
          errorPhenotype,
          loading: false,
          currentAntibodyTestResults,
          currentPhenotypeTestResults,
        };
      }
      const currentAntibodyTestResults =
        payload.filter === ResultFilter.PHENOTYPES ? state.currentAntibodyTestResults : payload.antibodyTestResults;
      const errorAntibodies = payload.filter === ResultFilter.PHENOTYPES ? state.errorAntibodies : null;
      const currentPhenotypeTestResults =
        payload.filter === ResultFilter.ANTIBODIES ? state.currentPhenotypeTestResults : payload.phenotypeTestResults;
      const errorPhenotype = payload.filter === ResultFilter.ANTIBODIES ? state.errorPhenotype : null;

      return {
        ...state,
        currentAntibodyTestResults,
        currentPhenotypeTestResults,
        loading: false,
        errorAntibodies,
        errorPhenotype,
      };
    }

    case personResultTypes.PERSON_POST_RESULT_REQUEST:
    case personResultTypes.PERSON_PUT_RESULT_REQUEST:
    case personResultTypes.PERSON_PATCH_RESULT_REQUEST:
    case personResultTypes.PERSON_DELETE_RESULT_REQUEST: {
      return { ...state, loading: true, errorAntibodies: null, errorPhenotype: null };
    }

    case personResultTypes.PERSON_POST_RESULT_RESPONSE:
    case personResultTypes.PERSON_PUT_RESULT_RESPONSE:
    case personResultTypes.PERSON_PATCH_RESULT_RESPONSE:
    case personResultTypes.PERSON_DELETE_RESULT_RESPONSE: {
      if (error) {
        // we don't want to record this as a fatal error. Let the screen catch the exception
        return { ...state, loading: false };
      }
      return { ...state, loading: false, errorAntibodies: null, errorPhenotype: null };
    }

    case ActionType.PURGE: {
      return getInitialState();
    }
  }

  return state;
}
