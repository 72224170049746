import {
  ActionType,
  AppError,
  ErrorType,
  ConsentStatus,
  ConsentStatusApi,
  PersonConsentLetter,
} from '../constants/Interfaces';
import API from '../constants/API';
import { mapApiError } from '../constants/Utils';
import Logger from '../constants/Logger';
import _ from 'lodash';

interface State {
  currentConsentStatus: ConsentStatus[];
  currentLetterStatus: ConsentStatus[];
  currentRequestsSent: ConsentStatus[];
  loading: boolean;
  error: AppError;
}

export function getInitialState(): State {
  return {
    currentConsentStatus: null,
    currentLetterStatus: null,
    currentRequestsSent: null,
    loading: false,
    error: null,
  };
}

export const personConsentTypes = {
  NEW_PERSON: 'NEW_PERSON',
  PERSON_GET_CONSENT_REQUEST: 'PERSON_GET_CONSENT_REQUEST',
  PERSON_GET_CONSENT_RESPONSE: 'PERSON_GET_CONSENT_RESPONSE',
  PERSON_POST_CONSENT_REQUEST: 'PERSON_POST_CONSENT_REQUEST',
  PERSON_POST_CONSENT_RESPONSE: 'PERSON_POST_CONSENT_RESPONSE',
};

const LOG_PREFIX = `Reducer: personConsent ->`;

export const personConsentActions = {
  /**
   * Get the consent data by person id
   */
  getPersonConsent: (personId: string) => async (dispatch) => {
    dispatch({ type: personConsentTypes.PERSON_GET_CONSENT_REQUEST });
    Logger.info(`${LOG_PREFIX} getPersonConsent: Getting consent results ${personId}`);
    try {
      const api = API.getInstance();
      const response: ConsentStatusApi = await api.get(`/person/${personId}/consent`);
      Logger.debug(`${LOG_PREFIX} getPersonConsent: Person consent received.`);
      dispatch({
        type: personConsentTypes.PERSON_GET_CONSENT_RESPONSE,
        error: false,
        payload: response,
      });
      return response;
    } catch (e) {
      dispatch({
        type: personConsentTypes.PERSON_GET_CONSENT_RESPONSE,
        payload: mapApiError(ErrorType.PERSON_RESULT_ERROR, e),
        error: true,
      });
      throw e;
    }
  },

  /**
   * Post a new consent line by person id and type of status
   */
  postPersonConsent: (newResult: ConsentStatusApi, personId) => async (dispatch) => {
    dispatch({ type: personConsentTypes.PERSON_POST_CONSENT_REQUEST });
    Logger.info(`${LOG_PREFIX} postPersonConsent: post person consent`);
    try {
      const api = API.getInstance();
      await api.post(`/person/${personId}/consent`, newResult);
      Logger.debug(`${LOG_PREFIX} postPersonConsent: post consent complete.`);
      dispatch({ type: personConsentTypes.PERSON_POST_CONSENT_RESPONSE, error: false });
      return true;
    } catch (e: any) {
      dispatch({
        type: personConsentTypes.PERSON_POST_CONSENT_RESPONSE,
        payload: mapApiError(ErrorType.PERSON_RESULT_ERROR, e),
        error: true,
      });
      throw e;
    }
  },

  /**
   * Post a set of consent updates from the dashboard. No state updates
   */
  postBatchConsent: (newStatus: PersonConsentLetter, personIds: any[]) => async () => {
    Logger.info(`${LOG_PREFIX} postPersonConsent: post person consent`);
    const api = API.getInstance();
    await api.post('persons/consent', {
      consentLetterBatch: {
        status: newStatus,
        personIds: _.uniq(personIds),
      },
    });
  },
};

export default function personConsent(state = getInitialState(), action) {
  const { type, payload, error } = action;

  switch (type) {
    case personConsentTypes.PERSON_GET_CONSENT_REQUEST: {
      return { ...state, loading: true, error: null };
    }
    case personConsentTypes.PERSON_GET_CONSENT_RESPONSE: {
      if (error) {
        return {
          ...state,
          error: payload,
          loading: false,
          currentConsentStatus: null,
          currentLetterStatus: null,
          currentRequestsSent: null,
        };
      }
      const { consent, consentLetter, consentOtherRequest } = payload;
      return {
        ...state,
        currentConsentStatus: consent || [],
        currentLetterStatus: consentLetter || [],
        currentRequestsSent: consentOtherRequest || [],
        loading: false,
        error: null,
      };
    }

    case personConsentTypes.PERSON_POST_CONSENT_REQUEST: {
      return { ...state, loading: true, error: null };
    }
    case personConsentTypes.PERSON_POST_CONSENT_RESPONSE: {
      if (error) {
        return { ...state, error: payload, loading: false };
      }
      return { ...state, loading: false, error: null };
    }

    case ActionType.PURGE:
    case personConsentTypes.NEW_PERSON: {
      return getInitialState();
    }
  }

  return state;
}
