import React, { useEffect, useState } from 'react';
import {
  ButtonPrimary,
  CheckboxInput,
  ContainerCard,
  DateInput,
  Header,
  HeaderButton,
  RadioInput,
  SelectInput,
  Spinner,
  Table,
  Text,
  TextInput,
} from '../components';
import { useHistory } from 'react-router-dom';
import './Search.scss';
import { personActions, personTypes } from '../reducers/person';
import {
  emailLength,
  SearchValidators,
  useValidatedField,
  validate,
  validationErrorMessages,
} from '../constants/Validators';
import {
  Person,
  PersonSearchParams,
  SearchType,
  SearchTypeOptions,
  SearchOptions,
  SearchOptionValues,
  ValidationState,
  GenericErrorCode,
  Permission,
} from '../constants/Interfaces';
import moment from 'moment-timezone';
import Logger from '../constants/Logger';
import _ from 'lodash';
import Layout from '../constants/Layout';
import { toast, ToastContainer } from 'react-toastify';
import { Messages, PersonFieldLabels, SearchTableLabels, UI } from '../constants/Messages';
import { useEventListener } from '../constants/Utils';
import { selectPermission } from '../constants/Selectors';
import { useAppDispatch, useAppSelector } from '../store/hooks';

const LOG_PREFIX = 'Screen -> Search ->';

export default function Search() {
  // https://stackoverflow.com/questions/31079081/programmatically-navigate-using-react-router
  const history = useHistory();

  const dispatch = useAppDispatch();
  const [firstLoadComplete, setFirstLoadComplete] = useState(false);

  const searchResults: Person[] = useAppSelector((state) => state.person.currentSearchResults);
  const searchParams: PersonSearchParams = useAppSelector((state) => state.person.currentSearchParams);
  const searchType: SearchType = useAppSelector((state) => state.person.searchType);

  const currentMaxSearchLimit: boolean = useAppSelector((state) => state.person.currentMaxSearchLimit);

  const currentResultsLimitedDueToFiltering: boolean = useAppSelector(
    (state) => state.person.currentResultsLimitedDueToFiltering
  );

  const searchLoading: boolean = useAppSelector((state) => state.person.loading);

  const canViewDonorId = useAppSelector(selectPermission(Permission.canViewDonorId));
  const canViewMobile = useAppSelector(selectPermission(Permission.canViewMobile));
  const canViewEmail = useAppSelector(selectPermission(Permission.canViewEmail));
  const canCreateDonor = useAppSelector(selectPermission(Permission.canCreateDonor));
  const canCreatePatient = useAppSelector(selectPermission(Permission.canCreatePatient));

  useEventListener('keydown', onKeyDown);

  /**
   * Slighly confusingly, simple search uses lastName or lastNames, and visually stores the user input in lastNameSimple
   * advanced search uses lastName or previousSurnames */
  const fields = {
    donorId: useValidatedField('', 'donorId'),
    firstName: useValidatedField('', 'firstName'),
    firstNameSearchBy: useValidatedField<SearchOptionValues>(SearchOptionValues.StartsWith),
    lastNameSimple: useValidatedField('', 'lastNameSimple'),
    lastNameCheckbox: useValidatedField(false, 'lastNameCheckbox'),
    abrNumber: useValidatedField('', 'abrNumber'),
    lastName: useValidatedField('', 'lastName'),
    lastNames: useValidatedField('', 'lastNames'),
    lastNameSearchBy: useValidatedField<SearchOptionValues>(SearchOptionValues.StartsWith),
    middleName: useValidatedField('', 'middleName'),
    middleNameSearchBy: useValidatedField<SearchOptionValues>(SearchOptionValues.StartsWith),
    previousLastName: useValidatedField('', 'previousLastName'),
    previousLastNameSearchBy: useValidatedField<SearchOptionValues>(SearchOptionValues.StartsWith),
    dob: useValidatedField('', 'dob'),
    hospitalNumber: useValidatedField('', 'hospitalNumber'),
    mobile: useValidatedField('', 'mobile'),
    email: useValidatedField('', 'email'),
  };
  useEffect(() => {
    if (searchParams && fields) {
      fields.donorId.setValue(searchParams.donorId);
      fields.firstName.setValue(searchParams.firstName);
      fields.lastNames.setValue(searchParams.lastNames);
      fields.lastNameCheckbox.setValue(searchParams.lastNameCheckbox);
      fields.firstNameSearchBy.setValue(searchParams.firstNameSearchBy || SearchOptionValues.StartsWith);
      fields.lastName.setValue(searchParams.lastName);
      fields.lastNameSearchBy.setValue(searchParams.lastNameSearchBy || SearchOptionValues.StartsWith);
      fields.abrNumber.setValue(searchParams.abrNumber);
      fields.middleName.setValue(searchParams.middleName);
      fields.middleNameSearchBy.setValue(searchParams.middleNameSearchBy || SearchOptionValues.StartsWith);
      fields.previousLastName.setValue(searchParams.previousLastName);
      fields.previousLastNameSearchBy.setValue(searchParams.previousLastNameSearchBy || SearchOptionValues.StartsWith);
      fields.dob.setValue(searchParams.dob);
      fields.hospitalNumber.setValue(searchParams.hospitalNumber);
      fields.mobile.setValue(searchParams.mobile);
      fields.email.setValue(searchParams.email);
      if (searchParams.lastNameCheckbox) {
        fields.lastNameSimple.setValue(searchParams.lastNames);
      } else {
        fields.lastNameSimple.setValue(searchParams.lastName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  async function handleNewPatient() {
    await dispatch(personActions.setSearchParams(getSearchParams()));
    history.push('/patient');
  }

  async function handleNewDonor() {
    await dispatch(personActions.setSearchParams(getSearchParams()));
    history.push('/donor');
  }

  function onChangeSearchType(value) {
    dispatch({ type: personTypes.SET_SEARCH_TYPE, payload: value });
  }

  // after the initial screen load, clear the search after switching search types
  useEffect(() => {
    if (firstLoadComplete) {
      clearSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchType]);

  // after the search type officialy changes and the screen rerenders, focus the first search field
  function focusFirstSearchField() {
    if (fields.lastNameSimple.inputRef.current) {
      fields.lastNameSimple.inputRef.current.getInputRef().current.focus();
    }
    if (fields.donorId.inputRef.current) {
      fields.donorId.inputRef.current.getInputRef().current.focus();
    }
    if (fields.lastName.inputRef.current) {
      fields.lastName.inputRef.current.getInputRef().current.focus();
    }
  }

  function onClearSearch() {
    clearSearch(true);
  }
  function clearSearch(forceClear?: boolean) {
    dispatch(personActions.clearSearch());
    fields.donorId.clear();
    if (forceClear || searchType !== SearchType.ADVANCED) {
      fields.firstName.clear();
      fields.lastName.clear();
    } else if (searchType === SearchType.ADVANCED) {
      fields.lastName.setValue(fields.lastNameSimple.value);
      fields.lastNames.clear();
    }
    fields.lastNames.clear();
    fields.lastNameSimple.clear();
    fields.lastNameCheckbox.setValue(false);
    fields.abrNumber.clear();
    fields.middleName.clear();
    fields.previousLastName.clear();
    fields.dob.clear();
    fields.hospitalNumber.clear();
    fields.mobile.clear();
    fields.email.clear();
    fields.lastNameSearchBy.setValue(SearchOptionValues.StartsWith);
    fields.firstNameSearchBy.setValue(SearchOptionValues.StartsWith);
    fields.middleNameSearchBy.setValue(SearchOptionValues.StartsWith);
    fields.previousLastNameSearchBy.setValue(SearchOptionValues.StartsWith);
    focusFirstSearchField();
  }

  function onKeyDown(event) {
    if (event && event.key === 'Enter' && !searchDisabled()) {
      search();
    }
    if (event && event.key === 'Escape') {
      clearSearch(true);
    }
  }

  function handleFieldUpdate(field: string) {
    return function (value: any) {
      fields[field].setValue(value);
      fields[field].setValidation(ValidationState.NULL);
      if (field === 'lastNames' && (!value || value.length === 0)) {
        handleFieldUpdate('firstName')('');
      }
    };
  }

  function validateField(field) {
    return async function () {
      const { value } = fields[field];
      return validate(fields[field], value, SearchValidators);
    };
  }

  async function validateAllFields() {
    // Validating all the fields again.
    const validators: Promise<boolean>[] = [];
    _.each(Object.keys(fields), (field: string) => {
      validators.push(validateField(field)());
    });
    const results: boolean[] = await Promise.all(validators);

    // If any of the validation has failed, terminate the process immediately and scroll to the the respective field.
    const firstFailedFieldIndex = _.indexOf(results, false);
    if (firstFailedFieldIndex > -1) {
      Logger.debug(
        `${LOG_PREFIX} -> validateAllFields: Validation failed for one or more fields. First failed field is ${firstFailedFieldIndex}`
      );
      return false;
    }
    return true;
  }

  function renderNoRecords() {
    return (
      <div className="centre-row">
        {!currentResultsLimitedDueToFiltering && <Text className="subheading">{UI.SEARCH_NO_RESULT}</Text>}
        <div onClick={onClearSearch} className="link" style={{ textAlign: 'center', marginLeft: 10 }}>
          {UI.SEARCH_CLEAR_SEARCH_LINK}
        </div>
      </div>
    );
  }

  function renderNewPersonControls() {
    return (
      <div className="centre-row table-margin">
        {(canCreateDonor || canCreatePatient) && (
          <Text className="subheading createNewLabel">{UI.SEARCH_CREATE_NEW_LABEL}</Text>
        )}
        <>
          {canCreatePatient && (
            <ButtonPrimary
              title={UI.SEARCH_CREATE_PATIENT_BUTTON}
              onClick={handleNewPatient}
              buttonClass={canCreateDonor && 'left'}
              containerStyle={{ paddingLeft: 0, paddingRight: canCreateDonor ? 1 : 117 }}
            />
          )}
          {canCreateDonor && (
            <ButtonPrimary
              title={UI.SEARCH_CREATE_DONOR_BUTTON}
              onClick={handleNewDonor}
              buttonClass={canCreatePatient && 'right'}
              containerStyle={{ paddingLeft: 0, paddingRight: 117 }}
            />
          )}
        </>
      </div>
    );
  }

  function getSearchParams(): PersonSearchParams {
    let finalLastNames = fields.lastNames.value?.toUpperCase();
    let finalLastName = fields.lastName.value?.toUpperCase();
    if (searchType === SearchType.NAME) {
      if (fields.lastNameCheckbox.value) {
        finalLastNames = fields.lastNameSimple.value?.toUpperCase();
        finalLastName = '';
      } else {
        finalLastName = fields.lastNameSimple.value?.toUpperCase();
        finalLastNames = '';
      }
    }

    return {
      donorId: fields.donorId.value,
      firstName: fields.firstName.value?.toUpperCase(),
      lastNames: finalLastNames,
      lastNameCheckbox: fields.lastNameCheckbox.value,
      abrNumber: fields.abrNumber.value,
      firstNameSearchBy:
        searchType === SearchType.ADVANCED && fields.firstName.value ? fields.firstNameSearchBy.value : null,
      lastName: finalLastName,
      lastNameSearchBy:
        searchType === SearchType.ADVANCED && fields.lastName.value ? fields.lastNameSearchBy.value : null,
      middleName: searchType === SearchType.ADVANCED ? fields.middleName.value?.toUpperCase() : null,
      middleNameSearchBy:
        searchType === SearchType.ADVANCED && fields.middleName.value ? fields.middleNameSearchBy.value : null,
      previousLastName: searchType === SearchType.ADVANCED ? fields.previousLastName.value?.toUpperCase() : null,
      previousLastNameSearchBy:
        searchType === SearchType.ADVANCED && fields.previousLastName.value
          ? fields.previousLastNameSearchBy.value
          : null,
      dob: searchType === SearchType.ADVANCED ? fields.dob.value : null,
      hospitalNumber: searchType === SearchType.ADVANCED ? fields.hospitalNumber.value : null,
      mobile: searchType === SearchType.ADVANCED ? fields.mobile.value : null,
      email: searchType === SearchType.ADVANCED ? fields.email.value : null,
      maxRecordCount: searchType === SearchType.ADVANCED ? 20 : 10,
    };
  }

  async function search() {
    const validated = await validateAllFields();
    dispatch(personActions.clearSearchMessage());
    if (validated) {
      try {
        await dispatch(personActions.search(getSearchParams()));
      } catch (error: any) {
        Logger.error(`${LOG_PREFIX} search: Error occurred while searching`, error);
        if (error?.response) {
          switch (error?.response.status) {
            case GenericErrorCode.PERMISSION_ERROR: {
              Logger.error(`${LOG_PREFIX} search: 403 in search`, error);
              toast(Messages.ERROR_403_API);
              break;
            }
            case GenericErrorCode.BAD_DATA: {
              Logger.error(`${LOG_PREFIX} search: 400 in search`, error);
              // attempt to get the error field
              if (error.response.data.field && fields && fields[error.response.data.field]) {
                const { field } = error.response.data;
                fields[field].setValidation(ValidationState.FAILED);
                fields[field].setErrorMessage(validationErrorMessages[field]);
              }
              toast(Messages.ERROR_API_VALIDATION_GENERIC);
              break;
            }
            default: {
              // Scenario: server error
              Logger.error(`${LOG_PREFIX} search: Server error in search`, error);
              toast(Messages.ERROR_GENERIC);
            }
          }
        } else if (error?.request) {
          // Scenario: network error
          Logger.error(`${LOG_PREFIX} -> search: Network error in search`, error);
          // network error, show snack bar and let user try again.
          toast(Messages.ERROR_GENERIC);
        } else {
          // Scenario: application error
          Logger.error(`${LOG_PREFIX} -> search: Application error in search`, error);
          toast(Messages.ERROR_GENERIC);
        }
      }
    }
  }

  function searchDisabled() {
    const includedFields = getSearchParams();
    delete includedFields.maxRecordCount;
    delete includedFields.lastNameCheckbox; // booleans can't use .trim()
    const searchFields = Object.values(includedFields).every((x) => !x || x === null || x.trim() === '');
    return !!searchFields;
  }

  const columns = React.useMemo(() => {
    const selectSearch = (abrNumber) => () => {
      if (abrNumber) {
        dispatch(personActions.newPerson());
        history.push(`/person/${abrNumber}`);
      }
    };

    function actionCell({ row }) {
      return (
        <div
          onClick={selectSearch(row.original.abrNumber)}
          className="link"
          style={{ textAlign: 'center', margin: '0 auto' }}
        >
          {UI.SEARCH_VIEW_LINK}
        </div>
      );
    }
    return [
      {
        Header: SearchTableLabels.ABR_NUMBER,
        accessor: 'abrNumber',
      },
      {
        Header: PersonFieldLabels.LAST_NAME,
        accessor: 'lastName',
      },
      {
        Header: PersonFieldLabels.FIRST_NAME,
        accessor: 'firstName',
      },
      {
        Header: PersonFieldLabels.MIDDLE_NAME,
        accessor: 'middleName',
      },
      {
        Header: PersonFieldLabels.GENDER,
        accessor: 'gender',
      },
      {
        Header: PersonFieldLabels.DATE_OF_BIRTH,
        accessor: 'dob',
        Cell: ({ value }) => (value ? moment(value).format('DD/MM/YYYY') : null),
      },
      {
        Header: PersonFieldLabels.BLOOD_GROUP,
        accessor: 'aboRhType',
        className: 'no-border-right',
      },
      {
        Header: '',
        accessor: 'action',
        Cell: actionCell,
        className: 'transparent-header flex',
      },
    ];
  }, [dispatch, history]);

  useEffect(() => {
    if (fields.lastNameSimple.inputRef.current) {
      fields.lastNameSimple.inputRef.current.getInputRef().current.focus();
    }
    setFirstLoadComplete(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const finalSearchTypeOptions = canViewDonorId
    ? SearchTypeOptions
    : _.pullAllBy(SearchTypeOptions, [{ value: SearchType.DONORID }], 'value');

  return (
    <div className="search">
      <Header selectedButton={HeaderButton.Search} />
      <div className="marginLarge">
        <ContainerCard title="">
          <div className="search-type-container">
            <RadioInput
              label={UI.SEARCH_TYPE_TITLE}
              options={finalSearchTypeOptions}
              onValueChange={onChangeSearchType}
              value={searchType}
              style={{ flexDirection: 'row' }}
              radioName="search"
            />
            <ButtonPrimary
              title="Clear"
              onClick={clearSearch}
              disabled={searchDisabled()}
              containerStyle={{ paddingRight: 0, marginLeft: 'auto' }}
            />
            <ButtonPrimary
              title="Search"
              onClick={search}
              disabled={searchDisabled()}
              containerStyle={{ paddingRight: 0 }}
            />
          </div>
          {searchType === SearchType.NAME && (
            <div className="search-filters-container">
              <div style={{ flexDirection: 'column', flex: 1 }}>
                <TextInput
                  label={PersonFieldLabels.LAST_NAME}
                  onValueChange={handleFieldUpdate('lastNameSimple')}
                  value={fields.lastNameSimple.value}
                  maxLength={60}
                  labelContainerStyle={{ minWidth: 'unset' }}
                  shouldShowIcon={false}
                  style={{ flex: 1 }}
                  ref={fields.lastNameSimple.inputRef}
                  inputStyle={{ textTransform: 'uppercase' }}
                  autoFocus
                />
                <div style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Text className="label" style={{ fontSize: 14 }}>
                    {UI.SEARCH_INCLUDE_PREVIOUS_SURNAMES_LABEL}&nbsp;&nbsp;
                  </Text>
                  <CheckboxInput
                    onValueChange={handleFieldUpdate('lastNameCheckbox')}
                    value={fields.lastNameCheckbox.value}
                    tabIndex={-1}
                  />
                </div>
              </div>
              <TextInput
                label={PersonFieldLabels.FIRST_NAME}
                onValueChange={handleFieldUpdate('firstName')}
                value={fields.firstName.value}
                disabled={!fields.lastNameSimple.value}
                maxLength={60}
                shouldShowIcon={false}
                style={{ flex: 1, marginLeft: Layout.backgroundMarginNarrow.left }}
                labelContainerStyle={{ minWidth: 'unset' }}
                inputStyle={{ textTransform: 'uppercase' }}
              />
              <TextInput
                label={UI.SEARCH_ABR_NUMBER}
                onValueChange={handleFieldUpdate('abrNumber')}
                onBlur={validateField('abrNumber')}
                value={fields.abrNumber.value}
                shouldShowIcon={false}
                style={{ flex: 1, marginLeft: Layout.backgroundMarginNarrow.left }}
                labelContainerStyle={{ minWidth: 'unset' }}
                validationState={fields.abrNumber.validation}
                errorMessage={fields.abrNumber.errorMessage}
              />
            </div>
          )}
          {searchType === SearchType.DONORID && (
            <div style={{ flexDirection: 'row' }}>
              <TextInput
                label={PersonFieldLabels.DONOR_ID}
                onValueChange={handleFieldUpdate('donorId')}
                onBlur={validateField('donorId')}
                value={fields.donorId.value}
                maxLength={7}
                labelContainerStyle={{ minWidth: 'unset' }}
                shouldShowIcon={false}
                validationState={fields.donorId.validation}
                errorMessage={fields.donorId.errorMessage}
                style={{ flex: 1 }}
                ref={fields.donorId.inputRef}
              />
              <div style={{ flex: 2 }} />
            </div>
          )}
          {searchType === SearchType.ADVANCED && (
            <div className="advanced-search-filters-container">
              <div style={{ flexDirection: 'column', flex: 0.7, marginRight: 20 }}>
                <TextInput
                  label={PersonFieldLabels.LAST_NAME}
                  onValueChange={handleFieldUpdate('lastName')}
                  value={fields.lastName.value}
                  maxLength={60}
                  labelContainerStyle={{ minWidth: 175 }}
                  shouldShowIcon={false}
                  style={{ marginLeft: Layout.backgroundMarginNarrow.left }}
                  ref={fields.lastName.inputRef}
                  inputStyle={{ textTransform: 'uppercase' }}
                />
                <TextInput
                  label={PersonFieldLabels.FIRST_NAME}
                  onValueChange={handleFieldUpdate('firstName')}
                  value={fields.firstName.value}
                  maxLength={60}
                  shouldShowIcon={false}
                  style={{ marginLeft: Layout.backgroundMarginNarrow.left }}
                  labelContainerStyle={{ minWidth: 175 }}
                  inputStyle={{ textTransform: 'uppercase' }}
                />
                <TextInput
                  label={PersonFieldLabels.MIDDLE_NAME}
                  onValueChange={handleFieldUpdate('middleName')}
                  value={fields.middleName.value}
                  maxLength={60}
                  shouldShowIcon={false}
                  style={{ marginLeft: Layout.backgroundMarginNarrow.left }}
                  labelContainerStyle={{ minWidth: 175 }}
                  inputStyle={{ textTransform: 'uppercase' }}
                />
                <TextInput
                  label={PersonFieldLabels.PREVIOUS_NAME_1}
                  onValueChange={handleFieldUpdate('previousLastName')}
                  value={fields.previousLastName.value}
                  maxLength={60}
                  shouldShowIcon={false}
                  style={{ marginLeft: Layout.backgroundMarginNarrow.left }}
                  labelContainerStyle={{ minWidth: 175 }}
                  inputStyle={{ textTransform: 'uppercase' }}
                />
                <DateInput
                  label={PersonFieldLabels.DATE_OF_BIRTH}
                  onValueChange={handleFieldUpdate('dob')}
                  value={fields.dob.value}
                  validationState={fields.dob.validation}
                  errorMessage={fields.dob.errorMessage}
                  ref={fields.dob.inputRef}
                  onBlur={validateField('dob')}
                  labelContainerStyle={{ minWidth: 175 }}
                  style={{ marginLeft: Layout.backgroundMarginNarrow.left }}
                  shouldShowIcon={false}
                />
                <p />
                <TextInput
                  label={PersonFieldLabels.HOSPITAL_NUMBER}
                  onValueChange={handleFieldUpdate('hospitalNumber')}
                  onBlur={validateField('hospitalNumber')}
                  validationState={fields.hospitalNumber.validation}
                  errorMessage={fields.hospitalNumber.errorMessage}
                  value={fields.hospitalNumber.value}
                  maxLength={60}
                  shouldShowIcon={false}
                  style={{ marginLeft: Layout.backgroundMarginNarrow.left }}
                  labelContainerStyle={{ minWidth: 175 }}
                />
                {canViewMobile && (
                  <>
                    <p />
                    <TextInput
                      label={PersonFieldLabels.MOBILE}
                      onValueChange={handleFieldUpdate('mobile')}
                      onBlur={validateField('mobile')}
                      validationState={fields.mobile.validation}
                      errorMessage={fields.mobile.errorMessage}
                      value={fields.mobile.value}
                      maxLength={60}
                      shouldShowIcon={false}
                      style={{ marginLeft: Layout.backgroundMarginNarrow.left }}
                      labelContainerStyle={{ minWidth: 175 }}
                    />
                  </>
                )}
                {canViewEmail && (
                  <>
                    <p />
                    <TextInput
                      label={PersonFieldLabels.EMAIL}
                      onValueChange={handleFieldUpdate('email')}
                      onBlur={validateField('email')}
                      validationState={fields.email.validation}
                      errorMessage={fields.email.errorMessage}
                      value={fields.email.value}
                      maxLength={emailLength}
                      shouldShowIcon={false}
                      style={{ marginLeft: Layout.backgroundMarginNarrow.left }}
                      labelContainerStyle={{ minWidth: 175 }}
                    />
                  </>
                )}
              </div>

              <div style={{ flexDirection: 'column', width: 210 }}>
                <SelectInput
                  label=""
                  clearable={false}
                  onValueChange={handleFieldUpdate('lastNameSearchBy')}
                  value={fields.lastNameSearchBy.value}
                  options={SearchOptions}
                  ref={fields.lastNameSearchBy.inputRef}
                  labelContainerStyle={{ minWidth: 'unset' }}
                />
                <SelectInput
                  label=""
                  clearable={false}
                  onValueChange={handleFieldUpdate('firstNameSearchBy')}
                  value={fields.firstNameSearchBy.value}
                  options={SearchOptions}
                  ref={fields.firstNameSearchBy.inputRef}
                  labelContainerStyle={{ minWidth: 'unset' }}
                />
                <SelectInput
                  label=""
                  clearable={false}
                  onValueChange={handleFieldUpdate('middleNameSearchBy')}
                  value={fields.middleNameSearchBy.value}
                  options={SearchOptions}
                  ref={fields.middleNameSearchBy.inputRef}
                  labelContainerStyle={{ minWidth: 'unset' }}
                />
                <SelectInput
                  label=""
                  clearable={false}
                  onValueChange={handleFieldUpdate('previousLastNameSearchBy')}
                  value={fields.previousLastNameSearchBy.value}
                  options={SearchOptions}
                  ref={fields.previousLastNameSearchBy.inputRef}
                  labelContainerStyle={{ minWidth: 'unset' }}
                />
                <TextInput
                  label=""
                  onValueChange={handleFieldUpdate('')}
                  value="Exact match"
                  disabled
                  style={{ width: 168 }}
                  shouldShowIcon={false}
                  labelContainerStyle={{ minWidth: 'unset' }}
                />
                <p />
                <TextInput
                  label=""
                  onValueChange={handleFieldUpdate('')}
                  value="Exact match"
                  disabled
                  style={{ width: 168 }}
                  shouldShowIcon={false}
                  labelContainerStyle={{ minWidth: 'unset' }}
                />
                {canViewMobile && (
                  <>
                    <p />
                    <TextInput
                      label=""
                      onValueChange={handleFieldUpdate('')}
                      value="Exact match"
                      disabled
                      style={{ width: 168 }}
                      shouldShowIcon={false}
                      labelContainerStyle={{ minWidth: 'unset' }}
                    />
                  </>
                )}
                {canViewEmail && (
                  <>
                    <p />
                    <TextInput
                      label=""
                      onValueChange={handleFieldUpdate('')}
                      value="Exact match"
                      disabled
                      style={{ width: 168 }}
                      shouldShowIcon={false}
                      labelContainerStyle={{ minWidth: 'unset' }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
          {searchLoading && <Spinner className="table-margin" />}
          {!searchLoading && searchResults && (
            <Table
              className="table-margin brand altRowBackground"
              data={searchResults}
              columns={columns}
              noDataChildren={renderNoRecords()}
            />
          )}
          {currentMaxSearchLimit && searchType !== SearchType.ADVANCED && (
            <Text className="subheading centre-row table-margin search-warning">{UI.SEARCH_RESULT_LIMIT_EXCEEDED}</Text>
          )}
          {currentMaxSearchLimit && searchType === SearchType.ADVANCED && (
            <Text className="subheading centre-row table-margin search-warning">
              {UI.SEARCH_RESULT_ADVANCED_LIMIT_EXCEEDED}
            </Text>
          )}
          {currentResultsLimitedDueToFiltering && (
            <Text className="subheading centre-text table-margin search-warning">
              {UI.SEARCH_RESULT_LIMITED_DUE_TO_FILTERING}
            </Text>
          )}
          {renderNewPersonControls()}
          <ToastContainer position="bottom-center" autoClose={10000} hideProgressBar draggable={false} />
        </ContainerCard>
      </div>
    </div>
  );
}
