import React from 'react';
import { Messages } from '../../../constants/Messages';
import { ButtonPrimary, Text } from '../../../components';
import Layout from '../../../constants/Layout';

interface Props {
  retryFunction?: () => void;
  message?: string;
}

export default function ErrorTab(props: Props) {
  const message = props.message || Messages.ERROR_GENERIC;
  return (
    <div
      className="marginNormal"
      style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
    >
      <Text>{message}</Text>
      {props.retryFunction && (
        <ButtonPrimary
          containerStyle={{ marginTop: Layout.backgroundMarginNormal.top }}
          title="Retry"
          onClick={props.retryFunction}
        />
      )}
    </div>
  );
}
