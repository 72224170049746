import React, { CSSProperties } from 'react';

interface Props {
  children?: any;
  testID?: string;
  accessibilityLabel?: string;
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
}

export class Text extends React.PureComponent<Props> {
  render() {
    const { style, children, onClick, className = 'subheading' } = this.props;
    return (
      <div onClick={onClick} className={`${className}`} style={{ ...style }}>
        {children}
      </div>
    );
  }
}
