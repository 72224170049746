import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { ActionType } from '../constants/Interfaces';
import { SpinnerScreen } from '../components';
import Logger from '../constants/Logger';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../store/hooks';
import CrossTabMessage from '../constants/CrossTabMessage';

const LOG_PREFIX = 'LogoutScreen';

export default function LogoutScreen() {
  // https://stackoverflow.com/questions/31079081/programmatically-navigate-using-react-router
  const [isLogoutFinished, setIsLogoutFinished] = useState(false);
  const { oktaAuth } = useOktaAuth();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const messager = CrossTabMessage.getInstance();

  useEffect(() => {
    function postLogoutAction() {
      dispatch({ type: ActionType.PURGE });
      messager?.postMessage('logout');
    }
    async function completeLogout() {
      try {
        await oktaAuth.signOut();
        postLogoutAction();
      } catch (e) {
        Logger.error(`${LOG_PREFIX} error during logout, attempting manual logout`, e);
        window.sessionStorage.removeItem('okta-token-storage');
        postLogoutAction();
        history.push('/');
      } finally {
        setIsLogoutFinished(true);
      }
    }

    if (dispatch && history && oktaAuth && messager) {
      completeLogout();
    }
  }, [dispatch, history, messager, oktaAuth]);

  const message = isLogoutFinished ? 'Redirecting to login screen' : 'Logging out';

  return <SpinnerScreen message={message} />;
}
