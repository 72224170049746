/**
 * Define common margins and padding of components
 */

// this should be removed and replaces with css classes.

const scaleConstant = 1;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  scaleConstant,
  backgroundMarginNormal: {
    left: 20 * scaleConstant,
    right: 20 * scaleConstant,
    top: 26.67 * scaleConstant,
    bottom: 26.67 * scaleConstant,
  },
  backgroundMarginNarrow: {
    left: 13.33 * scaleConstant,
    right: 13.33 * scaleConstant,
    top: 13.33 * scaleConstant,
    bottom: 13.33 * scaleConstant,
  },
};
