/* eslint-disable @typescript-eslint/no-shadow */
import {
  ActionType,
  Address,
  AppError,
  ErrorType,
  Person,
  PersonStatusUpdatePayload,
  PersonErrorCode,
  PersonErrorName,
  PersonResponse,
  PersonSearchParams,
  PostPersonResponse,
  SearchPersonResponse,
  SearchType,
  PersonDerived,
  PersonCommentUpdatePayload,
  PersonStatus,
} from '../constants/Interfaces';
import API from '../constants/API';
import { cleanObject, mapApiError, MobileConvert } from '../constants/Utils';
import Logger from '../constants/Logger';
import moment from 'moment-timezone';
import { personConsentTypes } from './personConsent';
import { personCardTypes } from './personCard';
import _ from 'lodash';

interface State {
  searchType: SearchType;
  currentSearchParams: PersonSearchParams;
  currentSearchResults: Person[];
  currentMaxSearchLimit: boolean;
  currentResultsLimitedDueToFiltering: boolean;
  currentPerson: Person;
  currentPersonDerived: PersonDerived;
  personSearchResult: object;
  loading: boolean;
  error: AppError;
}

export function getInitialState(): State {
  return {
    searchType: SearchType.NAME,
    currentSearchParams: {},
    currentSearchResults: null,
    currentMaxSearchLimit: null,
    currentResultsLimitedDueToFiltering: null,
    currentPerson: null,
    currentPersonDerived: null,
    personSearchResult: null,
    loading: false,
    error: null,
  };
}

// TODO: split search screen concepts into a seperate reducer
export const personTypes = {
  NEW_PERSON: 'NEW_PERSON',
  PERSON_GET_REQUEST: 'PERSON_GET_REQUEST',
  PERSON_GET_RESPONSE: 'PERSON_GET_RESPONSE',
  PERSON_GET_DERIVED_RESPONSE: 'PERSON_GET_DERIVED_RESPONSE',
  PERSON_POST_REQUEST: 'PERSON_POST_REQUEST',
  PERSON_POST_RESPONSE: 'PERSON_POST_RESPONSE',
  PERSON_PATCH_REQUEST: 'PERSON_PATCH_REQUEST',
  PERSON_PATCH_RESPONSE: 'PERSON_PATCH_RESPONSE',
  CLEAR_SEARCH: 'CLEAR_SEARCH',
  CLEAR_SEARCH_MESSAGE: 'CLEAR_SEARCH_MESSAGE',
  PERSON_SEARCH_REQUEST: 'PERSON_SEARCH_REQUEST',
  PERSON_SEARCH_RESPONSE: 'PERSON_SEARCH_RESPONSE',
  PERSON_SEARCH_COMPLETE: 'PERSON_SEARCH_COMPLETE',
  PERSON_SEARCH_SET: 'PERSON_SEARCH_SET',
  SET_SEARCH_TYPE: 'SET_SEARCH_TYPE',
};

const LOG_PREFIX = `Reducer: person ->`;

export const personActions = {
  newPerson: () => async (dispatch) => {
    dispatch({ type: personTypes.NEW_PERSON });
    // clear the child records as well
    dispatch({ type: personConsentTypes.NEW_PERSON });
    dispatch({ type: personCardTypes.NEW_PERSON });
    Logger.info(`${LOG_PREFIX} newPerson: removing current person`);
  },
  /**
   * Get the person data by id
   */
  getPerson: (abrNumber: string) => async (dispatch) => {
    dispatch({ type: personTypes.PERSON_GET_REQUEST });
    Logger.info(`${LOG_PREFIX} getPerson: Getting person information ${abrNumber}`);
    try {
      const api = API.getInstance();
      const response = await api.get(`/person/${abrNumber}`);
      const person: PersonResponse = response.records;
      const address: Address = person.addressLine1
        ? {
            addressLine1: person.addressLine1,
            addressLine2: person.addressLine2,
            state: person.state,
            suburb: person.suburb,
            postcode: person.postcode,
          }
        : null;
      const mobile = person.mobile ? { mobile: MobileConvert.parse(person.mobile) } : {};
      Logger.debug(`${LOG_PREFIX} getPerson: Person information received.`);
      await dispatch({ type: personConsentTypes.NEW_PERSON });
      await dispatch({ type: personCardTypes.NEW_PERSON });
      dispatch({
        type: personTypes.PERSON_GET_RESPONSE,
        error: false,
        payload: {
          ...person,
          ...mobile,
          address,
          // rhdGenotype: 'HEAJ4512_A2	2021/01/01			Test',
          // rhceGenotype: 'HEAJ4512_A2	2021/01/01			+	0	+	0	Test',
          // heaGenotype: 'HEAJ4512_A2	2021/01/01			+	0	+	0	0	0	0	+	0	+	0	+	0	+	+	0	+	+	+	+	+	0	+	0	+	+	0	+	+	+	+	+	0	+	0	0',
        },
      });
      return { ...person, ...mobile, address };
    } catch (e) {
      dispatch({
        type: personTypes.PERSON_GET_RESPONSE,
        payload: mapApiError(ErrorType.PERSON_ERROR, e),
        error: true,
      });
      throw e;
    }
  },

  /**
   * Get the person data by id, but only the values that might change due to child records changing
   */
  getPersonDerived: (abrNumber: string) => async (dispatch) => {
    Logger.info(`${LOG_PREFIX} getPersonDerived: Getting person derived information ${abrNumber}`);
    try {
      const api = API.getInstance();
      const response = await api.get(`/person/${abrNumber}`);
      const person: PersonResponse = response.records;
      Logger.debug(`${LOG_PREFIX} getPersonDerived: Person information received.`);
      dispatch({ type: personTypes.PERSON_GET_DERIVED_RESPONSE, error: false, payload: { ...person } });
      return { ...person };
    } catch (e) {
      dispatch({
        type: personTypes.PERSON_GET_DERIVED_RESPONSE,
        payload: mapApiError(ErrorType.PERSON_ERROR, e),
        error: true,
      });
      throw e;
    }
  },

  patchPerson:
    (person: Person | PersonStatusUpdatePayload | PersonCommentUpdatePayload) => async (dispatch, getState) => {
      dispatch({ type: personTypes.PERSON_PATCH_REQUEST });
      const { currentPerson } = getState().person;

      // diff check, do nothin if there are no changes
      const predictedPerson = { ...currentPerson, ...person };
      delete predictedPerson.canVerify;
      const diff = _.pickBy(predictedPerson, (v, k) => currentPerson[k] !== v);

      if (Object.keys(diff).length === 0) {
        Logger.info(`${LOG_PREFIX} patchPerson: no differences noticed, skipping patch`);
        return currentPerson;
      }

      Logger.info(`${LOG_PREFIX} patchPerson: sending person information`);
      try {
        const mobile = person.mobile ? { mobile: MobileConvert.format(person.mobile) } : {};
        const dob = person.dob ? { dob: moment(person.dob).format('YYYY-MM-DD HH:mm Z') } : {};
        const api = API.getInstance();
        // response only provides a "successful" message
        const { status } = await api.patch(`/person/${person.personId}`, {
          ...person,
          ...mobile,
          ...dob,
        });
        Logger.debug(`${LOG_PREFIX} patchPerson: Person information sent, merging generated values`);
        const dobReturn = person.dob ? { dob: moment(person.dob).format('YYYY-MM-DD') } : {};
        dispatch({
          type: personTypes.PERSON_PATCH_RESPONSE,
          error: false,
          payload: { ...person, ...dobReturn, status },
        });
        return { ...person, ...dobReturn, status };
      } catch (e: any) {
        if (
          e.response &&
          e.response.status === PersonErrorCode.BAD_DATA &&
          e.response.data &&
          e.response.data.errorCode === PersonErrorName.INVALID_REQUEST
        ) {
          // user saved without changing anything
          Logger.info(
            `${LOG_PREFIX} patchPerson: user submitted the screen without changing anything. Don't update the reducer state`
          );
          dispatch({ type: personTypes.PERSON_PATCH_RESPONSE, error: false, payload: { ...currentPerson } });
        } else {
          dispatch({
            type: personTypes.PERSON_PATCH_RESPONSE,
            payload: mapApiError(ErrorType.PERSON_ERROR, e),
            error: true,
          });
        }
        throw e;
      }
    },

  postPerson: (person: Person) => async (dispatch) => {
    dispatch({ type: personTypes.PERSON_POST_REQUEST });
    Logger.info(`${LOG_PREFIX} postPerson: sending person information`);
    try {
      const api = API.getInstance();
      const response: PostPersonResponse = await api.post(`/person`, {
        ...person,
        dob: moment(person.dob).format('YYYY-MM-DD HH:mm Z'),
        mobile: MobileConvert.format(person.mobile),
      });
      Logger.debug(`${LOG_PREFIX} postPerson: Person information sent, merging generated values`);
      dispatch({
        type: personTypes.PERSON_POST_RESPONSE,
        error: false,
        payload: { ...person, personId: response.id, status: PersonStatus.DRAFT },
      });
      return { ...person, ...response, status: PersonStatus.DRAFT };
    } catch (e) {
      dispatch({
        type: personTypes.PERSON_POST_RESPONSE,
        payload: mapApiError(ErrorType.PERSON_ERROR, e),
        error: true,
      });
      throw e;
    }
  },

  resetSearch: () => async (dispatch) => {
    dispatch({ type: personTypes.CLEAR_SEARCH });
    dispatch({ type: personTypes.SET_SEARCH_TYPE, payload: SearchType.NAME });
    Logger.info(`${LOG_PREFIX} resetSearch: resetting search`);
  },

  clearSearch: () => async (dispatch) => {
    dispatch({ type: personTypes.CLEAR_SEARCH });
    Logger.info(`${LOG_PREFIX} clearSearch: removing search`);
  },

  clearSearchMessage: () => async (dispatch) => {
    dispatch({ type: personTypes.CLEAR_SEARCH_MESSAGE });
    Logger.info(`${LOG_PREFIX} clearSearchMessage: removing message`);
  },

  setSearchParams: (search: PersonSearchParams) => async (dispatch) => {
    dispatch({ type: personTypes.PERSON_SEARCH_SET, payload: search });
    Logger.info(`${LOG_PREFIX} setSearchParams: set search params`);
  },

  search:
    (search: PersonSearchParams, storeSearchParams = true) =>
    async (dispatch) => {
      dispatch({ type: personTypes.PERSON_SEARCH_REQUEST });
      if (storeSearchParams) {
        dispatch({ type: personTypes.PERSON_SEARCH_SET, payload: search });
      }
      Logger.info(`${LOG_PREFIX} searchPerson: searching`);
      try {
        const empty = Object.values(search).every((v) => v === null || v === '');
        if (empty) {
          dispatch({ type: personTypes.PERSON_SEARCH_RESPONSE, error: false, payload: [] });
          return [];
        }
        const api = API.getInstance();
        const cleanParams = cleanObject({ ...search });
        if (cleanParams.mobile) cleanParams.mobile = MobileConvert.format(cleanParams.mobile);
        if (cleanParams.dob) cleanParams.dob = moment(cleanParams.dob).format('YYYY-MM-DD HH:mm Z');
        const response: SearchPersonResponse = await api.get(`/person`, cleanParams);
        Logger.debug(`${LOG_PREFIX} searchPerson: Person search received`);
        if (storeSearchParams) {
          dispatch({ type: personTypes.PERSON_SEARCH_RESPONSE, error: false, payload: response });
        } else {
          dispatch({ type: personTypes.PERSON_SEARCH_COMPLETE });
        }
        return response;
      } catch (e) {
        dispatch({
          type: personTypes.PERSON_SEARCH_RESPONSE,
          payload: mapApiError(ErrorType.PERSON_ERROR, e),
          error: true,
        });
        throw e;
      }
    },

  // TODO: this does not dispatch any actions, but it seems better to keep API calls in a reducer
  searchExternalDonorDetails: (donorID: string) => async (_dispatch, getState) => {
    Logger.info(`${LOG_PREFIX} searchExternalDonorDetails: searching ${donorID}`);
    if (getState().permissions?.donorDebug) {
      return {
        mobile: '0447123456',
        firstName: 'First',
        lastName: 'Last',
        middleName: 'Middle',
        dob: '2021-01-01',
        gender: 'F',
        bloodGroupRefId: 1,
        email: 'james.lewis@certussolutions.com',
      };
    }
    const api = API.getInstance();
    const response: PersonResponse = await api.get(`/donor/${donorID}`);
    const mobile = response.mobile ? { mobile: MobileConvert.parse(response.mobile) } : {};
    Logger.debug(`${LOG_PREFIX} searchExternalDonorDetails: donor details retrived" ${JSON.stringify(response)}`);

    return { ...response, ...mobile };
  },
};

export default function person(state = getInitialState(), action) {
  const { type, payload, error } = action;

  switch (type) {
    case personTypes.PERSON_GET_REQUEST:
    case personTypes.PERSON_PATCH_REQUEST:
    case personTypes.PERSON_POST_REQUEST: {
      return { ...state, loading: true, error: null };
    }

    case personTypes.PERSON_GET_RESPONSE:
    case personTypes.PERSON_POST_RESPONSE: {
      if (error) {
        return { ...state, error: payload, loading: false, currentPerson: null, currentPersonDerived: null };
      }
      const currentPerson = payload;
      const currentPersonDerived = payload;
      return { ...state, currentPerson, currentPersonDerived, loading: false, error: null };
    }

    case personTypes.PERSON_GET_DERIVED_RESPONSE: {
      if (error) {
        return { ...state, error: payload, loading: false, currentPersonDerived: null };
      }
      const currentPersonDerived = payload;
      return { ...state, currentPersonDerived, loading: false, error: null };
    }

    case personTypes.PERSON_PATCH_RESPONSE: {
      if (error) {
        return { ...state, error: payload, loading: false };
      }
      const patchedPerson = payload;
      const currentPerson = { ...state.currentPerson, ...patchedPerson };
      const currentPersonDerived = { ...state.currentPersonDerived, ...patchedPerson };
      return { ...state, currentPerson, currentPersonDerived, loading: false, error: null };
    }

    case personTypes.NEW_PERSON: {
      return { ...state, currentPerson: null, currentPersonDerived: null, loading: false, error: null };
    }

    case personTypes.CLEAR_SEARCH: {
      return {
        ...state,
        currentSearchParams: null,
        currentSearchResults: null,
        currentMaxSearchLimit: false,
        currentResultsLimitedDueToFiltering: false,
      };
    }

    case personTypes.CLEAR_SEARCH_MESSAGE: {
      return {
        ...state,
        currentMaxSearchLimit: false,
        currentResultsLimitedDueToFiltering: false,
      };
    }

    case personTypes.PERSON_SEARCH_REQUEST: {
      return { ...state, loading: true, error: null };
    }

    case personTypes.PERSON_SEARCH_SET: {
      return { ...state, currentSearchParams: payload };
    }

    case personTypes.PERSON_SEARCH_RESPONSE: {
      if (error) {
        return { ...state, error: payload, loading: false, currentSearchResults: null };
      }
      const currentSearchResults = payload.records;
      const currentMaxSearchLimit = payload.maxAllowedRecordLimit;
      const currentResultsLimitedDueToFiltering = payload.searchResultsLimitedDueToFiltering;
      return {
        ...state,
        currentSearchResults,
        currentMaxSearchLimit,
        currentResultsLimitedDueToFiltering,
        loading: false,
        error: null,
      };
    }

    case personTypes.PERSON_SEARCH_COMPLETE: {
      return { ...state, loading: false, error: null };
    }

    case personTypes.SET_SEARCH_TYPE: {
      return { ...state, searchType: payload };
    }

    case ActionType.PURGE: {
      return getInitialState();
    }
  }

  return state;
}
