import React, { RefObject } from 'react';
import { Input } from './Input';
import Colors from '../../constants/Colors';
import { ValidationState } from '../../constants/Interfaces';
import './DateInput.scss';

interface State {}

interface Props {
  autoComplete?: boolean;
  disabled?: boolean;
  inputStyle?: object;
  inputClassName?: String;
  labelStyle?: object;
  style?: object;
  label: string;
  onValueChange: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  value: string;
  icon?: any;
  shouldShowIcon?: boolean;
  shouldShowLabel?: boolean;
  required?: boolean;
  validationState?: ValidationState;
  errorMessage?: string;
  inProgressMessage?: string;
  instructionMessage?: string;
  labelContainerStyle?: object;
  autoFocus?: boolean;
  id?: string;
}

enum Inputs {
  DAY,
  MONTH,
  YEAR,
}

export class DateInput extends React.Component<Props, State> {
  private readonly inputRef: RefObject<HTMLInputElement>;
  private readonly monthRef: RefObject<HTMLInputElement>;
  private readonly yearRef: RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
    this.monthRef = React.createRef();
    this.yearRef = React.createRef();
    this.combineValues = this.combineValues.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  combineValues(day: string, month: string, year: string, caller: Inputs) {
    const { onValueChange } = this.props;

    if (!year && !month && !day) {
      // TODO: This undefined seems questionable
      onValueChange(undefined);
      return;
    }
    onValueChange(`${year}-${month}-${day}`);
    switch (caller) {
      case Inputs.DAY:
        if (day.length === 2 && this.monthRef.current) {
          this.monthRef.current.focus();
        }
        break;
      case Inputs.MONTH:
        if (month.length === 2 && this.yearRef.current) {
          this.yearRef.current.focus();
        }
        break;
    }
  }

  onFocus = (caller: Inputs, value) => (event) => {
    if (!value) {
      return;
    }
    if ((caller === Inputs.DAY || caller === Inputs.MONTH) && value.length === 2) {
      event.target.select();
    } else if (caller === Inputs.YEAR && value.length === 4) {
      event.target.select();
    }
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  render() {
    const {
      disabled = false,
      label,
      labelStyle,
      style,
      required,
      validationState = ValidationState.NULL,
      errorMessage,
      inProgressMessage,
      instructionMessage,
      icon,
      shouldShowIcon,
      shouldShowLabel,
      value,
      onBlur,
      inputStyle,
      inputClassName = '',
      labelContainerStyle,
      autoFocus,
      id,
    } = this.props;

    const valueColor = () => {
      if (validationState === ValidationState.FAILED) return Colors.red;
      return Colors.black;
    };

    const splitDate = value ? value.split('-') : '';
    const day = splitDate ? splitDate[2] : '';
    const month = splitDate ? splitDate[1] : '';
    const year = splitDate ? splitDate[0] : '';

    return (
      <Input
        className="date-input"
        label={label}
        style={{ ...style, flexGrow: 0, flexShrink: 1 }}
        labelStyle={labelStyle}
        required={required}
        errorMessage={errorMessage}
        inProgressMessage={inProgressMessage}
        instructionMessage={instructionMessage}
        validationState={validationState}
        icon={icon}
        shouldShowIcon={shouldShowIcon}
        shouldShowLabel={shouldShowLabel}
        labelContainerStyle={labelContainerStyle}
        onBlur={onBlur}
        disabled={disabled}
      >
        <input
          onChange={(result) => this.combineValues(result.target.value, month, year, Inputs.DAY)}
          maxLength={2}
          autoComplete="off"
          disabled={disabled}
          placeholder="dd"
          className={`input ${inputClassName}`}
          style={{ ...inputStyle, ...{ color: valueColor(), width: 30 } }}
          value={day || ''}
          onFocus={this.onFocus(Inputs.DAY, day)}
          ref={this.inputRef}
          autoFocus={autoFocus}
          id={id}
        />
        /
        <input
          onChange={(result) => this.combineValues(day, result.target.value, year, Inputs.MONTH)}
          maxLength={2}
          autoComplete="off"
          disabled={disabled}
          placeholder="mm"
          className={`input ${inputClassName}`}
          style={{ ...inputStyle, ...{ color: valueColor(), width: 30 } }}
          value={month || ''}
          onFocus={this.onFocus(Inputs.MONTH, month)}
          ref={this.monthRef}
        />
        /
        <input
          onChange={(result) => this.combineValues(day, month, result.target.value, Inputs.YEAR)}
          maxLength={4}
          autoComplete="off"
          disabled={disabled}
          placeholder="yyyy"
          className={`input ${inputClassName}`}
          style={{ ...inputStyle, ...{ color: valueColor(), width: 40 } }}
          value={year || ''}
          onFocus={this.onFocus(Inputs.YEAR, year)}
          ref={this.yearRef}
        />
      </Input>
    );
  }
}
