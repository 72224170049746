import { Permission } from './Interfaces';

// a selector for use with useSelector. To find if the logged in user has a specific permission.
export function selectPermission(permission: Permission) {
  return function (state) {
    if (state.permissions.permissionsDebug) {
      return true;
    }

    if (state.permissions.permissions) {
      return state.permissions.permissions[permission];
    }
    return false;
  };
}
