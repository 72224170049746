import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import API from './constants/API';
import { BrowserRouter as Router } from 'react-router-dom';

// fonts
import './assets/fonts/Montserrat_Light.woff2';
import './assets/fonts/Montserrat_Medium.woff2';
import './assets/fonts/Montserrat-SemiBold.woff2';
import './assets/fonts/Picador_Fela_Bold.woff2';

const { persistor, store } = configureStore();
API.getInstance(store);

// setup the redux store around the application

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={
          <div>
            <p>Loading</p>
          </div>
        }
        persistor={persistor}
      >
        <Router>
          <App />
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
