import React, { RefObject } from 'react';
import { Input } from './Input';
import Colors from '../../constants/Colors';
import { ElementLocation, ValidationState } from '../../constants/Interfaces';
import './TextInput.scss';
import Logger from '../../constants/Logger';

interface State {}

interface Props {
  disabled?: boolean;
  inputStyle?: object;
  labelStyle?: object;
  labelContainerStyle?: object;
  style?: object;
  label: string;
  testID?: string;
  onValueChange: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onKeyDown?: (event) => void;
  value: any;
  autocomplete?: 'on' | 'off';
  autoFocus?: boolean;
  placeholder?: string;
  icon?: any;
  shouldShowIcon?: boolean;
  shouldShowLabel?: boolean;
  required?: boolean;
  validationState?: ValidationState;
  errorMessage?: string;
  inProgressMessage?: string;
  instructionMessage?: string;
  maxLength?: number;
  textChildren?: any;
}

export class TextInput extends React.Component<Props, State> {
  private readonly inputRef: RefObject<HTMLInputElement>;
  private readonly viewRef: RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.viewRef = React.createRef();
    this.getLocation = this.getLocation.bind(this);
  }

  getLocation() {
    const { label } = this.props;
    Logger.debug(`Component -> TextInput -> getLocation: measuring location now for field ${label}`);
    if (this.viewRef && this.viewRef.current) {
      const rect = this.viewRef.current.getBoundingClientRect();
      Logger.debug(`Field ${label} location: ${rect.x}, ${rect.y}`);
      return {
        x: rect.x + window.scrollX,
        y: rect.y + window.scrollY,
      } as ElementLocation;
    }
    return null;
  }

  getInputRef() {
    return this.inputRef;
  }

  render() {
    const {
      testID,
      disabled = false,
      inputStyle,
      label,
      labelStyle,
      labelContainerStyle,
      style,
      placeholder,
      required,
      validationState = ValidationState.NULL,
      errorMessage,
      inProgressMessage,
      instructionMessage,
      icon,
      shouldShowIcon,
      shouldShowLabel,
      value,
      autocomplete = 'off',
      autoFocus,
      onValueChange,
      onBlur,
      onFocus,
      onKeyDown,
      maxLength = 256,
      textChildren,
    } = this.props;

    const valueColor = () => {
      if (validationState === ValidationState.FAILED) return Colors.red;
      return Colors.black;
    };

    return (
      <Input
        className="text-input"
        label={label}
        style={style}
        labelStyle={labelStyle}
        required={required}
        errorMessage={errorMessage}
        inProgressMessage={inProgressMessage}
        instructionMessage={instructionMessage}
        validationState={validationState}
        icon={icon}
        shouldShowIcon={shouldShowIcon}
        shouldShowLabel={shouldShowLabel}
        labelContainerStyle={labelContainerStyle}
        disabled={disabled}
        textChildren={textChildren}
      >
        <input
          data-testid={testID || label}
          autoComplete={autocomplete}
          onChange={(result) => onValueChange(result.target.value)}
          disabled={disabled}
          placeholder={placeholder}
          className="input"
          style={{ ...inputStyle, ...{ color: valueColor() } }}
          value={value || ''}
          onBlur={onBlur}
          onFocus={onFocus}
          maxLength={maxLength}
          ref={this.inputRef}
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
        />
      </Input>
    );
  }
}
