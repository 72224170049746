import React from 'react';
import { toast } from 'react-toastify';
import {
  BloodGroup,
  GenericErrorCode,
  Person,
  SearchDonorErrorCode,
  SearchPersonResponse,
  ValidationState,
} from '../../constants/Interfaces';
import { Messages, PersonFieldLabels, UI } from '../../constants/Messages';
import { personActions } from '../../reducers/person';
import moment from 'moment';
import { getReferenceLabel } from '../../constants/Utils';
import _ from 'lodash';
import Logger from '../../constants/Logger';

const LOG_PREFIX = 'PersonDonorSearch ->';

// search for a donor id from nbms
export async function searchDonorId(
  validateField: (field: string) => () => Promise<boolean>,
  setDonorIDLoading: (value: boolean) => void,
  dispatch: any,
  setConfirmTitle: (value: string) => void,
  setConfirmChildren: (value: any) => void,
  setConfirmAccept: (value: any) => void,
  setConfirmOpen: (value: any) => void,
  person: Person,
  fields: any,
  bloodGroups: BloodGroup[],
  confirmDonorIdSearch: (donorDetails: any) => () => void
) {
  const result = await validateField('donorId')();
  if (!result) {
    // Field will be marked as invalid in validateField
    return;
  }
  setDonorIDLoading(true);
  try {
    // search for records that already use this donor id
    const duplicateCheck: SearchPersonResponse = await dispatch(
      personActions.search({ donorId: fields.donorId.value }, false)
    );
    // don't check itself
    if (duplicateCheck.records && person) {
      _.pullAllBy(duplicateCheck.records, [{ abrNumber: person.abrNumber }], 'abrNumber');
    }
    // if there are records left display error
    if (duplicateCheck.records && duplicateCheck.records.length > 0) {
      fields.donorId.setValidation(ValidationState.FAILED);
      fields.donorId.setErrorMessage(Messages.PERSON_EDIT_DONOR_ID_DUPLICATE);
      toast(Messages.PERSON_EDIT_DONOR_ID_DUPLICATE);
      return;
    }
    // perform nbms data pull
    const donorDetails: Person = await dispatch(personActions.searchExternalDonorDetails(fields.donorId.value));
    // if pulling into an existing record and if the donor details do not match existing core data, show a warning
    if (person) {
      const differentDonorDetail = isDifferentDonorDetails(donorDetails, fields);
      setConfirmTitle(UI.PERSON_EDIT_POPUP_DONOR_MESSAGE);
      setConfirmChildren(
        <div>
          {differentDonorDetail && <div className="red">{UI.PERSON_EDIT_POPUP_DONOR_NON_MATCH}</div>}
          <table className="donorIdTable">
            <tr>
              <th> </th>
              <th>{UI.PERSON_EDIT_POPUP_DONOR_EXISTING_COLUMN}</th>
              <th>{UI.PERSON_EDIT_POPUP_DONOR_NEW_COLUMN}</th>
            </tr>
            <tr>
              <td
                className={fields.lastName.value?.toUpperCase() !== donorDetails.lastName?.toUpperCase() ? 'red' : ''}
              >
                {PersonFieldLabels.LAST_NAME}
              </td>
              <td>{fields.lastName.value?.toUpperCase()}</td>
              <td>{donorDetails.lastName?.toUpperCase()}</td>
            </tr>
            <tr>
              <td
                className={
                  fields.firstName.value?.toUpperCase().split(/[^a-zA-Z]/, 1)[0] !==
                  donorDetails.firstName?.toUpperCase().split(/[^a-zA-Z]/, 1)[0]
                    ? 'red'
                    : ''
                }
              >
                {PersonFieldLabels.FIRST_NAME}
              </td>
              <td>{fields.firstName.value}</td>
              <td>{donorDetails.firstName}</td>
            </tr>
            <tr>
              <td className={fields.dob.value !== donorDetails.dob ? 'red' : ''}>{PersonFieldLabels.DATE_OF_BIRTH}</td>
              <td>{fields.dob.value ? moment(fields.dob.value).format('DD/MM/YYYY') : null}</td>
              <td>{moment(donorDetails.dob).format('DD/MM/YYYY')}</td>
            </tr>
            <tr>
              <td
                className={
                  fields.bloodGroupRefId.value?.toString() !== donorDetails.bloodGroupRefId?.toString() ? 'red' : ''
                }
              >
                {PersonFieldLabels.BLOOD_GROUP}
              </td>
              <td>{getReferenceLabel(bloodGroups, fields.bloodGroupRefId.value)}</td>
              <td>{getReferenceLabel(bloodGroups, donorDetails.bloodGroupRefId)}</td>
            </tr>
          </table>
        </div>
      );
      setConfirmAccept(() => confirmDonorIdSearch(donorDetails));
      setConfirmOpen(true);
      return;
    }
    confirmDonorIdSearch(donorDetails)();
  } catch (e: any) {
    Logger.error(`${LOG_PREFIX} searchDonorId: Error occurred while searching for a donor id`, e);
    if (e.response && e.response.status === GenericErrorCode.PERMISSION_ERROR) {
      toast(Messages.ERROR_403_API);
    } else if (e.response && e.response.status === SearchDonorErrorCode.BAD_DATA) {
      fields.donorId.setValidation(ValidationState.FAILED);
      fields.donorId.setErrorMessage(Messages.PERSON_EDIT_DONOR_ID_DATE_ERROR);
      toast(Messages.PERSON_EDIT_DONOR_ID_DATE_ERROR);
    } else if (e.response && e.response.status === SearchDonorErrorCode.NO_DONOR) {
      fields.donorId.setValidation(ValidationState.FAILED);
      fields.donorId.setErrorMessage(Messages.PERSON_EDIT_DONOR_ID_NOT_EXISTS);
      toast(Messages.PERSON_EDIT_DONOR_ID_NOT_EXISTS);
    } else {
      toast(Messages.ERROR_GENERIC);
    }
  } finally {
    setDonorIDLoading(false);
  }
}

// check if retrived donor details are different than the current details.
function isDifferentDonorDetails(donorDetails: Person, fields) {
  return (
    fields.firstName.value?.toUpperCase().split(/[^a-zA-Z]/, 1)[0] !==
      donorDetails.firstName?.toUpperCase().split(/[^a-zA-Z]/, 1)[0] ||
    fields.lastName.value?.toUpperCase() !== donorDetails.lastName?.toUpperCase() ||
    fields.dob.value !== donorDetails.dob ||
    fields.bloodGroupRefId.value?.toString() !== donorDetails.bloodGroupRefId?.toString()
  );
}
