import React, { CSSProperties } from 'react';
import './GenotypeTable.scss';
import { Text } from '../text/Text';
import { Table } from './Table';

export enum GenotypeTypes {
  RHD,
  RHCE,
  HEA,
}

interface Props {
  style?: CSSProperties;
  label: string;
  labelStyle?: CSSProperties;
  tableStyle?: CSSProperties;
  value?: string;
  type: GenotypeTypes;
}

export function GenotypeTable(props: Props) {
  const { style, label, labelStyle, tableStyle, value, type } = props;

  const renderLabel = () => (
    <Text className="label subheading" style={labelStyle}>
      {label}
    </Text>
  );
  if (!value || value.length === 0) {
    return renderLabel();
  }
  const splitValue = value.split('\t');
  // const data = splitValue.slice(4);
  const data = {};
  const columns = [];

  switch (type) {
    case GenotypeTypes.RHD:
      splitValue.slice(4).forEach((dataValue, index) => {
        const columnName = RHDColumns[index];
        if (columnName) {
          data[columnName] = dataValue;
          columns.push({ Header: columnName, accessor: columnName });
        }
      });
      break;
    case GenotypeTypes.RHCE:
      splitValue.slice(4).forEach((dataValue, index) => {
        const columnName = RHCEColumns[index];
        if (columnName) {
          data[columnName] = dataValue;
          columns.push({ Header: columnName, accessor: columnName });
        }
      });
      break;
    case GenotypeTypes.HEA:
      splitValue.slice(4).forEach((dataValue, index) => {
        const columnName = HEAColumns[index];
        if (columnName) {
          data[columnName] = dataValue;
          columns.push({ Header: columnName, accessor: columnName, style: { width: 40 } });
        }
      });
      break;
  }

  return (
    <div className={`genotype-table t${type}`} style={style}>
      {renderLabel()}
      <Table
        data={[data]}
        columns={columns}
        className="table-margin spacing brand altRowBackground"
        style={{ flex: 1, ...tableStyle }}
      />
    </div>
  );
}

const RHDColumns = ['Variant'];

const RHCEColumns = ['C', 'c', 'E', 'e', 'Variant'];

const HEAColumns = [
  'c',
  'C',
  'e',
  'E',
  'V',
  'VS',
  'K',
  'k',
  'Kpa',
  'Kpb',
  'Jsa',
  'Jsb',
  'Fya',
  'Fyb',
  'Jka',
  'Jkb',
  'M',
  'N',
  'S',
  's',
  'U',
  'Lua',
  'Lub',
  'Dia',
  'Dib',
  'Coa',
  'Cob',
  'Doa',
  'Dob',
  'Hy',
  'Joa',
  'LWa',
  'LWb',
  'Sc1',
  'Sc2',
  'HbS',
];
