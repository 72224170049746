import { EmailVerifyResponse, MobileVerifyResponse, ValidationState, Validator } from './Interfaces';
import { Messages } from './Messages';
import API from './API';
import _ from 'lodash';
import moment from 'moment-timezone';
import Logger from './Logger';
import { useRef, useState } from 'react';

const LOG_PREFIX = 'Validators ->';

/**
 * Defines regular expressions and validators to validate user input
 */

export const emailValidator: RegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const mobileValidator: RegExp = /^04[0-9]{8}$/;
export const donorNumberValidator: RegExp = /^(?!0{7})\d{7}$/;
export const numberValidator: RegExp = /^([0-9])+$/;
export const decimalValidator: RegExp = /^([0-9.])+$/;
export const dateFormatValidator: RegExp = /^[0-9]{4}[-]{1}[0-9]{2}[-]{1}[0-9]{2}$/;
export const hospitalNumberValidator: RegExp = /^[a-zA-Z]{1}[0-9]{7}$/;

export const emailLength = 254;

const validEmails = [];
const validMobiles = [];

/**
 * Empty value validator. Used to validate whether mandatory fields have been filled.
 * @param value the input value.
 */
async function isFilled(value: any): Promise<boolean> {
  return !_.isEmpty(_.trim(value));
}

/**
 * Empty array validator. Used to validate whether mandatory fields have been filled.
 * @param value the input value.
 */
/*
async function isFilledArray(value: any): Promise<boolean> {
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  return isFilled(value);
}
*/

/**
 * Simple boolean validator that checks whether the given input is true or not.
 * @param value the input value.
 */
/*
async function isTruthy(value: boolean): Promise<boolean> {
  return value;
}*/

/**
 * Date format validator. Used to validate if a value is a date or not.
 * @param value the input value.
 */
async function isDate(value: any): Promise<boolean> {
  return syncIsDate(value);
}

export function syncIsDate(value: any): boolean {
  if (!value) {
    return true;
  }
  if (value.length < 10) {
    return false;
  }
  if (!dateFormatValidator.test(value)) {
    return false;
  }
  // undefined is apparently valid.
  // --01 is apparently valid
  return moment(value).isValid();
}

/**
 * Check the date isn't too far in the past
 * @param value the input value.
 */
async function minDate(value: any): Promise<boolean> {
  return moment(value).isAfter('1000-01-01');
}

/**
 * Check the date isn't in the future
 * @param value the input value.
 */
async function beforeFutureDate(value: any): Promise<boolean> {
  return moment(value).isBefore(moment());
}

/**
 * Check the date isn't in the future. If empty return true
 * @param value the input value.
 */
async function beforeFutureDateForSearch(value: any): Promise<boolean> {
  if (!value) {
    return true;
  }
  return moment(value).isBefore(moment());
}
/**
 * Gender value validator. Used to validate if a value is a gender nor not.
 * @param value the input value.
 */
/*
async function isGender(value: any): Promise<boolean> {
  return value === Gender.MALE || value === Gender.FEMALE;
}
*/

/**
 * number format validator
 * @param value the number.
 */
async function isNumber(value: string): Promise<boolean> {
  if (!value || value.length === 0) {
    return true;
  }
  return numberValidator.test(value);
}

/**
 * decimal format validator
 * @param value the number.
 */
async function isDecimal(value: string): Promise<boolean> {
  if (!value || value.length === 0) {
    return true;
  }
  return decimalValidator.test(value);
}

/**
 * not number format validator - Special characters and alphanumeric allowed
 * @param value the number.
 */
/*
async function isNotNumber(value: string): Promise<boolean> {
  if (!value || value.length === 0) {
    return true;
  }
  return !numberValidator.test(value);
}
*/

/**
 * number format validator
 * @param value the number.
 */
async function isDonorNumber(value: string): Promise<boolean> {
  if (!value || value.length === 0) {
    return true;
  }
  return donorNumberValidator.test(value);
}

/**
 * Mobile number format validator. Used to validate if a mobile number is of an expected format.
 * @param value the mobile number.
 */
async function isCompleteMobile(value: string): Promise<boolean> {
  if (!value || value.length === 0) {
    return true;
  }
  return mobileValidator.test(value);
}

/**
 * Email format validator. Used to validate if an email is of an expected format.
 * @param value the email address.
 */
async function isCompleteEmail(value: string): Promise<boolean> {
  if (!value || value.length === 0) {
    return true;
  }
  return emailValidator.test(value);
}

/**
 * Hospital number format validator.
 * @param value input value - Hospital number.
 */
async function isCompleteHospitalNumber(value: string): Promise<boolean> {
  if (!value || value.length === 0) {
    return true;
  }
  return hospitalNumberValidator.test(value);
}

/**
 * Experian mobile number validator. Used to validate if a mobile number is valid against Experian.
 * @param value the mobile number.
 */
async function isValidMobile(value: string): Promise<boolean> {
  if (!value || value.length === 0) {
    return true;
  }

  // Return true for a previously online validated mobile
  if (_.includes(validMobiles, value)) {
    return true;
  }

  Logger.info(`Validator -> isValidMobile: Validating mobile number against Experian now`);
  const api = API.getInstance();
  try {
    const response: MobileVerifyResponse = await api.post(
      '/phone/verify',
      {
        // eslint-disable-next-line id-blacklist
        number: `0061${value.substring(1)}`,
        output_format: 'AUS',
      },
      null
    );

    if (
      response &&
      response.result &&
      response.result.confidence &&
      response.result.confidence.toLowerCase() === 'verified'
    ) {
      validMobiles.push(value);
      return true;
    }
    return false;
  } catch (e) {
    Logger.error(
      `Validator -> isValidMobile: Error occurred when validating mobile number. Details: ${JSON.stringify(e)}`
    );
    return false;
  }
}

/**
 * Experian email validator. Used to validate if an email is valid against Experian.
 * @param value the email.
 */
async function isValidEmail(value: string): Promise<boolean> {
  if (!value || value.length === 0) {
    return true;
  }

  // Return true for a previously online validated email
  if (_.includes(validEmails, value)) {
    return true;
  }

  Logger.info(`Validator -> isValidEmail: Validating email address against Experian now`);
  const api = API.getInstance();
  try {
    const response: EmailVerifyResponse = await api.post(
      '/email/verify',
      {
        email: value,
        timeout: 10,
      },
      null
    );

    if (
      response &&
      response.result &&
      response.result.confidence &&
      (response.result.confidence.toLowerCase() === 'verified' ||
        response.result.confidence.toLowerCase() === 'unknown')
    ) {
      validEmails.push(value);
      return true;
    }
    return false;
  } catch (e) {
    Logger.error(`Validator -> isValidEmail: Error occurred when validating email.`, e);
    return false;
  }
}

/**
 * isDonationNumber. Min length 8, Max length 16
 * @param value the input value.
 */
async function isDonationNumber(value: any): Promise<boolean> {
  if (!value || value.length === 0) {
    return true;
  }
  return value.length <= 16;
}
/**
 * A validator for genotype related csv importing
 * @param value
 * @returns boolean
 */
async function isNotIncludeHashNAME(value: string): Promise<boolean> {
  if (!value || value.length === 0) {
    return true;
  }
  if (value.includes('#NAME?')) {
    return false;
  }
  return true;
}

/**
 * RHDGenotype length check
 * @param value
 * @returns
 */
async function isRHDGenotypeFormat(value: string): Promise<boolean> {
  if (!value || value.length === 0) {
    return true;
  }
  const columns = value.split('\t');
  return columns.length === 5;
}

/**
 * RHDGenotype mandatroy fields check
 * @param value
 * @returns
 */
async function isRHDGenotypeMandatoryFields(value: string): Promise<boolean> {
  if (!value || value.length === 0) {
    return true;
  }
  const columns = value.split('\t');
  return columns[0].length > 0 && columns[1].length > 0 && columns[4].length > 0;
}

/**
 * RHCEGenotype length check
 * @param value
 * @returns
 */
async function isRHCEGenotypeFormat(value: string): Promise<boolean> {
  if (!value || value.length === 0) {
    return true;
  }
  const columns = value.split('\t');
  return columns.length === 9;
}

/**
 * RHCEGenotype mandatroy fields check
 * @param value
 * @returns
 */
async function isRHCEGenotypeMandatoryFields(value: string): Promise<boolean> {
  if (!value || value.length === 0) {
    return true;
  }
  const columns = value.split('\t');
  return (
    columns[0].length > 0 &&
    columns[1].length > 0 &&
    columns[4].length > 0 &&
    columns[5].length > 0 &&
    columns[6].length > 0 &&
    columns[7].length > 0
  );
}

/**
 * HEA Genotype length check
 * @param value
 * @returns
 */
async function isHEAGenotypeFormat(value: string): Promise<boolean> {
  if (!value || value.length === 0) {
    return true;
  }
  const columns = value.split('\t');
  return columns.length === 40;
}

/**
 * isHospitalAndDonationNumberPresent. Donation number & Hospital Number should not be present in same test
 * @param hospitalNumber the input value for hospital number.
 * @param donationNumber the input value for donation number.
 */
export async function isHospitalAndDonationNumberPresent(hospitalNumber: any, donationNumber: any): Promise<boolean> {
  if (hospitalNumber && donationNumber) {
    return false;
  }
  return true;
}

/**
 * A utility validation function to check if one of the fields is filled on an object
 * @param object the object to check
 * @param fields an array of field names
 * @returns boolean
 */
export function checkOneFieldExistsOnObject(object: any, fields: string[]) {
  for (const field of fields) {
    if (object[field]) {
      if (Array.isArray(object[field])) {
        if (object[field].length > 0) {
          return true;
        }
      } else {
        return true;
      }
    }
  }
  return false;
}

/**
 * Sets of validations to apply to a screens fields.
 * The Validate function will find the correct array based on a string, then run the validation functions in order to check the field is valid.
 */
export const PersonValidators: { [s: string]: Validator | Validator[] } = {
  donorId: [
    {
      fn: isDonorNumber,
      errorMessage: Messages.ERROR_INVALID_DONOR_ID,
    },
  ],
  hospitalNumber: {
    fn: isCompleteHospitalNumber,
    errorMessage: Messages.ERROR_INVALID_HOSPITAL_NUMBER,
  },
  firstName: {
    fn: isFilled,
    errorMessage: Messages.ERROR_EMPTY_FIRST_NAME,
  },
  lastName: {
    fn: isFilled,
    errorMessage: Messages.ERROR_EMPTY_LAST_NAME,
  },
  dob: [
    {
      fn: isFilled,
      errorMessage: Messages.ERROR_EMPTY_DOB,
    },
    {
      fn: isDate,
      errorMessage: Messages.ERROR_DOB_FORMAT,
    },
    {
      fn: minDate,
      errorMessage: Messages.ERROR_DOB_FORMAT,
    },
    {
      fn: beforeFutureDate,
      errorMessage: Messages.ERROR_DOB_FUTURE,
    },
  ],
  email: [
    {
      fn: isCompleteEmail,
      errorMessage: Messages.ERROR_INCOMPLETE_EMAIL,
    },
    {
      fn: isValidEmail,
      errorMessage: Messages.ERROR_INVALID_EMAIL,
      inProgressMessage: Messages.MSG_VALIDATING_EMAIL,
    },
  ],
  mobile: [
    {
      fn: isCompleteMobile,
      errorMessage: Messages.ERROR_INCOMPLETE_MOBILE,
    },
    {
      fn: isValidMobile,
      errorMessage: Messages.ERROR_INVALID_MOBILE,
      inProgressMessage: Messages.MSG_VALIDATING_MOBILE,
    },
  ],
  rhdGenotype: [
    {
      fn: isNotIncludeHashNAME,
      errorMessage: Messages.ERROR_INVALID_GENOTYPE_HASH_NAME,
    },
    {
      fn: isRHDGenotypeFormat,
      errorMessage: Messages.ERROR_INVALID_GENOTYPE_LENGTH,
    },
    {
      fn: isRHDGenotypeMandatoryFields,
      errorMessage: Messages.ERROR_INVALID_RHD_GENOTYPE_MISSING_FIELDS,
    },
  ],
  rhceGenotype: [
    {
      fn: isNotIncludeHashNAME,
      errorMessage: Messages.ERROR_INVALID_GENOTYPE_HASH_NAME,
    },
    {
      fn: isRHCEGenotypeFormat,
      errorMessage: Messages.ERROR_INVALID_GENOTYPE_LENGTH,
    },
    {
      fn: isRHCEGenotypeMandatoryFields,
      errorMessage: Messages.ERROR_INVALID_RHCE_GENOTYPE_MISSING_FIELDS,
    },
  ],
  heaGenotype: [
    {
      fn: isNotIncludeHashNAME,
      errorMessage: Messages.ERROR_INVALID_GENOTYPE_HASH_NAME,
    },
    {
      fn: isHEAGenotypeFormat,
      errorMessage: Messages.ERROR_INVALID_GENOTYPE_LENGTH,
    },
  ],
};

// I did have a seperate version of this with an actual mandatory donorId
// however the screen should never have to actually check if it's filled in the normal validation flow
export const DonorValidators: { [s: string]: Validator | Validator[] } = {
  ...PersonValidators,
};

export const SearchValidators: { [s: string]: Validator | Validator[] } = {
  abrNumber: {
    fn: isNumber,
    errorMessage: Messages.ERROR_INVALID_ABR_ID,
  },
  donorId: {
    fn: isDonorNumber,
    errorMessage: Messages.ERROR_INVALID_DONOR_ID,
  },
  hospitalNumber: {
    fn: isCompleteHospitalNumber,
    errorMessage: Messages.ERROR_INVALID_HOSPITAL_NUMBER,
  },
  dob: [
    {
      fn: isDate,
      errorMessage: Messages.ERROR_DOB_FORMAT,
    },
    {
      fn: beforeFutureDateForSearch,
      errorMessage: Messages.ERROR_DOB_FUTURE,
    },
  ],
  mobile: [
    {
      fn: isCompleteMobile,
      errorMessage: Messages.ERROR_INCOMPLETE_MOBILE,
    },
  ],
  email: [
    {
      fn: isCompleteEmail,
      errorMessage: Messages.ERROR_INVALID_EMAIL_SEARCH,
    },
  ],
};

export const ReportValidators: { [s: string]: Validator | Validator[] } = {
  dateFrom: [
    {
      fn: isFilled,
      errorMessage: Messages.ERROR_EMPTY_DATE,
    },
    {
      fn: isDate,
      errorMessage: Messages.ERROR_DOB_FORMAT,
    },
    {
      fn: beforeFutureDateForSearch,
      errorMessage: Messages.ERROR_DATE_FUTURE,
    },
  ],
  dateTo: [
    {
      fn: isFilled,
      errorMessage: Messages.ERROR_EMPTY_DATE,
    },
    {
      fn: isDate,
      errorMessage: Messages.ERROR_DATE_FORMAT,
    },
    {
      fn: beforeFutureDateForSearch,
      errorMessage: Messages.ERROR_DATE_FUTURE,
    },
  ],
  consentStatus: [
    {
      fn: isFilled,
      errorMessage: Messages.ERROR_EMPTY_STATUS,
    },
  ],
};

export const AntibodyValidators: { [s: string]: Validator | Validator[] } = {
  sampleDate: [
    {
      fn: isFilled,
      errorMessage: Messages.ERROR_EMPTY_SAMPLE_DATE,
    },
    {
      fn: isDate,
      errorMessage: Messages.ERROR_DATE_FORMAT,
    },
    {
      fn: minDate,
      errorMessage: Messages.ERROR_DATE_FORMAT,
    },
    {
      fn: beforeFutureDate,
      errorMessage: Messages.ERROR_DATE_FUTURE,
    },
  ],
  antibodyReference: {
    fn: isFilled,
    errorMessage: `Antibody Reference: ${Messages.ERROR_EMPTY_VALUE}`,
  },
  quantity: {
    fn: isDecimal,
    errorMessage: `Quant field must be a decimal`,
  },
};

export const PhenotypeValidators: { [s: string]: Validator | Validator[] } = {
  sampleDate: [
    {
      fn: isFilled,
      errorMessage: Messages.ERROR_EMPTY_SAMPLE_DATE,
    },
    {
      fn: isDate,
      errorMessage: Messages.ERROR_DATE_FORMAT,
    },
    {
      fn: minDate,
      errorMessage: Messages.ERROR_DATE_FORMAT,
    },
    {
      fn: beforeFutureDate,
      errorMessage: Messages.ERROR_DATE_FUTURE,
    },
  ],
  donationNumber: [
    {
      fn: isDonationNumber,
      errorMessage: Messages.ERROR_DONATION_NUMBER,
    },
  ],
  testedByReference: [
    {
      fn: isFilled,
      errorMessage: `Tested By: ${Messages.ERROR_EMPTY_VALUE}`,
    },
  ],
};

export const StatusValidators: { [s: string]: Validator | Validator[] } = {
  date: [
    {
      fn: isFilled,
      errorMessage: Messages.ERROR_EMPTY_DATE,
    },
    {
      fn: isDate,
      errorMessage: Messages.ERROR_DATE_FORMAT,
    },
    {
      fn: minDate,
      errorMessage: Messages.ERROR_DATE_FORMAT,
    },
    {
      fn: beforeFutureDate,
      errorMessage: Messages.ERROR_DATE_FUTURE,
    },
  ],
  status: [
    {
      fn: isFilled,
      errorMessage: Messages.ERROR_EMPTY_VALUE,
    },
  ],
  notifiedByStatus: [
    {
      fn: isFilled,
      errorMessage: Messages.ERROR_EMPTY_VALUE,
    },
  ],
  ahpRefId: [
    {
      fn: isFilled,
      errorMessage: Messages.ERROR_EMPTY_VALUE,
    },
  ],
};

export const DonorTestSearchValidators: { [s: string]: Validator | Validator[] } = {
  date: [
    {
      fn: isFilled,
      errorMessage: Messages.ERROR_EMPTY_SAMPLE_DATE,
    },
    {
      fn: isDate,
      errorMessage: Messages.ERROR_DATE_FORMAT,
    },
    {
      fn: minDate,
      errorMessage: Messages.ERROR_DATE_FORMAT,
    },
    {
      fn: beforeFutureDate,
      errorMessage: Messages.ERROR_DATE_FUTURE,
    },
  ],
};

export interface ValidatedField {
  value: any;
  setValue: (value: any) => void;
  validation: ValidationState;
  setValidation: (value: ValidationState) => void;
  inProgressMessage: string;
  setInProgressMessage: (value: string) => void;
  errorMessage: string;
  setErrorMessage: (value: string) => void;
  clear: () => void;
  inputRef: React.MutableRefObject<any>;
  name?: string;
}

/**
 * Hook for a validated field. A validate field constants the field value and current state of validation
 * @param initialValue The initial value of the field
 * @param fieldName the string to find the field validation in the validation object
 * @returns a hook containing: value, setValue, validation, setValidation, inProgressMessage, setInProgressMessage, errorMessage, setErrorMessage, clear, inputRef, name
 */
export function useValidatedField<T>(initialValue: T = undefined, fieldName?: string): ValidatedField {
  // store the fields current value
  const [value, setValue] = useState<T>(initialValue);
  // store the fields current validation state
  const [validation, setValidation] = useState(ValidationState.NULL);
  // store any in progress message that should be displayed while asynchronous validation is running
  const [inProgressMessage, setInProgressMessage] = useState(null);
  // stores any error message that the field should be displaying
  const [errorMessage, setErrorMessage] = useState(null);
  // space to store a ref to the field's component
  const inputRef = useRef(null);
  // the name of the field that matches the field to what validators it should run in it's validation list.
  const name = fieldName;

  // function to reset the field to empty
  function clear() {
    setValue(initialValue);
    setValidation(ValidationState.NULL);
    setInProgressMessage(null);
    setErrorMessage(null);
  }

  return {
    value,
    setValue,
    validation,
    setValidation,
    inProgressMessage,
    setInProgressMessage,
    errorMessage,
    setErrorMessage,
    clear,
    inputRef,
    name,
  };
}

/**
 * Validate field against Validators. A field might has 0 or more validators. This method will use the validators
 * in the sequence that is defined. If any validator has failed to match, it will immediately set validation
 * state to fail. Remote validator always comes after local validator.
 * @param field the field name
 * @param value the value of the field
 */
export async function validate(field: ValidatedField, value: any, validatorList: any, row?: any): Promise<boolean> {
  Logger.debug(`${LOG_PREFIX} validate: Validating field ${field.name} against value ${value}`);
  const validators: Validator[] = _.concat(validatorList[field.name]);

  // Looping through the validator to execute
  for (const validator of validators) {
    if (validator) {
      // if a validator has an inProgressMessage
      if (validator.inProgressMessage) {
        field.setValidation(ValidationState.NULL);
        field.setInProgressMessage(validator.inProgressMessage);
        Logger.debug(`${LOG_PREFIX} validate: Validating field ${field} in progress`);
      }
      const result: boolean = await validator.fn(value, row);
      if (!result) {
        // if a result has failed, fail immediately
        if (validator.inProgressMessage) {
          field.setInProgressMessage(null);
        }
        if (validator.errorMessage) {
          field.setErrorMessage(validator.errorMessage);
        }
        field.setValidation(ValidationState.FAILED);
        Logger.debug(`${LOG_PREFIX} validate: Validating field ${field} failed`);
        return false;
      }
      if (validator.inProgressMessage) {
        field.setInProgressMessage(null);
      }
    }
  }

  // if all results are positive and we arrive here, we consider the field validation successful
  field.setValidation(value ? ValidationState.PASSED : ValidationState.NULL);
  Logger.debug(`${LOG_PREFIX} validate: Validating field ${field} passed`);
  return true;
}

export const validationErrorMessages = {
  firstName: Messages.ERROR_EMPTY_FIRST_NAME,
  middleName: Messages.ERROR_INVALID_DATA,
  lastName: Messages.ERROR_EMPTY_LAST_NAME,
  lastNames: Messages.ERROR_EMPTY_LAST_NAME,
  previousName1: Messages.ERROR_INVALID_DATA,
  previousName2: Messages.ERROR_INVALID_DATA,
  previousName3: Messages.ERROR_INVALID_DATA,
  previousLastName: Messages.ERROR_INVALID_DATA,
  dob: Messages.ERROR_EMPTY_DOB,
  gender: Messages.ERROR_EMPTY_GENDER,
  email: Messages.ERROR_INVALID_EMAIL,
  mobile: Messages.ERROR_INVALID_MOBILE,
  address: Messages.ERROR_INVALID_DATA,
  hospitalNumber: Messages.ERROR_INVALID_DATA,
  donorID: Messages.ERROR_INVALID_DATA,
  bloodGroup: Messages.ERROR_INVALID_DATA,
};
