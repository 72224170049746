import React from 'react';
import { Text } from '../text/Text';
import Colors from '../../constants/Colors';
import { ValidationState } from '../../constants/Interfaces';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import './Input.scss';

interface State {}

interface Props {
  children?: any;
  textChildren?: any;
  className?: string;
  disabled?: boolean;
  labelStyle?: object;
  labelContainerStyle?: object;
  style?: object;
  label: string;
  required?: boolean;
  validationState?: ValidationState;
  errorMessage?: string;
  inProgressMessage?: string;
  instructionMessage?: string;
  icon?: any;
  shouldShowLabel?: boolean;
  shouldShowIcon?: boolean;
  onBlur?: () => void;
}

export class Input extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.onParentBlur = this.onParentBlur.bind(this);
  }

  onParentBlur(event) {
    const { onBlur } = this.props;
    if (!onBlur) {
      return;
    }
    // check if the next target is within this input, if so don't actually fire the event
    if (!event.currentTarget.contains(event.relatedTarget)) {
      onBlur();
    }
  }

  render() {
    const {
      disabled = false,
      children,
      textChildren,
      className,
      label,
      labelStyle,
      labelContainerStyle,
      shouldShowIcon = true,
      shouldShowLabel = true,
      icon,
      style,
      required,
      validationState = ValidationState.NULL,
      errorMessage,
      inProgressMessage,
      instructionMessage,
    } = this.props;

    const borderClass = () => {
      if (validationState === ValidationState.FAILED) return 'validation-failed';
      // if (validationState === ValidationState.PASSED) return { borderColor: Colors.green, borderStyle: 'solid' };
      if (disabled) return 'disabled';
      return 'validation-null';
    };

    const labelColor = () => {
      if (validationState === ValidationState.FAILED) return Colors.red;
      return Colors.darkStone;
    };

    const rightIcon = () => {
      let iconColor = Colors.black;
      if (validationState === ValidationState.FAILED) {
        iconColor = Colors.red;
      }
      if (validationState === ValidationState.PASSED) {
        iconColor = Colors.green;
      }

      // if icon is specified, use that but override the icon color
      // TODO: does this work
      if (icon) {
        return {
          size: 20,
          ...icon,
          color: iconColor,
        };
      }

      if (validationState === ValidationState.PASSED) {
        return <AiOutlineCheckCircle color={Colors.green} size={20} />;
      }
      if (validationState === ValidationState.FAILED) {
        return <AiOutlineCloseCircle color={Colors.red} size={20} />;
      }

      // default no icon
      return null;
    };

    const helpText = () => {
      if (instructionMessage && !inProgressMessage && validationState !== ValidationState.FAILED) {
        return (
          <Text className="instruction-text" style={{ ...styles.instructionMessage }}>
            {instructionMessage}
            {textChildren}
          </Text>
        );
      }
      if (inProgressMessage && validationState === ValidationState.NULL) {
        return (
          <Text className="instruction-text" style={{ ...styles.instructionMessage }}>
            {inProgressMessage}
            {textChildren}
          </Text>
        );
      }
      if (errorMessage && validationState === ValidationState.FAILED) {
        return (
          <Text className="instruction-text" style={{ ...styles.errorMessage }}>
            {errorMessage}
            {textChildren}
          </Text>
        );
      }
      if (textChildren) {
        return (
          // the margin is to counter the jiggle when this changes to one of the above returns
          <div className="instruction-text" style={{ marginTop: 1, marginBottom: 3 }}>
            {textChildren}
          </div>
        );
      }
      return null;
    };

    return (
      <div className={`input-control ${className}`} style={{ ...styles.container, ...style }}>
        <div style={{ flexDirection: 'row' }}>
          {shouldShowLabel && (
            <div className="label-container" style={labelContainerStyle}>
              <Text className="label" style={{ ...styles.labelText, ...labelStyle, ...{ color: labelColor() } }}>
                {label}
              </Text>
              {required ? <Text style={{ ...styles.requiredAsterisks, ...{ color: labelColor() } }}>*</Text> : null}
            </div>
          )}

          <div className={`container ${borderClass()}`} style={{ ...style }} onBlur={this.onParentBlur}>
            {children}
          </div>
          {shouldShowIcon && <div className="icon">{rightIcon()}</div>}
        </div>

        {helpText()}
      </div>
    );
  }
}

const styles = {
  container: {
    opacity: 1,
    width: '100%',
    flexDirection: 'column' as 'column',
  },
  labelText: {},
  requiredAsterisks: {
    // fontSize: Fonts.sizes.large,
  },
  instructionMessage: {
    marginTop: 4,
    lineHeight: '14px',
    color: Colors.darkStone,
    flexDirection: 'row' as 'row',
  },
  errorMessage: {
    marginTop: 4,
    lineHeight: '14px',
    color: Colors.red,
    flexDirection: 'row' as 'row',
  },
};
