import React, { CSSProperties } from 'react';
import { ButtonPrimary } from '..';
import './ContainerCard.scss';

interface Props {
  title?: string;
  titleStyle?: CSSProperties;
  buttonTitle?: string;
  buttonDisabled?: boolean;
  buttonOnClick?: () => void;
  className?: string;
  style?: CSSProperties;
  hidden?: boolean;
  onContainerClick?: () => void;
  onTitleClick?: () => void;
  children?: any;
}

export function ContainerCard(props: Props) {
  const {
    style,
    title,
    hidden,
    children,
    buttonOnClick,
    buttonDisabled = false,
    buttonTitle,
    titleStyle,
    className,
    onContainerClick,
    onTitleClick,
  } = props;
  const additionalClasses = className ? ` ${className}` : '';
  return (
    <div
      className={`containerCard${additionalClasses}`}
      style={{
        ...style,
        ...{ display: !hidden ? 'flex' : 'none' },
      }}
      onClick={onContainerClick}
    >
      <div style={{ flexDirection: 'row', ...titleStyle }} onClick={onTitleClick}>
        <div className="title" style={{ ...titleStyle }}>
          {title}
        </div>
        {buttonTitle && buttonOnClick && (
          <ButtonPrimary
            title={buttonTitle}
            disabled={buttonDisabled}
            onClick={buttonOnClick}
            containerStyle={{ marginLeft: 'auto' }}
          />
        )}
      </div>
      {children}
    </div>
  );
}
