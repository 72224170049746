import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory, useLocation } from 'react-router-dom';
import { SpinnerScreen } from '../components';
import CrossTabMessage from '../constants/CrossTabMessage';
import Logger from '../constants/Logger';

export default function RedirectScreen() {
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  const location = useLocation();
  const messager = CrossTabMessage.getInstance();
  const oktaDomain = process.env.REACT_APP_OKTA_DOMAIN;
  const redirectType = location.search.substring(1, 3);
  const token = location.search.substring(4);

  useEffect(() => {
    async function closeSession() {
      try {
        if (await oktaAuth.session.exists()) await oktaAuth.session.close();
        await oktaAuth.signOut();
        messager?.postMessage('logout');
        const url = redirectType === 'at' ? `${oktaDomain}/?at=${token}` : `${oktaDomain}/?rt=${token}`;
        window.location.href = url;
      } catch (e: any) {
        Logger.error(`error when closing session: ${e.message}`);
        history.push('/');
      }
    }
    if (oktaAuth && history && location) closeSession();
  }, [oktaAuth, history, oktaDomain, redirectType, token, location, messager]);

  const message = redirectType === 'at' ? 'Redirecting to activation link' : 'Redirecting to password reset link';

  return <SpinnerScreen message={message} />;
}
