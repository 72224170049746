import React, { useState } from 'react';
import './PersonBar.scss';
import { ButtonLink, ButtonPrimary, Confirm, DateInput, Spinner, TextInput } from '../../../components';
import { AntibodyTestResult, GenericErrorCode, Person, ValidationState } from '../../../constants/Interfaces';
import { Messages, PersonFieldLabels } from '../../../constants/Messages';
import { DonorTestSearchValidators, useValidatedField, validate } from '../../../constants/Validators';
import API from '../../../constants/API';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { useEventListener } from '../../../constants/Utils';

interface Props {
  person: Person;
  onAddDonorAntibody: (test: AntibodyTestResult) => void;
  isOpen: boolean;
  setIsOpen: any;
}

export default function AddDonorTestPopup(props: Props) {
  const { person, onAddDonorAntibody, isOpen, setIsOpen } = props;

  const donorAntibodyTestSearchDate = useValidatedField(null, 'date');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEventListener('keydown', onKeyDown);
  function close() {
    setSearchResults([]);
    setIsOpen(false);
    donorAntibodyTestSearchDate.setValue('');
    donorAntibodyTestSearchDate.setValidation(ValidationState.NULL);
  }

  async function onKeyDown(event) {
    if (isOpen) {
      if (event && event.key === 'Enter') {
        if (await onDateBlur()) {
          search();
        }
      }
      if (event && event.key === 'Escape') {
        close();
      }
    }
  }

  const select = (id) => async () => {
    // do api search
    setIsLoading(true);
    const api = API.getInstance();
    try {
      const result = await api.get(`donation/${id}/`);
      onAddDonorAntibody(result);
      close();
    } catch (e: any) {
      if (e?.response?.status === GenericErrorCode.NOT_FOUND) {
        toast('No Test Result found for requested Donation Number');
      } else if (e?.response?.status === GenericErrorCode.PERMISSION_ERROR) {
        toast(Messages.ERROR_403_API);
      } else {
        toast(Messages.ERROR_GENERIC_API);
      }
    } finally {
      setIsLoading(false);
    }
  };

  async function search() {
    // send person.donorId and donorAntibodyTestSearchDate to api
    // do api search
    setIsLoading(true);
    const api = API.getInstance();
    try {
      const result = await api.get(`donor/${person.donorId}/donations`, {
        sampleDate: moment(donorAntibodyTestSearchDate.value).format('YYYY-MM-DD'),
      });
      setSearchResults(result.records);
    } catch (e: any) {
      setSearchResults([]);
      if (e?.response?.status === GenericErrorCode.NOT_FOUND) {
        toast('No Collection found for requested Donor Number');
      } else if (e?.response?.status === GenericErrorCode.PERMISSION_ERROR) {
        toast(Messages.ERROR_403_API);
      } else {
        toast(Messages.ERROR_GENERIC_API);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const onDateChange = (e) => {
    donorAntibodyTestSearchDate.setValue(e);
    setSearchResults([]);
    if (e && e.length === 10) {
      validate(donorAntibodyTestSearchDate, e, DonorTestSearchValidators);
    } else {
      donorAntibodyTestSearchDate.setValidation(ValidationState.NULL);
    }
  };

  const onDateBlur = () =>
    validate(donorAntibodyTestSearchDate, donorAntibodyTestSearchDate.value, DonorTestSearchValidators);

  // controls Cancel button visibilty
  const handleDecline = isLoading ? null : close;

  // TODO: Collection Date validation

  return (
    <Confirm isOpen={isOpen} title="Add Donor Result" declineTitle="Cancel" handleDecline={handleDecline} maxWidth="md">
      {isLoading ? (
        <Spinner style={{ height: 45 }} />
      ) : (
        <>
          <div style={{ flexDirection: 'row' }}>
            <TextInput
              label={PersonFieldLabels.DONOR_ID}
              onValueChange={() => {}}
              value={person.donorId}
              maxLength={60}
              labelContainerStyle={{ minWidth: 80 }}
              disabled
            />
            <DateInput
              label="Collection Date"
              required
              onValueChange={onDateChange}
              value={donorAntibodyTestSearchDate.value}
              validationState={donorAntibodyTestSearchDate.validation}
              errorMessage={donorAntibodyTestSearchDate.errorMessage}
              onBlur={onDateBlur}
              labelContainerStyle={{ minWidth: 135 }}
              autoFocus
            />
            <ButtonPrimary
              title="Search"
              onClick={search}
              disabled={donorAntibodyTestSearchDate.validation !== ValidationState.PASSED}
              containerStyle={{ paddingRight: 0 }}
            />
          </div>
          {searchResults.length > 0 && (
            <div style={{ alignItems: 'center' }}>
              <table style={{ width: 300 }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'left' }}>Donation number</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults.map((item, i) => (
                    <tr key={`donor-donation-${i}`}>
                      <td>{item}</td>
                      <td>
                        <ButtonLink onClick={select(item)} title="Select" style={{ marginRight: 0, marginTop: 0 }} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </Confirm>
  );
}
