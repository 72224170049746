import { useIdleTimer } from 'react-idle-timer';
import Logger from './Logger';
import NavigationControl from './NavigationControl';
import { getUsernameFromOkta, LogoutUser } from './Utils';

// in millis
// export const idleTimeout = 1000 * 60 * 15
const minutes: number = parseInt(process.env.REACT_APP_IDLE_TIMEOUT, 10) || 30;
const idleTimeout = 1000 * 60 * minutes;

export function useIdleTimerWrapper() {
  /**
   * Idle timer config
   */
  const handleOnIdle = (event) => {
    Logger.info(`user is idle ${JSON.stringify(event)}`);
    Logger.info(`last active ${IdleTimer.getLastActiveTime()}`);
    if (getUsernameFromOkta()) {
      const nav = NavigationControl.getInstance();
      nav.forceAllowNavigation = true;
      LogoutUser();
      nav.forceAllowNavigation = false;
    }
  };

  const handleOnActive = (event) => {
    Logger.info(`user is active ${JSON.stringify(event)}`);
    Logger.info(`time remaining ${IdleTimer.getRemainingTime()}`);
  };

  const handleOnAction = (event) => {
    Logger.debug(`user did something ${JSON.stringify(event)}`);
  };

  const IdleTimer = useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: true,
    },
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      // 'visibilitychange'
    ],
  });

  return IdleTimer;
}
