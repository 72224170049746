import React from 'react';
import { Text } from './Text';
import './DetailText.scss';

interface Props {
  testID?: string;
  accessibilityLabel?: string;
  style?: object;
  className?: string;
  valueClassName?: string;
  onClick?: () => void;
  detail: string;
  label: string;
  labelStyle?: object;
}

interface State {}

export class DetailText extends React.PureComponent<Props, State> {
  render() {
    const { style, onClick, className = '', detail, valueClassName = '', label, labelStyle } = this.props;
    return (
      <div className="detail-text" style={style} onClick={onClick}>
        <Text className={`label subheading ${className}`} style={{ ...labelStyle }}>
          {label}
        </Text>
        <Text className={`printNoScroll value ${valueClassName}`}>{`${detail || ''}`}</Text>
      </div>
    );
  }
}
