import React, { CSSProperties } from 'react';
import { PersonStatus, PersonStatusLabel } from '../../constants/Interfaces';
import { Text } from '../../components';
import './StatusLabel.scss';
import { UI } from '../../constants/Messages';

interface Props {
  status: PersonStatus;
  label?: string;
  style?: CSSProperties;
  className?: string;
}

export default function StatusLabel(props: Props) {
  const label = props.label || UI.STATUS_LABEL_DEFAULT;
  const className = props.className || 'label';
  return (
    <Text className={`${className} status-label`} style={{ ...{ marginLeft: 15 }, ...props.style }}>
      {label}
      <span className={props.status}>{`${PersonStatusLabel.get(props.status)}`} </span>
    </Text>
  );
}
